export default () => ({
  divider: {
    marginTop: '15px',
    marginBottom: '15px'
  },
  root: {
    flexGrow: 1,
    padding: '30px'
  },
  anchorStyle: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue'
  },
  textField: {
    width: '90%'
  },
  addButton: {
    padding: '5px 40px',
    marginTop: '15px'
  },
  approvalButton: {
    padding: '5px 10px',
    marginLeft: '10px'
  },
  modalHeading: {
    marginRight: '15px',
    fontSize: '15px'
  },
  item: {
    marginBottom: '20px'
  }
})
