import React, { useState, useEffect } from 'react';
import {
  Table,
  Select,
  Button,
  message,
  Drawer,
  Form,
  Input,
  Switch,
  Space,
  Modal,
} from 'antd';
import axiosInstance from 'config/axios';
import endpoints from 'v2/config/endpoints';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import EditDrawer from './editSportsConfig';

const { Option } = Select;

const SportsReportCardTable = () => {
  const selectedAcademicYear = useSelector(
    (state) => state.commonFilterReducer?.selectedYear
  );
  const history = useHistory();
  const [data, setData] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [gradesList, setGradesList] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [grade, setGrade] = useState(null);
  const [termId, setTermId] = useState('1');
  const [loading, setLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingData, setEditingData] = useState(null);
  const [editingType, setEditingType] = useState('');
  const [existingData, setExistingData] = useState([]);

  useEffect(() => {
    fetchBranches({ session_year: selectedAcademicYear?.id });
  }, [selectedAcademicYear]);

  useEffect(() => {
    if (selectedBranch && grade && termId) {
      fetchData();
    }
  }, [selectedBranch, grade, termId]);

  const fetchBranches = async (params = {}) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`${endpoints.academics.branches}`, {
        params: params,
      });
      setBranchList(response.data?.data?.results || []);
    } catch (error) {
      console.error('Error fetching branches:', error);
      message.error('Failed to fetch branches');
    }
    setLoading(false);
  };

  const fetchGrades = async (params = {}) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`${endpoints.academics.grades}`, {
        params: params,
      });
      setGradesList(response.data?.data || []);
    } catch (error) {
      console.error('Error fetching grades:', error);
      message.error('Failed to fetch grades');
    }
    setLoading(false);
  };

  const handleBranchChange = (value, key) => {
    setSelectedBranch(value);
    setGrade(null);
    fetchGrades({
      session_year: selectedAcademicYear?.id,
      branch_id: key?.key,
    });
  };

  const handleEdit = (record, type) => {
    let editingData = { ...record };
    let existingData;

    switch (type) {
      case 'sport':
        existingData = data;
        break;
      case 'competition':
        editingData = { ...record, subject_id: record.subject_id };
        existingData = data.flatMap((sport) =>
          sport.competition.map((comp) => ({ ...comp, subject_id: sport.id }))
        );
        break;
      case 'param':
        editingData = { ...record, subject_id: record.subject_id };
        existingData =
          data
            .find((item) => item.id === record.subject_id)
            ?.sports_params.map((param) => ({
              ...param,
              subject_id: record.subject_id,
            })) || [];
        break;
      default:
        existingData = [];
    }

    setEditingData(editingData);
    setEditingType(type);
    setExistingData(existingData);
    setDrawerVisible(true);
  };

  const handleDelete = async (id, type) => {
    try {
      const params = {};
      params[`${type}_update`] = id;

      const response = await axiosInstance.delete(`${endpoints.assessmentReportConfig.getSportsTemplate}`, {
        params,
      });
      if (response.status === 200) {
        message.success('Deleted successfully');
        fetchData();
      }
    } catch (error) {
      message.error('Failed to delete the item');
      console.error('Error deleting item:', error);
    }
  };

  const showDeleteConfirm = (id, type) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this item?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDelete(id, type);
      },
    });
  };

  const handleSubmit = async (values, type) => {
    try {
      let updateData;
      switch (type) {
        case 'sport':
          updateData = { subject_update: [values] };
          break;
        case 'competition':
          updateData = {
            levels_update: [{ ...values, subject_id: editingData.subject_id }],
          };
          break;
        case 'param':
          updateData = {
            params_update: [{ ...values, subject_id: editingData.subject_id }],
          };
          break;
        default:
          throw new Error('Invalid update type');
      }

      const response = await axiosInstance.put(
        `${endpoints.assessmentReportConfig.getSportsTemplate}?acad_session=${selectedBranch}&grade=${grade}&term_id=${termId}`,
        updateData
      );
      if (response.status === 200) {
        message.success('Updated successfully');
        setDrawerVisible(false);
        fetchData();
      }
    } catch (error) {
      console.error('Error updating item:', error);
      message.error('Failed to update');
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${endpoints.assessmentReportConfig.getSportsTemplate}?acad_session=${selectedBranch}&grade=${grade}&term_id=${termId}`
      );
      setData(response.data.result);
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('Failed to fetch data');
    }
    setLoading(false);
  };

  const columns = [
    {
      title: <span className='th-white th-fw-700'>Sport Name</span>,
      dataIndex: 'subject_name',
      key: 'subject_name',
    },
    {
      title: <span className='th-white th-fw-700'>Term Name</span>,
      dataIndex: 'term_name',
      key: 'term_name',
    },
    {
      title: <span className='th-white th-fw-700'>Sequence</span>,
      dataIndex: 'sequence',
      key: 'sequence',
    },
    {
      title: <span className='th-white th-fw-700'>Action</span>,
      key: 'action',
      render: (_, record) => (
        <>
          <EditOutlined
            style={{
              fontSize: 20,
              cursor: 'pointer',
              color: '#1B4CCB',
              marginRight: 10,
            }}
            onClick={() => handleEdit(record, 'sport')}
          />
          <DeleteOutlined
            style={{
              fontSize: 20,
              cursor: 'pointer',
              color: '#ff4d4f',
            }}
            onClick={() => showDeleteConfirm(record.id, 'subject')}
          />
        </>
      ),
    },
  ];

  const expandedRowRender = (record) => {
    const competitionColumns = [
      {
        title: <span className='th-white th-fw-700'>Level Name</span>,
        dataIndex: 'level_name',
        key: 'level_name',
      },
      {
        title: <span className='th-white th-fw-700'>Sequence</span>,
        dataIndex: 'sequence',
        key: 'sequence',
      },
      {
        title: <span className='th-white th-fw-700'>Action</span>,
        key: 'action',
        render: (_, competitionRecord) => (
          <>
            <EditOutlined
              style={{
                fontSize: 20,
                cursor: 'pointer',
                color: '#1B4CCB',
                marginRight: 10,
              }}
              onClick={() => handleEdit(competitionRecord, 'competition')}
            />
            <DeleteOutlined
              style={{
                fontSize: 20,
                cursor: 'pointer',
                color: '#ff4d4f',
              }}
              onClick={() => showDeleteConfirm(competitionRecord.id, 'levels')}
            />
          </>
        ),
      },
    ];

    const paramColumns = [
      {
        title: <span className='th-white th-fw-700'>Parameter</span>,
        dataIndex: 'param_name',
        key: 'param_name',
      },
      {
        title: <span className='th-white th-fw-700'>Sequence</span>,
        dataIndex: 'sequence',
        key: 'sequence',
      },
      {
        title: <span className='th-white th-fw-700'>Action</span>,
        key: 'action',
        render: (_, paramRecord) => (
          <>
            <EditOutlined
              style={{
                fontSize: 20,
                cursor: 'pointer',
                color: '#1B4CCB',
                marginRight: 10,
              }}
              onClick={() => handleEdit(paramRecord, 'param')}
            />
            <DeleteOutlined
              style={{ fontSize: 20, cursor: 'pointer', color: '#ff4d4f' }}
              onClick={() => showDeleteConfirm(paramRecord.id, 'params')}
            />
          </>
        ),
      },
    ];

    return (
      <div>
        <h4 className='th-16'>Competition Levels</h4>
        <Table
          columns={competitionColumns}
          dataSource={record.competition}
          pagination={false}
        />
        <h4 className='th-16'>Sports Parameters</h4>
        <Table
          columns={paramColumns}
          dataSource={record.sports_params}
          pagination={false}
        />
      </div>
    );
  };

  return (
    <div className='px-3'>
      <div className='row'>
        <div className='col-md-3'>
          <Select
            className='w-100'
            value={selectedBranch}
            onChange={handleBranchChange}
            style={{ marginRight: 16 }}
            placeholder='Select Branch'
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            optionFilterProp='children'
          >
            {branchList.map((branch) => (
              <Option key={branch.branch.id} value={branch.id}>
                {branch.branch?.branch_name}
              </Option>
            ))}
          </Select>
        </div>
        <div className='col-md-3'>
          <Select
            className='w-100'
            value={grade}
            onChange={(value) => setGrade(value)}
            style={{ marginRight: 16 }}
            placeholder='Select Grade'
            disabled={!selectedBranch}
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            optionFilterProp='children'
          >
            {gradesList.map((grade) => (
              <Option key={grade.grade_id} value={grade.grade_id}>
                {grade.grade_name}
              </Option>
            ))}
          </Select>
        </div>
        <div className='col-md-3'>
          <Select
            className='w-100'
            value={termId}
            onChange={(value) => setTermId(value)}
            style={{ marginRight: 16 }}
            placeholder='Select Term'
          >
            <Option value='1'>Semester 1</Option>
            <Option value='2'>Semester 2</Option>
            <Option value='3'>Semester 3</Option>
            <Option value='4'>Semester 4</Option>
          </Select>
        </div>
        <div className='col-md-3 text-right'>
          <Button
            type='primary'
            onClick={() => history.push('/report-config/create-sports-template')}
          >
            Create Template
          </Button>
        </div>
      </div>
      <div className='col-md-12 mt-3'>
        <Table
          columns={columns}
          expandable={{
            expandedRowRender: expandedRowRender,
            rowExpandable: (record) =>
              record.competition?.length > 0 || record.sports_params?.length > 0,
          }}
          dataSource={data}
          rowKey='id'
          loading={loading}
        />
      </div>
      <EditDrawer
        visible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        onSubmit={handleSubmit}
        data={editingData}
        type={editingType}
        existingData={existingData || []}
      />
    </div>
  );
};

export default SportsReportCardTable;
