import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  Button,
  Typography,
  SvgIcon,
  Dialog,
  Slide,
} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Tooltip from '@material-ui/core/Tooltip';
import { withRouter } from 'react-router-dom';
import endpoints from '../../config/endpoints';
import axiosInstance from '../../config/axios';
import EbookPdf from './EbookPDF';

import unfiltered from '../../assets/images/unfiltered.svg';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginLeft: -30,
  },
  card: {
    textAlign: 'center',
    margin: theme.spacing(1),
    backgroundPosition: 'center',
    backgroundSize: 'auto',
  },
  textEffect: {
    overflow: 'hidden',
    display: '-webkit-box',
    maxWidth: '100%',
    '-webkit-line-clamp': '3',
    '-webkit-box-orient': 'vertical',
    textOverflow: 'ellipsis',
    margin: '0%',
    padding: '0%',
    height: '65px !important',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
}));
function Transition(props) {
  return <Slide direction='up' {...props} />;
}

function GridList(props) {
  const classes = useStyles();
  const { data, totalEbooks } = props;
  const [loading, setLoading] = useState(false);
  const [timeSpent, setTimeSpent] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [open, setOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [selectedItem, setSelectedItem] = useState('');

  const handleClickOpen = (data) => {
    setSelectedItem(data);
    const ebookName = data.ebook_name;
    const necrtUrl = data.ebook_link;
    const url = data.ebook_file_type;
    if (ebookName && ebookName.includes('NCERT')) {
      window.open(necrtUrl);
    } else {
      const { host } = new URL(axiosInstance.defaults.baseURL)
      const hostSplitArray = host.split('.')
      const subDomainLevels = hostSplitArray.length - 2
      let domain = ''
      let subDomain = ''
      let subSubDomain = ''
      if (hostSplitArray.length > 2) {
        domain = hostSplitArray.slice(hostSplitArray.length - 2).join('')
      }
      if (subDomainLevels === 2) {
        subSubDomain = hostSplitArray[0]
        subDomain = hostSplitArray[1]
      } else if (subDomainLevels === 1) {
        subDomain = hostSplitArray[0]
      }
      setPdfUrl(url && url);
      setLoading(true);
      setOpen(true);
      axiosInstance
        .get(`${endpoints.ebook.EbookUser}?ebook_id=${data.id}`
        )
        .then(({ data }) => {
          console.log(data);
          setLoading(false);
          setPageNumber(data.page_number);
          setTimeSpent(data.time_spent);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedItem('');
  };

  return (
    <div className={classes.root}>
      {data.length !== 0 ? (
        <Grid container spacing={2}>
          {data &&
            data.map((item) => (
              <Grid item md={4} xs={12} key={item.id}>
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <Card
                      style={{
                        width: '100%',
                        height: '160px',
                        borderRadius: 10,
                        padding: '5px',
                        backgroundColor: item?.ebook_type === '2' ? '#fefbe8' : '',
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item md={5} xs={6}>
                          <img
                            src={`${endpoints.centralBucket}/${item.ebook_thumbnail}`}
                            alt='crash'
                            width='100%'
                            height='150px'
                            style={{ borderRadius: '8px', border: '1px solid lightgray' }}
                          />
                        </Grid>
                        <Grid item md={7} xs={6} style={{ textAlign: 'left' }}>
                          <Grid container spacing={1}>
                            <Grid
                              item
                              md={12}
                              xs={12}
                              style={{
                                padding: '0px 10px',
                                margin: '0px',
                                textAlign: 'right',
                                display: 'none'
                              }}
                            >
                              <MoreHorizIcon
                                style={{
                                  fontSize: '25px',
                                  color: '#FF6B6B',
                                  fontWeight: 'bold',
                                }}
                              />
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <Typography
                                title={item && item.ebook_name}
                                className={classes.textEffect}
                                color="secondary"
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 'bold',
                                  marginTop: '5px',
                                  marginRight: '2px'
                                }}
                              >
                                {item && item.ebook_name}
                              </Typography>
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <Typography
                                color="secondary"
                                title={
                                  item &&
                                  item.updated_at &&
                                  new Date(item.updated_at).toLocaleDateString()
                                }
                                style={{ fontSize: '10px' }}
                              >
                                Publication on&nbsp;
                                {item &&
                                  item.updated_at &&
                                  new Date(item.updated_at).toLocaleDateString()}
                              </Typography>
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <Button
                                size='small'
                                color='primary'
                                variant='contained'
                                style={{
                                  width: '100px',
                                  height: '25px',
                                  fontSize: '15px',
                                  borderRadius: '6px',
                                  color: 'white',
                                }}
                                onClick={() => handleClickOpen(item)}
                              >
                                Read
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            ))}
        </Grid>
      ) : (
        ''
      )}
      {data.length === 0 || totalEbooks === 0 ? (
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <div className={classes.periodDataUnavailable}>
              <SvgIcon component={() => <img src={unfiltered} alt='crash' />} />
              {props?.filtered == true ?
                <Typography variant='h6' color='secondary'>
                  No Ebooks Available For The Selected Subject
                </Typography> :
                <Typography variant='h6' color='secondary'>
                  Please Select Filters
                </Typography>
              }
            </div>
          </Grid>
        </Grid>
      ) : (
        ''
      )}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        style={{ zIndex: '10000' }}
        TransitionComponent={Transition}
      >
        <Grid>
          <EbookPdf
            pageNumber={pageNumber}
            timeStore={timeSpent}
            id={selectedItem?.id}
            url={`${pdfUrl && pdfUrl}`}
            passLoad={loading}
            goBackFunction={handleClose}
            name={selectedItem?.ebook_name}
          />
        </Grid>
      </Dialog>
    </div>
  );
}

export default withRouter(GridList);
