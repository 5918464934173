import LetsEduvateLogo from './../../../assets/images/logo.png';
import ContactUsLogo from './../../../assets/images/contactUs.jpg';
import React from 'react';
import { Card, Typography, Space, Row, Col } from 'antd';

const { Title, Paragraph } = Typography;

const ContactUs = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f0f2f5',
      }}
    >
      <Card
        style={{
          width: 800,
          boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        }}
        className='th-br-20'
      >
        <Space direction='vertical' size='large' style={{ width: '100%' }}>
          <div style={{ textAlign: 'center' }}>
            <img src={LetsEduvateLogo} alt='Company Logo' style={{ height: 50 }} />
          </div>

          <Title level={3} style={{ textAlign: 'center' }} className='m-0'>
            How to Contact Us
          </Title>
          <Row gutter={[16, 16]} align='middle'>
            <Col md={12}><img src={ContactUsLogo} alt='Company Logo' style={{ height: 250 }} /></Col>
            <Col md={12}>
              <Paragraph className='text-justify'>
                If you have any questions or concerns, please don't hesitate to reach out
                to us. You can contact our Grievance Officer/Data Protection Officer
                through any of the following methods:
              </Paragraph>

              <Paragraph strong>
                Email: <a href='mailto:info@letseduvate.com'>info@letseduvate.com</a>
              </Paragraph>
            </Col>
          </Row>
        </Space>
      </Card>
    </div>
  );
};

export default ContactUs;
