import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Col, Input, Progress, Row } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
const ChangePassword = ({ changePasswordModalData, setChangePasswordModalData }) => {
  let userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const { erp: erpId, role_details } = userDetails;

  let passwordInstructions = [
    {
      isChecked: changePasswordModalData?.data?.validationCheck?.length ? true : false,
      instructions: 'New password must contain atleast 8 characters.',
    },
    {
      isChecked: changePasswordModalData?.data?.validationCheck?.hasUpperCase
        ? true
        : false,
      instructions: 'New password must contain atleast 1 uppercase.',
    },
    {
      isChecked: changePasswordModalData?.data?.validationCheck?.hasLowerCase
        ? true
        : false,
      instructions: 'New password must contain atleast 1 lowercase.',
    },
    {
      isChecked: changePasswordModalData?.data?.validationCheck?.hasDigit ? true : false,
      instructions: 'New password must contain atleast 1 number.',
    },
    {
      isChecked: changePasswordModalData?.data?.validationCheck?.hasSpecialChar
        ? true
        : false,
      instructions: 'New password must contain atleast 1 special character.',
    },
    {
      isChecked: changePasswordModalData?.data?.validationCheck?.sameAsErp ? true : false,
      instructions: 'New password should not be same as your ERP ID.',
    },
  ];

  const customFormat = (percent) => {
    if (percent > 0 && percent < 59) {
      return (
        <span className='text-center'>
          {parseInt(changePasswordModalData?.data?.passwordStrength).toFixed(0)} % <br />{' '}
          Weak
        </span>
      );
    } else if (percent >= 60 && percent < 99) {
      return (
        <span className='text-center'>
          {parseInt(changePasswordModalData?.data?.passwordStrength).toFixed(0)} % <br />{' '}
          Medium
        </span>
      );
    } else {
      return (
        <span className='text-center'>
          {parseInt(changePasswordModalData?.data?.passwordStrength).toFixed(0)} % <br />{' '}
          Strong
        </span>
      );
    }
  };

  const checkPasswordStrength = (value, keyName) => {
    if (keyName === 'newPassword') {
      const strengthChecks = {
        length: 0,
        hasUpperCase: false,
        hasLowerCase: false,
        hasDigit: false,
        hasSpecialChar: false,
        sameAsErp: false,
      };

      const sanitizedPassword = value.replace(/\s/g, '');

      strengthChecks.length = sanitizedPassword.length >= 8 ? true : false;
      strengthChecks.hasUpperCase = /[A-Z]+/.test(sanitizedPassword);
      strengthChecks.hasLowerCase = /[a-z]+/.test(sanitizedPassword);
      strengthChecks.hasDigit = /[0-9]+/.test(sanitizedPassword);
      strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(sanitizedPassword);
      strengthChecks.sameAsErp =
        sanitizedPassword.toString().toLowerCase() == erpId.toString().toLowerCase() ||
        !sanitizedPassword
          ? false
          : true;

      // setValidationCheck(strengthChecks);

      let verifiedList = Object.values(strengthChecks).filter((value) => value);

      setChangePasswordModalData({
        ...changePasswordModalData,
        data: {
          ...changePasswordModalData?.data,
          newPassword: sanitizedPassword,
          passwordStrength: `${(verifiedList.length / 6) * 100}`,
          validationCheck: { ...strengthChecks },
        },
      });
    } else if (keyName === 'confirmPassword') {
      setChangePasswordModalData({
        ...changePasswordModalData,
        data: {
          ...changePasswordModalData?.data,
          confirmPassword: value.replace(/\s/g, ''),
        },
      });
    }
  };
  console.log({ changePasswordModalData, passwordInstructions });
  return (
    <div className='row'>
      <div className='col-md-12'>
        <div className=' py-3 px-2 mb-3'>
          <div className='row flex-row-reverse'>
            <div className='col-md-6 col-12 mb-3'>
              <h6 className='mt-3'>Instructions:</h6>
              <ul style={{ listStyle: 'none' }}>
                {Array.isArray(passwordInstructions) &&
                  passwordInstructions.length > 0 &&
                  passwordInstructions.map((item, index) => (
                    <li className='pb-2' key={index}>
                      {item.isChecked ? (
                        <CheckCircleOutlined className='th-green mr-1' />
                      ) : (
                        <CloseCircleOutlined className='th-red mr-1' />
                      )}{' '}
                      {item.instructions}
                    </li>
                  ))}
                <li className='pb-2'>
                  {changePasswordModalData?.data?.newPassword?.length > 0 &&
                  changePasswordModalData?.data?.newPassword ===
                    changePasswordModalData?.data?.confirmPassword ? (
                    <CheckCircleOutlined className='th-green mr-1' />
                  ) : (
                    <CloseCircleOutlined className='th-red mr-1' />
                  )}
                  New password and confirm password should match
                </li>
              </ul>
            </div>
            <div className='col-md-6 col-12  mb-3'>
              <Row gutter={[8, 8]}>
                <Col md={24}>
                  <label htmlFor='new_password' className='' title='New Password*'>
                    New Password
                  </label>
                  <Input.Password
                    placeholder='Enter new password'
                    autocomplete='new-password'
                    value={changePasswordModalData?.data?.newPassword}
                    onChange={(e) => {
                      checkPasswordStrength(e.target.value, 'newPassword');
                    }}
                  />
                </Col>
                <Col md={24}>
                  <label htmlFor='new_password' className='' title='New Password*'>
                    Confirm Password
                  </label>
                  <Input.Password
                    autocomplete='new-password'
                    placeholder='Confirm new password'
                    value={changePasswordModalData?.data?.confirmPassword}
                    onChange={(e) => {
                      checkPasswordStrength(e.target.value, 'confirmPassword');
                    }}
                  />
                </Col>
                {parseInt(changePasswordModalData?.data?.passwordStrength) > 0 && (
                  <Col md={24}>
                    <Progress
                      percent={changePasswordModalData?.data?.passwordStrength}
                      strokeColor={
                        Object.values(
                          changePasswordModalData?.data?.validationCheck
                        ).filter((value) => value).length == 6
                          ? '#20c51c'
                          : Object.values(
                              changePasswordModalData?.data?.validationCheck
                            ).filter((value) => value).length >= 3
                          ? '#ff9922'
                          : '#f8222f'
                      }
                      format={customFormat}
                      style={{ width: '97%' }}
                    />
                  </Col>
                )}
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
