import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const FilterIcon = () => {
  return (
    <SvgIcon width='18.025' height='18.01' viewBox='0 0 18.025 18.01'>
      <g
        transform='translate(1729.336 -7072.049)'
        fill='none'
        fillRule='none'
        stroke='#042955'
      >
        <path d="M16.2,0H.8A.8.8,0,0,0,.234,1.36L6.376,7.5v6.842a.8.8,0,0,0,.34.653l2.656,1.859a.8.8,0,0,0,1.254-.653V7.5l6.141-6.14A.8.8,0,0,0,16.2,0Z" transform="translate(-1728.824 7072.549)" />
      </g>
    </SvgIcon>
  )
};

export default FilterIcon;
