export default (theme) => ({
  divider: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2
  },
  header: {
    fontWeight: 'lighter'
  },
  container: {
    padding: '5px'
  }
})
