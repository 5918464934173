import React, { useState, useContext } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Button, useTheme, IconButton, SvgIcon, Checkbox } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import useStyles from './useStyles';
import endpoints from '../../../../config/endpoints';
import axiosInstance from '../../../../config/axios';
import moment from 'moment';
import axios from 'axios';
import { AlertNotificationContext } from '../../../../context-api/alert-context/alert-state';
import { Drawer } from 'antd';
import ReactHtmlParser from 'react-html-parser';


const QuestionBankCard = ({
  period,
  setPeriodDataForView,
  setViewMoreData,
  setViewMore,
  setLoading,
  index,
  setSelectedIndex,
  setCallFlag,
  onClick,
  showAddToQuestionPaper,
  periodColor,
  toggleComplete,
  toggleCompleteQuestion,
  isSelectAll,
  redFlag,
  checkbox,
  periodData,
  questionId
}) => {
  const themeContext = useTheme();
  const { setAlert } = useContext(AlertNotificationContext);
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));
  const classes = useStyles();
  const [showMenu, setShowMenu] = useState(false);
  const [showPeriodIndex, setShowPeriodIndex] = useState();
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [questionName, setQuestionName] = useState(period.question_answer);
  const [isChecked,setIsChecked]= useState(false);
  const [selectedPublished, setSelectedPublished] = useState([]);

  const handlePeriodMenuOpen = (index, id) => {
    setShowMenu(true);
    setShowPeriodIndex(index);
  };

  const handlePeriodMenuClose = (index) => {
    setShowMenu(false);
    setShowPeriodIndex();
  };
  const handleViewMore = () => {
    setLoading(true);
    // axiosInstance
    //   .get(`${endpoints.questionBank.viewMoreData}?question=${period.id}`)
    if (period?.is_central) {
      axios
        .get(`${endpoints.questionBank.viewMoreData}?question=${period?.id}`, {
          headers: { 'x-api-key': 'vikash@12345#1231' },
        })
        .then((result) => {
          if (result?.data?.status_code === 200) {
            setLoading(false);
            setViewMore(true);
            setViewMoreData(result?.data?.result);
            // setState({editData:result.data.result})
            setPeriodDataForView(period);
            setSelectedIndex(index);
          } else {
            setLoading(false);
            setViewMore(false);
            setViewMoreData({});
            setPeriodDataForView([]);
            setAlert('error', result?.data?.message);
            setSelectedIndex(-1);
          }
        })
        .catch((error) => {
          setLoading(false);
          setViewMore(false);
          setViewMoreData({});
          setPeriodDataForView([]);
          setAlert('error', error?.message);
          setSelectedIndex(-1);
        });
    }
    if (!period.is_central) {
      axiosInstance
        .get(`${endpoints.questionBank.erpViewMoreData}?question=${period?.id}`)
        .then((result) => {
          if (result?.data?.status_code === 200) {
            setLoading(false);
            setViewMore(true);
            setViewMoreData(result?.data?.result);
            // setState({editData:result.data.result})
            setPeriodDataForView(period);
            setSelectedIndex(index);
          } else {
            setLoading(false);
            setViewMore(false);
            setViewMoreData({});
            setPeriodDataForView([]);
            setAlert('error', result?.data?.message);
            setSelectedIndex(-1);
          }
        })
        .catch((error) => {
          setLoading(false);
          setViewMore(false);
          setViewMoreData({});
          setPeriodDataForView([]);
          setAlert('error', error?.message);
          setSelectedIndex(-1);
        });
    }
  };


  const getquestionLevel = (type) => {
    switch (type) {
      case 1:
        return 'Easy';
      case 2:
        return 'Average';
      case 3:
        return 'Difficult';
      default :
        return '--'
    }
  };

  const questionType = (type) => {
    switch (type) {
      case 1:
        return 'MCQ Single Choice';
      case 2:
        return 'MCQ Multiple Choice';
      case 3:
        return 'Match the Following';
      case 4:
        return 'Video Question';
      case 5:
        return 'PPT Question';
      case 6:
        return 'Matrix Questions';
      case 7:
        return 'Comprehension Questions';
      case 8:
        return 'True False';
      case 9:
        return 'Fill In The Blanks';
      case 10:
        return 'Descriptive';
      default:
        return '--';
    }
  };
  function extractContent(s) {
    const span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }

  const extractContentOption = (s) => {
    if (s?.length > 0 && s.indexOf('<') > -1) {
      let newarr = s.replace(/</g, '&lt;');
      newarr = newarr.replace('&lt;p>', '');
      newarr = newarr.replace('&lt;/p>', '')
      console.log('extract', newarr)
      // newarr = newarr.replaceAll('&lt;br />',' ');
      newarr = newarr.split('&lt;br />').join(' ')
      const span = document.createElement('span');
      span.innerHTML = newarr;
      return span.textContent || span.innerText;
    } else {
      const span = document.createElement('span');
      span.innerHTML = s;
      return span.textContent || span.innerText;
    }
  }
  // const DiaClickOpen = () => {
  //   setdiaOpen(true);
  // };

  // const DiaClose = () => {
  //   setdiaOpen(false);
  // };
  const handleDelete = () => {
    setDeleteAlert(true);
  };
  const handleDeleteConfirm = (obj) => {
    axiosInstance
      .put(`${endpoints.questionBank.erpQuestionPublishing}`, {
        question: obj?.id,
        is_delete: true,
      })
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setCallFlag((prev) => !prev);
          setAlert('success', 'Question Deleted Successfully');
          setDeleteAlert(false);
        } else {
          setAlert('error', 'ERROR!');
        }
      })
      .catch((error) => {
        setAlert('error', error?.message);
      });
  };
  const handleDeleteCancel = () => {
    setDeleteAlert(false);
  };
  return (
    <Paper
      className={`${periodColor ? classes.selectedRoot : classes.root} ${period?.question_status === '2' ? classes.verifiedColor : classes.notverified}`}
      style={
        isMobile
          ? { margin: '0rem auto' }
          : {
              margin: '0rem auto -1.1rem auto',
              height: 300,
              // background: period?.question_status === '2' ? '#FCEEEE ' : '',
              // border: period?.question_status === '2' ? '1px solid red ' : '',
            }
        // ? { margin: '0rem auto', height: '165px' }
        // : { margin: '0rem auto -1.1rem auto', height: '165px' }
      }
    >
      <Grid container spacing={2}>
        <Grid item sm = {11} xs={8}>
          <Box style={{display:'flex', alignItems:'center'}}>
            {period?.question_status === '3' && !showAddToQuestionPaper ? 
              <Checkbox
              id={period?.id}
              checked={period?.checked}
              onChange={(e) => toggleComplete(e,period,index)} 
              name={period?.id}
              size="small"
             inputProps={{ 'aria-label': 'primary checkbox' }}
             />
          : ''}
          {showAddToQuestionPaper && period?.question_status === '2' ? 
              <Checkbox
              id={period?.id}
              checked={period?.checked}
              onChange={(e) => toggleCompleteQuestion(e,period,index)} 
              name={period?.id}
              size="small"
             inputProps={{ 'aria-label': 'primary checkbox' }}
             />
          : ''}
          <Box>
             <Typography
              className={classes.title}
              variant='p'
              component='p'
              color='primary'
            >
              {questionType(period?.question_type) }
            </Typography>
          </Box>
          
          <Grid md={2} xs={2} sm={2} style={{marginLeft:'1%'}}>
             <Typography
              // className={classes.title}
              variant='p'
              component='p'
              // color='primary'
            >
              ({getquestionLevel(parseInt(period?.question_level)) })
            </Typography>
          </Grid>
          </Box>
        </Grid>
        {!period.is_central && (
          <Grid item sm = {1} xs={4} className={classes.textRight}>
            <Box>
              <span
                className='period_card_menu'
                onClick={() => handlePeriodMenuOpen(index)}
                onMouseLeave={handlePeriodMenuClose}
              >
                <IconButton className='moreHorizIcon' color='primary'>
                  <MoreHorizIcon />
                </IconButton>
                {showPeriodIndex === index && showMenu ? (
                  <div className='tooltipContainer'>
                    <span className={` ${classes.tooltiptext} tooltiptext`}>
                      <div onClick={handleDelete}>Delete</div>
                      <Dialog open={deleteAlert} onClose={handleDeleteCancel}>
                        <DialogTitle id='draggable-dialog-title'>
                          Delete Question
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            Are you sure you want to delete ?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={handleDeleteCancel}
                            className='labelColor cancelButton'
                          >
                            Cancel
                          </Button>
                          <Button
                            color='primary'
                            variant='contained'
                            style={{ color: 'white' }}
                            onClick={(e) => handleDeleteConfirm(period)}
                          >
                            Confirm
                          </Button>
                        </DialogActions>
                      </Dialog>
                      {/* <Dialog
                        open={Diaopen}
                        onClose={DiaClose}
                        // style={{ backgroundColor: 'transparent', opacity: '0.4' }}
                        aria-labelledby='alert-dialog-title'
                        aria-describedby='alert-dialog-description'
                      >
                        <DialogTitle id='alert-dialog-title'>
                          {'Confirmation For Delete'}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id='alert-dialog-description'>
                            Are You Sure to Delete ??.
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={(e) => handleDelete(period)} color='primary'>
                            yes
                          </Button>
                          <Button onClick={DiaClose} color='primary' autoFocus>
                            No
                          </Button>
                        </DialogActions>
                      </Dialog> */}
                    </span>
                  </div>
                ) : null}
              </span>
            </Box>
          </Grid>
        )}
        <Grid item xs={12} sm={12} className='bank-card'>
        {period.question_type === 7 ? (
              <Typography
                className={classes.content}
                variant='p'
                component='p'
                color='secondary'
                noWrap
              >
                {extractContent(questionName[0]?.question).length > 70 ? extractContent(questionName[0]?.question).substring(0,70) + '...' : extractContent(questionName[0]?.question)}
                {/* {extractContent(questionName[0]?.question).length > 70 ? ReactHtmlParser(questionName[0]?.question).substring(0,70) + '...' : ReactHtmlParser(questionName[0]?.question)} */}
              </Typography>
          ) : (
              <Typography
                className={classes.content}
                variant='p'
                component='p'
                color='secondary'
                noWrap
              >
                {/* Question: {extractContentOption(questionName[0]?.question).length > 70 ? ReactHtmlParser(questionName[0]?.question).substring(0,70) + '...' : ReactHtmlParser(questionName[0]?.question)} */}
                Question: {ReactHtmlParser(questionName[0]?.question)}
              </Typography>
          )}
        </Grid>
        <Grid item sm = {8} xs={6} >
          <Box>
            <Typography
              className={classes.content}
              variant='p'
              component='p'
              color='secondary'
              noWrap
            >
              {period?.question_status === '1' ? 'Draft' : null}
              {period?.question_status === '2' ? 'Published' : null}
              {period?.question_status === '3' ? 'Review' : null}
            </Typography>
          </Box>
          <Box>
            <Typography 
            //  className={classes.title}
             variant='p'
             component='p'
             >
              Created_on : {moment(period?.created_at).format('L')}
            </Typography>
          </Box>
        </Grid>
        
      </Grid>
      <Grid container spacing={2} style={{marginTop: 10}}>
        <Grid item  sm = {periodColor ? 4 : 5} xs={12} >
          {!periodColor ? (
            <Button
              variant='contained'
              style={{ color: 'white' ,width: '100%' , borderRadius:'5px',height:'30px'}}
              color='primary'
              size='small'
              onClick={handleViewMore}
            >
              VIEW MORE
            </Button>
          ) : (
            <Button
              variant='contained'
              style={{ color: 'white', width: '72%', visibility: 'hidden',borderRadius:'5px',height:'30px' }}
              color='primary'
              size='small'
              onClick={handleViewMore}
            >
              VIEW MORE
            </Button>
          )}
          
        </Grid>
        <Grid item  sm = {periodColor ? 8 : 7} xs={12} >
          {showAddToQuestionPaper && period?.question_status === '2' ? (
              <Button
                variant='contained'
                style={{  color: 'white', width: '100%', fontSize: 14 }}
                color='primary'
                size='small'
                onClick={() => onClick(period)}
              >
                ADD TO QUESTION PAPER
              </Button>
            ) : (
              ''
            )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default QuestionBankCard;
