import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const LikeIcon = () => {
  return (
    <SvgIcon width="25.556" height="24.491" viewBox="0 0 25.556 24.491">
      <g transform="translate(0 10.648)" fill='#f9ab5d'>
        <path className="a" d="M2.662,224A2.666,2.666,0,0,0,0,226.662v8.518a2.666,2.666,0,0,0,2.662,2.662H5.856a2.643,2.643,0,0,0,1.6-.539V224Z" transform="translate(0 -224)" />
      </g>
      <g transform="translate(8.519)" fill='#f9ab5d'>
        <path className="a" d="M187.7,25.308a2.361,2.361,0,0,0-.693-1.676,2.644,2.644,0,0,0,.681-2.033,2.752,2.752,0,0,0-2.777-2.414h-6.577a15.31,15.31,0,0,0,.848-4.259c0-2.31-1.962-4.259-3.194-4.259a3.349,3.349,0,0,0-1.93.648.535.535,0,0,0-.2.416v3.611l-3.067,6.643-.128.065V33.463a6.976,6.976,0,0,0,2.662.629H183.1a2.449,2.449,0,0,0,2.413-1.86,2.4,2.4,0,0,0-.193-1.6,2.385,2.385,0,0,0,1.066-3.2A2.384,2.384,0,0,0,187.7,25.308Z" transform="translate(-170.667 -10.667)" />
      </g>
    </SvgIcon>
  );
};

export default LikeIcon;
