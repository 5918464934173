/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import { Grid, TextField, Divider, Button, Typography } from '@material-ui/core';
import './style.scss';
import { withRouter } from 'react-router-dom';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Pagination } from '@material-ui/lab';
import MediaQuery from 'react-responsive';
import Loader from '../../components/loader/loader';
import axiosInstance from '../../config/axios';
import endpoints from '../../config/endpoints';
import TotalStudentBar from './totalStudentStrengthBar';
import TotalStudentStrengthCard from './totalStrenghtCard';
import TotalStudentWiseDetails from './totalStudentWiseDetails';
import { AlertNotificationContext } from '../../context-api/alert-context/alert-state';
import filterImage from '../../assets/images/unfiltered.svg';
import Layout from '../Layout';
import { makeStyles } from '@material-ui/core';
import CommonBreadcrumbs from '../../components/common-breadcrumbs/breadcrumbs';
import { getModuleInfo } from '../../utility-functions';
import { useLocation } from 'react-router-dom';
import FileSaver from 'file-saver';

const useStyles = makeStyles((theme) => ({
  studentStrenghtDownloadButton: {
    width: '100%',
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.primary.main} !important`,
    backgroundColor: 'white !important',
  },
}));
const StudentStrength = ({ history }) => {
  const { token } = JSON.parse(localStorage.getItem('userDetails')) || {}
  const [acadminYearList, setAcadminYearList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState('');
  const { setAlert } = useContext(AlertNotificationContext);
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState('');
  const [selectedCard, setSelectedCard] = useState('');
  const [hRef, setHRef] = useState([]);
  const [academicYear, setAcademicYear] = useState([]);
  const [selectedAcademicYear, setSelectedAcadmeicYear] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const classes = useStyles();
  const location = useLocation();

  const handlePagination = (event, page) => {
    setPage(page);
    handleFilter(page);
  };

  // const moduleId = 290;
  function getModuleId() {
    const tempObj = {
      '/student-strength/': 'View School Strength',
      default: 'View School Strength',
    };
    const moduleName = tempObj[location.pathname] || tempObj['default'];
    return getModuleInfo(moduleName).id;
  }

  // const file = () => {    
  //   setLoading(true);
  //   axiosInstance
  //     .get(
  //       `${endpoints.studentListApis.downloadExcelAllstudents2}?academic_year_id=${
  //         selectedAcademicYear && selectedAcademicYear.id
  //       }&export_type=csv`
  //     )
  //     .then((result) => {
  //       if (result.status === 200) {
  //         // window.location.href = `/qbox${endpoints.studentListApis.downloadExcelAllstudents}?academic_year_id=${selectedAcademicYear && selectedAcademicYear.id
  //         // }&export_type=csv`
  //         // setDownloadFile(true)
  //         setLoading(false);
  //       }
  //     });
  // };

  const file = () => {
    setLoading(true);
    axiosInstance.get(`${endpoints.studentListApis.downloadExcelAllstudents2}?academic_year_id=${
      selectedAcademicYear && selectedAcademicYear.id
    }`,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType : 'arraybuffer'
        }) 
        .then((res) => {
        const blob = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        FileSaver.saveAs(blob, 'branch-all.xls');
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setAlert('error', 'Something Wrong!')
      });
  };

  const file2 = () => {
    console.log('orchids','file2')
    setLoading(true);
    axiosInstance.get(`${endpoints.studentListApis.downloadBranchWiseStudent2}?academic_year_id=${selectedAcademicYear && selectedAcademicYear.id}&branch_id=${selectedBranch?.branch?.id}`,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType : 'arraybuffer'
        }) 
        .then((res) => {
        const blob = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        FileSaver.saveAs(blob, 'branch.xls');
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setAlert('error', 'Something Wrong!')
      });
  };

  useEffect(() => {
    setHRef([
      {
        csv: `${endpoints.studentListApis.downloadExcelAllstudents}?academic_year_id=${
          selectedAcademicYear && selectedAcademicYear.id
        }&export_type=csv`,
      },
      {
        csv: `${endpoints.studentListApis.downloadBranchWiseStudent}?academic_year_id=${
          selectedAcademicYear && selectedAcademicYear.id
        }&branch_id=${selectedBranch?.branch?.id}
          &export_type=csv`,
      },
    ]);
    // console.log('The data', selectedBranch.branch?.id);
  }, [selectedAcademicYear, selectedBranch]);

  function callApi(api, key) {
    setLoading(true);
    axiosInstance
      .get(api)
      .then((result) => {
        if (result.status === 200) {
          if (key === 'academicYearList') {
            console.log(result?.data?.data || [], 'checking');
            setAcademicYear(result?.data?.data || []);
          }
          if (key === 'branchList') {
            console.log(result?.data?.data || [], 'checking');
            setBranchList(result?.data?.data?.results || []);
          }

          setLoading(false);
        } else {
          setAlert('error', result.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        setAlert('error', error.message);
        setLoading(false);
      });
  }
  useEffect(() => {
    callApi(`${endpoints.userManagement.academicYear}`, 'academicYearList');
  }, []);

  function handleClearFilter() {
    setSelectedAcadmeicYear('');
    setSelectedBranch('');
    setFilteredData('');
    setPage(1);
  }

  function handleFilter(pageNumber) {
    if (!selectedAcademicYear) {
      setAlert('error', 'Select Acadminc year');
      return;
    }
    if (!selectedBranch) {
      setAlert('error', 'Select Branch');
      return;
    }
    setLoading(true);
    console.log(selectedBranch, 'branch');
    axiosInstance
      .get(
        `${endpoints.studentListApis.branchWiseStudentCount}?academic_year_id=${
          selectedAcademicYear && selectedAcademicYear.id
        }&branch_id=${selectedBranch && selectedBranch.branch.id}&page_number=${
          pageNumber || 1
        }&page_size=${15}`
      )
      .then((result) => {
        setLoading(false);
        if (result.data.status_code === 200) {
          setTotalPages(result.data.total_pages);
          console.log(result.data.total_pages);
          setFilteredData(result.data.data);
        } else {
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        setAlert('error', error.message);
      });
  }

  return (
    <Layout>
      <CommonBreadcrumbs componentName='School Strength' />
      <div style={{ width: '100%', overflow: 'hidden' }}>
        <Grid container spacing={2} className='studentStrenghtBody'>
          <Grid item md={12} xs={12} className='studentStrengthFilterDiv'>
            <Grid container spacing={2}>
              <Grid item md={4} xs={12}>
                <Autocomplete
                  style={{ width: '100%' }}
                  size='small'
                  onChange={(event, value) => {
                    setSelectedAcadmeicYear(value);
                    if (value) {
                      callApi(
                        `${endpoints.communication.branches}?session_year=${
                          value?.id
                        }&module_id=${getModuleId()}`,
                        'branchList'
                      );
                    }
                    setSelectedBranch([]);
                  }}
                  id='branch_id'
                  className='dropdownIcon'
                  value={selectedAcademicYear || ''}
                  options={academicYear || ''}
                  getOptionLabel={(option) => option?.session_year || ''}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Academic Year'
                      placeholder='Academic Year'
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Autocomplete
                  style={{ width: '100%' }}
                  size='small'
                  onChange={(event, value) => {
                    setSelectedBranch([]);
                    if (value) {
                      const selectedId = value.branch.id;
                      setSelectedBranch(value);
                      callApi(
                        `${endpoints.academics.grades}?session_year=${
                          selectedAcademicYear.id
                        }&branch_id=${selectedId.toString()}&module_id=${getModuleId()}`,
                        'gradeList'
                      );
                    }
                  }}
                  id='branch_id'
                  className='dropdownIcon'
                  value={selectedBranch || ''}
                  options={branchList || ''}
                  getOptionLabel={(option) => option?.branch?.branch_name || ''}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Branch'
                      placeholder='Branch'
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Divider className='studentStrenghtDivider' />
            <Grid container spacing={2}>
              <MediaQuery minWidth={1523}>
                <Grid item md={1} xs={12}>
                  <Button
                    variant='contained'
                    size='medium'
                    style={{ width: '100%' }}
                    className='cancelButton labelColor'
                    onClick={() => handleClearFilter()}
                  >
                    Clear All
                  </Button>
                </Grid>
                <Grid item md={1} xs={12}>
                  <Button
                    variant='contained'
                    size='medium'
                    color='primary'
                    style={{ color: 'white', width: '100%' }}
                    onClick={() => {
                      setPage(1);
                      handleFilter(1);
                      setSelectedCard('');
                    }}
                  >
                    Filter
                  </Button>
                </Grid>
                <Grid item md={3} />

                <Grid item>
                  <Button
                    size='medium'
                    // href={hRef && hRef[0] && hRef[0].csv}
                    onClick={() => {
                      file();
                    }}
                    className={classes.studentStrenghtDownloadButton}
                  >
                    Download All Branch Excel
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    variant='contained'
                    size='medium'
                    color='primary'
                    style={{ color: 'white', width: '100%' }}
                    // href={hRef && hRef[1] && hRef[1].csv}
                    onClick={() => {
                      file2();
                    }}
                  >
                    Download Branch Excel
                  </Button>
                </Grid>
              </MediaQuery>
              <MediaQuery minWidth={1101} maxWidth={1522}>
                <Grid item>
                  <Button
                    variant='contained'
                    size='medium'
                    className='cancelButton labelColor'
                    style={{ width: '100%' }}
                    onClick={() => handleClearFilter()}
                  >
                    <span style={{ fontSize: '13px' }}> Clear All</span>
                  </Button>
                </Grid>
                <Grid item md={1}>
                  <Button
                    variant='contained'
                    size='medium'
                    style={{ color: 'white', width: '100%' }}
                    color='primary'
                    onClick={() => {
                      setPage(1);
                      handleFilter(1);
                      setSelectedCard('');
                    }}
                  >
                    <span style={{ fontSize: '13px' }}>Filter</span>
                  </Button>
                </Grid>
                <Grid item md={4} />

                <Grid item style={{ marginLeft: '5%' }}>
                  <Button
                    size='medium'
                    // href={hRef && hRef[0] && hRef[0].csv}
                    // href={downloadFile && hRef && hRef[0] && hRef[0].csv}
                    // href={hRef && hRef[0] && hRef[0].csv}
                    className={classes.studentStrenghtDownloadButton}
                    onClick={() => {
                      file();
                    }}
                  >
                    <span style={{ fontSize: '13px' }}> Download All Branch Excel</span>
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    variant='contained'
                    size='medium'
                    color='primary'
                    style={{ color: 'white', width: '100%' }}
                    // href={hRef && hRef[1] && hRef[1].csv}
                    // href={downloadFile && hRef && hRef[1] && hRef[1].csv}
                    // href={hRef && hRef[1] && hRef[1].csv}
                    onClick={() => {
                      file2();
                    }}
                  >
                    <span style={{ fontSize: '13px' }}> Download Branch Excel</span>
                  </Button>
                </Grid>
              </MediaQuery>
              <MediaQuery minWidth={600} maxWidth={1100}>
                <Grid item>
                  <Button
                    variant='contained'
                    size='medium'
                    style={{ width: '100%' }}
                    className='cancelButton labelColor'
                    onClick={() => handleClearFilter()}
                  >
                    Clear All
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant='contained'
                    size='medium'
                    color='primary'
                    onClick={() => {
                      setPage(1);
                      handleFilter(1);
                      setSelectedCard('');
                    }}
                    style={{ color: 'white', width: '100%' }}
                  >
                    Filter
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    size='medium'
                    // href={hRef && hRef[0] && hRef[0].csv}
                    onClick={() => {
                      file();
                    }}
                    className={classes.studentStrenghtDownloadButton}
                  >
                    Download All Branch Excel
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    variant='contained'
                    size='medium'
                    color='primary'
                    // href={hRef && hRef[1] && hRef[1].csv}
                    onClick={() => {
                      file2();
                    }}
                    style={{ color: 'white', width: '100%' }}
                  >
                    Download Branch Excel
                  </Button>
                </Grid>
              </MediaQuery>
              <MediaQuery maxWidth={599}>
                <Grid item xs={12}>
                  <Button
                    variant='contained'
                    size='medium'
                    style={{ width: '100%' }}
                    className='cancelButton labelColor'
                    onClick={() => handleClearFilter()}
                  >
                    Clear All
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant='contained'
                    size='medium'
                    color='primary'
                    onClick={() => {
                      setPage(1);
                      handleFilter(1);
                      setSelectedCard('');
                    }}
                    style={{ color: 'white', width: '100%' }}
                  >
                    Filter
                  </Button>
                </Grid>
                <Grid item md={4} />

                <Grid item xs={12}>
                  <Button
                    size='medium'
                    // href={hRef && hRef[0] && hRef[0].csv}
                    onClick={() => {
                      file();
                    }}
                    className={classes.studentStrenghtDownloadButton}
                  >
                    Download All Branch Excel
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    variant='contained'
                    size='medium'
                    color='primary'
                    // href={hRef && hRef[1] && hRef[1].csv}
                    onClick={() => {
                      file2();
                    }}
                    style={{ color: 'white', width: '100%' }}
                  >
                    Download Branch Excel
                  </Button>
                </Grid>
              </MediaQuery>
            </Grid>
          </Grid>
        </Grid>
        {!filteredData && (
          <Grid container spacing={2}>
            <Grid item md={12} xs={12} style={{ textAlign: 'center', marginTop: '10px' }}>
              <img src={filterImage} alt='crash' height='250px' width='250px' />
              <Typography>
                Please select the filter to dislpay student strength
              </Typography>
            </Grid>
          </Grid>
        )}
        {filteredData && (
          <Grid container spacing={2} className='studentStrenghtBody1'>
            <Grid item md={11} xs={12}>
              <TotalStudentBar
                fullData={(filteredData && filteredData.overall_stat) || {}}
              />
            </Grid>
            <Grid
              item
              md={selectedCard ? 6 : 11}
              xs={12}
              className='studentStrenghtBody2'
            >
              <Grid container spacing={3} style={{ marginLeft: '-20px' }}>
                {filteredData &&
                  filteredData.grade_wise_data &&
                  filteredData.grade_wise_data.lenght !== 0 &&
                  filteredData.grade_wise_data.map((item) => (
                    <Grid item md={selectedCard ? 6 : 4} xs={12} key={item.id}>
                      <TotalStudentStrengthCard
                        fullData={item || {}}
                        handleSelectCard={setSelectedCard}
                        selectedId={selectedCard || ''}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Grid>
            {selectedCard && (
              <Grid item md={5} xs={12} className='studentStrenghtBody2'>
                <TotalStudentWiseDetails
                  year={(selectedAcademicYear && selectedAcademicYear.id) || 0}
                  branch={(selectedBranch && selectedBranch.branch?.id) || 0}
                  grade={selectedCard || 0}
                  hadleClearGrade={setSelectedCard}
                  fullWidth
                />
              </Grid>
            )}
          </Grid>
        )}

        {filteredData && filteredData.grade_wise_data ? (
          <Grid container justify='center'>
            <Pagination
              onChange={handlePagination}
              style={{ marginTop: 25 }}
              count={totalPages}
              color='primary'
              page={page}
            />
          </Grid>
        ) : (
          ''
        )}
        {loading && <Loader />}
      </div>
    </Layout>
  );
};

export default withRouter(StudentStrength);
