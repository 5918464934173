import React, { useEffect, useState } from 'react';
import { Drawer, Form, Input, Switch, Space, Button, message } from 'antd';

const EditDrawer = ({ visible, onClose, onSubmit, data, type, existingData }) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (visible && data) {
      form.setFieldsValue(data);
    }
  }, [visible, data, form]);

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    let sequenceExists;

    if (type === 'sport') {
      sequenceExists = existingData.some(
        (item) => item.sequence === parseInt(values.sequence) && item.id !== values.id
      );
    } else if (type === 'competition') {
      sequenceExists = existingData.some(
        (item) => item.sequence === parseInt(values.sequence) && item.id !== values.id
      );
    } else if (type === 'param') {
      sequenceExists = existingData.some(
        (item) =>
          item.sequence === parseInt(values.sequence) &&
          item.id !== values.id &&
          item.subject_id === values.subject_id
      );
    }

    if (sequenceExists) {
      message.error(
        'This sequence already exists in this category. Please choose a different sequence.'
      );
      setIsSubmitting(false);
    } else {
      try {
        await onSubmit(values, type);
        onClose();
      } catch (error) {
        console.error('Failed to update:', error);
        message.error('Failed to update. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const renderSportForm = () => (
    <>
      <Form.Item name='id' hidden>
        <Input />
      </Form.Item>
      <Form.Item name='subject_name' label='Sport Name' rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name='sequence' label='Sequence' rules={[{ required: true }]}>
        <Input type='number' />
      </Form.Item>
    </>
  );

  const renderCompetitionForm = () => (
    <>
      <Form.Item name='id' hidden>
        <Input />
      </Form.Item>
      <Form.Item name='subject_id' hidden>
        <Input />
      </Form.Item>
      <Form.Item name='level_name' label='Level Name' rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name='sequence' label='Sequence' rules={[{ required: true }]}>
        <Input type='number' min={0} />
      </Form.Item>
    </>
  );

  const renderParamForm = () => (
    <>
      <Form.Item name='id' hidden>
        <Input />
      </Form.Item>
      <Form.Item name='subject_id' hidden>
        <Input />
      </Form.Item>
      <Form.Item name='param_name' label='Parameter Name' rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name='sequence' label='Sequence' rules={[{ required: true }]}>
        <Input type='number' min={0} />
      </Form.Item>
    </>
  );

  const getFormContent = () => {
    switch (type) {
      case 'sport':
        return renderSportForm();
      case 'competition':
        return renderCompetitionForm();
      case 'param':
        return renderParamForm();
      default:
        return null;
    }
  };

  return (
    <Drawer
      title={`Edit ${type.charAt(0).toUpperCase() + type.slice(1)}`}
      placement='right'
      onClose={onClose}
      visible={visible}
      width={400}
    >
      <Form form={form} layout='vertical' onFinish={handleSubmit}>
        {getFormContent()}
        <Form.Item>
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type='primary' htmlType='submit' loading={isSubmitting}>
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default EditDrawer;
