import {
  Button,
  Form,
  Pagination,
  Result,
  Select,
  Tabs,
  message,
  DatePicker,
} from 'antd';
import endpoints from 'config/endpoints';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axiosInstance from 'v2/config/axios';
import axios from 'axios';
import FilesViewEvaluate from './fileViewEvaluate';
import './index.scss';
import Loader from '../../../components/loader/loader';
import moment from 'moment';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

const EvaluatorHomework = ({ is_auditor }) => {
  const history = useHistory();
  const { TabPane } = Tabs;
  const [gradeList, setGradeList] = useState([]);
  const [grade, setGrade] = useState('');
  const [sectionList, setSectionList] = useState([]);
  const [section, setSection] = useState('');
  const [sectionMappingId, setSectionMappingId] = useState('');

  const { Option } = Select;
  const selectedYear = useSelector((state) => state.commonFilterReducer?.selectedYear);
  const loggedUserData = JSON.parse(localStorage.getItem('userDetails')) || {};
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [pageLimit, setPageLimit] = useState(15);
  const [loading, setLoading] = useState(false);

  const [showFilters, setShowFilters] = useState(true);

  const [volumeList, setVolumeList] = useState([]);
  const [volume, setVolume] = useState('');
  const [dates, setDates] = useState(null);

  const [subjectList, setSubjectList] = useState([]);
  const [subject, setSubject] = useState('');

  const dateFormat = 'DD-MM-YYYY';
  let defaultStartDate = moment().subtract(6, 'days');
  const [startDate, setStartDate] = useState(defaultStartDate.format('DD-MM-YYYY'));
  const [endDate, setEndDate] = useState(moment().format('DD-MM-YYYY'));

  const [evaluateData, setEvaluateData] = useState([]);
  const [countData, setCountData] = useState(null);
  const [selectedHomeworkIndex, setSelectedHomeworkIndex] = useState(0);
  const [isAuditor, setIsAuditor] = useState(is_auditor);
  const [rating, setRating] = useState([]);
  const [showTab, setShowTab] = useState('1');
  const [evaluatorList, setEvaluatorList] = useState([]);
  const [selectedEvaluator, setSelectedEvaluator] = useState();

  const onChange = (key) => {
    setShowTab(key);
    setPageNo(1);
    fetchTeacherData({
      is_assessed: key === '1' ? 'True' : 'False',
      start_date: startDate,
      end_date: endDate,
      sub_sec_mpng: subject,
      page: 1,
      evaluator_ids: selectedEvaluator,
    });
  };

  useEffect(() => {
    if (grade && section && subject) {
      fetchEvaluator({ sub_sec_mpng: subject });
    } else {
      setEvaluatorList([]);
      setSelectedEvaluator(null);
    }
  }, [grade, subject, section]);

  const handleFilters = () => {
    if (showFilters) {
      setShowFilters(false);
    } else {
      setShowFilters(true);
    }
  };

  const formRef = useRef();

  const selectedBranch = useSelector(
    (state) => state.commonFilterReducer?.selectedBranch
  );

  useEffect(() => {
    fetchGrade(selectedBranch?.branch?.id);
  }, [selectedBranch]);

  const fetchGrade = async (branch) => {
    try {
      const result = await axiosInstance.get(
        `${endpoints.communication.grades}?session_year=${selectedYear?.id}&branch_id=${selectedBranch?.branch?.id}`
      );
      if (result.data.status_code === 200) {
        setGradeList(result.data.data);
      } else {
        message.error(result.data.message);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const gradeOptions = gradeList?.map((each) => {
    return (
      <Option key={each?.grade_id} value={each.grade_id}>
        {each?.grade__grade_name}
      </Option>
    );
  });

  const handleChangeGrade = (each) => {
    setPageNo(1);
    if (each) {
      setGrade(each?.value);
      fetchSection(each?.value);
    }
  };

  const handleClearGrade = () => {
    setGrade('');
    setSection('');
    setSectionMappingId('');
    setSectionList([]);
    setSubjectList([]);
    setSubject('');
    setSelectedEvaluator(null);
    formRef.current.setFieldsValue({
      grade: null,
      section: null,
      subject: null,
    });
    setEvaluateData([]);
  };
  const fetchSection = async (grade) => {
    try {
      const result = await axiosInstance.get(
        `${endpoints.academics.sections}?session_year=${selectedYear?.id}&branch_id=${selectedBranch?.branch?.id}&grade_id=${grade}`
      );
      if (result.data.status_code === 200) {
        setSectionList(result.data.data);
      } else {
        message.error(result.data.message);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const fetchRating = async (params = {}) => {
    console.log({ params });
    await axiosInstance
      .get(`${endpoints.centralizedHomework.rating}`, {
        params: { ...params },
      })
      .then((res) => {
        if (res?.data?.status_code === 200) {
          setRating(res?.data?.result);
        } else {
          message.error(res?.data?.message);
        }
        console.log({ res });
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  const fetchEvaluator = async (params = {}) => {
    console.log({ params });
    await axiosInstance
      .get(`${endpoints.centralizedHomework.evaluatorList}`, {
        params: { ...params },
      })
      .then((res) => {
        if (res?.data?.status_code === 200) {
          setEvaluatorList(res?.data?.result);
        } else {
          message.error(res?.data?.message);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  const evaluatorOptions = evaluatorList?.map((each) => {
    return (
      <Option key={each?.evaluator_id} value={each.user_id}>
        {each?.name}
      </Option>
    );
  });

  const handleChangeEvaluator = (each) => {
    setPageNo(1);
    setSelectedEvaluator(each ?? each);
  };

  const sectionOptions = sectionList?.map((each) => {
    return (
      <Option key={each?.id} value={each.sec_id}>
        {each?.sec_name}
      </Option>
    );
  });

  const fetchSubjectList = async (sectionId) => {
    try {
      const result = await axiosInstance.get(
        `${endpoints.centralizedHomework.subjectList}?session_year=${selectedYear?.id}&branch=${selectedBranch?.branch?.id}&grade=${grade}&section=${sectionId}`,
        {
          headers: {
            Authorization: `Bearer ${loggedUserData?.token}`,
          },
        }
      );
      if (result.data.status_code === 200) {
        setSubjectList(result?.data?.data);
      } else {
        message.error(result.data.message);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const subjectListOptions = subjectList?.map((each) => (
    <Select.Option key={each?.id} value={each?.subject_mapping_id}>
      {each?.sub_name}
    </Select.Option>
  ));

  const fetchTeacherData = async (params = {}) => {
    setEvaluateData([]);
    setSelectedHomeworkIndex(0);
    setPageNo(1);
    if (!subject) {
      return message.error('Please Select Filters !');
    }
    if (!startDate) {
      return message.error('Please Select Start Date !');
    }
    if (!endDate) {
      return message.error('Please Select End Date !');
    }
    setLoading(true);
    try {
      const result = await axiosInstance.get(
        `${endpoints.homework.teacherData}`,
        {
          params: { ...params },
        },
        {
          headers: {
            Authorization: `Bearer ${loggedUserData?.token}`,
          },
        }
      );
      if (result.data.status_code === 200) {
        let evalData = result?.data?.result?.results;
        let ungrouppedData = [];
        evalData.forEach((item) => {
          if (item.erp === 'INVALID') {
            item.homework.forEach((hw) => {
              ungrouppedData.push({
                date: item.date,
                erp: item.erp,
                homework: [hw],
              });
            });
          } else {
            ungrouppedData.push(item);
          }
        });
        setEvaluateData(ungrouppedData);
        if (result?.data?.result?.results?.[0]?.homework[0]?.is_audited) {
          const ratingData = await fetchRating({
            hw_dist_file: result?.data?.result?.results[0]?.homework[0]?.id,
          });
        }
        setCountData(result?.data?.result);
        setTotalPage(result?.data?.result?.count);
        setPageLimit(result?.data?.result?.limit);
        setLoading(false);
      } else {
        message.error(result.data.message);
        setLoading(false);
      }
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  const handleChangeSection = (each) => {
    if (each) {
      setPageNo(1);
      const section = each?.value;
      setSectionMappingId(each?.key);
      setSection(section);
      fetchSubjectList(section);
      setSubject('');
      formRef.current.setFieldsValue({
        subject: null,
      });
    }
  };

  const handleClearSection = () => {
    setSection([]);
    setSectionMappingId('');
    setSubject('');
    setSubjectList([]);
    formRef.current.setFieldsValue({
      subject: null,
    });
    setEvaluateData([]);
  };

  const handleChangeSubject = (each) => {
    if (each) {
      setPageNo(1);
      setSubject(each);
    } else {
      setSubject('');
      formRef.current.setFieldsValue({
        subject: null,
      });
      setEvaluateData([]);
    }
  };

  const handleDateChange = (value) => {
    if (value) {
      setStartDate(moment(value[0]).format('DD-MM-YYYY'));
      setEndDate(moment(value[1]).format('DD-MM-YYYY'));
      setDates(value);
      setPageNo(1);
    } else {
      setEvaluateData([]);
      setStartDate(null);
      setEndDate(null);
      setDates(null);
    }
  };

  const onOpenChange = (open) => {
    if (open) {
      setStartDate(null);
      setEndDate(null);
      setDates([null, null]);
      formRef.current.setFieldsValue({
        date: [null, null],
      });
    } else {
      setDates(null);
    }
  };

  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > 6;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > 6;

    return !!tooEarly || !!tooLate;
  };

  const handleEvaluatorReport = () => {
    history.push('/homework/centralized-eval-reports');
  };

  return (
    <React.Fragment>
      {loading && <Loader />}

      <div className='px-3'>
        <div className='col-md-12 p-0 d-flex justify-content-end'>
          {showFilters ? (
            <div>
              <Button
                icon={<UpOutlined />}
                onClick={handleFilters}
                type='dashed'
                size='small'
              >
                Collapse{' '}
              </Button>
            </div>
          ) : (
            <div>
              <Button
                icon={<DownOutlined />}
                onClick={handleFilters}
                type='dashed'
                size='small'
              >
                Expand
              </Button>
            </div>
          )}
        </div>
        {showFilters && (
          <div className='row' style={{ borderBottom: '1px solid #d6d6d6' }}>
            <Form
              id='filterForm'
              // className='mt-3'
              layout={'vertical'}
              ref={formRef}
              style={{ width: '100%' }}
            >
              <div className='row justify-content-between'>
                <div
                  className={isAuditor ? 'col-xl-12 col-12 row' : 'col-xl-7 col-md-6 row'}
                >
                  <div
                    className={`${
                      isAuditor ? 'col-xl-3 col-md-3' : 'col-xl-4 col-md-4'
                    }  col-sm-6 col-12 pl-0`}
                  >
                    <div className='mb-2 text-left'>Grade</div>

                    <Form.Item name='grade'>
                      <Select
                        // mode='multiple'
                        getPopupContainer={(trigger) => trigger.parentNode}
                        maxTagCount={1}
                        allowClear={true}
                        suffixIcon={<DownOutlined className='th-grey' />}
                        className='th-grey th-bg-grey th-br-4 w-100 text-left th-select'
                        placement='bottomRight'
                        showArrow={true}
                        onChange={(e, value) => handleChangeGrade(value)}
                        onClear={handleClearGrade}
                        dropdownMatchSelectWidth={false}
                        filterOption={(input, options) => {
                          return (
                            options.children.toLowerCase().indexOf(input.toLowerCase()) >=
                            0
                          );
                        }}
                        showSearch
                        placeholder='Select Grade'
                      >
                        {gradeOptions}
                      </Select>
                    </Form.Item>
                  </div>

                  <div
                    className={`${
                      isAuditor ? 'col-xl-3 col-md-3' : 'col-xl-4 col-md-4'
                    }  col-sm-6 col-12 pl-0`}
                  >
                    <div className='mb-2 text-left'>Section</div>

                    <Form.Item name='section'>
                      <Select
                        // mode='multiple'
                        getPopupContainer={(trigger) => trigger.parentNode}
                        maxTagCount={1}
                        allowClear={true}
                        suffixIcon={<DownOutlined className='th-grey' />}
                        className='th-grey th-bg-grey th-br-4 w-100 text-left th-select'
                        placement='bottomRight'
                        showArrow={true}
                        onChange={(e, value) => handleChangeSection(value)}
                        onClear={handleClearSection}
                        dropdownMatchSelectWidth={false}
                        filterOption={(input, options) => {
                          return (
                            options.children.toLowerCase().indexOf(input.toLowerCase()) >=
                            0
                          );
                        }}
                        showSearch
                        placeholder='Select section'
                      >
                        {sectionOptions}
                      </Select>
                    </Form.Item>
                  </div>

                  <div
                    className={`${
                      isAuditor ? 'col-xl-3 col-md-3' : 'col-xl-4 col-md-4'
                    }  col-sm-6 col-12 pl-0`}
                  >
                    <div className='mb-2 text-left'>Subject</div>
                    <Form.Item name='subject'>
                      <Select
                        getPopupContainer={(trigger) => trigger.parentNode}
                        maxTagCount={1}
                        allowClear={true}
                        suffixIcon={<DownOutlined className='th-grey' />}
                        className='th-grey th-bg-grey th-br-4 w-100 text-left th-select'
                        placement='bottomRight'
                        showArrow={true}
                        onChange={(e) => handleChangeSubject(e)}
                        dropdownMatchSelectWidth={false}
                        filterOption={(input, options) => {
                          return (
                            options.children.toLowerCase().indexOf(input.toLowerCase()) >=
                            0
                          );
                        }}
                        showSearch
                        placeholder='Select Subject'
                      >
                        {subjectListOptions}
                      </Select>
                    </Form.Item>
                  </div>

                  {isAuditor && (
                    <div
                      className={`${
                        isAuditor ? 'col-xl-3 col-md-3' : 'col-xl-4 col-md-4'
                      }  col-sm-6 col-12 pl-0`}
                    >
                      <div className='mb-2 text-left'>Evaluator</div>
                      <Form.Item name='evaluator'>
                        <Select
                          getPopupContainer={(trigger) => trigger.parentNode}
                          maxTagCount={1}
                          allowClear={true}
                          suffixIcon={<DownOutlined className='th-grey' />}
                          className='th-grey th-bg-grey th-br-4 w-100 text-left th-select'
                          placement='bottomRight'
                          showArrow={true}
                          dropdownMatchSelectWidth={false}
                          onChange={(e) => handleChangeEvaluator(e)}
                          filterOption={(input, options) => {
                            return (
                              options.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                          showSearch
                          placeholder='Select Evaluator'
                        >
                          {evaluatorOptions}
                        </Select>
                      </Form.Item>
                    </div>
                  )}

                  <div
                    className={`${
                      isAuditor ? 'col-xl-3 col-md-3' : 'col-xl-4 col-md-4'
                    }  col-sm-6 col-12 pl-0`}
                  >
                    <Form.Item name='date'>
                      <RangePicker
                        className='th-width-100 th-br-4'
                        onCalendarChange={(value) => handleDateChange(value)}
                        onOpenChange={onOpenChange}
                        defaultValue={dates || [moment().subtract(6, 'days'), moment()]}
                        format={dateFormat}
                        disabledDate={disabledDate}
                        separator={'to'}
                      />
                    </Form.Item>
                  </div>

                  <div
                    className={`${
                      isAuditor ? 'col-xl-3 col-md-3' : 'col-xl-4 col-md-4'
                    }  col-sm-6 col-12 pl-0`}
                  >
                    <Form.Item name='filter'>
                      <Button
                        className=' th-br-4 w-100  th-select'
                        type='primary'
                        onClick={() => {
                          fetchTeacherData({
                            is_assessed: showTab === '1' ? 'True' : 'False',
                            start_date: startDate,
                            end_date: endDate,
                            sub_sec_mpng: subject,
                            page: pageNo,
                            evaluator_ids: selectedEvaluator,
                          });
                        }}
                      >
                        Filter
                      </Button>
                    </Form.Item>
                  </div>
                  {isAuditor && (
                    <div
                      className={`${
                        isAuditor ? 'col-xl-3 col-md-3' : 'col-xl-4 col-md-4'
                      }  col-sm-6 col-12 pl-0`}
                    >
                      <Form.Item name='filter'>
                        <Button
                          className=' th-br-4 w-100  th-select'
                          type='primary'
                          onClick={handleEvaluatorReport}
                        >
                          Evaluator Report
                        </Button>
                      </Form.Item>
                    </div>
                  )}
                </div>
                {!isAuditor && (
                  <div className='col-md-5 col-xl-3  p-0 row mb-2'>
                    <div
                      className='col-md-12 py-2 mt-2'
                      style={{
                        boxShadow:
                          'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
                        borderRadius: '10px',
                        marginBottom: '5px',
                        height: 'fit-content',
                      }}
                    >
                      <div
                        className='col-md-12 row justify-content-between th-13'
                        style={{ marginTop: '6px' }}
                      >
                        <div>
                          <span>
                            Total Assessed :{' '}
                            <span style={{ color: 'green' }}>
                              {countData?.total_assessed
                                ? countData?.total_assessed
                                : '0'}
                            </span>{' '}
                          </span>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {/* <span>Completed </span> */}
                          <span
                            style={{
                              backgroundColor: 'green',
                              color: 'white',
                              height: '15px',
                              width: '15px',
                              borderRadius: '5px',
                              display: 'inline-block',
                              marginLeft: '20px',
                            }}
                          ></span>
                        </div>
                      </div>
                      <div
                        className='col-md-12 row justify-content-between th-13'
                        style={{ marginTop: '6px' }}
                      >
                        <div>
                          <span>
                            Total Under Assessed :{' '}
                            <span style={{ color: 'red' }}>
                              {countData?.total_under_assessed
                                ? countData?.total_under_assessed
                                : '0'}
                            </span>
                          </span>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {/* <span>Pending </span> */}
                          <span
                            style={{
                              backgroundColor: 'red',
                              color: 'white',
                              height: '15px',
                              width: '15px',
                              borderRadius: '5px',
                              display: 'inline-block',
                              marginLeft: '20px',
                            }}
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Form>
          </div>
        )}

        <div className='mt-4 '>
          <div className='th-tabs th-tabs-hw mt-3 th-bg-white mb-3'>
            <Tabs type='card' onChange={onChange} defaultActiveKey={showTab}>
              <TabPane tab='Assessed' key='1'>
                {evaluateData?.length === 0 ? (
                  <div className='col-12'>
                    <Result
                      status='warning'
                      title={<span className='th-grey'>No Data Available</span>}
                    />
                  </div>
                ) : (
                  <FilesViewEvaluate
                    selectedHomeworkIndex={selectedHomeworkIndex}
                    setSelectedHomeworkIndex={setSelectedHomeworkIndex}
                    evaluateData={evaluateData}
                    rating={rating}
                    selectedGrade={grade}
                    selectedSubSecMap={section}
                    fetchTeacherData={fetchTeacherData}
                    fetchRating={fetchRating}
                    startDate={startDate}
                    endDate={endDate}
                    sub_sec_mpng={subject}
                    sectionMappingId={sectionMappingId}
                    page={pageNo}
                    isAuditor={isAuditor}
                    activeTab={showTab}
                    selectedEvaluator={selectedEvaluator}
                  />
                )}
                {/*  */}
              </TabPane>
              <TabPane tab='Under Assessed' key='2'>
                {evaluateData?.length === 0 ? (
                  <div className='col-12'>
                    <Result
                      status='warning'
                      title={<span className='th-grey'>No Data Available</span>}
                    />
                  </div>
                ) : (
                  <FilesViewEvaluate
                    selectedHomeworkIndex={selectedHomeworkIndex}
                    setSelectedHomeworkIndex={setSelectedHomeworkIndex}
                    rating={rating}
                    evaluateData={evaluateData}
                    selectedGrade={grade}
                    selectedSubSecMap={section}
                    fetchTeacherData={fetchTeacherData}
                    fetchRating={fetchRating}
                    startDate={startDate}
                    endDate={endDate}
                    sub_sec_mpng={subject}
                    sectionMappingId={sectionMappingId}
                    page={pageNo}
                    isAuditor={isAuditor}
                    activeTab={showTab}
                    selectedEvaluator={selectedEvaluator}
                  />
                )}
                {/* */}
              </TabPane>
            </Tabs>

            <Pagination
              current={pageNo}
              total={totalPage}
              showSizeChanger={false}
              pageSize={pageLimit}
              onChange={(current) => {
                setPageNo(current);
                setSelectedHomeworkIndex(0);
                fetchTeacherData({
                  is_assessed: showTab === '1' ? 'True' : 'False',
                  start_date: startDate,
                  end_date: endDate,
                  sub_sec_mpng: subject,
                  page: current,
                  evaluator_ids: selectedEvaluator,
                });
              }}
              className='text-center mt-3'
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EvaluatorHomework;
