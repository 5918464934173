/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  fetchBranches,
  fetchModules,
  setCreateRolePermissionsState,
  createRole,
  setModulePermissionsRequestData,
  fetchAcademicYears,
} from '../../redux/actions';

import styles from './useStyles';
import CommonBreadcrumbs from '../../components/common-breadcrumbs/breadcrumbs';
import ModuleCard from '../../components/module-card';
import Loading from '../../components/loader/loader';
import { AlertNotificationContext } from '../../context-api/alert-context/alert-state';

class CreateRole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roleName: '',
      roleNameError: '',
      selectionError: '',
      moduleId: '',
      academicYearList: [],
      NavData: JSON.parse(localStorage.getItem('navigationData')),
    };
  }
  // const [moduleId, setModuleId] = useState('');

  componentDidMount() {
    const { fetchModules } = this.props;
    fetchModules();
    if (this.state.NavData && this.state.NavData.length) {
      this.state.NavData.forEach((item) => {
        if (
          item.parent_modules === 'Role Management' &&
          item.child_module &&
          item.child_module.length > 0
        ) {
          item.child_module.forEach((item) => {
            if (item.child_name === 'View Role') {
              this.setState({ moduleId: item.child_id });
              fetchAcademicYears(item.child_id).then((data) => {
                let transformedData = '';
                transformedData = data?.map((obj) => ({
                  id: obj.id,
                  session_year: obj.session_year,
                }));
                this.setState({ academicYearList: transformedData });
              });
            }
          });
        }
      });
    }
  }

  handleRoleNameChange = (e) => {
    this.setState({ roleName: e.target.value });
  };

  alterCreateRolePermissions = (module) => {
    const { modules, alterCreateRolePermissionsState } = this.props;
    const moduleIndex = modules.findIndex((obj) => obj.id === module.id);
    const modulesArray = JSON.parse(JSON.stringify(modules));
    modulesArray[moduleIndex] = module;
    alterCreateRolePermissionsState(modulesArray);
  };

  handleCreateRole = () => {
    // eslint-disable-next-line camelcase
    const { history } = this.props;
    const { roleName } = this.state;
    const { modulePermissionsRequestData, createRole, modules } = this.props;

    // if (!reqObj.role_name) {
    //   this.setState({ roleNameError: 'Please enter a role name' });
    //   return;
    // }
    // if (!reqObj.Module.length) {
    //   this.setState({ roleNameError: '' });
    //   this.setState({ selectionError: 'Please select some role' });
    //   return;
    // }

    this.setState({ selectionError: '' });

    const requestData = [];

    modules.forEach((module) => {
      module.module_child.forEach((subModule) => {
        // const index = modulePermissionsRequestData.findIndex(
        //   (obj) => obj.modules_id == subModule.module_child_id
        // );
        // if (index === -1) {
        const currentSubModule = subModule;
        const includeInRequest = Object.keys(currentSubModule).some((key) => {
          if (key.includes('my_')) {
            if (currentSubModule[key]) {
              return true;
            }
          }
          if (key.includes('custom_')) {
            if (currentSubModule[key].length > 0) {
              return true;
            }
          }
          return false;
        });
        if (includeInRequest) {
          const reqObj = {
            modules_id: currentSubModule.module_child_id,
            my_branch: currentSubModule.my_branch,
            my_grade: currentSubModule.my_grade,
            my_section: currentSubModule.my_section,
            my_subject: currentSubModule.my_subject,
            custom_year: currentSubModule.custom_year.map((year) => year.id),
            custom_grade: currentSubModule.custom_grade.map((grade) => grade.id),
            custom_section: currentSubModule.custom_section.map((section) => section.id),
            custom_branch: currentSubModule.custom_branch.map((branch) => branch.id),
            custom_subject: currentSubModule.custom_subject.map((subject) => subject.id),
          };
          requestData.push(reqObj);
          // }
          // const reqObj = {
          //   modules_id: subModule.module_child_id,
          //   my_branch: subModule.my_branch,
          //   my_grade: subModule.my_grade,
          //   my_section: subModule.my_section,
          //   my_subject: subModule.my_subject,
          //   custom_grade: subModule.custom_grade.map((grade) => grade.id),
          //   custom_section: subModule.custom_section.map((section) => section.id),
          //   custom_branch: subModule.custom_branch.map((branch) => branch.id),
          //   custom_subject: subModule.custom_subject.map((subject) => subject.id),
          // };
          // requestData.push(reqObj);
        }
      });
    });
    const { setAlert } = this.context;
    if (requestData.length > 0) {
      if (!roleName.trim().length) {
        setAlert('error', 'Please provide a role name');
        return;
      }
      const reqObj = {
        role_name: roleName,
        Module: requestData,
      };
      createRole(reqObj)
        .then(() => {
          setAlert('success', 'Role created successfully');
          history.push('/role-management');
        })
        .catch(() => {
          setAlert('error', 'Creation Failed');
        });
    } else {
      setAlert('error', 'Please select permissions for atleast one module');
    }
  };

  render() {
    const {
      modules,
      fetchingModules,
      branches,
      modulePermissionsRequestData,
      setModulePermissionsRequestData,
      classes,
      history,
    } = this.props;
    const { roleNameError, selectionError } = this.state;
    const modulesListing = () => {
      if (fetchingModules) return <Loading message='Loading modules...' />;
      if (modules?.length > 0) {
        return modules.map((module) => (
          <Grid item xs={12} sm={6} lg={12}>
            <ModuleCard
              module={module}
              academicYear={this.state.academicYearList}
              alterCreateRolePermissions={this.alterCreateRolePermissions}
              branches={branches}
              modulePermissionsRequestData={modulePermissionsRequestData}
              setModulePermissionsRequestData={setModulePermissionsRequestData}
            />
          </Grid>
        ));
      }
      return 'No modules';
    };
    return (
      <>
        <CommonBreadcrumbs
          componentName='Role Management'
          childComponentName='Create Role'
        />
        <div className={classes.root}>
          {/* <div className='bread-crumbs-container'> */}
          <div className='back-btn-container'>
            <Button
              variant='contained'
              startIcon={<ArrowBackIcon style={{ color: 'rgb(140, 140, 140)' }} />}
              size='medium'
              className='cancelButton labelColor'
              onClick={() => history.push('/role-management')}
            >
              Back
            </Button>
          </div>
          {/* </div> */}

          <Grid
            container
            alignItems='center'
            spacing={2}
            className={classes.formContainer}
          >
            <Grid item>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ color: 'red' }}>{roleNameError}</span>
                <TextField
                  id='outlined-helperText'
                  label='Role name'
                  defaultValue=''
                  variant='outlined'
                  inputProps={{ maxLength: 100 }}
                  onChange={this.handleRoleNameChange}
                  color='secondary'
                  size='small'
                />
              </div>
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                size='medium'
                style={{ color: 'white' }}
                onClick={this.handleCreateRole}
              >
                Add Role
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.spacer}>
            <Grid item>
              <Typography className={classes.sectionHeader}>Number of modules</Typography>
            </Grid>
          </Grid>

          <Divider className={classes.divider} />

          <span style={{ color: 'red' }}>{selectionError}</span>
          <Grid container spacing={2} className={classes.modulesContainer}>
            {modulesListing()}
          </Grid>
        </div>
      </>
    );
  }
}

CreateRole.contextType = AlertNotificationContext;

const mapStateToProps = (state) => ({
  modules: state.roleManagement.createRoleModulePermissionsState,
  fetchingModules: state.roleManagement.fetchingModules,
  branches: state.roleManagement.branches,
  modulePermissionsRequestData: state.roleManagement.modulePermissionsRequestData,
});

const mapDispatchToProps = (dispatch) => ({
  fetchModules: () => {
    dispatch(fetchModules());
  },
  fetchBranches: () => {
    dispatch(fetchBranches());
  },
  fetchAcademicYears: () => {
    dispatch(fetchAcademicYears());
  },
  alterCreateRolePermissionsState: (params) => {
    dispatch(setCreateRolePermissionsState(params));
  },
  setModulePermissionsRequestData: (params) => {
    dispatch(setModulePermissionsRequestData(params));
  },
  createRole: (params) => {
    return dispatch(createRole(params));
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CreateRole));
