import React, { useState, useEffect } from 'react';
import {
  Form,
  Select,
  Input,
  Button,
  Space,
  Card,
  Divider,
  Switch,
  Typography,
  Row,
  Col,
  Tooltip,
  message,
  Breadcrumb,
  Popover,
} from 'antd';
import {
  MinusCircleOutlined,
  PlusOutlined,
  InfoCircleOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons';
import axiosInstance from 'config/axios';
import endpointsV2 from 'v2/config/endpoints';
import endpoints from 'config/endpoints';
import Layout from 'containers/Layout';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';

const { Option } = Select;
const { Title, Text } = Typography;

const VlcReportConfig = () => {
  const selectedAcademicYear = useSelector(
    (state) => state.commonFilterReducer?.selectedYear
  );
  const history = useHistory();
  const [form] = Form.useForm();
  const [branchList, setBranchList] = useState([]);
  const [gradesList, setGradesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [formValid, setFormValid] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState({});
  const [prefilledData, setPrefilledData] = useState(null);

  useEffect(() => {
    fetchBranches({ session_year: selectedAcademicYear?.id });
  }, [selectedAcademicYear]);

  useEffect(() => {
    if (prefilledData && Array.isArray(prefilledData) && prefilledData.length > 0) {
      const formattedData = prefilledData.map((subject) => ({
        subject_name: subject.subject_name,
        sequence: subject.sequence,
        level_dict: subject.level_details.map((level) => ({
          subject_name: level.subject_name,
          sequence: level.sequence,
          is_hide: level.is_hide,
        })),
        params_dict: subject.subject_params.map((param) => ({
          param_name: param.param_name,
          sequence: param.sequence,
          is_hide: param.is_hide,
        })),
        grade_dict: subject.grade_details.map((grade) => ({
          grade_scale: grade.grade_scale,
          scale_name: grade.scale_name,
          sequence: grade.sequence,
          is_hide: grade.is_hide,
        })),
      }));

      form.setFieldsValue({ subjects: formattedData });
      const isValid = validateSubjects(formattedData);
      setFormValid(isValid);
    }
  }, [prefilledData, form]);

  const showPopover = (fieldName) => {
    setPopoverVisible((prev) => ({ ...prev, [fieldName]: true }));
  };

  const hidePopover = (fieldName) => {
    setPopoverVisible((prev) => ({ ...prev, [fieldName]: false }));
  };

  const handleRemoveConfirm = (fieldName, removeSubject) => {
    removeSubject(fieldName);
    hidePopover(fieldName);
  };

  const fetchBranches = async (params = {}) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`${endpointsV2.academics.branches}`, {
        params: params,
      });
      setBranchList(response.data?.data?.results || []);
    } catch (error) {
      console.error('Error fetching branches:', error);
    }
    setLoading(false);
  };

  const handleBranchChange = (values, key) => {
    setSelectedBranches(values);
    resetForm();
    form.setFieldsValue({ acad_sessions: values, grades: undefined, term_id: undefined });
    setSelectedGrades([]);
    setSelectedTerm(null);
    fetchGrades({
      session_year: selectedAcademicYear?.id,
      branch_id: key?.map((each) => each.key)?.toString(),
    });
  };

  const fetchGrades = async (params = {}) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`${endpointsV2.academics.grades}`, {
        params: params,
      });
      setGradesList(response.data?.data || []);
    } catch (error) {
      console.error('Error fetching grades:', error);
    }
    setLoading(false);
  };

  const handleGradeChange = (value) => {
    const gradesList = [value];
    setSelectedGrades(gradesList);
    form.setFieldsValue({ grades: gradesList });
    if (value && selectedBranches.length > 0 && selectedTerm) {
      fetchVlcTemplate(gradesList, selectedTerm);
    }
  };

  const handleTermChange = (value) => {
    setSelectedTerm(value);
    form.setFieldsValue({ term_id: value });
    if (selectedGrades && selectedBranches.length > 0 && value) {
      fetchVlcTemplate(selectedGrades, value);
    }
  };

  const fetchVlcTemplate = async (grades, term) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${endpointsV2.assessmentReportConfig.reportTemplateStatus}`,
        {
          params: {
            acad_session: selectedBranches.join(','),
            grade: grades.join(','),
            term_id: term,
            rc_type: 1,
          },
        }
      );
      if (response.status === 200 && response?.data?.message) {
        message.warning(response?.data?.message);
        if (selectedBranches.length === 1) {
          const templateResponse = await axiosInstance.get(
            `${endpointsV2.assessmentReportConfig.getVlcTemplate}`,
            {
              params: {
                acad_session: selectedBranches[0],
                grade: grades[0],
                term_id: term,
              },
            }
          );
          setPrefilledData(templateResponse.data?.result || []);
        }
      } else {
        setPrefilledData(null);
      }
    } catch (error) {
      console.error('Error fetching Arts & SOCH template:', error);
      setPrefilledData(null);
    }
    setLoading(false);
  };

  const resetForm = () => {
    form.resetFields(['subjects']);
  };

  const validateUniqueSequence = (path, value) => {
    return new Promise((resolve, reject) => {
      const formValues = form.getFieldValue(path);
      if (!formValues || !Array.isArray(formValues)) {
        resolve();
        return;
      }

      const sequences = formValues
        .map((item) => Number(item.sequence))
        .filter((seq) => !isNaN(seq));
      const uniqueSequences = new Set(sequences);

      if (sequences.length !== uniqueSequences.size) {
        reject('Duplicate sequence found. Please use unique sequences.');
      } else {
        resolve();
      }
    });
  };

  const validateSubjects = (subjects) => {
    if (!Array.isArray(subjects) || subjects.length === 0) return false;
    return subjects.every(
      (subject) =>
        subject.subject_name &&
        !isNaN(Number(subject.sequence)) &&
        Array.isArray(subject.level_dict) &&
        subject.level_dict.length > 0 &&
        subject.level_dict.every(
          (level) => level.subject_name && !isNaN(Number(level.sequence))
        ) &&
        Array.isArray(subject.params_dict) &&
        subject.params_dict.length > 0 &&
        subject.params_dict.every(
          (param) => param.param_name && !isNaN(Number(param.sequence))
        ) &&
        Array.isArray(subject.grade_dict) &&
        subject.grade_dict.length > 0 &&
        subject.grade_dict.every(
          (grade) =>
            grade.grade_scale && grade.scale_name && !isNaN(Number(grade.sequence))
        )
    );
  };

  const onFinish = (values) => {
    setSubmitting(true);
    const mergedValues = {
      ...values,
      subjects: values.subjects.map((subject, index) => {
        if (index < (prefilledData?.length || 0)) {
          return {
            ...prefilledData[index],
            ...subject,
            level_dict: [
              ...(prefilledData[index].level_details || []),
              ...subject.level_dict.slice(
                (prefilledData[index].level_details || []).length
              ),
            ],
            params_dict: [
              ...(prefilledData[index].subject_params || []),
              ...subject.params_dict.slice(
                (prefilledData[index].subject_params || []).length
              ),
            ],
            grade_dict: [
              ...(prefilledData[index].grade_details || []),
              ...subject.grade_dict.slice(
                (prefilledData[index].grade_details || []).length
              ),
            ],
          };
        }
        return subject;
      }),
    };

    axiosInstance
      .post(`${endpointsV2.assessmentReportConfig.getVlcTemplate}`, mergedValues)
      .then((res) => {
        message.success(`Arts & SOCH Report Template Created Successfully`);
        resetForm();
        setSelectedBranches([]);
        setSelectedGrades([]);
        setSelectedTerm(null);
        setGradesList([]);
        setPrefilledData(null);
        form.resetFields();
      })
      .catch((err) => {
        console.log({ err });
        message.error('Something went wrong');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Layout>
      <div className='row py-3 px-2 ' style={{ position: 'relative' }}>
        <div className='col-12'>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item className='th-grey th-16' href='/assessment/report-config'>
              Assessment
            </Breadcrumb.Item>
            <Breadcrumb.Item className='th-black-1 th-16'>
              Arts & SOCH Report Config
            </Breadcrumb.Item>
            <Breadcrumb.Item className='th-black-1 th-16'>
              Create Arts & SOCH Report
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className='col-12 mt-3'>
          <Form
            className='th-bg-white p-3'
            form={form}
            name='subject_form'
            layout='vertical'
            onFinish={onFinish}
            onValuesChange={(_, allValues) => {
              const isValid = validateSubjects(allValues.subjects);
              setFormValid(isValid);
            }}
          >
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name='acad_sessions'
                  label='Branches'
                  rules={[
                    {
                      required: true,
                      message: 'Please select at least one branch',
                    },
                  ]}
                >
                  <Select
                    mode='multiple'
                    placeholder='Select branches'
                    onChange={handleBranchChange}
                    loading={loading}
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {branchList.map((branch) => (
                      <Option key={branch.branch.id} value={branch.id}>
                        {branch.branch.branch_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name='grades'
                  label='Grades'
                  rules={[{ required: true, message: 'Please select grade' }]}
                >
                  <Select
                    placeholder='Select grades'
                    loading={loading}
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={handleGradeChange}
                    value={selectedGrades[0]}
                  >
                    {gradesList.map((grade) => (
                      <Option key={grade.id} value={grade.grade_id}>
                        {grade.grade_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name='term_id'
                  label='Term'
                  rules={[{ required: true, message: 'Please select a term' }]}
                >
                  <Select
                    placeholder='Select term'
                    onChange={handleTermChange}
                    value={selectedTerm}
                  >
                    <Option value='1'>Semester 1</Option>
                    <Option value='2'>Semester 2</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <>
              <Divider orientation='left th-primary'>Subjects</Divider>
              <Form.List
                name='subjects'
                rules={[
                  {
                    validator: async (_, subjects) => {
                      if (!subjects || subjects.length < 1) {
                        return Promise.reject(
                          new Error('At least one Subject is required')
                        );
                      }
                    },
                  },
                ]}
              >
                {(fields, { add: addSubject, remove: removeSubject }) => (
                  <>
                    {fields.map((field, index) => (
                      <Card
                        key={field.key}
                        title={<Title level={5}>{`Subject ${index + 1}`}</Title>}
                        extra={
                          index < (prefilledData?.length || 0) ? (
                            <Button
                              type='link'
                              danger
                              icon={<MinusCircleOutlined />}
                              disabled
                            />
                          ) : (
                            <Tooltip title='Remove Subject'>
                              <Popover
                                content={
                                  <div>
                                    <p>Are you sure you want to remove this subject?</p>
                                    <Button
                                      type='primary'
                                      size='small'
                                      onClick={() =>
                                        handleRemoveConfirm(field.name, removeSubject)
                                      }
                                    >
                                      Yes
                                    </Button>
                                    <Button
                                      size='small'
                                      style={{ marginLeft: 8 }}
                                      onClick={() => hidePopover(field.name)}
                                    >
                                      No
                                    </Button>
                                  </div>
                                }
                                title='Confirm Removal'
                                trigger='click'
                                visible={popoverVisible[field.name]}
                                onVisibleChange={(visible) =>
                                  visible
                                    ? showPopover(field.name)
                                    : hidePopover(field.name)
                                }
                              >
                                <Button
                                  type='link'
                                  danger
                                  icon={<MinusCircleOutlined />}
                                />
                              </Popover>
                            </Tooltip>
                          )
                        }
                        style={{ marginBottom: 24 }}
                      >
                        <Row gutter={16}>
                          <Col span={16}>
                            <Form.Item
                              {...field}
                              name={[field.name, 'subject_name']}
                              label='Subject Name'
                              rules={[
                                { required: true, message: 'Subject name is required' },
                              ]}
                            >
                              <Input
                                placeholder='Subject Name'
                                disabled={index < (prefilledData?.length || 0)}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item
                              {...field}
                              name={[field.name, 'sequence']}
                              label='Sequence'
                              rules={[
                                { required: true, message: 'Sequence is required' },
                                {
                                  validator: (_, value) =>
                                    validateUniqueSequence(['subjects'], Number(value)),
                                },
                              ]}
                            >
                              <Input
                                type='number'
                                placeholder='Sequence'
                                min={0}
                                disabled={index < (prefilledData?.length || 0)}
                                style={{
                                  WebkitAppearance: 'none',
                                  MozAppearance: 'textfield',
                                  appearance: 'textfield',
                                }}
                                onChange={(e) => {
                                  const value = Number(e.target.value);
                                  const subjects = form.getFieldValue('subjects') || [];
                                  const updatedSubjects = subjects.map((subject, idx) =>
                                    idx === field.name
                                      ? { ...subject, sequence: value }
                                      : subject
                                  );
                                  form.setFieldsValue({ subjects: updatedSubjects });
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        {/* Participation Section */}
                        <Divider orientation='left' className='th-primary'>
                          Participation
                        </Divider>
                        <Form.List
                          name={[field.name, 'level_dict']}
                          rules={[
                            {
                              validator: async (_, levels) => {
                                if (!levels || levels.length < 1) {
                                  return Promise.reject(
                                    new Error('At least one Participation is required')
                                  );
                                }
                              },
                            },
                          ]}
                        >
                          {(childFields, { add: addChild, remove: removeChild }) => (
                            <>
                              {childFields.map((childField, childIndex) => (
                                <Row
                                  key={childField.key}
                                  gutter={16}
                                  align='middle'
                                  style={{ marginBottom: 8 }}
                                >
                                  <Col span={16}>
                                    <Form.Item
                                      {...childField}
                                      name={[childField.name, 'subject_name']}
                                      validateTrigger={['onChange', 'onBlur']}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Participation name is required',
                                        },
                                      ]}
                                    >
                                      <Input
                                        placeholder='Participation Name'
                                        disabled={
                                          index < (prefilledData?.length || 0) &&
                                          childIndex <
                                            ((prefilledData[index]?.level_details || [])
                                              .length || 0)
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={4}>
                                    <Form.Item
                                      {...childField}
                                      name={[childField.name, 'sequence']}
                                      validateTrigger={['onChange', 'onBlur']}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Sequence is required',
                                        },
                                        {
                                          validator: (_, value) =>
                                            validateUniqueSequence(
                                              ['subjects', field.name, 'level_dict'],
                                              Number(value)
                                            ),
                                        },
                                      ]}
                                    >
                                      <Input
                                        type='number'
                                        placeholder='Sequence'
                                        min={0}
                                        disabled={
                                          index < (prefilledData?.length || 0) &&
                                          childIndex <
                                            ((prefilledData[index]?.level_details || [])
                                              .length || 0)
                                        }
                                        style={{
                                          WebkitAppearance: 'none',
                                          MozAppearance: 'textfield',
                                          appearance: 'textfield',
                                        }}
                                        onChange={(e) => {
                                          const value = Number(e.target.value);
                                          const subjects =
                                            form.getFieldValue('subjects') || [];
                                          const updatedSubjects = subjects.map(
                                            (subject, subjectIndex) => {
                                              if (subjectIndex === field.name) {
                                                const updatedLevels = (
                                                  subject.level_dict || []
                                                ).map((level, levelIndex) =>
                                                  levelIndex === childField.name
                                                    ? { ...level, sequence: value }
                                                    : level
                                                );
                                                return {
                                                  ...subject,
                                                  level_dict: updatedLevels,
                                                };
                                              }
                                              return subject;
                                            }
                                          );
                                          form.setFieldsValue({
                                            subjects: updatedSubjects,
                                          });
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={2}>
                                    <Form.Item
                                      {...childField}
                                      name={[childField.name, 'is_hide']}
                                      valuePropName='checked'
                                      initialValue={false}
                                    >
                                      <Switch
                                        checkedChildren='Hide'
                                        unCheckedChildren='Show'
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={2}>
                                    {index < (prefilledData?.length || 0) &&
                                    childIndex <
                                      ((prefilledData[index]?.level_details || [])
                                        .length || 0) ? (
                                      <Button
                                        type='link'
                                        danger
                                        icon={<MinusCircleOutlined />}
                                        disabled
                                      />
                                    ) : (
                                      <Tooltip title='Remove Participation'>
                                        <Button
                                          type='link'
                                          danger
                                          onClick={() => removeChild(childField.name)}
                                          icon={<MinusCircleOutlined />}
                                        />
                                      </Tooltip>
                                    )}
                                  </Col>
                                </Row>
                              ))}
                              <Form.Item>
                                <Button
                                  type='dashed'
                                  onClick={() => {
                                    const subjects = form.getFieldValue('subjects') || [];
                                    const updatedSubjects = subjects.map(
                                      (subject, subjectIndex) => {
                                        if (subjectIndex === field.name) {
                                          const levels = subject.level_dict || [];
                                          return {
                                            ...subject,
                                            level_dict: [
                                              ...levels,
                                              {
                                                subject_name: '',
                                                sequence: levels.length + 1,
                                                is_hide: false,
                                              },
                                            ],
                                          };
                                        }
                                        return subject;
                                      }
                                    );
                                    form.setFieldsValue({ subjects: updatedSubjects });
                                  }}
                                  block
                                  icon={<PlusOutlined />}
                                >
                                  Add Participation
                                </Button>
                              </Form.Item>
                            </>
                          )}
                        </Form.List>

                        {/* Parameters Section */}
                        <Divider orientation='left' className='th-primary'>
                          Parameters
                        </Divider>
                        <Form.List
                          name={[field.name, 'params_dict']}
                          rules={[
                            {
                              validator: async (_, params) => {
                                if (!params || params.length < 1) {
                                  return Promise.reject(
                                    new Error('At least one Parameter is required')
                                  );
                                }
                              },
                            },
                          ]}
                        >
                          {(paramFields, { add: addParam, remove: removeParam }) => (
                            <>
                              {paramFields.map((paramField, paramIndex) => (
                                <Row
                                  key={paramField.key}
                                  gutter={16}
                                  align='middle'
                                  style={{ marginBottom: 8 }}
                                >
                                  <Col span={16}>
                                    <Form.Item
                                      {...paramField}
                                      name={[paramField.name, 'param_name']}
                                      validateTrigger={['onChange', 'onBlur']}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Parameter name is required',
                                        },
                                      ]}
                                    >
                                      <Input
                                        placeholder='Parameter Name'
                                        disabled={
                                          index < (prefilledData?.length || 0) &&
                                          paramIndex <
                                            ((prefilledData[index]?.subject_params || [])
                                              .length || 0)
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={4}>
                                    <Form.Item
                                      {...paramField}
                                      name={[paramField.name, 'sequence']}
                                      validateTrigger={['onChange', 'onBlur']}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Sequence is required',
                                        },
                                        {
                                          validator: (_, value) =>
                                            validateUniqueSequence(
                                              ['subjects', field.name, 'params_dict'],
                                              Number(value)
                                            ),
                                        },
                                      ]}
                                    >
                                      <Input
                                        type='number'
                                        placeholder='Sequence'
                                        min={0}
                                        disabled={
                                          index < (prefilledData?.length || 0) &&
                                          paramIndex <
                                            ((prefilledData[index]?.subject_params || [])
                                              .length || 0)
                                        }
                                        style={{
                                          WebkitAppearance: 'none',
                                          MozAppearance: 'textfield',
                                          appearance: 'textfield',
                                        }}
                                        onChange={(e) => {
                                          const value = Number(e.target.value);
                                          const subjects =
                                            form.getFieldValue('subjects') || [];
                                          const updatedSubjects = subjects.map(
                                            (subject, subjectIndex) => {
                                              if (subjectIndex === field.name) {
                                                const updatedParams = (
                                                  subject.params_dict || []
                                                ).map((param, pIndex) =>
                                                  pIndex === paramField.name
                                                    ? { ...param, sequence: value }
                                                    : param
                                                );
                                                return {
                                                  ...subject,
                                                  params_dict: updatedParams,
                                                };
                                              }
                                              return subject;
                                            }
                                          );
                                          form.setFieldsValue({
                                            subjects: updatedSubjects,
                                          });
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={2}>
                                    <Form.Item
                                      {...paramField}
                                      name={[paramField.name, 'is_hide']}
                                      valuePropName='checked'
                                      initialValue={false}
                                    >
                                      <Switch
                                        checkedChildren='Hide'
                                        unCheckedChildren='Show'
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={2}>
                                    {index < (prefilledData?.length || 0) &&
                                    paramIndex <
                                      ((prefilledData[index]?.subject_params || [])
                                        .length || 0) ? (
                                      <Button
                                        type='link'
                                        danger
                                        icon={<MinusCircleOutlined />}
                                        disabled
                                      />
                                    ) : (
                                      <Tooltip title='Remove Parameter'>
                                        <Button
                                          type='link'
                                          danger
                                          onClick={() => removeParam(paramField.name)}
                                          icon={<MinusCircleOutlined />}
                                        />
                                      </Tooltip>
                                    )}
                                  </Col>
                                </Row>
                              ))}
                              <Form.Item>
                                <Button
                                  type='dashed'
                                  onClick={() => {
                                    const subjects = form.getFieldValue('subjects') || [];
                                    const updatedSubjects = subjects.map(
                                      (subject, subjectIndex) => {
                                        if (subjectIndex === field.name) {
                                          const params = subject.params_dict || [];
                                          return {
                                            ...subject,
                                            params_dict: [
                                              ...params,
                                              {
                                                param_name: '',
                                                sequence: params.length + 1,
                                                is_hide: false,
                                              },
                                            ],
                                          };
                                        }
                                        return subject;
                                      }
                                    );
                                    form.setFieldsValue({ subjects: updatedSubjects });
                                  }}
                                  block
                                  icon={<PlusOutlined />}
                                >
                                  Add Parameter
                                </Button>
                              </Form.Item>
                            </>
                          )}
                        </Form.List>

                        {/* Grade Scales Section */}
                        <Divider orientation='left' className='th-primary'>
                          Grade Scales
                        </Divider>
                        <Form.List
                          name={[field.name, 'grade_dict']}
                          rules={[
                            {
                              validator: async (_, grades) => {
                                if (!grades || grades.length < 1) {
                                  return Promise.reject(
                                    new Error('At least one Grade Scale is required')
                                  );
                                }
                              },
                            },
                          ]}
                        >
                          {(gradeFields, { add: addGrade, remove: removeGrade }) => (
                            <>
                              {gradeFields.map((gradeField, gradeIndex) => (
                                <Row
                                  key={gradeField.key}
                                  gutter={16}
                                  align='middle'
                                  style={{ marginBottom: 8 }}
                                >
                                  <Col span={5}>
                                    <Form.Item
                                      {...gradeField}
                                      name={[gradeField.name, 'grade_scale']}
                                      validateTrigger={['onChange', 'onBlur']}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Grade scale is required',
                                        },
                                      ]}
                                    >
                                      <Select
                                        placeholder='Grade'
                                        disabled={
                                          index < (prefilledData?.length || 0) &&
                                          gradeIndex <
                                            ((prefilledData[index]?.grade_details || [])
                                              .length || 0)
                                        }
                                      >
                                        <Option value='1'>L1</Option>
                                        <Option value='2'>L2</Option>
                                        <Option value='3'>L3</Option>
                                        <Option value='4'>L4</Option>
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                  <Col span={10}>
                                    <Form.Item
                                      {...gradeField}
                                      name={[gradeField.name, 'scale_name']}
                                      validateTrigger={['onChange', 'onBlur']}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Scale name is required',
                                        },
                                      ]}
                                    >
                                      <Input
                                        placeholder='Scale Name'
                                        disabled={
                                          index < (prefilledData?.length || 0) &&
                                          gradeIndex <
                                            ((prefilledData[index]?.grade_details || [])
                                              .length || 0)
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={5}>
                                    <Form.Item
                                      {...gradeField}
                                      name={[gradeField.name, 'sequence']}
                                      validateTrigger={['onChange', 'onBlur']}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Sequence is required',
                                        },
                                        {
                                          validator: (_, value) =>
                                            validateUniqueSequence(
                                              ['subjects', field.name, 'grade_dict'],
                                              Number(value)
                                            ),
                                        },
                                      ]}
                                    >
                                      <Input
                                        type='number'
                                        placeholder='Sequence'
                                        min={0}
                                        disabled={
                                          index < (prefilledData?.length || 0) &&
                                          gradeIndex <
                                            ((prefilledData[index]?.grade_details || [])
                                              .length || 0)
                                        }
                                        style={{
                                          WebkitAppearance: 'none',
                                          MozAppearance: 'textfield',
                                          appearance: 'textfield',
                                        }}
                                        onChange={(e) => {
                                          const value = Number(e.target.value);
                                          const subjects =
                                            form.getFieldValue('subjects') || [];
                                          const updatedSubjects = subjects.map(
                                            (subject, subjectIndex) => {
                                              if (subjectIndex === field.name) {
                                                const updatedGrades = (
                                                  subject.grade_dict || []
                                                ).map((grade, gIndex) =>
                                                  gIndex === gradeField.name
                                                    ? { ...grade, sequence: value }
                                                    : grade
                                                );
                                                return {
                                                  ...subject,
                                                  grade_dict: updatedGrades,
                                                };
                                              }
                                              return subject;
                                            }
                                          );
                                          form.setFieldsValue({
                                            subjects: updatedSubjects,
                                          });
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={2}>
                                    <Form.Item
                                      {...gradeField}
                                      name={[gradeField.name, 'is_hide']}
                                      valuePropName='checked'
                                      initialValue={false}
                                    >
                                      <Switch
                                        checkedChildren='Hide'
                                        unCheckedChildren='Show'
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={2}>
                                    {index < (prefilledData?.length || 0) &&
                                    gradeIndex <
                                      ((prefilledData[index]?.grade_details || [])
                                        .length || 0) ? (
                                      <Button
                                        type='link'
                                        danger
                                        icon={<MinusCircleOutlined />}
                                        disabled
                                      />
                                    ) : (
                                      <Tooltip title='Remove Grade Scale'>
                                        <Button
                                          type='link'
                                          danger
                                          onClick={() => removeGrade(gradeField.name)}
                                          icon={<MinusCircleOutlined />}
                                        />
                                      </Tooltip>
                                    )}
                                  </Col>
                                </Row>
                              ))}
                              <Form.Item>
                                <Button
                                  type='dashed'
                                  onClick={() => {
                                    const subjects = form.getFieldValue('subjects') || [];
                                    const updatedSubjects = subjects.map(
                                      (subject, subjectIndex) => {
                                        if (subjectIndex === field.name) {
                                          const grades = subject.grade_dict || [];
                                          return {
                                            ...subject,
                                            grade_dict: [
                                              ...grades,
                                              {
                                                grade_scale: '',
                                                scale_name: '',
                                                sequence: grades.length + 1,
                                                is_hide: false,
                                              },
                                            ],
                                          };
                                        }
                                        return subject;
                                      }
                                    );
                                    form.setFieldsValue({ subjects: updatedSubjects });
                                  }}
                                  block
                                  icon={<PlusOutlined />}
                                >
                                  Add Grade Scale
                                </Button>
                              </Form.Item>
                            </>
                          )}
                        </Form.List>
                      </Card>
                    ))}
                    <Form.Item>
                      <Form.Item>
                        <Button
                          type='dashed'
                          onClick={() => {
                            const subjects = form.getFieldValue('subjects') || [];
                            const newSubject = {
                              subject_name: '',
                              sequence: subjects.length + 1,
                              level_dict: [],
                              params_dict: [],
                              grade_dict: [],
                            };
                            form.setFieldsValue({
                              subjects: [...subjects, newSubject],
                            });
                          }}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Subject
                        </Button>
                      </Form.Item>
                    </Form.Item>
                  </>
                )}
              </Form.List>

              <Form.Item className='text-right'>
                <Button
                  type='primary'
                  htmlType='submit'
                  disabled={!formValid || submitting}
                  loading={submitting}
                >
                  Submit
                </Button>
              </Form.Item>
            </>
          </Form>
        </div>
      </div>
    </Layout>
  );
};

export default VlcReportConfig;
