import React, { useState, useEffect } from 'react';
import {
  Table,
  Radio,
  Select,
  Button,
  message,
  Modal,
  Popover,
  Space,
  Card,
  Form,
  Drawer,
} from 'antd';
import axiosInstance from 'v2/config/axios';
import endpoints from 'v2/config/endpoints';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isSuccess, getStatusLabel } from '../../report-card-utils';
import { getReportCardStatus, updateReportCardStatus } from '../../apis';

const { Option } = Select;
const pageSize = 15;

const ReportStatusTable = () => {
  const selectedBranchId = useSelector(
    (state) => state.commonFilterReducer?.selectedBranch?.id
  );
  const history = useHistory();
  const [reportType, setReportType] = useState('report');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [termId, setTermId] = useState('1');
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [mappingList, setMappingList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalVisible, setModalVisible] = useState(false);
  const [reportCardId, setReportCardId] = useState(null);

  useEffect(() => {
    if (reportType === 'report') {
      fetchReportCardStatus();
    } else if (reportType !== 'report' && selectedBranchId && termId) {
      fetchData(currentPage);
    }
  }, [reportType, termId, updateFlag, currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const fetchData = async (page = 1) => {
    let params = {
      acad_session: selectedBranchId,
      term_id: termId,
      rc_type: reportType,
      page: page,
      page_size: pageSize,
    };
    try {
      const response = await axiosInstance.get(
        `${endpoints.reportCardPipeline.publishReport}`,
        {
          params: params,
        }
      );
      setData(response.data.result.results);
      setTotalPages(response.data.result.total_pages);
      setCurrentPage(response.data.result.current_page);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchReportCardStatus = async () => {
    setLoading(true);
    try {
      const {
        result = [],
        message: msg = 'Error',
        status = 400,
        total_pages: totalPages = 0,
      } = await getReportCardStatus(currentPage, pageSize);
      setMappingList(result);
      setTotalPages(totalPages);
      const isSuccesful = isSuccess(status);
      if (isSuccesful) {
        message.success(msg);
      } else {
        message.error(msg);
      }
    } catch (err) {
      message.error('An error occurred');
    }
    setLoading(false);
  };

  const handlePublishNotification = async (id, notify) => {
    setLoading(true);
    try {
      const payload = { id, status: 2, notify: notify };
      const { status_code: status = 400, message: msg = 'Error' } =
        await updateReportCardStatus(payload);
      const isSuccesful = isSuccess(status);
      if (isSuccesful) {
        message.success(msg);
        setUpdateFlag((prev) => !prev);
        handleModalClose();
      } else {
        message.error(msg);
      }
    } catch (err) {
      message.error('An error occurred');
    }
    setLoading(false);
    handleModalClose();
  };

  const handleUnpublishNotification = async (id) => {
    setLoading(true);
    try {
      const payload = { id, status: 1 };
      const { status_code: status = 400, message: msg = 'Error' } =
        await updateReportCardStatus(payload);
      const isSuccesful = isSuccess(status);
      if (isSuccesful) {
        message.success(msg);
        setUpdateFlag((prev) => !prev);
      } else {
        message.error(msg);
      }
    } catch (err) {
      message.error('An error occurred');
    }
    setLoading(false);
  };

  const handlePublishUnpublish = (record) => {
    if (record?.is_published) {
      handlePublish(record?.id, true);
    } else {
      handlePublish(record?.id, false);
    }
  };

  const showPopover = (id) => {
    setPopoverVisible((prev) => ({ ...prev, [id]: true }));
  };

  const hidePopover = (id) => {
    setPopoverVisible((prev) => ({ ...prev, [id]: false }));
  };

  const handlePublish = async (id, status) => {
    setLoading(true);
    try {
      const payload = {
        is_published: !status,
      };
      const response = await axiosInstance.put(
        `${endpoints.reportCardPipeline.publishReport}${id}/`,
        payload
      );

      if (response.status === 200) {
        message.success(`Successfully ${status ? 'Unpublished' : 'Published'}`);
        setUpdateFlag((prev) => !prev);
      } else {
        message.error(
          response.data.message || `Failed to ${status ? 'unpublish' : 'publish'} report`
        );
      }
    } catch (err) {
      console.error(`Error publishing report:`, err);
      message.error(
        err.response?.data?.message ||
          `Failed to ${status ? 'unpublish' : 'publish'} report`
      );
    } finally {
      setLoading(false);
      setPopoverVisible((prev) => ({ ...prev, [id]: false }));
    }
  };

  const handleModalOpen = (id) => {
    setReportCardId(id);
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setReportCardId(null);
  };

  const resetSelections = () => {
    setTermId('1');
    setPopoverVisible(false);
  };

  const handleTermChange = (value) => {
    setTermId(value);
    setCurrentPage(1);
  };

  const renderButtons = (id, status) => (
    <Space>
      <Button
        type='primary'
        disabled={status === '1'}
        style={{ borderRadius: '8px' }}
        onClick={() =>
          history.push({
            pathname: '/assessment-reports/',
            state: {
              reportcardpipeline: true,
            },
          })
        }
      >
        View
      </Button>
      {status === '1' && (
        <Button
          type='primary'
          style={{ backgroundColor: '#8FD14F', borderRadius: '8px', width: '100px' }}
          onClick={() => handleModalOpen(id)}
        >
          Publish
        </Button>
      )}
      {status === '2' && (
        <Button
          type='primary'
          style={{ borderRadius: '8px', width: '100px' }}
          danger
          onClick={() => handleUnpublishNotification(id)}
        >
          Unpublish
        </Button>
      )}
    </Space>
  );

  const getRowStyle = (record, index) => {
    return {
      backgroundColor: index % 2 === 0 ? '#ffffff' : '#f0f0f0',
    };
  };

  const columns = [
    {
      title: <span className='th-white th-event-12 th-fw-700 text-nowrap'>Branch</span>,
      dataIndex: ['acad_session', 'branch_name'],
      key: 'branch',
    },
    {
      title: <span className='th-white th-event-12 th-fw-700 text-nowrap'>Grade</span>,
      dataIndex: ['grade', 'grade_name'],
      key: 'grade',
    },
    {
      title: <span className='th-white th-event-12 th-fw-700 text-nowrap'>Term</span>,
      dataIndex: 'term_id_name',
      key: 'term',
    },
    {
      title: <span className='th-white th-event-12 th-fw-700 text-nowrap'>Status</span>,
      key: 'status',
      render: (_, record) => {
        return reportType === 'report'
          ? getStatusLabel(record.status)
          : record?.is_published
          ? 'Published'
          : 'Not Published';
      },
    },
    {
      title: <span className='th-white th-event-12 th-fw-700 text-nowrap'>Action</span>,
      key: 'action',
      align: 'center',
      render: (_, record) => (
        <>
          {reportType !== 'report' && (
            <Popover
              content={
                <div>
                  <p>
                    Are you sure you want to{' '}
                    {record.is_published ? 'unpublish' : 'publish'}?
                  </p>
                  <Button onClick={() => handlePublishUnpublish(record)}>Yes</Button>
                  <Button onClick={() => hidePopover(record.id)}>No</Button>
                </div>
              }
              title={`Confirm ${record.is_published ? 'Unpublish' : 'Publish'}`}
              trigger='click'
              visible={popoverVisible[record.id]}
              onVisibleChange={(visible) =>
                visible ? showPopover(record.id) : hidePopover(record.id)
              }
            >
              <Button
                type={record.is_published ? 'danger' : 'primary'}
                style={{ width: '100px', borderRadius: '8px' }}
                size='small'
              >
                {record.is_published ? 'Unpublish' : 'Publish'}
              </Button>
            </Popover>
          )}
        </>
      ),
    },
  ];

  const tableColumns = [
    {
      title: <span className='th-white th-event-12 th-fw-700 text-nowrap'>Branch</span>,
      dataIndex: 'branch_details',
      key: 'branch',
      width: 50,
      align: 'center',
      render: (branchDetails) => branchDetails?.branch_name || '',
    },
    {
      title: <span className='th-white th-event-12 th-fw-700 text-nowrap'>Grade</span>,
      dataIndex: 'grade_details',
      key: 'grade',
      width: 170,
      align: 'center',
      render: (gradeDetails) => gradeDetails?.grade_name || '',
    },
    {
      title: <span className='th-white th-event-12 th-fw-700 text-nowrap'>Status</span>,
      dataIndex: 'status',
      key: 'status',
      width: 170,
      align: 'center',
      render: (status) => getStatusLabel(status),
    },
    {
      title: <span className='th-white th-event-12 th-fw-700 text-nowrap'>Action</span>,
      key: 'action',
      width: 170,
      align: 'center',
      render: (_, record) => renderButtons(record.id, record.status),
    },
  ];

  return (
    <div>
      <Card>
        <Radio.Group
          onChange={(e) => {
            setCurrentPage(1);
            setReportType(e.target.value);
            if (e.target.value !== 'report') {
              resetSelections();
            }
          }}
          value={reportType}
          className='mb-3'
        >
          <Radio value='report'>Report Card</Radio>
          <Radio value='1'>Arts & SOCH Report</Radio>
          <Radio value='2'>Sports Report</Radio>
        </Radio.Group>
        {reportType !== 'report' && (
          <div
            style={{
              marginBottom: 16,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Select
              style={{ width: '30%' }}
              value={termId}
              onChange={(value) => handleTermChange(value)}
              placeholder='Select Term'
            >
              <Option value='1'>Semester 1</Option>
              <Option value='2'>Semester 2</Option>
            </Select>
          </div>
        )}
        {reportType === 'report' ? (
          <>
            <Table
              columns={tableColumns}
              dataSource={mappingList}
              rowKey='id'
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: totalPages * pageSize,
                onChange: setCurrentPage,
              }}
              onRow={(record, index) => ({
                style: getRowStyle(record, index),
              })}
            />

            <Modal
              title='Send Notification'
              visible={modalVisible}
              onCancel={handleModalClose}
              footer={[
                <Button
                  key='no'
                  onClick={() => handlePublishNotification(reportCardId, false)}
                >
                  No
                </Button>,
                <Button
                  key='yes'
                  type='primary'
                  onClick={() => handlePublishNotification(reportCardId, true)}
                >
                  Yes
                </Button>,
              ]}
            >
              <p className='m-4'>Do you want to send whatsapp notification?</p>
            </Modal>
          </>
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            loading={loading}
            rowKey='id'
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              total: totalPages * pageSize,
              onChange: handlePageChange,
            }}
            onRow={(record, index) => ({
              style: getRowStyle(record, index),
            })}
          />
        )}
      </Card>
    </div>
  );
};

export default ReportStatusTable;
