import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import axiosInstance from '../../../config/axios';
import endpoints from '../../../config/endpoints';
import { InputNumber, message, Popconfirm, Select, Tag } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  ReloadOutlined,
  EyeFilled,
} from '@ant-design/icons';
import QuestionDetailsModal from './questionDetailsModal';

const Question = ({
  currentQuestion,
  index,
  onlyShowQuestion,
  createQuestionmodalData,
  setCreateQuestionmodalData,
}) => {
  const [questionViewModal, setQuestionViewModal] = useState(false);
  const handleViewQuestion = () => {
    setQuestionViewModal(true);
  };
  const handleCloseViewQuestion = () => {
    setQuestionViewModal(false);
  };

  const handleMarksChange = (value) => {
    setCreateQuestionmodalData({
      ...createQuestionmodalData,
      data: {
        ...createQuestionmodalData?.data,
        selectedQuestionPaper: {
          ...createQuestionmodalData?.data?.selectedQuestionPaper,
          questions: createQuestionmodalData?.data?.selectedQuestionPaper?.questions?.map(
            (eachQ, eachIndex) => {
              return eachIndex !== index
                ? { ...eachQ }
                : { ...eachQ, question_mark: value };
            }
          ),
        },
      },
    });
  };

  return (
    <>
      <div className='row px-1'>Question {index + 1}</div>
      <div
        className='row p-2 mb-3 th-br-8 th-pointer'
        style={{ outline: '1px solid #f3f3f3' }}
      >
        <div className='col-md-12 px-0 d-flex justify-content-between'>
          <div className='pb-2'>
            <Tag>{currentQuestion?.question_type_name}</Tag>

            <span className='th-12'>
              {currentQuestion?.grade?.[0]?.grade_name} |{' '}
              {currentQuestion?.subject?.[0]?.subject_name} |
              {currentQuestion?.volume?.[0]?.volume_name} |{' '}
              {currentQuestion?.module?.[0]?.lt_module_name} |
              {currentQuestion?.chapter?.[0]?.chapter_name} |{' '}
              {currentQuestion?.key_concept?.[0]?.topic_name}
            </span>
          </div>
          <div className='text-right' style={{ minWidth: '175px' }}>
            <Tag
              icon={<EyeFilled />}
              color='processing'
              onClick={() => {
                handleViewQuestion();
              }}
              className='th-pointer th-12'
            >
              View
            </Tag>
            {!onlyShowQuestion && (
              <>
                <span className='th-12 ml-1'>Marks:</span>
                <InputNumber
                  // className='w-100'
                  controls={false}
                  placeholder='Question Mark'
                  size='small'
                  style={{ width: '55px' }}
                  disabled={createQuestionmodalData?.isEdit?.show}
                  onChange={(value) => handleMarksChange(value)}
                  value={currentQuestion?.question_mark}
                  precision={2}
                />
              </>
            )}
          </div>
        </div>
        <div
          className='col-md-12 pl-0 border-right th-bg-blue-1 th-br-8'
          style={{ maxHeight: '200px', overflowY: 'auto' }}
        >
          <div
            className=' row p-2 '
            // onClick={handleViewQuestion}
          >
            {/* <span className='pr-2'>Q {(currentPage - 1) * 15 + index + 1}.</span>{' '} */}
            <div className='th-question-box '>
              {ReactHtmlParser(currentQuestion?.question)}
            </div>{' '}
          </div>
        </div>
      </div>
      {questionViewModal && (
        <QuestionDetailsModal
          questionViewModal={questionViewModal}
          handleCloseViewQuestion={handleCloseViewQuestion}
          currentQuestion={currentQuestion}
          index={index}
        />
      )}
    </>
  );
};

export default Question;
