import React, { useEffect, useRef, useState } from 'react';
import Layout from 'containers/Layout';
import {
  Breadcrumb,
  Button,
  Form,
  Input,
  message,
  Modal,
  Pagination,
  Popconfirm,
  Table,
  Tag,
} from 'antd';
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import endpoints from 'config/endpoints';
import axiosInstance from 'config/axios';
import { useHistory } from 'react-router-dom';

const ZoneList = () => {
  const formRef = useRef();
  const history = useHistory();
  const [zones, setZones] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  //eslint-disable-next-line
  const [pageLimit, setPageLimit] = useState(15);
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState(null);

  useEffect(() => {
    // if (history.location?.state?.data?.previousURL !== '/master-management/city') {
    //   history.push('/master-management/city');
    // }
    fetchZone({ page: 1 });
  }, []);

  const fetchZone = async (params = {}) => {
    let fixedParams = {
      paginated: true,
      page_size: pageLimit,
      ...params,
    };
    setLoading(true);
    try {
      const response = await axiosInstance.get(`${endpoints.masterManagement.zone}`, {
        params: { ...fixedParams },
      });
      setZones(response?.data?.result?.results);
      setTotalPage(response?.data?.result?.count);
    } catch (error) {
      console.error('Error fetching zones:', error);
    } finally {
      setLoading(false);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    formRef.current.resetFields();
    setEditData(null);
    setIsModalVisible(false);
  };

  const handleSubmit = async (values) => {
    try {
      let formData = {
        zone_name: values?.zone_name,
      };
      if (editData) {
        await axiosInstance.put(
          `${endpoints.masterManagement.zone}${editData?.id}/`,
          formData
        );
        message.success('zone edited successfully');
      } else {
        await axiosInstance.post(`${endpoints.masterManagement.zone}`, formData);
        message.success('zone added successfully');
      }

      setIsModalVisible(false);
      fetchZone({ page: 1 }); // Refresh the zone list
    } catch (error) {
      message.error(`${editData ? 'Error in updating zone' : 'Error in adding zone'}`);
    } finally {
      formRef.current.resetFields();
      handleCancel();
    }
  };

  const columns = [
    {
      title: <span className='th-white th-fw-700 '>Sl. No.</span>,
      dataIndex: 'id',
      render: (data, row, index) => <span className='th-black-1 th-14'>{index + 1}</span>,
      key: 'id',
      width: '10%',
    },
    {
      title: <span className='th-white th-fw-700 '>Zone Name</span>,
      dataIndex: 'zone_name',
      render: (data) => <span className='th-black-1 th-14'>{data}</span>,
      key: 'zone_name',
    },

    {
      title: <span className='th-white th-fw-700 '>Action</span>,
      key: 'action',
      render: (_, record) => (
        <span>
          <Tag
            icon={<EditOutlined />}
            className='th-br-4 th-pointer'
            onClick={() => handleEdit(record)}
            color='processing'
          >
            Edit
          </Tag>
          {!record?.is_delete ? (
            <Popconfirm title='Sure to delete?' onConfirm={() => handleDelete(record)}>
              <Tag
                icon={<DeleteOutlined />}
                style={{ marginLeft: 8 }}
                color='error'
                className='th-br-4 th-pointer'
              >
                Delete
              </Tag>
            </Popconfirm>
          ) : (
            <Popconfirm title='Sure to restore?' onConfirm={() => handleDelete(record)}>
              <Tag icon={<UndoOutlined />} style={{ marginLeft: 8 }} color='error'>
                Delete
              </Tag>
            </Popconfirm>
          )}
        </span>
      ),
    },
  ];

  const handleEdit = (record) => {
    // Implement edit functionality
    // setEditData((prevState) => ({ ...prevState, ...record }));
    setEditData(record);
    setIsModalVisible(true);
    setTimeout(() => {
      formRef.current.setFieldsValue({
        zone_name: record?.zone_name,
      });
    }, 100);
  };

  const handleDelete = async (record) => {
    // Implement delete functionality

    try {
      await axiosInstance.delete(`${endpoints.masterManagement.zone}${record?.id}/`);
      message.success('zone deleted successfully');
    } catch (error) {
      message.error('failed to deleting zone');
    } finally {
      fetchZone({ page: 1 });
    }
  };

  const handleRestore = async (record) => {
    // Implement delete functionality

    try {
      await axiosInstance.delete(`${endpoints.masterManagement.zone}${record?.id}/`);
      message.success('zone deleted successfully');
    } catch (error) {
      message.error('failed to deleting zone');
    } finally {
      fetchZone({ page: 1 });
    }
  };

  console.log({ editData });

  return (
    <React.Fragment>
      <Layout>
        <div className='row py-3'>
          <div className='col-md-6 th-bg-grey' style={{ zIndex: 2 }}>
            <Breadcrumb separator='>'>
              <Breadcrumb.Item className='th-black-1 th-16 th-grey'>
                Master Management
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className='th-black-1 th-16 th-grey th-pointer'
                onClick={() => {
                  history.push('/master-management/branch-table');
                }}
              >
                Branch
              </Breadcrumb.Item>
              <Breadcrumb.Item className='th-black-1 th-16'>Zone</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12'>
            <div className='th-bg-white th-br-5 py-3 px-2 px-4'>
              <div className='d-flex justify-content-between align-items-center'>
                <div>
                  <h5>Zone List</h5>
                </div>
                <div>
                  <Button
                    icon={<PlusOutlined />}
                    onClick={showModal}
                    style={{ marginBottom: 16 }}
                    type='primary'
                    className=' ml-3 th-br-4'
                  >
                    Add Zone
                  </Button>
                </div>
              </div>

              {/* <Button
                icon={<ArrowLeftOutlined />}
                onClick={() => history.goBack()}
                style={{ marginBottom: 16 }}
                type='default'
                className='float-right'
              >
                Go Back
              </Button> */}

              <Table
                className='th-table'
                rowClassName={(record, index) =>
                  index % 2 === 0 ? 'th-bg-grey' : 'th-bg-white'
                }
                loading={loading}
                columns={columns}
                rowKey={(record) => record?.id}
                dataSource={zones}
                pagination={false}
                scroll={{ y: '300px' }}
              />

              {zones?.length > 0 && (
                <div className='pt-3 '>
                  <Pagination
                    current={pageNo}
                    total={totalPage}
                    showSizeChanger={false}
                    pageSize={pageLimit}
                    onChange={(current) => {
                      setPageNo(current);
                      fetchZone({ page: current });
                    }}
                    className='text-center'
                  />
                </div>
              )}

              <Modal
                title={`${editData ? 'Edit Zone' : 'Add Zone'}`}
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                centered
                className='th-upload-modal'
              >
                <div className='p-3'>
                  <Form ref={formRef} layout='vertical' onFinish={handleSubmit}>
                    <Form.Item
                      name='zone_name'
                      label='Zone Name'
                      rules={[{ required: true, message: 'Please enter the zone name' }]}
                    >
                      <Input
                        // defaultValue={editData ? editData?.zone_name : null}/
                        value={editData?.zone_name ? editData?.zone_name : ''}
                        placeholder='Zone Name'
                      />
                    </Form.Item>
                    <Form.Item className='text-center'>
                      <Button type='primary' htmlType='submit' className='px-4 th-br-4'>
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default ZoneList;
