if (
  window.location.href.includes('localhost') ||
  window.location.href.includes('ui-revamp1') ||
  window.location.href.includes('orchids-stage')
) {
  var X_DTS_HOST = 'qa.olvorchidnaigaon.letseduvate.com';
} else if (window.location.href.includes('b2b-stage')) {
  var X_DTS_HOST = 'qa.mit.letseduvate.com';
} else if (
  window.location.href.includes('test.orchids') ||
  window.location.href.includes('orchids-prod') ||
  window.location.href.includes('orchids.prod')
) {
  var X_DTS_HOST = 'orchids.letseduvate.com';
} else if (
  window.location.href.includes('b2b-prod') ||
  window.location.href.includes('b2b.prod')
) {
  var X_DTS_HOST = 'b2b.letseduvate.com';
} else {
  var X_DTS_HOST = window.location.host;
}

export { X_DTS_HOST };
