import React, { useEffect, useRef } from 'react';
import './style.scss';
import ReactHtmlParser from 'react-html-parser';
const QuestionPaperPrintPreview = React.forwardRef(({ templateFrom, isPrint }, ref) => {
  const contentRef = useRef();
  const printContainerRef = useRef(null);

  useEffect(() => {
    const handleBeforePrint = () => {
      const content = contentRef.current;
      const elements = content.querySelectorAll('.check-break');
      const printContainer = printContainerRef.current;
      elements.forEach((element) => {
        const rect = element.getBoundingClientRect();
        if (rect.bottom > printContainer.scrollHeight) {
          element.classList.add('force-page-break');
        }
      });
    };
    window.addEventListener('beforeprint', handleBeforePrint);
    return () => {
      window.removeEventListener('beforeprint', handleBeforePrint);
    };
  }, []);

  useEffect(() => {
    const addPageNumbers = () => {
      const printContainer = printContainerRef.current;
      const totalPages = Math.ceil(printContainer.scrollHeight / 1450);
      for (let i = 1; i <= totalPages; i++) {
        const pageNumberDiv = document.createElement('div');
        const pageNumber = document.createTextNode(`Page ${i}`);
        pageNumberDiv.style.writingMode = 'vertical-lr';
        pageNumberDiv.style.position = 'absolute';
        pageNumberDiv.style.bottom = `calc(100% - (${i} * 385mm - 10mm))`;
        pageNumberDiv.style.height = '130px';
        pageNumberDiv.appendChild(pageNumber);
        printContainer.appendChild(pageNumberDiv);
        pageNumberDiv.style.left = `calc(100% - (${pageNumberDiv.offsetWidth}px) + 25px)`;
      }
    };

    if (isPrint) {
      addPageNumbers();
    }
  }, [isPrint]);

  return (
    <div ref={ref} className='print-container'>
      <div ref={printContainerRef} className='container border p-1 content-container'>
        <div className='d-flex bg-light p-2'>
          <div className='col-6 d-flex align-items-center'>
            <div className='mr-3 bg-white br-10 rounded-6'>
              <img
                src={templateFrom?.school_logo}
                alt='OrchidsLogo'
                className='rounded-6 image'
                style={{ width: '80px', height: '80px' }}
              />
            </div>
            <div>
              <div className='school-name'>{templateFrom?.school_name}</div>
              <div className='text-muted'>Powered By Eduvate</div>
            </div>
          </div>
          <div className='col-6 d-flex flex-column align-items-end'>
            <div className='p-2 rounded mb-2 w-75 d-flex bg-custom'>
              <div>Name:</div>
            </div>
            <div className='p-2 rounded mb-2 w-75 d-flex bg-custom'>
              <div>ERP:</div>
            </div>
          </div>
        </div>
        <div className='d-flex p-2 mb-3  justify-content-between bg-custom border-bottom-custom'>
          <div className='d-flex flex-row'>
            <div className='font-weight-bold'> {templateFrom?.grade}</div>
          </div>
          <div className='d-flex flex-row'>
            <div className='font-weight-bold mr-1'>Subject:</div>
            <div>{templateFrom?.subject_name}</div>
          </div>
          <div className='d-flex flex-row'>
            <div className='font-weight-bold mr-1'>Duration:</div>
            <div>{templateFrom?.duration} Min</div>
          </div>
          <div className='d-flex flex-row'>
            <div className='font-weight-bold mr-1'>Total Marks:</div>
            <div> {templateFrom?.marks}</div>
          </div>
        </div>
        <div className='mb-1 rounded d-flex flex-column align-items-center pl-4 pr-4 p-1'>
          <div className='font-weight-bold title-cutom '>{templateFrom?.test_name}</div>
        </div>
        <div className='mb-1 rounded d-flex flex-column pl-4 pr-4 p-1 bg-custom'>
          <div className='font-weight-bold'>General Instructions</div>
          <p className='th-questions-instructions'>
            {ReactHtmlParser(templateFrom?.test_instructions)}
          </p>
        </div>
        {templateFrom?.sections?.map((eachSection, sectionIndex) => {
          const sectionTotalMarks = eachSection.questions.reduce(
            (total, question) => total + parseFloat(question.marks || 0),
            0
          );
          const sectionQuestion =
            eachSection?.section_marks / eachSection?.questions?.length;

          return (
            <div className='p-3'>
              <div
                className='py-2 px-4 rounded bg-custom check-break section-container'
                // ref={contentRef}
              >
                <div className='d-flex align-items-center'>
                  <div className='font-weight-bold bold text-center flex-fill ml-5'>
                    Section{' '}
                    {eachSection?.description.replace(/\d+/, (match) =>
                      String.fromCharCode(64 + parseInt(match))
                    )}
                  </div>
                  <div className='font-weight-bold section-mark'>
                    {eachSection?.questions?.length > 0 && sectionQuestion
                      ? `Marks: ${eachSection?.questions?.length} × ${sectionQuestion}M = ${eachSection?.section_marks}M`
                      : '0M'}
                  </div>
                </div>
                <div>{eachSection?.instruction}</div>
              </div>
              {eachSection?.questions?.map((eachQuestion, index) => {
                return (
                  <>
                    <div className='p-2'>
                      <div
                        className='d-flex justify-content-between mb-1'
                        style={{ width: '100%' }}
                      >
                        <div className='d-flex' style={{ width: '90%' }}>
                          <div className='mr-2'>
                            {index +
                              1 +
                              (sectionIndex > 0
                                ? templateFrom?.sections
                                    ?.slice(0, sectionIndex)
                                    ?.map((each) => each.questions)
                                    ?.flat()?.length
                                : 0)}
                            .
                          </div>
                          <div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: eachQuestion?.question_answer?.length
                                  ? eachQuestion?.question_answer[0]?.question
                                  : null,
                              }}
                              className='pl-1 align-self-center generated-question-div question-custom check-break mb-1'
                            />
                          </div>
                        </div>

                        <div className='th-question-marks-info text-right'>{`${eachQuestion?.question_mark[0]} marks`}</div>
                      </div>
                      {eachQuestion?.question_type !== 'Fill in the Blanks' ? (
                        eachQuestion?.question_answer?.length &&
                        eachQuestion?.question_answer[0]?.options &&
                        eachQuestion?.question_answer[0]?.options?.length ? (
                          <>
                            {eachQuestion?.question_answer?.length ? (
                              <div className='container row ml-2'>
                                {eachQuestion?.question_answer[0]?.options?.map(
                                  (eachOption, index) => {
                                    return (
                                      <div className='col-6 option-container check-break'>
                                        <div className='row'>
                                          <div className='font-size-12 mr-1'>
                                            {String.fromCharCode(
                                              65 + index
                                            )?.toLowerCase()}
                                            .
                                          </div>
                                          <div className='font-size-12'>
                                            {eachOption[`option${index + 1}`]?.title
                                              ? eachOption[`option${index + 1}`]?.title
                                              : ![undefined, null].includes(
                                                  eachOption[`option${index + 1}`]
                                                    ?.optionValue
                                                )
                                              ? typeof eachOption[`option${index + 1}`]
                                                  ?.optionValue === 'string'
                                                ? eachOption[`option${index + 1}`]
                                                    ?.optionValue
                                                : eachOption[
                                                    `option${index + 1}`
                                                  ]?.optionValue.toString()
                                              : null}
                                          </div>
                                        </div>
                                        <div className='option-image-container'>
                                          {Array.isArray(
                                            eachOption[`option${index + 1}`]?.images
                                          )
                                            ? eachOption[`option${index + 1}`]?.images
                                                ?.length
                                              ? eachOption[
                                                  `option${index + 1}`
                                                ]?.images.map((eachImage, indexImage) => (
                                                  <img
                                                    src={eachImage}
                                                    alt='option image'
                                                    className='p-2 check-break'
                                                    key={indexImage}
                                                    style={{
                                                      maxHeight: '150px',
                                                      maxWidth: '200px',
                                                    }}
                                                  />
                                                ))
                                              : null
                                            : eachOption[`option${index + 1}`]
                                                ?.images && (
                                                <img
                                                  src={
                                                    eachOption[`option${index + 1}`]
                                                      ?.images
                                                  }
                                                  alt='option image'
                                                  className='p-2 check-break'
                                                  key={index + 1}
                                                  style={{
                                                    maxHeight: '150px',
                                                    maxWidth: '200px',
                                                  }}
                                                />
                                              )}
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            ) : null}
                          </>
                        ) : null
                      ) : null}
                      {/* {eachQuestion?.question?.sub_questions?.map(
                            (each_sub, sub_index) => {
                              return (
                                <>
                                  <div className='pl-4 check-break'>
                                    <QuestionView question={each_sub} showAns={false} />
                                  </div>
                                </>
                              );
                            }
                          )} */}
                    </div>
                  </>
                );
              })}
            </div>
          );
        })}
        <div className='text-center pt-3'>-End of Question Paper-</div>
      </div>
    </div>
  );
});
export default QuestionPaperPrintPreview;
