import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  btn: {
    width: '100%',
    background: '#ECECEC',
    color: theme.palette.secondary.main,
    margin: 4,
    fontFamily: 'Andika New Basic, sans-serif',
  },
  closeBtn: {
    padding: '0.3rem 1.1rem',
    borderRadius: '0.6rem',
    fontSize: '0.8rem',
  },
  questionCotainer: {
    margin: '10px 0px',
    color: theme.palette.secondary.main,
  },
  questionText: {
    background: '#ECECEC',
    padding: 4,
    display: 'flex',
    alignItems: 'top',
  },
  questionSubText: {
    background: '#ECECEC',
    padding: 4,
    display: 'flex',
    alignItems: 'top',
    marginTop: '5px',
    fontFamily: 'Andika New Basic, sans-serif',

  },
  answersContainer: {
    padding: 2,
    width: '90%',
    margin: 'auto',
    fontFamily: 'Andika New Basic, sans-serif',
  },
}));
export default useStyles;
