import React, { useContext, useState, useEffect } from 'react';
import { Button, Table, Modal, Input, message, Drawer, Space, Tabs } from 'antd';
import { useSelector } from 'react-redux';
import Loader from 'components/loader/loader';
import axiosInstance from 'config/axios';
import endpoints from 'config/endpoints';
import { AlertNotificationContext } from 'context-api/alert-context/alert-state';
import { generateQueryParamSting } from 'utility-functions';
import apiRequest from 'containers/dashboard/StudentDashboard/config/apiRequest';
import NoFilterData from 'components/noFilteredData/noFilterData';
import EypReportCard from 'containers/assessment-central/assesment-report-card/eypReportCard';
import SportsMarksUpload from '../sportsScoreUpload';
import axios from 'axios';
import { X_DTS_HOST } from 'v2/reportApiCustomHost';
import StudentAssessment from '../viewReportCard';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const { TextArea } = Input;

const StudentWiseReport = ({
  setisstudentList,
  isstudentList,
  setIsPreview,
  filterData,
  setReportCardDataNew,
  setPEReportCardData,
  setIsFilter,
  isFilter,
  eypConfig,
  showPEConfig,
}) => {
  const history = useHistory();
  const { token } = JSON.parse(localStorage.getItem('userDetails')) || {};
  const [studentList, setStudentList] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const { setAlert } = useContext(AlertNotificationContext);
  const selectedAcademicYear = useSelector(
    (state) => state.commonFilterReducer?.selectedYear
  );
  const [openModal, setOpenModal] = useState(false);
  const [studentId, setStudentId] = useState();
  const [teacherRemark, setTeacherRemark] = useState('');
  const { user_id: teacher_id } = JSON.parse(localStorage.getItem('userDetails'));
  const [isEditRemark, setIsEditRemark] = useState(false);
  const [editId, setEditId] = useState();
  const [vlcDrawer, setVlcDrawer] = useState(false);
  const [sportsDrawer, setSportsDrawer] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);

  const [vlcReportData, setVlcReportData] = useState(null);
  console.log({
    setisstudentList,
    isstudentList,
    setIsPreview,
    filterData,
    setReportCardDataNew,
    setPEReportCardData,
    setIsFilter,
    isFilter,
    eypConfig,
    showPEConfig,
  });

  useEffect(() => {
    if (isFilter || isstudentList) getERP();
  }, [isFilter, isstudentList]);

  useEffect(() => {
    setStudentList([]);
  }, [filterData]);

  const getERP = () => {
    setIsLoading(true);
    setIsFilter(false);
    let params = `?branch=${filterData?.branch?.branch?.id}&session_year=${selectedAcademicYear?.id}&grade=${filterData.grade?.grade_id}&section=${filterData.section?.section_id}`;
    axiosInstance
      .get(`${endpoints.communication.studentUserList}${params}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setStudentList(result.data?.data?.results);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleNewPreview = (erpId, ID) => {
    let paramObj = {
      acad_session_id: filterData.branch?.id,
      erp_id: erpId,
      grade_id: filterData.grade?.grade_id,
      section_id: filterData.section?.section_id,
    };
    const isPreview = Object.values(paramObj).every(Boolean);
    if (!isPreview) {
      for (const [key, value] of Object.entries(paramObj).reverse()) {
        if (key === 'acad_session_id' && !Boolean(value))
          setAlert('error', `Please select Branch`);
        else if (!Boolean(value)) setAlert('error', `Please select ${key}.`);
      }
      return;
    } else {
      setIsLoading(true);
      let params = `?${generateQueryParamSting({ ...paramObj })}`;
      fetchNewReportCardData(params);
      if (showPEConfig) {
        fetchPEReprtCardData({
          branch_id: filterData?.branch?.branch?.id,
          grade_id: filterData?.grade?.grade_id,
          erp_id: erpId,
        });
      }
    }
  };

  const fetchNewReportCardData = (params) => {
    setIsLoading(true);
    apiRequest(
      'get',
      `${endpoints.assessmentReportTypes.reportCardDataNew}${params}`,
      null,
      null,
      false,
      10000
    )
      .then((result) => {
        if (result) {
          setisstudentList(false);
          setReportCardDataNew(result?.data?.result);
          setIsPreview(true);
          setIsLoading(false);
        }
        setIsLoading(false);
        setisstudentList(false);
      })
      .catch((error) => {
        setAlert(
          'error',
          error?.response?.data?.message || 'Error while fetching Report card'
        );
        setIsLoading(false);
        setisstudentList(false);
      });
  };

  const fetchPEReprtCardData = (params = {}) => {
    setIsLoading(true);
    axios
      .get(`${endpoints.assessmentReportTypes.physicalEducationReportCard}`, {
        params: params,
        headers: { 'X-DTS-HOST': X_DTS_HOST, authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if (response.status === 200) {
          setPEReportCardData(response.data);
        } else {
          setPEReportCardData([]);
        }
      })
      .catch((error) => {
        message.error(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleRemark = (id) => {
    setOpenModal(true);
    setStudentId(id);
    setIsLoading(true);
    axiosInstance
      .get(
        `assessment/teacher-remarks/?teacher=${teacher_id}&student=${id}&acad_session=${filterData?.branch?.id}&grade=${filterData?.grade?.grade_id}`
      )
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status_code === 200) {
          if (res?.data?.result?.length > 0) {
            setTeacherRemark(res?.data?.result[0].remarks);
            setIsEditRemark(true);
            setEditId(res?.data?.result[0].id);
          } else {
            setTeacherRemark('');
            setIsEditRemark(false);
          }
        } else {
          return setAlert('error', 'Something went wrong , fetching Remark Failed !');
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setAlert(
          'error',
          error?.response?.data?.message ||
            error?.response?.data?.msg ||
            'fetching Remark Failed !'
        );
      });
  };

  const handleRemarkSubmit = () => {
    setIsLoading(true);
    let params = {
      student: studentId,
      teacher: teacher_id,
      remarks: teacherRemark,
      acad_session: filterData?.branch?.id,
      grade: filterData?.grade?.grade_id,
    };
    if (isEditRemark) {
      axiosInstance
        .put(`assessment/teacher-remarks/${editId}/`, params)
        .then((res) => {
          setIsLoading(false);
          if (res?.data?.status_code !== 200) {
            setAlert('error', res?.data?.message || 'Remarks Submission Failed !');
          } else {
            setOpenModal(false);
            setAlert('success', res?.data?.message || 'Remarks Submitted Successfully !');
            setIsEditRemark(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setAlert(
            'error',
            error?.response?.data?.message ||
              error?.response?.data?.msg ||
              'Submitting Remarks Failed !'
          );
        });
    } else {
      axiosInstance
        .post(`assessment/teacher-remarks/`, params)
        .then((res) => {
          setIsLoading(false);
          if (res?.data?.status_code !== 200) {
            setAlert('error', res?.data?.message || 'Remarks Submission Failed !');
          } else {
            setOpenModal(false);
            setAlert('success', res?.data?.message || 'Remarks Submitted Successfully !');
            setIsEditRemark(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setAlert(
            'error',
            error.response.data.message ||
              error.response.data.msg ||
              'Submitting Remarks Failed !'
          );
        });
    }
  };

  const columns = [
    {
      title: <span className='th-white th-fw-700'>Student Name</span>,
      dataIndex: ['user', 'first_name'],
      key: 'name',
      render: (text, record) => `${record.user.first_name} ${record.user.last_name}`,
    },
    {
      title: <span className='th-white th-fw-700'>ERP Id</span>,
      dataIndex: 'erp_id',
      key: 'erp_id',
    },
    {
      title: <span className='th-white th-fw-700'>Action</span>,
      key: 'action',
      render: (text, record) => (
        <Space>
          {!eypConfig.includes(String(filterData.grade?.grade_id)) && (
            <Button onClick={() => handleRemark(record.user.id)}>Remark</Button>
          )}
          {eypConfig.includes(String(filterData.grade?.grade_id)) ? (
            <EypReportCard
              erpId={record.erp_id}
              gradeId={filterData.grade?.grade_id}
              acadSessionId={filterData?.branch?.id}
              branchName={filterData?.branch?.branch?.branch_name}
            />
          ) : (
            // <Button onClick={() => handleNewPreview(record.erp_id, record.id)}>
            //   View
            // </Button>
            <Button
              onClick={() =>
                history.push('/assessment-reports/view-reports', {
                  filterData: filterData,
                  studentData: record,
                  studentList: studentList,
                })
              }
            >
              View
            </Button>
          )}
          <Button
            onClick={() => {
              setSelectedStudent(record);
              setVlcDrawer(true);
            }}
          >
            Upload Arts & SOCH Marks
          </Button>

          <Button
            onClick={() => {
              setSelectedStudent(record);
              setSportsDrawer(true);
            }}
          >
            Upload Sports Marks
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div className='px-4'>
      {loading && <Loader />}
      <Table
        columns={columns}
        dataSource={studentList}
        rowKey={(record) => record.id}
        pagination={false}
      />
      <Modal
        title='Remarks'
        visible={openModal}
        onOk={handleRemarkSubmit}
        onCancel={() => setOpenModal(false)}
      >
        <TextArea
          rows={4}
          value={teacherRemark}
          onChange={(e) => setTeacherRemark(e.target.value)}
          maxLength={400}
        />
      </Modal>
      <Drawer
        title={`Arts & SOCH Marks Upload - ${
          selectedStudent
            ? `${selectedStudent.user.first_name} ${selectedStudent.user.last_name}`
            : ''
        }`}
        maskClosable={false}
        width={800}
        visible={vlcDrawer}
        onClose={() => {
          setVlcDrawer(false);
          setSelectedStudent(null);
        }}
      >
        <StudentAssessment
          gradeId={filterData.grade?.grade_id}
          acadSessionId={filterData?.branch?.id}
          studentId={selectedStudent?.id}
          sectionId={filterData?.section?.id}
          visible={vlcDrawer}
        />
      </Drawer>
      <Drawer
        title={`Sports Marks Upload - ${
          selectedStudent
            ? `${selectedStudent.user.first_name} ${selectedStudent.user.last_name}`
            : ''
        }`}
        maskClosable={false}
        width={800}
        visible={sportsDrawer}
        onClose={() => {
          setSportsDrawer(false);
          setSelectedStudent(null);
        }}
      >
        <SportsMarksUpload
          visible={sportsDrawer}
          studentData={selectedStudent}
          filterData={filterData}
        />
      </Drawer>
      {studentList.length === 0 && <NoFilterData data='No Data Found' />}
    </div>
  );
};

export default StudentWiseReport;
