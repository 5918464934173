import ENVCONFIG from 'config/config';
import endpoints from '../../config/endpoints';
let { user_level: userLevel } = JSON.parse(localStorage.getItem('userDetails')) || '';
let token = JSON.parse(localStorage.getItem('userDetails'))?.token || '';
const {
  apiGateway: { baseEvent },
} = ENVCONFIG;

export const FaqRoutes = [
  { key: 'Take Class', path: '/take-class' },
  {
    key: 'View Class',
    path:
      window.location.host === endpoints?.aolConfirmURL
        ? '/online-class/view-class'
        : '/erp-online-class',
  },
  { key: 'Resources', path: '/online-class/resource' },
  { key: 'Workshop', path: '/online-class/workshop' },
  {
    key: 'Attend Online Class',
    path:
      window.location.host === endpoints?.aolConfirmURL
        ? '/online-class/attend-class'
        : '/erp-online-class-student-view',
  },
  {
    key: 'Teacher View Class',
    path:
      window.location.host === endpoints?.aolConfirmURL
        ? '/online-class/teacher-view-class'
        : '/erp-online-class-teacher-view',
  },
  { key: 'Create Class', path: '/online-class/create-class' },
  { key: 'Attendance Teacher View', path: '/online-class/attendance-teacher-view' },
  { key: 'Online Class', path: '/create-class' },
  { key: 'Online Class', path: '/aol-view' },
  { key: 'Configuration', path: '/homework/admin' },
  { key: 'Management View', path: '/homework/coordinator' },
  { key: 'Student Homework', path: '/homework/student' },
  { key: 'Teacher Homework', path: '/homework/teacher' },
  { key: 'Centralized Homework', path: '/homework/centralized' },
  { key: 'Upload Homework', path: '/centralized-homework/homework-upload-status' },
  { key: 'Teacher Classwork Report', path: '/classwork-report-teacher-view' },
  { key: 'Student Classwork Report', path: '/classwork/student-report' },
  { key: 'Teacher Homework Report', path: '/homework-report-teacher-view' },
  { key: 'Student Homework Report', path: '/homework/student-report' },
  { key: 'Communication', path: '/communication' },
  { key: 'Add Group', path: '/addgroup' },
  { key: 'View Role', path: '/role-management' },
  { key: 'User Groups', path: '/viewgroup' },
  { key: 'View Publication', path: '/publications' },
  { key: 'Announcement', path: '/announcement-list' },
  { key: 'Send Message', path: '/communication/sendmessage' },
  { key: 'Add SMS Credit', path: '/communication/smscredit' },
  { key: 'SMS&Email Log', path: '/communication/messageLog' },
  { key: 'Dashboard', path: '/dashboard' },
  { key: 'User Management', path: '/user-management' },
  { key: 'OnBoarding Report', path: '/onboarding-report' },
  { key: 'Ebook View', path: '/ebook/view' },
  { key: 'Online Books', path: '/online-books/' },
  { key: 'Ibook View', path: '/intelligent-book/view' },
  { key: 'Create User', path: '/user-management/create-user' },
  { key: 'Bulk Upload Status', path: '/user-management/bulk-upload' },
  { key: 'Bulk Status Upload', path: '/finance/BulkOperation/BulkUploadStatus' },
  { key: 'View User', path: '/user-management/view-users' },
  { key: 'Section Shuffle', path: '/user-management/section-shuffling' },
  { key: 'Virtual School', path: '/virtual-school' },
  { key: 'Access-Blocker', path: '/user-management/access-blocker' },
  { key: 'Assign Role', path: '/user-management/assign-role' },
  { key: 'Branch', path: '/master-management/branch-table' },
  { key: 'Branch Acad Mapping', path: '/master-management/branch-acad-table' },
  { key: 'Subject Mapping', path: '/master-management/subject-mapping-table' },
  { key: 'Subject', path: '/master-management/subject-table' },
  { key: 'Section Mapping', path: '/master-management/section-mapping-table' },
  { key: 'Chapter Creation', path: '/master-management/chapter-type-table' },
  { key: 'Topic', path: '/master-management/topic-table' },
  { key: 'Section', path: '/master-management/section-table' },
  { key: 'Grade', path: '/master-management/grade-table' },
  { key: 'Academic Year', path: '/master-management/academic-year-table' },
  { key: 'Message Type', path: '/master-management/message-type-table' },
  { key: 'Signature Upload', path: '/master-management/signature-upload' },
  { key: 'Event Category', path: '/master-management/event-category' },
  { key: 'System Config', path: '/master-management/system-config' },
  { key: 'Discussion Category', path: '/master-management/discussion-category' },
  { key: 'Course', path: '/course-list' },
  { key: 'Course Price', path: '/course-price' },
  { key: 'Content Mapping', path: '/subject/grade' },
  { key: 'Teacher Forum', path: '/teacher-forum' },
  { key: 'Student Forum', path: '/student-forum' },
  { key: 'homework-teacher', path: '/homework/teacher' },
  {
    key: 'Teacher View',
    path: `${
      window.location.pathname == '/lesson-plan/teacher-view/period-view'
        ? '/lesson-plan/teacher-view/period-view'
        : '/lesson-plan/teacher-view'
    }`,
  },
  {
    key: 'Student View',
    path: `${
      window.location.pathname == '/lesson-plan/student-view/period-view'
        ? '/lesson-plan/student-view/period-view'
        : '/lesson-plan/student-view'
    }`,
  },
  { key: 'Assign Transport Fees', path: '/feeType/assign_other_fees' },
  { key: 'App/Reg Fee Type', path: '/feeType/RegistrationFee' },
  { key: 'Teacher Calendar', path: '/attendance-calendar/teacher-view' },
  { key: 'Student Calendar', path: '/attendance-calendar/student-view' },
  { key: 'Management Report', path: '/lesson-plan/report' },
  { key: 'Graphical Report', path: '/lesson-plan/graph-report' },
  { key: 'discussion-forum', path: '/discussion-forum' },
  { key: 'Student Blogs', path: '/blog/student/dashboard' },
  { key: 'Teacher Blogs', path: '/blog/teacher' },
  { key: 'Management Blogs', path: '/blog/admin' },
  { key: 'Principal Blogs', path: '/blog/principal' },
  {
    key: 'Application/registration Receipt Book',
    path: '/finance/Application/registration/ReceiptBook',
  },
  { key: 'Genre', path: '/blog/genre' },
  { key: 'Word Count Configuration', path: '/blog/wordcount-config' },
  { key: 'Student Diary', path: '/diary/student' },
  { key: 'Teacher Diary', path: '/diary/teacher' },
  { key: 'Student Shuffle', path: '/finance/StudentShuffleRequest' },
  { key: 'Question Bank', path: '/question-chapter-wise' },
  { key: 'Question Paper', path: '/assessment-question' },
  { key: 'Create Test', path: '/assesment' },
  {
    key: 'View Attendance',
    path:
      userLevel == 13 ? '/student-attendance-dashboard' : '/teacher-attendance-verify',
  },
  { key: 'Mark Student Attendance', path: '/mark-student-attendance' },
  { key: 'Mark Staff Attendance', path: '/mark-staff-attendance' },
  { key: 'Take Test', path: '/assessment' },
  { key: 'Individual Student Report', path: '/assessment-student-report' },
  { key: 'Assessment Report', path: '/assessment-reports' },
  { key: 'Report Card', path: '/assessment/report-card' },
  { key: 'Marks Upload', path: '/assessment/marks-upload' },
  { key: 'Report Card Pipeline', path: '/assessment/report-card-pipeline' },
  { key: 'Report Card Category', path: '/assessment/category' },
  { key: 'Report Card Config', path: '/assessment/report-config' },
  { key: 'Grading System Config', path: '/assessment/grading-system' },
  { key: 'ID Card View', path: '/student-id-card' },
  { key: 'View School Strength', path: '/student-strength' },
  { key: 'Signature Upload', path: '/master-management/signature-upload' },
  { key: 'Teacher Circular', path: '/teacher-circular' },
  { key: 'Student Circular', path: '/student-circular' },
  {
    key: 'Grievance Teacher',
    action: () =>
      window.open(
        `${ENVCONFIG?.apiGateway?.finance}/sso/ticket/${token}#/auth/login`,
        '_blank'
      ),
  },
  {
    key: 'Grievance Student',
    action: () =>
      window.open(
        `${ENVCONFIG?.apiGateway?.finance}/sso/ticket/${token}#/auth/login`,
        '_blank'
      ),
  },
  { key: 'Normal Fee Type', path: '/feeType/normalFeeType' },
  { key: 'Misc. Fee Type', path: '/feeType/miscFeeType' },
  { key: 'Curricular Fee Type', path: '/feeType/CurricularFeeType' },
  { key: 'Add Transport Fees', path: '/feeType/OtherFeeType' },
  { key: 'Assign Transport Fees', path: '/feeType/assign_other_fees' },
  { key: 'App/Reg Fee Type', path: '/feeType/RegistrationFee' },
  { key: 'View Fee Plan', path: '/feePlan/ViewFeePlan' },
  { key: 'Concession Settings', path: '/finance/ConcessionSetting' },
  { key: 'Ledger', path: '/finance/Ledger' },
  { key: 'Total Paid and Due Report', path: '/finance/TotalPaidReport' },
  {
    key: 'Other Fee Total Paid and Due Report',
    path: '/finance/OtherFeeTotalPaidReport',
  },
  { key: 'Tally Report', path: '/finance/TallyReport' },
  {
    key: 'Application/registration Receipt Book',
    path: '/finance/Application/registration/ReceiptBook',
  },
  { key: 'Wallet Report', path: '/finance/WalletReport' },
  { key: 'Concession Report', path: '/finance/ConcessionReport' },
  { key: 'Bounce Report', path: '/finance/ChequeBounceReport' },
  { key: 'Student Shuffle', path: '/finance/StudentShuffleRequest' },
  { key: 'Misc. Fee Class', path: '/finance/MiscFeeClass' },
  { key: 'Assign Coupon', path: '/finance/AssignCoupon' },
  { key: 'Create Coupon', path: '/finance/CreateCoupon' },
  { key: 'Deposit', path: '/finance/DepositTab' },
  { key: 'Total Forms & Report', path: '/finance/TotalFormReport' },
  { key: 'Unassign Fee Requests', path: '/finance/UnassignFeeRequests' },
  { key: 'Create Receipt Ranges', path: '/finance/ReceiptRange' },
  { key: 'Store Report', path: '/finance/StoreReport' },
  { key: 'Ledger Tab', path: '/student/LegerTab' },
  { key: 'Registration Form', path: '/admissions/registrationForm/' },
  { key: 'Admission Form', path: '/finance/accountant/admissionForm' },
  { key: 'Application Form', path: '/finance/accountant/applicationFrom' },
  { key: 'Online Admissions', path: '/finance/admissions/OnlineAdmission' },
  { key: 'Manage Bank & Fee Accounts', path: '/finance/BankAndFeeAccounts' },
  { key: 'Last Date Settings', path: '/finance/Setting/LastDateSetting' },
  { key: 'Receipt Settings', path: '/finance/Setting/ReceiptSettings' },
  { key: 'Fee Structure Upload', path: '/finance/BulkOperation/Feestructure' },
  { key: 'Student Wallet', path: '/finance/studentwallet' },
  { key: 'Fee Collection', path: '/finance/student/FeeCollection' },
  {
    key: 'Assign Delivery charge kit books & uniform',
    path: '/finance/student/AssignDeliveryCharge',
  },
  { key: 'Assign / Change fee plan', path: '/finance/student/ChnageFeePlanToStudent' },
  { key: 'Bulk Report Upload', path: '/finance/BulkOperation/BulkReportUpload' },
  { key: 'Bulk Upload Status', path: '/finance/BulkOperation/BulkUploadStatus' },
  { key: 'Upload Online Payments', path: '/finance/BulkOperation/UploadOnlinePayment' },
  {
    key: 'Permanent Active / Inactive',
    path: '/finance/BulkOperation/BulkActiveInactive',
  },
  {
    key: 'Temporary Active / Inactive',
    path: '/finance/BulkOperation/BulkActiveInactiveParent',
  },
  { key: 'Active/Inactive', path: '/finance/Student/ActiveInactive' },
  { key: 'Student Active/Inactive', path: '/finance/Student/ActiveInactive/Admin' },
  { key: 'Student Promotion', path: '/finance/Student/StudentPromotion' },
  { key: 'QR code', path: '/finance/Student/OqCodeGenerate' },
  { key: 'Communications', path: '/finance/Student/Communication' },
  { key: 'Income Tax Certificate', path: '/finance/Student/IncomeTaxCertificate' },
  { key: 'Fee Pay Request', path: '/finance/Approval/Requests/FeePaymentRequests' },
  { key: 'Store Pay Request', path: '/finance/Approval/Requests/StorePaymentRequests' },
  {
    key: 'Accept and Reject payments',
    path: '/finance/Approval/Requests/AcceptRejectPayment',
  },
  { key: 'Post Dated cheque', path: '/finance/Approval/Requests/PostDateCheque' },
  { key: 'Billing Details', path: '/finance/E-Mandate/BillingDetails' },
  {
    key: 'Generate Subsequent Payment',
    path: '/finance/E-Mandate/GenerateSubsequentPayment',
  },
  { key: 'Add Branch', path: '/finance/E-Mandate/AddBranch' },
  { key: 'Customer Details', path: '/finance/E-Mandate/CustomerDetails' },
  { key: 'Add Customer Details', path: '/finance/E-Mandate/AdminCustomerDetails' },
  { key: 'Add Order Details', path: '/finance/E-Mandate/OrderDetails' },
  { key: 'Order Details', path: '/finance/E-Mandate/OrderDetails' },
  { key: 'Total Billing Details', path: '/finance/E-Mandate/TotalBillingDetails' },
  { key: 'Create Link', path: '/finance/E-Mandate/CreateLink' },
  { key: 'Petty Cash Expense', path: '/finance/Expanse Management/PettyExpense' },
  { key: 'Student Info', path: '/finance/student/studentInfo' },
  { key: 'Party List', path: '/finance/Expanse Management/PartyList' },
  {
    key: 'Student Shuffle Requests',
    path: '/finance/Approval/Requests/StudentShuffleRequest',
  },
  { key: 'Manage Payment', path: '/finance/ManagePayments' },
  { key: 'Fee Structure', path: '/finance/FeeStructure' },
  { key: 'Books & Uniform', path: '/finance/student_store' },
  { key: 'Shipping Payment', path: '/finance/ShippingPayment' },
  { key: 'School store', path: '/Store/AddItems' },
  { key: 'Kit', path: '/Store/CreateKit' },
  { key: 'sub Category allow', path: '/Store/SubCategoryAllow' },
  { key: 'Accountant Login', path: '/finance/BulkOperation/AccountantLogin' },
  { key: 'Add Gst', path: '/Store/AddGst' },
  { key: 'Order Status Upload', path: '/Store/OrderStatusUpload' },
  { key: 'Receipt Book', path: '/finance/ReceiptBook' },
  { key: 'Transactions Report', path: '/finance/TransactionStatus' },
  { key: 'Teacher Time Table', path: '/timetable/teacherview' },
  { key: 'Student Time Table', path: '/timetable/studentview' },
  { key: 'Book Appointment', path: '/appointments' },
  { key: 'Appointment Responder', path: '/responder-view' },
  { key: 'Contact Us', path: '/contact-us' },
  { key: 'Student Attendance Report', path: '/student-attendance-report' },
  { key: 'Manage Orchadio', path: '/orchadio/manage-orchadio' },
  { key: 'Student Orchadio', path: '/orchadio/view-orchadio' },
  { key: 'Teacher Homework Report', path: '/homework-report-teacher-view' },
  { key: 'Teacher Classwork Report', path: '/classwork-report-teacher-view' },
  { key: 'Subject Training', path: '/subjectTrain' },
  { key: 'Enroll Courses', path: '/enrollTrainingfCourses' },
  { key: 'Enrolled Courses', path: '/enrolledSelfCourses' },
  { key: 'Induction Training', path: '/inductionTrain' },
  { key: 'Treasure Box', path: '/tressurebox' },
  { key: 'My Notes', path: '/learning-notes' },
  { key: 'Calendar', path: '/inhouse_calendar' },
  { key: 'Learning', path: '/learningVideos' },
  { key: 'Notification', path: '/View_notification' },
  { key: 'Blogs', path: '/blogSureLearning' },
  { key: 'Trainer Driven Courses', path: '/trainerDriven' },
  { key: 'Self Driven Courses', path: '/assignedCoursesByCordinator' },
  { key: 'All/Completed Courses', path: '/sure_learning/completed_courses' },
  { key: 'Class Initiation Form', path: '/sure_learning/class_initiation_form' },
  { key: 'Initiate Class', path: '/sure_learning/initiate_class' },
  { key: 'Resources', path: '/sure_learning/resources' },
  { key: 'Assessment Scores', path: '/sure_learning/assessment_report' },
  {
    key: 'View Finance',
    path: `https://uidev.erpfinance.letseduvate.com/sso/${token}`,
  },
  { key: 'Event Tracker', path: `${baseEvent}?${token}` },
  { key: 'Create Area', path: '/observation-area' },
  { key: 'Create Observation', path: '/observation' },
  { key: 'Evaluation', path: '/observation-evalutaion' },
  { key: 'Observation Report', path: '/observation-report' },
  { key: 'Create Activity', path: '/blog/create' },
  { key: 'My Blogs', path: '/blog/wall/redirect' },
  { key: 'Blog Activity', path: '/blog/wall/central/redirect' },
  { key: 'Create Activity Type', path: '/blog/createactivitytype' },
  { key: 'School Wall', path: '/school-wall' },
  { key: 'Blog', path: '/blog/activityreview' },
  // { key: 'Create Rating', path: '/blog/createratingtype' },
  { key: 'Create Rating', path: '/create-subject-wise-rating' },
  { key: 'Class Section Wise Strength', path: '/student_count_report' },
  { key: 'Connection pod', path: '/online-class/connection-pod' },
  { key: 'Assign User Level', path: '/user-level-table' },
  { key: 'Trainee Courses', path: '/sure-learning-trainee-courses' },
  { key: 'Assign Trainee', path: '/sure-learning-assign-teacher' },
  { key: 'Reassign Trainee', path: '/sure-learning-re-assign-teacher' },
  { key: 'Assessment Review', path: '/sure-learning-assessment-review' },
  { key: 'Report', path: '/sure-learning-course-wise-user-report' },
  { key: 'Branch wise report', path: '/sure-learning-branch-level-detailed-report' },
  { key: 'Enroll Self Courses', path: '/enrollTrainingfCourses' },
  { key: 'Self Courses', path: '/assignedCoursesByCordinator' },
  { key: 'Assign Lead Teacher\n', path: '/sure-learning-assign-lead-teacher' },
  { key: 'Consolidated Report', path: '/sure-learning-consolidated-report' },
  { key: 'Weekly Report', path: '/sure-learning-weekly-report' },
  { key: 'Assign Teacher', path: '/sure-learning/assign-teacher' },
  { key: 'Curriculum Completion', path: '/curriculum-completion-student-subject/' },
  { key: 'File Category', path: '/file-category/' },
  { key: 'Non Academic Staff', path: '/user-management/non-academic-staff' },
  { key: 'Activity Dashboard', path: '/activity-management-dashboard' },
  { key: 'FAQ', path: '/frequently-asked-questions' },
  {key : 'Assign Video Observation', path : '/assign-video-observation'}
];
