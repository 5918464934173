export const STUDENT_ONLINECLASS_REQUEST = 'STUDENT_ONLINECLASS_REQUEST';
export const STUDENT_ONLINECLASS_SUCCESS = 'STUDENT_ONLINECLASS_SUCCESS';
export const STUDENT_ONLINECLASS_FAILURE = 'STUDENT_ONLINECLASS_FAILURE';

export const MANAGEMENT_ONLINECLASS_REQUEST = 'MANAGEMENT_ONLINECLASS_REQUEST';
export const MANAGEMENT_ONLINECLASS_SUCCESS = 'MANAGEMENT_ONLINECLASS_SUCCESS';
export const MANAGEMENT_ONLINECLASS_FAILURE = 'MANAGEMENT_ONLINECLASS_FAILURE';

export const RESOURCE_ONLINECLASS_REQUEST = 'RESOURCE_ONLINECLASS_REQUEST';
export const RESOURCE_ONLINECLASS_SUCCESS = 'RESOURCE_ONLINECLASS_SUCCESS';
export const RESOURCE_ONLINECLASS_FAILURE = 'RESOURCE_ONLINECLASS_FAILURE';

export const CLASS_ACCEPT_SUCCESS = 'CLASS_ACCEPT_SUCCESS';
export const CLASS_JOIN_SUCCESS = 'CLASS_JOIN_SUCCESS';

export const LIST_GRADE_REQUEST = 'LIST_GRADE_REQUEST';
export const LIST_GRADE_SUCCESS = 'LIST_GRADE_SUCCESS';
export const LIST_GRADE_FAILURE = 'LIST_GRADE_FAILURE';

export const LIST_SECTION_REQUEST = 'LIST_SECTION_REQUEST';
export const LIST_SECTION_SUCCESS = 'LIST_SECTION_SUCCESS';
export const LIST_SECTION_FAILURE = 'LIST_SECTION_FAILURE';

export const CANCEL_CLASS = 'CANCEL_CLASS';

export const SET_TAB = 'SET_TAB';

export const SET_RESOURCE_TAB = 'SET_RESOURCE_TAB';

export const SET_MANAGEMENT_PAGE = 'SET_MANAGEMENT_PAGE';
export const SET_RESOURCE_PAGE = 'SET_RESOURCE_PAGE';
