const fetchParticipants = 'fetch_participants';
const fetchLeaderboard = 'fetch_leaderboard';
const removeUser = 'remove_user';
const respondToQuestion = 'respond_to_question';
const startQuiz = 'start_quiz';
const endQuiz = 'end_quiz';
const joinLobby = 'join_lobby';

// event labesl
const eventLabels = {
  fetchParticipants,
  fetchLeaderboard,
  removeUser,
  respondToQuestion,
  startQuiz,
  endQuiz,
  joinLobby,
};

export {
  eventLabels,
  fetchParticipants,
  fetchLeaderboard,
  removeUser,
  respondToQuestion,
  startQuiz,
  endQuiz,
  joinLobby,
};
