import React, { useEffect, useState } from 'react';
import ClickerAssignUsersFilters from './filter';
import { getArrayValues } from '../../../../utility-functions';
import endpoints from '../../../config/endpoints';
import axiosInstance from 'v2/config/axios';
import {
  Empty,
  message,
  Pagination,
  Spin,
  Switch,
  Table,
  Tag,
  Modal,
  Button,
  Form,
  InputNumber,
  Popconfirm,
} from 'antd';

const AssignClickerUser = ({ tabValue }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState({
    branch: null,
    grade: null,
    section: [],
    loading: false,
  });
  const [usersListData, setUsersListData] = useState({
    data: null,
    page: { currentPage: 1, pageSize: 15, totalData: null },
  });

  const [modalState, setModalState] = useState({
    visible: false,
    erpId: '',
    clickerId: '',
  });

  useEffect(() => {
    setFilterData({
      branch: null,
      grade: null,
      section: [],
      loading: false,
    });
  }, [tabValue]);

  const getUsersList = (params = {}) => {
    const { branch, grade, section } = filterData;
    const newParams = {
      acad_session: params.branch?.id || branch?.id,
      grade: params.grade?.grade_id || grade?.grade_id,
      section_mapping_id: params.section
        ? getArrayValues(params.sections, 'id')?.join(',')
        : section
        ? getArrayValues(section, 'id')?.join(',')
        : null,
      page: params.page || usersListData.page.currentPage,
    };

    if (!newParams.acad_session) {
      message.error('Please select branch');
      return;
    }

    if (!newParams.grade) {
      message.error('Please select grade');
      return;
    }

    setFilterData((prev) => ({ ...prev, loading: true }));
    axiosInstance
      .get(endpoints.clicker.clickerUser, { params: { ...newParams } })
      .then((res) => {
        setUsersListData({
          data: res.data.results,
          page: {
            currentPage: res.data.current_page,
            totalData: res.data.count,
            pageSize: usersListData.page.pageSize,
          },
        });
      })
      .catch((err) => {
        message.error(err?.response?.data?.message ?? err?.message);
        setUsersListData([]);
      })
      .finally(() => setFilterData((prev) => ({ ...prev, loading: false })));
  };

  const handleModalOpen = (record) => {
    setModalState({
      visible: true,
      erpId: record.erp_id,
      clickerId: record.clicker_id || '',
    });
    form.setFieldsValue({ clickerId: record.clicker_id || null });
  };

  const handleModalClose = () => {
    setModalState({
      visible: false,
      erpId: null,
      clickerId: null,
    });
    form.resetFields();
  };

  const handleSubmit = () => {
    setLoading(true);
    axiosInstance
      .post(endpoints.clicker.clickerUser, {
        erp_id: modalState.erpId,
        clicker_id: modalState.clickerId,
      })
      .then((res) => {
        if (res?.data?.status_code === 200) {
          message.success(res?.data?.message || 'Clicker ID updated successfully');
          getUsersList();
          handleModalClose();
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ||
            error?.message ||
            'Failed to update Clicker ID'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const removeClicker = (id) => {
    axiosInstance
      .delete(`${endpoints.clicker.clickerUser}${id}/`, {
        erp_id: modalState.erpId,
        clicker_id: modalState.clickerId,
      })
      .then((res) => {
        message.success(res?.data?.message || 'Clicker ID removed successfully');
        getUsersList();
        handleModalClose();
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ||
            error?.message ||
            'Failed to remove Clicker ID'
        );
      });
  };

  const updateClickerStatus = (id, status) => {
    axiosInstance
      .put(`${endpoints.clicker.clickerUser}${id}/`, {
        is_active: status,
      })
      .then((res) => {
        if (res?.data?.status_code == 200) {
          message.success(res?.data?.message || 'Clicker ID status updated successfully');
          getUsersList();
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ||
            error?.message ||
            'Failed to update Clicker ID'
        );
      });
  };

  const columns = [
    {
      title: <span className='th-white th-fw-700'>Name</span>,
      width: '20%',
      dataIndex: 'name',
      render: (name) => <span className='th-black-1 th-14 text-break'>{name}</span>,
    },
    {
      title: <span className='th-white th-fw-700'>ERP Id</span>,
      width: '20%',
      dataIndex: 'erp_id',
      render: (id) => <span className='th-black-1 th-14 text-break'>{id}</span>,
    },
    {
      title: <span className='th-white th-fw-700'>Clicker Id</span>,
      width: '15%',
      render: (isActive, record) => (
        <span className='th-black-1 th-14 text-break'>
          {record?.clicker_is_delete ? '' : record?.clicker_id}
        </span>
      ),
    },
    {
      title: <span className='th-white th-fw-700'>Status</span>,
      width: '15%',
      dataIndex: 'clicker_is_active',
      align: 'center',
      render: (isActive, record) => (
        <Switch
          checked={isActive}
          onClick={() => {
            if (record?.clicker_auth_id) {
              updateClickerStatus(record?.clicker_auth_id, !isActive);
            } else {
              message.warning('Please assign Clicker ID first !!');
            }
          }}
        />
      ),
    },
    {
      title: <span className='th-white th-fw-700'>Action</span>,
      align: 'center',
      width: '30%',
      render: (_, record) => (
        <>
          <Tag
            className='th-br-4 th-pointer'
            color='processing'
            onClick={() => handleModalOpen(record)}
          >
            {record.clicker_id ? 'Edit Clicker Id' : 'Assign Clicker Id'}
          </Tag>

          {!record.clicker_is_delete && record?.clicker_id && (
            <Popconfirm
              onConfirm={() => removeClicker(record?.clicker_auth_id)}
              title='Are you sure to remove this clicker id?'
              okText='Yes'
              cancelText='No'
            >
              <Tag className='th-br-4 th-pointer' color='error'>
                Remove Clicker Id
              </Tag>
            </Popconfirm>
          )}
        </>
      ),
    },
  ];

  return (
    <div className='row'>
      <div className='col-md-12 th-bg-white th-br-8 px-1'>
        <div className='th-br-5'>
          <ClickerAssignUsersFilters
            filterData={filterData}
            setFilterData={setFilterData}
            getListData={getUsersList}
            bulkUpload={{ visible: true, title: 'Bulk Upload' }}
          />
        </div>
        <div className='th-br-5 py-2'>
          {usersListData.data ? (
            <div className='col-md-12 mb-3'>
              <Table
                className='th-table'
                rowClassName={(_, index) =>
                  index % 2 === 0 ? 'th-bg-grey' : 'th-bg-white'
                }
                loading={filterData.loading}
                columns={columns}
                dataSource={usersListData.data}
                rowKey='id'
                pagination={false}
                scroll={{ y: '50vh' }}
              />
              {usersListData.data.length > 0 && (
                <Pagination
                  current={usersListData.page.currentPage}
                  total={usersListData.page.totalData}
                  pageSize={usersListData.page.pageSize}
                  onChange={(current) => getUsersList({ page: current })}
                  className='text-center pt-3'
                  showSizeChanger={false}
                />
              )}
            </div>
          ) : (
            <div
              className='row justify-content-center align-items-center'
              style={{ height: 300 }}
            >
              <div className='col-12 text-center'>
                <Spin tip='Loading...' size='large' spinning={filterData.loading}>
                  {!filterData.loading && (
                    <Empty description='Please apply filter to view users.' />
                  )}
                </Spin>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        title={modalState.clickerId ? 'Edit Clicker ID' : 'Assign Clicker ID'}
        visible={modalState.visible}
        onCancel={handleModalClose}
        footer={null}
        centered
        className='th-modal'
      >
        <Form form={form} onFinish={handleSubmit} layout='vertical'>
          <Form.Item
            label='Clicker ID'
            name='clickerId'
            rules={[
              { required: true, message: 'Please enter a Clicker ID' },
              {
                pattern: /^[1-9]\d{9,}$/,
                message: 'Clicker ID must be at least 10 digits and not start with zero',
              },
            ]}
            initialValue={modalState.clickerId}
          >
            <InputNumber
              controls={false}
              onKeyDown={(e) => {
                if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                  e.preventDefault();
                }
              }}
              className='w-100'
              placeholder='Enter Clicker ID'
              value={modalState.clickerId}
              onChange={(e) => setModalState((prev) => ({ ...prev, clickerId: e }))}
            />
          </Form.Item>
          <Form.Item>
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
              <Button onClick={handleModalClose}>Cancel</Button>
              <Button type='primary' htmlType='submit' loading={loading}>
                Submit
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AssignClickerUser;
