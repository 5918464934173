 
  const countryList=[
    {  
      "country": "Afghanistan",
      "callingCode": "+993"
    },
    {  
      "country": "Albania",
      "callingCode": "+355"
    },
    {  
      "country": "Algeria",
      "callingCode": "+213"
    },
    {  
      "country": "Andorra",
      "callingCode": "+376"
    },
    {  
      "country": "Angola",
      "callingCode": "+244"
    },
    {  
      "country": "Anguilla",
      "callingCode": "+1"
    },
    {  
      "country": "Argentina",
      "callingCode": "+54"
    },
    {  
      "country": "Armenia",
      "callingCode": "+374"
    },
    {  
      "country": "Aruba",
      "callingCode": "+297"
    },
    {  
      "country": "Australia",
      "callingCode": "+61"
    },
    {  
      "country": "Austria",
      "callingCode": "+43"
    },
    {  
      "country": "Azerbaijan",
      "callingCode": "+994"
    },
    {  
      "country": "Bahamas",
      "callingCode": "+1"
    },
    {  
      "country": "Bahrain",
      "callingCode": "+973"
    },
    {  
      "country": "Bangladesh",
      "callingCode": "+880"
    },
    {  
      "country": "Barbados",
      "callingCode": "+1"
    },
    {  
      "country": "Belarus",
      "callingCode": "+375"
    },
    {  
      "country": "Belgium",
      "callingCode": "+32"
    },
    {  
      "country": "Belize",
      "callingCode": "+501"
    },
    {  
      "country": "Benin",
      "callingCode": "+229"
    },
    {  
      "country": "Bermuda",
      "callingCode": "+1"
    },
    {  
      "country": "Bhutan",
      "callingCode": "+975"
    },
    {  
      "country": "Bolivia",
      "callingCode": "+591"
    },
    {  
      "country": "Bonaire",
      "callingCode": "+599"
    },
    {  
      "country": "Botswana",
      "callingCode": "+267"
    },
    {  
      "country": "Brazil",
      "callingCode": "+55"
    },
    {  
      "country": "Brunei",
      "callingCode": "+673"
    },
    {  
      "country": "Bulgaria",
      "callingCode": "+359"
    },
    {  
      "country": "Burma",
      "callingCode": "+95"
    },
    {  
      "country": "Burundi",
      "callingCode": "+257"
    },
    {  
      "country": "Cambodia",
      "callingCode": "+855"
    },
    {  
      "country": "Cameroon",
      "callingCode": "+237"
    },
    {  
      "country": "Canada",
      "callingCode": "+1"
    },
    {  
      "country": "Cape Verde",
      "callingCode": "+238"
    },
    {  
      "country": "Ceuta",
      "callingCode": "+34"
    },
    {  
      "country": "Chad",
      "callingCode": "+235"
    },
    {  
      "country": "Chile",
      "callingCode": "+56"
    },
    {  
      "country": "Colombia",
      "callingCode": "+57"
    },
    {  
      "country": "Comoros",
      "callingCode": "+269"
    },
    {  
      "country": "Croatia",
      "callingCode": "+385"
    },
    {  
      "country": "Cuba",
      "callingCode": "+53"
    },
    {  
      "country": "Curaçao",
      "callingCode": "+599"
    },
    {  
      "country": "Cyprus",
      "callingCode": "+357"
    },
    {  
      "country": "Denmark",
      "callingCode": "+45"
    },
    {  
      "country": "Djibouti",
      "callingCode": "+253"
    },
    {  
      "country": "Dominica",
      "callingCode": "+1"
    },
    {  
      "country": "East Timor",
      "callingCode": "+670"
    },
    {  
      "country": "Easter Island",
      "callingCode": "+56"
    },
    {  
      "country": "Ecuador",
      "callingCode": "+593"
    },
    {  
      "country": "Egypt",
      "callingCode": "+20"
    },
    {  
      "country": "England",
      "callingCode": "+44"
    },
    {  
      "country": "Equatorial Guinea",
      "callingCode": "+240"
    },
    {  
      "country": "Eritrea",
      "callingCode": "+291"
    },
    {  
      "country": "Estonia",
      "callingCode": "+372"
    },
    {  
      "country": "Ethiopia",
      "callingCode": "+251"
    },
    {  
      "country": "Eswatini",
      "callingCode": "+268"
    },
    {  
      "country": "Falkland Islands",
      "callingCode": "+500"
    },
    {  
      "country": "Fiji",
      "callingCode": "+679"
    },
    {  
      "country": "Finland",
      "callingCode": "+358"
    },
    {  
      "country": "France",
      "callingCode": "+33"
    },
    {  
      "country": "Gabon",
      "callingCode": "+241"
    },
    {  
      "country": "Gambia",
      "callingCode": "+220"
    },
    {  
      "country": "Georgia",
      "callingCode": "+995"
    },
    {  
      "country": "Germany",
      "callingCode": "+49"
    },
    {  
      "country": "Ghana",
      "callingCode": "+233"
    },
    {  
      "country": "Gibraltar",
      "callingCode": "+350"
    },
    {  
      "country": "Greece",
      "callingCode": "+30"
    },
    {  
      "country": "Greenland",
      "callingCode": "+299"
    },
    {  
      "country": "Grenada",
      "callingCode": "+1"
    },
    {  
      "country": "Guadeloupe",
      "callingCode": "+590"
    },
    {  
      "country": "Guam",
      "callingCode": "+1"
    },
    {  
      "country": "Guatemala",
      "callingCode": "+502"
    },
    {  
      "country": "Guernsey",
      "callingCode": "+44"
    },
    {  
      "country": "Guinea",
      "callingCode": "+224"
    },
    {  
      "country": "Guyana",
      "callingCode": "+592"
    },
    {  
      "country": "Haiti",
      "callingCode": "+509"
    },
    {  
      "country": "Hawaii",
      "callingCode": "+1"
    },
    {  
      "country": "Honduras",
      "callingCode": "+504"
    },
    {  
      "country": "Hong Kong",
      "callingCode": "+852"
    },
    {  
      "country": "Hungary",
      "callingCode": "+36"
    },
    {  
      "country": "Iceland",
      "callingCode": "+354"
    },
    {  
      "country": "India",
      "callingCode": "+91"
    },
    {  
      "country": "Indonesia",
      "callingCode": "+62"
    },
    {  
      "country": "Iran",
      "callingCode": "+98"
    },
    {  
      "country": "Iraq",
      "callingCode": "+964"
    },
    {  
      "country": "Ireland",
      "callingCode": "+353"
    },
    {  
      "country": "Israel",
      "callingCode": "+972"
    },
    {  
      "country": "Italy",
      "callingCode": "+39"
    },
    {  
      "country": "Jamaica",
      "callingCode": "+1"
    },
    {  
      "country": "Japan",
      "callingCode": "+81"
    },
    {  
      "country": "Jersey",
      "callingCode": "+44"
    },
    {  
      "country": "Jordan",
      "callingCode": "+962"
    },
    {  
      "country": "Kazakhstan",
      "callingCode": "+7"
    },
    {  
      "country": "Kenya",
      "callingCode": "+254"
    },
    {  
      "country": "Kiribati",
      "callingCode": "+686"
    },
    {  
      "country": "Kosovo",
      "callingCode": "+383"
    },
    {  
      "country": "Kuwait",
      "callingCode": "+965"
    },
    {  
      "country": "Kyrgyzstan",
      "callingCode": "+996"
    },
    {  
      "country": "Laos",
      "callingCode": "+856"
    },
    {  
      "country": "Latvia",
      "callingCode": "+371"
    },
    {  
      "country": "Lebanon",
      "callingCode": "+961"
    },
    {  
      "country": "Lesotho",
      "callingCode": "+266"
    },
    {  
      "country": "Liberia",
      "callingCode": "+231"
    },
    {  
      "country": "Libya",
      "callingCode": "+218"
    },
    {  
      "country": "Liechtenstein",
      "callingCode": "+423"
    },
    {  
      "country": "Lithuania",
      "callingCode": "+370"
    },
    {  
      "country": "Luxembourg",
      "callingCode": "+352"
    },
    {  
      "country": "Macau",
      "callingCode": "+853"
    },
    {  
      "country": "Madagascar",
      "callingCode": "+261"
    },
    {  
      "country": "Madeira",
      "callingCode": "+351"
    },
    {  
      "country": "Malawi",
      "callingCode": "+265"
    },
    {  
      "country": "Malaysia",
      "callingCode": "+60"
    },
    {  
      "country": "Maldives",
      "callingCode": "+960"
    },
    {  
      "country": "Mali",
      "callingCode": "+223"
    },
    {  
      "country": "Malta",
      "callingCode": "+356"
    },
    {  
      "country": "Martinique",
      "callingCode": "+596"
    },
    {  
      "country": "Mauritania",
      "callingCode": "+222"
    },
    {  
      "country": "Mauritius",
      "callingCode": "+230"
    },
    {  
      "country": "Mayotte",
      "callingCode": "+262"
    },
    {  
      "country": "Melilla",
      "callingCode": "+34"
    },
    {  
      "country": "Mexico",
      "callingCode": "+52"
    },
    {  
      "country": "Moldova",
      "callingCode": "+373"
    },
    {  
      "country": "Monaco",
      "callingCode": "+377"
    },
    {  
      "country": "Mongolia",
      "callingCode": "+976"
    },
    {  
      "country": "Montenegro",
      "callingCode": "+382"
    },
    {  
      "country": "Montserrat",
      "callingCode": "+1"
    },
    {  
      "country": "Morocco",
      "callingCode": "+212"
    },
    {  
      "country": "Mozambique",
      "callingCode": "+258"
    },
    {  
      "country": "Myanmar",
      "callingCode": "+95"
    },
    {  
      "country": "Artsakh",
      "callingCode": "+374"
    },
    {  
      "country": "Namibia",
      "callingCode": "+264"
    },
    {  
      "country": "Nauru",
      "callingCode": "+674"
    },
    {  
      "country": "Nepal",
      "callingCode": "+977"
    },
    {  
      "country": "Netherlands",
      "callingCode": "+31"
    },
    {  
      "country": "New Zealand",
      "callingCode": "+64"
    },
    {  
      "country": "Nicaragua",
      "callingCode": "+505"
    },
    {  
      "country": "Niger",
      "callingCode": "+227"
    },
    {  
      "country": "Nigeria",
      "callingCode": "+234"
    },
    {  
      "country": "Niue",
      "callingCode": "+683"
    },
    {  
      "country": "North Korea",
      "callingCode": "+850"
    },
    {  
      "country": "Norway",
      "callingCode": "+47"
    },
    {  
      "country": "Oman",
      "callingCode": "+968"
    },
    {  
      "country": "Pakistan",
      "callingCode": "+92"
    },
    {  
      "country": "Palau",
      "callingCode": "+680"
    },
    {  
      "country": "Panama",
      "callingCode": "+507"
    },
    {  
      "country": "Paraguay",
      "callingCode": "+595"
    },
    {  
      "country": "Peru",
      "callingCode": "+51"
    },
    {  
      "country": "Philippines",
      "callingCode": "+63"
    },
    {  
      "country": "Poland",
      "callingCode": "+48"
    },
    {  
      "country": "Portugal",
      "callingCode": "+351"
    },
    {  
      "country": "Príncipe",
      "callingCode": "+239"
    },
    {  
      "country": "Qatar",
      "callingCode": "+974"
    },
    {  
      "country": "Réunion",
      "callingCode": "+262"
    },
    {  
      "country": "Romania",
      "callingCode": "+40"
    },
    {  
      "country": "Rwanda",
      "callingCode": "+250"
    },
    {  
      "country": "Saba",
      "callingCode": "+599"
    },
    {  
      "country": "Samoa",
      "callingCode": "+685"
    },
    {  
      "country": "Saudi Arabia",
      "callingCode": "+966"
    },
    {  
      "country": "Scotland",
      "callingCode": "+44"
    },
    {  
      "country": "Senegal",
      "callingCode": "+221"
    },
    {  
      "country": "Serbia",
      "callingCode": "+381"
    },
    {  
      "country": "Seychelles",
      "callingCode": "+248"
    },
    {  
      "country": "Sierra Leone",
      "callingCode": "+232"
    },
    {  
      "country": "Singapore",
      "callingCode": "+65"
    },
    {  
      "country": "Slovakia",
      "callingCode": "+421"
    },
    {  
      "country": "Slovenia",
      "callingCode": "+386"
    },
    {  
      "country": "Somalia",
      "callingCode": "+252"
    },
    {  
      "country": "South Africa",
      "callingCode": "+27"
    },
    {  
      "country": "South Korea",
      "callingCode": "+82"
    },
    {  
      "country": "Spain",
      "callingCode": "+34"
    },
    {  
      "country": "Sri Lanka",
      "callingCode": "+94"
    },
    {  
      "country": "Sudan",
      "callingCode": "+249"
    },
    {  
      "country": "Suriname",
      "callingCode": "+597"
    },
    {  
      "country": "Svalbard",
      "callingCode": "+47"
    },
    {  
      "country": "Sweden",
      "callingCode": "+46"
    },
    {  
      "country": "Switzerland",
      "callingCode": "+41"
    },
    {  
      "country": "Syria",
      "callingCode": "+963"
    },
    {  
      "country": "Taiwan",
      "callingCode": "+886"
    },
    {  
      "country": "Tajikistan",
      "callingCode": "+992"
    },
    {  
      "country": "Tanzania",
      "callingCode": "+255"
    },
    {  
      "country": "Thailand",
      "callingCode": "+66"
    },
    {  
      "country": "Togo",
      "callingCode": "+228"
    },
    {  
      "country": "Tokelau",
      "callingCode": "+690"
    },
    {  
      "country": "Tonga",
      "callingCode": "+676"
    },
    {  
      "country": "Tunisia",
      "callingCode": "+216"
    },
    {  
      "country": "Turkey",
      "callingCode": "+90"
    },
    {  
      "country": "Turkmenistan",
      "callingCode": "+993"
    },
    {  
      "country": "Tuvalu",
      "callingCode": "+688"
    },
    {  
      "country": "Uganda",
      "callingCode": "+256"
    },
    {  
      "country": "Ukraine",
      "callingCode": "+380"
    },
    {  
      "country": "UAE",
      "callingCode": "+971"
    },
    {  
      "country": "UK",
      "callingCode": "+44"
    },
    {  
      "country": "USA",
      "callingCode": "+1"
    },
    {  
      "country": "Uruguay",
      "callingCode": "+598"
    },
    {  
      "country": "Uzbekistan",
      "callingCode": "+998"
    },
    {  
      "country": "Vanuatu",
      "callingCode": "+678"
    },
    {  
      "country": "Vatican City",
      "callingCode": "+39"
    },
    {  
      "country": "Venezuela",
      "callingCode": "+58"
    },
    {  
      "country": "Vietnam",
      "callingCode": "+84"
    },
    {  
      "country": "Wales",
      "callingCode": "+44"
    },
    {  
      "country": "Western Sahara",
      "callingCode": "+212"
    },
    {  
      "country": "Yemen",
      "callingCode": "+967"
    },
    {  
      "country": "Zambia",
      "callingCode": "+260"
    },
    {  
      "country": "Zimbabwe",
      "callingCode": "+263"
    }
]

export default countryList