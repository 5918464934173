import React, { useState, useEffect, useRef } from 'react';
import { Form } from 'antd';
import { connect } from 'react-redux';
import './styles.scss';

const mapStateToProps = ({ dispatch, user, settings }) => ({
  dispatch,
  user,
  settings,
});

const GoogleMapLocator = (props) => {
  console.log({ props });

  const [state, setState] = useState({
    address: props?.google,
    mapPosition: {
      lat: props?.center?.lat,
      lng: props?.center?.lng,
    },
    hasLoaded: false,
  });

  const mapRef = useRef(null);
  const inputRef = useRef(null);
  const map = useRef(null);
  const marker = useRef(null);
  const infowindow = useRef(null);
  const autocomplete = useRef(null);
  console.log({ state, inputRef });
  useEffect(() => {
    initMap();

    return () => {
      // Cleanup function
      if (map.current) {
        window.google.maps.event.clearInstanceListeners(map.current);
      }
      if (marker.current) {
        window.google.maps.event.clearInstanceListeners(marker.current);
      }
      if (autocomplete.current) {
        window.google.maps.event.clearInstanceListeners(autocomplete.current);
      }
    };
  }, [props.isEdit]); // Re-run when isEdit changes

  useEffect(() => {
    if (props?.isEdit) {
      inputRef.current.value = props.google;
    }
  }, [props.google, props.isEdit]);

  useEffect(() => {
    if (
      props.center.lat !== state.mapPosition.lat ||
      props.center.lng !== state.mapPosition.lng
    ) {
      setState((prevState) => ({
        ...prevState,
        mapPosition: {
          lat: props?.center?.lat,
          lng: props?.center?.lng,
        },
      }));

      if (state.hasLoaded && window.google) {
        updateMapPosition();
      }
    }
  }, [props.center, state.hasLoaded]);

  const updateMapPosition = () => {
    const mapPositionLatLng = new window.google.maps.LatLng(
      props?.center?.lat,
      props?.center?.lng
    );

    if (map.current) {
      map.current.setCenter(mapPositionLatLng);
    }
    if (marker.current) {
      marker.current.setPosition(mapPositionLatLng);
    }
    if (infowindow.current && props?.isInfo) {
      infowindow.current.setPosition({
        lat: props?.center?.lat + 0.0015,
        lng: props?.center?.lng,
      });
    }
  };

  const initMap = async () => {
    if (!window.google) {
      console.error('Google Maps API not loaded');
      return;
    }

    const { Map, InfoWindow } = await window.google.maps.importLibrary('maps');
    const { Marker } = await window.google.maps.importLibrary('marker');

    setState((prevState) => ({ ...prevState, hasLoaded: true }));

    const mapPositionLatLng = new window.google.maps.LatLng(
      state.mapPosition?.lat,
      state.mapPosition?.lng
    );

    // render map
    const mapInstance = new Map(mapRef.current, {
      center: mapPositionLatLng,
      zoom: props?.zoom,
      mapId: '1',
      gestureHandling: 'auto',
      draggableCursor: 'pointer',
    });

    // render marker
    const markerInstance = new Marker({
      map: mapInstance,
      position: props?.position,
      draggable: props?.isEdit ? true : false,
    });

    // render infowindow
    let infowindowInstance;
    if (props?.isInfo) {
      infowindowInstance = new InfoWindow({
        map: mapInstance,
        content: state.address,
        position: {
          lat: state.mapPosition.lat + 0.0015,
          lng: state.mapPosition.lng,
        },
      });
    }

    // map listener
    mapInstance.addListener('click', (e) => {
      markerInstance.setPosition(e.latLng);
      if (props?.isInfo) {
        infowindowInstance.close();
      }
      inputRef.current.value = '';
      onMarkerDragEnd(e);
      props.onMarkerDragEnd(e);
    });

    // marker listener
    markerInstance.addListener('click', (e) => {
      if (props?.isInfo) {
        props.onMarkerDragEnd(e);
        onMarkerDragEnd(e);
        infowindowInstance.open(mapInstance, markerInstance);
      }
    });
    markerInstance.addListener('dragend', (e) => {
      props.onMarkerDragEnd(e);
      onMarkerDragEnd(e);
    });
    markerInstance.addListener('drag', (e) => {
      if (props?.isInfo) {
        infowindowInstance.close();
      }
      inputRef.current.value = '';
    });

    // render autocomplete
    const { Autocomplete } = await window.google.maps.importLibrary('places');

    const autocompleteInstance = new Autocomplete(inputRef.current, {
      fields: ['place_id', 'address_components', 'geometry', 'formatted_address'],
      strictBounds: false,
    });
    autocompleteInstance.bindTo('bounds', mapInstance);
    autocompleteInstance.addListener('place_changed', () => {
      if (props?.isInfo) {
        infowindowInstance.close();
      }
      props.onPlaceSelected(autocompleteInstance);
      onPlaceSelected();
    });

    props.onLoad(autocompleteInstance);

    autocomplete.current = autocompleteInstance;
    map.current = mapInstance;
    marker.current = markerInstance;
    if (props?.isInfo) {
      infowindow.current = infowindowInstance;
    }
  };

  const onMarkerDragEnd = async (event) => {
    const newLat = event.latLng.lat();
    const newLng = event.latLng.lng();

    const { Geocoder } = await window.google.maps.importLibrary('geocoding');
    const geocoder = new Geocoder();
    const latLng = new window.google.maps.LatLng(newLat, newLng);
    const request = {
      location: latLng,
    };

    geocoder.geocode(request, (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        const address = results[0].formatted_address;
        setState((prevState) => ({
          ...prevState,
          address: address || '',
          mapPosition: {
            lat: newLat,
            lng: newLng,
          },
        }));
        if (props?.isInfo) {
          infowindow.current.setPosition({
            lat: newLat + 0.0015,
            lng: newLng,
          });
          infowindow.current.setContent(address);
          infowindow.current.open(map.current, marker.current);
        }
        inputRef.current.value = address;
      } else {
        console.error('Geocoder failed due to: ' + status);
      }
    });
  };

  const onPlaceSelected = () => {
    const place = autocomplete.current.getPlace();
    console.log('place selected', place);
    if (place) {
      const address = place.formatted_address;
      const geoaddress = place.formatted_address;
      const latValue =
        place &&
        place.geometry &&
        place.geometry.location &&
        place.geometry.location.lat();
      const lngValue =
        place &&
        place.geometry &&
        place.geometry.location &&
        place.geometry.location.lng();
      //   inputRef.current.value = place?.formatted_address;
      setState((prevState) => ({
        ...prevState,
        address: address || '',
        geoaddress: geoaddress || '',
        mapPosition: {
          lat: latValue,
          lng: lngValue,
        },
      }));
      if (props?.isInfo) {
        infowindow.current.setPosition({
          lat: latValue + 0.0015,
          lng: lngValue,
        });
        infowindow.current.setContent(address);
        infowindow.current.open(map.current, marker.current);
      }
      inputRef.current.value = place?.formatted_address;
      marker.current.setPosition({ lat: latValue, lng: lngValue });
    }
  };

  let mapContent;
  if (props && props.center && props.center?.lat !== undefined) {
    mapContent = (
      <>
        <div
          ref={mapRef}
          style={{
            width: '100%',
            height: props.height,
          }}
        ></div>
        {props.isEdit ? (
          <Form.Item name={props.inputName}>
            <input
              ref={inputRef}
              defaultValue={props?.google}
              value={state.address}
              type='text'
              placeholder='Enter a location'
              style={{
                boxSizing: `border-box`,
                border: `1px solid grey`,
                width: `100%`,
                height: `32px`,
                padding: `0 12px`,
                outline: 'none',
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                textOverflow: `ellipses`,
              }}
            />
          </Form.Item>
        ) : null}
      </>
    );
  } else {
    mapContent = <div style={{ height: props.height }} />;
  }

  return mapContent;
};

export default connect(mapStateToProps)(GoogleMapLocator);
