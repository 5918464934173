import React, { useRef, useState, useEffect, useCallback } from 'react';
import axiosInstance from '../../../config/axios';
import endpoints from '../../../v2/config/endpoints';
import { message, Button, Tooltip } from 'antd';
import {
  FontSizeOutlined,
  ClearOutlined,
  EditOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  SaveOutlined,
} from '@ant-design/icons';

const DrawingCanvas = ({ imageUrl, fileUrl, fileId }) => {
  const baseCanvasRef = useRef(null);
  const drawingCanvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [baseContext, setBaseContext] = useState(null);
  const [drawingContext, setDrawingContext] = useState(null);
  const [activeAction, setActiveAction] = useState('draw');
  const [rotation, setRotation] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [cursorStyle, setCursorStyle] = useState('crosshair');

  const loadImage = useCallback(() => {
    const baseCanvas = baseCanvasRef.current;
    const baseCtx = baseCanvas.getContext('2d');
    setBaseContext(baseCtx);

    const drawingCanvas = drawingCanvasRef.current;
    const drawingCtx = drawingCanvas.getContext('2d');
    setDrawingContext(drawingCtx);

    const image = new Image();
    image.crossOrigin = 'Anonymous';
    image.src = `${imageUrl}?timestamp=${new Date().getTime()}`;
    image.onload = () => {
      const containerWidth = baseCanvas.parentElement.clientWidth;
      const aspectRatio = image.width / image.height;
      const canvasWidth = containerWidth;
      const canvasHeight = containerWidth / aspectRatio;

      baseCanvas.width = canvasWidth;
      baseCanvas.height = canvasHeight;
      drawingCanvas.width = canvasWidth;
      drawingCanvas.height = canvasHeight;

      baseCtx.clearRect(0, 0, canvasWidth, canvasHeight);
      drawingCtx.clearRect(0, 0, canvasWidth, canvasHeight);

      baseCtx.drawImage(image, 0, 0, canvasWidth, canvasHeight);
    };
  }, [imageUrl]);

  const preventDefault = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    loadImage();

    const drawingCanvas = drawingCanvasRef.current;
    drawingCanvas.addEventListener('touchstart', preventDefault);
    drawingCanvas.addEventListener('touchmove', preventDefault);
    drawingCanvas.addEventListener('pointerdown', preventDefault);
    drawingCanvas.addEventListener('pointermove', preventDefault);

    return () => {
      drawingCanvas.removeEventListener('touchstart', preventDefault);
      drawingCanvas.removeEventListener('touchmove', preventDefault);
      drawingCanvas.removeEventListener('pointerdown', preventDefault);
      drawingCanvas.removeEventListener('pointermove', preventDefault);
    };
  }, [loadImage]);

  const setActiveActionAndCursor = (action) => {
    setActiveAction(action);
    switch (action) {
      case 'draw':
        setCursorStyle('crosshair');
        break;
      case 'erase':
        setCursorStyle('cell');
        break;
      case 'text':
        setCursorStyle('text');
        break;
      default:
        setCursorStyle('default');
    }
  };

  const startDrawing = (e) => {
    if (activeAction === 'text') return;
    setIsDrawing(true);
    handleDrawingAction(e);
  };

  const stopDrawing = () => {
    if (activeAction === 'text') return;
    setIsDrawing(false);
    drawingContext.beginPath();
  };

  const handleDrawingAction = (e) => {
    if (!isDrawing) return;

    const drawingCanvas = drawingCanvasRef.current;
    const rect = drawingCanvas.getBoundingClientRect();
    const x = ((e.clientX || e.touches?.[0].clientX) - rect.left) / zoomLevel;
    const y = ((e.clientY || e.touches?.[0].clientY) - rect.top) / zoomLevel;

    if (activeAction === 'erase') {
      drawingContext.lineWidth = 20;
      drawingContext.lineCap = 'round';
      drawingContext.strokeStyle = 'white';
      drawingContext.globalCompositeOperation = 'destination-out';
    } else {
      drawingContext.lineWidth = 2;
      drawingContext.lineCap = 'round';
      drawingContext.strokeStyle = 'black';
      drawingContext.globalCompositeOperation = 'source-over';
    }

    drawingContext.lineTo(x, y);
    drawingContext.stroke();
    drawingContext.beginPath();
    drawingContext.moveTo(x, y);
  };

  const rotateImage = (direction) => {
    const newRotation = (rotation + direction * 90 + 360) % 360;
    setRotation(newRotation);

    const baseCanvas = baseCanvasRef.current;
    const baseCtx = baseCanvas.getContext('2d');
    const drawingCanvas = drawingCanvasRef.current;
    const drawingCtx = drawingCanvas.getContext('2d');

    const tempCanvas = document.createElement('canvas');
    const tempCtx = tempCanvas.getContext('2d');
    tempCanvas.width = baseCanvas.height;
    tempCanvas.height = baseCanvas.width;

    tempCtx.translate(tempCanvas.width / 2, tempCanvas.height / 2);
    tempCtx.rotate((direction * 90 * Math.PI) / 180);
    tempCtx.drawImage(baseCanvas, -baseCanvas.width / 2, -baseCanvas.height / 2);
    tempCtx.drawImage(drawingCanvas, -drawingCanvas.width / 2, -drawingCanvas.height / 2);

    baseCanvas.width = tempCanvas.width;
    baseCanvas.height = tempCanvas.height;
    drawingCanvas.width = tempCanvas.width;
    drawingCanvas.height = tempCanvas.height;
    baseCtx.drawImage(tempCanvas, 0, 0);
    drawingCtx.drawImage(tempCanvas, 0, 0);
  };

  const saveImage = () => {
    const baseCanvas = baseCanvasRef.current;
    const drawingCanvas = drawingCanvasRef.current;
    const mergedCanvas = document.createElement('canvas');
    mergedCanvas.width = baseCanvas.width;
    mergedCanvas.height = baseCanvas.height;
    const mergedCtx = mergedCanvas.getContext('2d');
    mergedCtx.drawImage(baseCanvas, 0, 0);
    mergedCtx.drawImage(drawingCanvas, 0, 0);

    mergedCanvas.toBlob(async (blob) => {
      if (blob) {
        const imageFile = new File([blob], 'edited-image.png', { type: 'image/png' });
        await sendImageToAPI(imageFile);
      }
    }, 'image/png');
  };

  const sendImageToAPI = async (file) => {
    const fd = new FormData();
    fd.append('file', file);
    fd.append('destination_path', fileUrl);

    axiosInstance
      .patch(`${endpoints.homework.updateImage}${fileId}/`, fd)
      .then((res) => {
        if (res?.data?.status_code === 200) {
          message.success('Attachment Added');
        }
      })
      .catch((e) => {
        message.error('Upload Failed');
      });
  };

  const handleCanvasClick = (e) => {
    if (activeAction !== 'text') return;

    const drawingCanvas = drawingCanvasRef.current;
    const rect = drawingCanvas.getBoundingClientRect();
    const x = (e.clientX - rect.left) / zoomLevel;
    const y = (e.clientY - rect.top) / zoomLevel;

    const text = prompt('Enter text:');
    if (text) {
      const prevCompositeOperation = drawingContext.globalCompositeOperation;
      drawingContext.globalCompositeOperation = 'source-over';

      drawingContext.font = `${16 * zoomLevel}px Arial`;
      drawingContext.fillStyle = 'red';
      drawingContext.fillText(text, x, y);

      drawingContext.globalCompositeOperation = prevCompositeOperation;
    }
  };

  const zoomIn = () => {
    setZoomLevel(zoomLevel + 0.1);
    const baseCanvas = baseCanvasRef.current;
    const drawingCanvas = drawingCanvasRef.current;
    baseCanvas.style.transform = `scale(${zoomLevel + 0.1})`;
    drawingCanvas.style.transform = `scale(${zoomLevel + 0.1})`;
  };

  const zoomOut = () => {
    if (zoomLevel > 0.2) {
      setZoomLevel(zoomLevel - 0.1);
      const baseCanvas = baseCanvasRef.current;
      const drawingCanvas = drawingCanvasRef.current;
      baseCanvas.style.transform = `scale(${zoomLevel - 0.1})`;
      drawingCanvas.style.transform = `scale(${zoomLevel - 0.1})`;
    }
  };

  return (
    <div>
      <div style={{ position: 'relative', height: '500px', overflow: 'auto' }}>
        <canvas ref={baseCanvasRef} style={{ position: 'absolute', left: 0, top: 0 }} />
        <canvas
          ref={drawingCanvasRef}
          onMouseDown={startDrawing}
          onMouseUp={stopDrawing}
          onMouseMove={handleDrawingAction}
          onMouseOut={stopDrawing}
          onPointerDown={startDrawing}
          onPointerUp={stopDrawing}
          onPointerMove={handleDrawingAction}
          onPointerOut={stopDrawing}
          onClick={handleCanvasClick}
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            cursor: cursorStyle,
            touchAction: 'none',
          }}
        />
      </div>
      <div className='mt-2 text-center'>
        <Tooltip title='Add Text' zIndex={'10000'}>
          <Button
            icon={<FontSizeOutlined />}
            onClick={() => setActiveActionAndCursor('text')}
            type={activeAction === 'text' ? 'primary' : 'default'}
            style={{ marginRight: '5px' }}
          />
        </Tooltip>
        <Tooltip title='Eraser' zIndex={'10000'}>
          <Button
            icon={<ClearOutlined />}
            onClick={() => setActiveActionAndCursor('erase')}
            type={activeAction === 'erase' ? 'primary' : 'default'}
            style={{ marginRight: '5px' }}
          />
        </Tooltip>
        <Tooltip title='Draw' zIndex={'10000'}>
          <Button
            icon={<EditOutlined />}
            onClick={() => setActiveActionAndCursor('draw')}
            type={activeAction === 'draw' ? 'primary' : 'default'}
            style={{ marginRight: '5px' }}
          />
        </Tooltip>
        <Tooltip title='Rotate Left' zIndex={'10000'}>
          <Button
            icon={<RotateLeftOutlined />}
            onClick={() => rotateImage(-1)}
            style={{ marginRight: '5px' }}
          />
        </Tooltip>
        <Tooltip title='Rotate Right' zIndex={'10000'}>
          <Button
            icon={<RotateRightOutlined />}
            onClick={() => rotateImage(1)}
            style={{ marginRight: '5px' }}
          />
        </Tooltip>
        <Tooltip title='Zoom In' zIndex={'10000'}>
          <Button
            icon={<ZoomInOutlined />}
            onClick={zoomIn}
            style={{ marginRight: '5px' }}
          />
        </Tooltip>
        <Tooltip title='Zoom Out' zIndex={'10000'}>
          <Button
            icon={<ZoomOutOutlined />}
            onClick={zoomOut}
            style={{ marginRight: '5px' }}
          />
        </Tooltip>
        <Tooltip title='Save' zIndex={'10000'}>
          <Button icon={<SaveOutlined />} onClick={saveImage} type='primary' />
        </Tooltip>
      </div>
    </div>
  );
};

export default DrawingCanvas;
