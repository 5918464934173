/* eslint-disable */
import React, { useState } from 'react';
import JSPDF from 'jspdf';
import 'jspdf-autotable';
import _ from 'lodash';
import FrontImg from 'assets/images/Sports_BG.jpg';
import SelfDefenceIcon from 'assets/images/Self_defence_icon.png';
import BasketballIcon from 'assets/images/basket_icon.png';
import SwimmingIcon from 'assets/images/swimming_icon.png';
import ChessIcon from 'assets/images/Chess_icon.png';
import FootballIcon from 'assets/images/football_icon.png';
import SkatingIcon from 'assets/images/Skating.png';
import ENVCONFIG from 'v2/config/config';
import OrchidsLogo from 'assets/images/Orchids_logo_white.png';

import './customFont';

const sportIcons = {
  CHESS: ChessIcon,
  FOOTBALL: FootballIcon,
  'SELF-DEFENCE': SelfDefenceIcon,
  SKATING: SkatingIcon,
  BASKETBALL: BasketballIcon,
  SWIMMING: SwimmingIcon,
};

const {
  s3: { ERP_BUCKET = '' },
} = ENVCONFIG;
const SportsReportCard = (reportCardData, principalSignData) => {
  const doc = new JSPDF({
    orientation: 'p',
    unit: 'mm',
    format: [210, 297],
    lineHeight: 1.3,
    compress: true,
  });
  const margin = 5;
  const columnGap = 10;
  const pageWidth = doc.internal.pageSize.width;

  // First page
  var frontImg = new Image();
  frontImg.src = FrontImg;
  doc.addImage(frontImg, 'jpg', 0, 0, 210, 297);
  let y = 40;

  var orchidsLogo = new Image();
  orchidsLogo.src = OrchidsLogo;
  doc.addImage(orchidsLogo, 'png', 65, 3, 74, 20);

  doc.setFontSize(24);
  doc.setFont('LoveYaLikeASister-Regular', 'normal');
  doc.setTextColor(85, 19, 160);
  doc.text(`FOR EVERY CHAMPION`, 105, y, null, null, 'center');
  doc.setDrawColor(45, 0, 123);
  doc.setLineDash([1, 1]);
  doc.setFontSize(16);
  y += 15;
  doc.text(`Name: ${reportCardData?.erp?.name}`, 20, y, null, null, 'left');
  y += 2;
  doc.setFontSize(22);
  doc.line(37, y, 190, y);

  doc.setFontSize(16);
  y += 10;
  doc.text(`ERP Code: ${reportCardData?.erp?.erp_id}`, 20, y, null, null, 'left');
  y += 2;
  doc.setFontSize(22);
  doc.line(45, y, 190, y);
  y += 2;

  doc.setFontSize(16);

  y += 10;
  doc.text(
    `Branch:  ${reportCardData?.section_mapping?.branch_name}`,
    20,
    y,
    null,
    null,
    'left'
  );
  y += 2;
  doc.setFontSize(22);
  doc.line(45, y, 190, y);
  y += 10;
  doc.setFontSize(16);
  doc.text(
    `Grade: ${reportCardData?.section_mapping?.grade_name}`,
    20,
    y,
    null,
    null,
    'left'
  );
  doc.text(
    `Section: ${reportCardData?.section_mapping?.section_name}`,
    115,
    y,
    null,
    null,
    'left'
  );

  y += 2;
  doc.setFontSize(22);
  doc.line(40, y, 85, y);
  doc.line(138, y, 190, y);

  doc.setFontSize(22);
  y += 19;
  doc.setLineDash([]);
  doc.setFontSize(18);
  // Create gradient background from left to right
  const gradientWidth = 165;
  const gradientHeight = 12;
  const startX = (210 - gradientWidth) / 2;
  const startY = y - 8;
  const colors = [
    { r: 196, g: 255, b: 217 },
    { r: 172, g: 212, b: 240 },
    { r: 158, g: 169, b: 255 },
  ];

  const steps = 80;
  for (let i = 0; i < steps; i++) {
    const t = i / (steps - 1);
    const r = Math.round(colors[0].r * (1 - t) + colors[2].r * t);
    const g = Math.round(colors[0].g * (1 - t) + colors[2].g * t);
    const b = Math.round(colors[0].b * (1 - t) + colors[2].b * t);

    doc.setFillColor(r, g, b);
    doc.rect(
      startX + (i * gradientWidth) / steps,
      startY,
      gradientWidth / steps,
      gradientHeight,
      'F'
    );
  }

  // Add text on top of the gradient
  doc.setTextColor(0, 0, 128);
  doc.text(`CHAMPION'S PARTICIPATION REPORT`, 105, y, null, null, 'center');

  y += 10;

  // Add the new table
  const maxTableWidth = pageWidth / 1.2;
  const sports = reportCardData?.report_card_json?.sports || [];
  const levels = sports[0]?.levels_dict?.map((level) => level?.level_name) || [];
  const sportNames = sports?.map((sport) => sport?.subject_name) || [];

  const tableData = [
    ['MATCHES', ...sportNames],
    ...levels?.map((level) => [level, ...Array(sportNames?.length).fill('')]),
  ];

  // Fill in the scores
  sports?.forEach((sport, sportIndex) => {
    sport?.levels_dict?.forEach((level) => {
      const rowIndex = tableData.findIndex((row) => row[0] === level?.level_name);
      if (rowIndex !== -1) {
        tableData[rowIndex][sportIndex + 1] = level?.score ? level?.score : '-';
      }
    });
  });

  doc.autoTable({
    startY: y,
    head: [tableData[0]],
    body: tableData.slice(1),
    theme: 'grid',
    styles: {
      fontSize: 8,
      cellPadding: 1.5,
      halign: 'center',
      valign: 'middle',
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
      minCellHeight: 10,
      textColor: [0, 0, 0],
    },
    headStyles: {
      fillColor: [173, 216, 230],
      textColor: [0, 0, 0],
      fontStyle: 'bold',
      fontSize: 9,
      cellPadding: 2,
    },
    columnStyles: {
      0: { cellWidth: 30, fillColor: [173, 216, 230] },
    },
    tableWidth: maxTableWidth - 10,
    didParseCell: function (data) {
      if (data.section === 'body' && data.column.index === 0) {
        data.cell.styles.fillColor = [173, 216, 230];
      }
    },
    margin: { left: 22, right: 10 },
  });

  y = doc.lastAutoTable.finalY + 10;

  y += 8;
  doc.setFontSize(16);
  const remarksLabel = 'Remarks: ';
  const remarksText = reportCardData?.remark || '';
  const maxWidth = 168;
  const remarksX = 22 + doc.getTextWidth(remarksLabel);
  const lineHeight = 8;

  const splitRemarks = doc.splitTextToSize(
    remarksText,
    maxWidth - doc.getTextWidth(remarksLabel)
  );

  doc.text(remarksLabel, 22, y);

  splitRemarks.forEach((line, index) => {
    doc.text(line, remarksX, y + index * lineHeight);
  });

  y += splitRemarks.length * lineHeight * 0.65;

  doc.setDrawColor(45, 0, 123);
  doc.setLineDash([1, 1]);
  doc.line(50, y, 190, y);
  y += 8;
  doc.setDrawColor(1, 1, 1);
  const signatureY = y + 20;
  doc.setFontSize(12);
  doc.setTextColor(0, 0, 0);

  if (principalSignData && principalSignData.length > 0) {
    const signatureImage = principalSignData[0].signature;
    if (signatureImage) {
      const img = new Image();
      img.src = ERP_BUCKET + signatureImage;

      try {
        doc.addImage(img, 'JPEG', 25, signatureY - 20, 40, 15);
      } catch {
        doc.line(60, signatureY - 7, 26, signatureY - 7);
      }
    } else {
      doc.line(60, signatureY - 7, 26, signatureY - 7);
    }
  } else {
    doc.line(60, signatureY - 7, 26, signatureY - 7);
  }

  doc.text('Principal', 35, signatureY);

  doc.line(175, signatureY - 7, 150, signatureY - 7);
  doc.text('Parents', 157, signatureY);

  doc.setLineDash([]);
  // Second page
  doc.addPage();

  doc.setFillColor(45, 0, 123);
  const gradientWidth2 = 120;
  const startX2 = (210 - gradientWidth2) / 2;
  doc.rect(0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height, 'F');
  for (let i = 0; i < steps; i++) {
    const t = i / (steps - 1);
    const r = Math.round(colors[0].r * (1 - t) + colors[2].r * t);
    const g = Math.round(colors[0].g * (1 - t) + colors[2].g * t);
    const b = Math.round(colors[0].b * (1 - t) + colors[2].b * t);

    doc.setFillColor(r, g, b);
    doc.rect(startX2 + (i * gradientWidth2) / steps, 4.5, gradientWidth2 / steps, 8, 'F');
  }
  doc.text(
    `L1-ADVANCE     L2-INTERMEDIATE     L3-BEGINNER`,
    105,
    10,
    null,
    null,
    'center'
  );

  const maxColumnWidth = (pageWidth - 3 * margin) / 2;
  const drawRoundedRect = (x, y, w, h, r, color) => {
    doc.setFillColor(color);
    doc.roundedRect(x, y, w, h, r, r, 'F');
  };

  const calculateTableHeight = (data) => {
    return (data + 1) * 7 + 16;
  };
  const textLengthCalculator = (text) => {
    return doc.getTextWidth(text);
  };

  let secondPageY = 17;

  for (let i = 0; i < sports.length; i += 2) {
    const leftSport = sports[i];
    const rightSport = sports[i + 1];

    const leftTableData = leftSport?.params_dict?.map((param) => [
      param.param_name,
      param.value,
    ]);
    const rightTableData = rightSport
      ? rightSport?.params_dict?.map((param) => [param.param_name, param.value])
      : [];

    const tempLeftDataHeight = leftSport?.params_dict?.map((param) =>
      textLengthCalculator(param?.param_name)
    );

    let tempL = tempLeftDataHeight?.filter((each) => each > 115);

    const tempRightDataHeight = rightSport?.params_dict.map((param) =>
      textLengthCalculator(param.param_name)
    );

    let tempR = tempRightDataHeight?.filter((each) => each > 115);

    const leftHeight = calculateTableHeight(
      tempLeftDataHeight.length + tempL.length * 0.8
    );
    const rightHeight = rightSport
      ? calculateTableHeight(tempRightDataHeight.length + tempR.length * 0.8)
      : 0;
    const rowHeight = Math.max(leftHeight, rightHeight);

    // Left sport
    const leftStartX = margin;
    drawRoundedRect(leftStartX, secondPageY, maxColumnWidth, rowHeight, 10, '#87CEEB');

    const leftIcon = sportIcons[leftSport.subject_name];
    if (leftIcon) {
      doc.addImage(leftIcon, 'PNG', leftStartX - 3, secondPageY - 3, 15, 15);
    }

    doc.setFontSize(14);
    doc.setTextColor(0, 0, 0);
    doc.setFont('Helvetica', 'bold');
    const centerX = leftStartX + maxColumnWidth / 2;
    doc.text(leftSport.subject_name, centerX, secondPageY + 8, { align: 'center' });

    doc.autoTable({
      startY: secondPageY + 14,
      head: [['Student is able to perform', 'Achievement']],
      body: leftTableData,
      theme: 'plain',
      styles: {
        fontSize: 8,
        cellPadding: 1.4,
        overflow: 'linebreak',
        cellWidth: 'wrap',
        halign: 'left',
        valign: 'middle',
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
        fillColor: null,
      },
      headStyles: {
        fillColor: null,
        textColor: [0, 0, 0],
        fontStyle: 'bold',
      },
      columnStyles: {
        0: { cellWidth: maxColumnWidth - 30 },
        1: { cellWidth: 22, halign: 'center' },
      },
      margin: { left: leftStartX + 5 },
      tableWidth: maxColumnWidth - 10,
      didDrawCell: (data) => {
        if (data.section === 'head' || data.section === 'body') {
          doc.setDrawColor(0);
          doc.setLineWidth(0.1);
          doc.line(data.cell.x, data.cell.y, data.cell.x + data.cell.width, data.cell.y);
          doc.line(
            data.cell.x,
            data.cell.y + data.cell.height,
            data.cell.x + data.cell.width,
            data.cell.y + data.cell.height
          );
          doc.line(data.cell.x, data.cell.y, data.cell.x, data.cell.y + data.cell.height);
          doc.line(
            data.cell.x + data.cell.width,
            data.cell.y,
            data.cell.x + data.cell.width,
            data.cell.y + data.cell.height
          );
        }
      },
    });

    // Right sport (if exists)
    if (rightSport) {
      const rightStartX = margin * 2 + maxColumnWidth;
      drawRoundedRect(rightStartX, secondPageY, maxColumnWidth, rowHeight, 10, '#87CEEB');

      const rightIcon = sportIcons[rightSport?.subject_name];
      if (rightIcon) {
        doc.addImage(rightIcon, 'PNG', rightStartX - 3, secondPageY - 3, 15, 15);
      }

      doc.setFontSize(14);
      doc.setTextColor(0, 0, 0);
      doc.setFont('Helvetica', 'bold');

      const rightCenterX = rightStartX + maxColumnWidth / 2;
      doc.text(rightSport?.subject_name, rightCenterX, secondPageY + 8, {
        align: 'center',
      });

      doc.autoTable({
        startY: secondPageY + 14,
        head: [['Student is able to perform', 'Achievement']],
        body: rightTableData,
        theme: 'plain',
        styles: {
          fontSize: 8,
          cellPadding: 1.4,
          overflow: 'linebreak',
          cellWidth: 'wrap',
          halign: 'left',
          valign: 'middle',
          lineColor: [0, 0, 0],
          lineWidth: 0.1,
          fillColor: null,
        },
        headStyles: {
          fillColor: null,
          textColor: [0, 0, 0],
          fontStyle: 'bold',
        },
        columnStyles: {
          0: { cellWidth: maxColumnWidth - 30 },
          1: { cellWidth: 22, halign: 'center' },
        },
        margin: { left: rightStartX + 5 },
        tableWidth: maxColumnWidth - 10,
        didDrawCell: (data) => {
          if (data.section === 'head' || data.section === 'body') {
            doc.setDrawColor(0);
            doc.setLineWidth(0.1);
            doc.line(
              data.cell.x,
              data.cell.y,
              data.cell.x + data.cell.width,
              data.cell.y
            );
            doc.line(
              data.cell.x,
              data.cell.y + data.cell.height,
              data.cell.x + data.cell.width,
              data.cell.y + data.cell.height
            );
            doc.line(
              data.cell.x,
              data.cell.y,
              data.cell.x,
              data.cell.y + data.cell.height
            );
            doc.line(
              data.cell.x + data.cell.width,
              data.cell.y,
              data.cell.x + data.cell.width,
              data.cell.y + data.cell.height
            );
          }
        },
      });
    }

    secondPageY += rowHeight + margin; // Move to the next row
  }
  // Add signature space

  doc.setProperties({
    title: `Sports-${reportCardData?.erp?.name}- ${reportCardData?.erp?.erp_id}`,
  });
  const pdfBlob = doc.output('blob');
  const pdfUrl = URL.createObjectURL(pdfBlob);

  return { blob: pdfBlob, url: pdfUrl };
};

export default SportsReportCard;
