import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  tableCell: {
    color: theme.palette.secondary.main,
    padding: 0,
  },
}));

export default useStyles;
