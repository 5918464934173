import React, { useState, useEffect } from 'react';
import {
  Form,
  Select,
  Input,
  Button,
  Card,
  Divider,
  Typography,
  Row,
  Col,
  Tooltip,
  message,
  Breadcrumb,
  Popover,
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import axiosInstance from 'config/axios';
import endpointsV2 from 'v2/config/endpoints';
import endpoints from 'config/endpoints';
import Layout from 'containers/Layout';
import { useSelector } from 'react-redux';

const { Option } = Select;
const { Title } = Typography;

const SportsReportConfig = () => {
  const selectedAcademicYear = useSelector(
    (state) => state.commonFilterReducer?.selectedYear
  );
  const [form] = Form.useForm();
  const [branchList, setBranchList] = useState([]);
  const [gradesList, setGradesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [formValid, setFormValid] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState({});
  const [prefilledData, setPrefilledData] = useState(null);

  useEffect(() => {
    fetchBranches({ session_year: selectedAcademicYear?.id });
  }, [selectedAcademicYear]);

  useEffect(() => {
    if (prefilledData && Array.isArray(prefilledData) && prefilledData.length > 0) {
      const formattedData = prefilledData.map((sport) => ({
        ...sport,
        params_dict: sport.sports_params || [],
      }));
      const initialValues = {
        level_dict: prefilledData[0]?.competition || [],
        sports: formattedData,
      };
      form.setFieldsValue(initialValues);
      setTimeout(() => {
        const currentValues = form.getFieldsValue();
        const isValid = validateForm(currentValues);
        setFormValid(isValid);
      }, 0);
    }
  }, [prefilledData, form]);

  const showPopover = (fieldName) => {
    setPopoverVisible((prev) => ({ ...prev, [fieldName]: true }));
  };

  const hidePopover = (fieldName) => {
    setPopoverVisible((prev) => ({ ...prev, [fieldName]: false }));
  };

  const handleRemoveConfirm = (fieldName, removeFunction) => {
    removeFunction(fieldName);
    hidePopover(fieldName);
  };

  const fetchBranches = async (params = {}) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`${endpointsV2.academics.branches}`, {
        params: params,
      });
      setBranchList(response.data?.data?.results || []);
    } catch (error) {
      console.error('Error fetching branches:', error);
    }
    setLoading(false);
  };

  const handleBranchChange = (values, key) => {
    setSelectedBranches(values);
    form.setFieldsValue({ acad_sessions: values, grades: undefined, term_id: undefined });
    setSelectedGrades([]);
    setSelectedTerm(null);
    fetchGrades({
      session_year: selectedAcademicYear?.id,
      branch_id: key?.map((each) => each.key)?.toString(),
    });
  };

  const fetchGrades = async (params = {}) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`${endpointsV2.academics.grades}`, {
        params: params,
      });
      setGradesList(response.data?.data || []);
    } catch (error) {
      console.error('Error fetching grades:', error);
    }
    setLoading(false);
  };

  const handleGradeChange = (value) => {
    const gradesList = [value];
    setSelectedGrades(gradesList);
    form.setFieldsValue({ grades: gradesList });
    if (value && selectedBranches.length > 0 && selectedTerm) {
      fetchSportsRCTemplate(gradesList, selectedTerm);
    }
  };

  const handleTermChange = (value) => {
    setSelectedTerm(value);
    form.setFieldsValue({ term_id: value });
    if (selectedGrades && selectedBranches.length > 0 && value) {
      fetchSportsRCTemplate(selectedGrades, value);
    }
  };

  const fetchSportsRCTemplate = async (grades, term) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${endpointsV2.assessmentReportConfig.reportTemplateStatus}`,
        {
          params: {
            acad_session: selectedBranches.join(','),
            grade: grades.join(','),
            term_id: term,
            rc_type: 2,
          },
        }
      );
      if (response.status === 200 && response?.data?.message) {
        message.warning(response?.data?.message);

        if (selectedBranches.length === 1) {
          const templateResponse = await axiosInstance.get(
            `${endpointsV2.assessmentReportConfig.getSportsTemplate}`,
            {
              params: {
                acad_session: selectedBranches[0],
                grade: grades[0],
                term_id: term,
              },
            }
          );
          setPrefilledData(templateResponse.data?.result || []);
          const initialValues = {
            level_dict: templateResponse.data?.result[0]?.competition || [],
            sports: templateResponse.data?.result || [],
          };
          setTimeout(() => {
            const currentValues = form.getFieldsValue();
            const isValid = validateForm(currentValues);
            setFormValid(isValid);
          }, 0);
        }
      } else {
        setPrefilledData(null);
      }
    } catch (error) {
      console.error('Error fetching sports RC template:', error);
      setPrefilledData(null);
    }
    setLoading(false);
  };

  const validateForm = (values) => {
    if (!Array.isArray(values.level_dict) || values.level_dict.length === 0) {
      return false;
    }
    if (!Array.isArray(values.sports) || values.sports.length === 0) {
      return false;
    }

    const isValid = values.sports.some((sport) => {
      if (!sport || !sport.subject_name) {
        return false;
      }
      if (!Array.isArray(sport.params_dict) || sport.params_dict.length === 0) {
        return false;
      }

      return sport.params_dict.some((param) => param && param.param_name);
    });

    return isValid;
  };

  const validateUniqueSequence = (path) => {
    return (_, value) => {
      const formValues = form.getFieldValue(path);
      if (!formValues || !Array.isArray(formValues)) {
        return Promise.resolve();
      }

      const sequences = formValues
        .map((item) => Number(item.sequence))
        .filter((seq) => !isNaN(seq));
      const uniqueSequences = new Set(sequences);

      if (sequences.length !== uniqueSequences.size) {
        return Promise.reject('Duplicate sequences found. Please use unique sequences.');
      }
      return Promise.resolve();
    };
  };

  const validateUniqueSportSequence = (_, value) => {
    const sports = form.getFieldValue('sports') || [];
    if (!Array.isArray(sports)) {
      return Promise.resolve();
    }
    const sequences = sports
      .map((sport) => sport && Number(sport.sequence))
      .filter((seq) => !isNaN(seq));
    const uniqueSequences = new Set(sequences);

    if (sequences.length !== uniqueSequences.size) {
      return Promise.reject(
        'Duplicate sequences found in Sports. Please use unique sequences.'
      );
    }
    return Promise.resolve();
  };

  const validateUniqueParamSequence = (sportIndex) => (_, value) => {
    const sports = form.getFieldValue('sports');
    const params = sports[sportIndex].params_dict || [];
    const sequences = params
      .map((param) => Number(param.sequence))
      .filter((seq) => !isNaN(seq));
    const uniqueSequences = new Set(sequences);

    if (sequences.length !== uniqueSequences.size) {
      return Promise.reject(
        'Duplicate sequences found in Parameters. Please use unique sequences.'
      );
    }
    return Promise.resolve();
  };

  const onFinish = (values) => {
    setSubmitting(true);
    const mergedValues = {
      ...values,
      level_dict: [
        ...(prefilledData?.[0]?.competition || []),
        ...values.level_dict.slice((prefilledData?.[0]?.competition || []).length),
      ],
      sports: values.sports.map((sport, index) => {
        if (index < (prefilledData?.length || 0)) {
          return {
            ...prefilledData[index],
            ...sport,
            params_dict: [
              ...(prefilledData[index].sports_params || []),
              ...sport.params_dict.slice(
                (prefilledData[index].sports_params || []).length
              ),
            ],
          };
        }
        return sport;
      }),
    };

    axiosInstance
      .post(endpoints?.sportsCardConfig.createSportsConfig, mergedValues)
      .then((res) => {
        message.success(`Sports Report Template Created Successfully`);
        form.resetFields();
        setSelectedBranches([]);
        setSelectedGrades([]);
        setSelectedTerm(null);
        setGradesList([]);
        setPrefilledData(null);
      })
      .catch((err) => {
        console.log({ err });
        message.error('Something went wrong');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Layout>
      <div className='row py-3 px-2 ' style={{ position: 'relative' }}>
        <div className='col-12'>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item className='th-grey th-16' href='/assessment/report-config'>
              Assessment
            </Breadcrumb.Item>
            <Breadcrumb.Item className='th-black-1 th-16'>
              Sports Report Config
            </Breadcrumb.Item>
            <Breadcrumb.Item className='th-black-1 th-16'>
              Create Sports Report
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className='col-12 mt-3'>
          <Form
            className='th-bg-white p-3'
            form={form}
            name='sports_form'
            layout='vertical'
            onFinish={onFinish}
            onValuesChange={() => {
              const currentValues = form.getFieldsValue();
              const isValid = validateForm(currentValues);
              setFormValid(isValid);
            }}
          >
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name='acad_sessions'
                  label='Branches'
                  rules={[
                    {
                      required: true,
                      message: 'Please select at least one branch',
                    },
                  ]}
                >
                  <Select
                    mode='multiple'
                    placeholder='Select branches'
                    onChange={handleBranchChange}
                    loading={loading}
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {branchList.map((branch) => (
                      <Option key={branch.branch.id} value={branch.id}>
                        {branch.branch.branch_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name='grades'
                  label='Grades'
                  rules={[{ required: true, message: 'Please select grade' }]}
                >
                  <Select
                    placeholder='Select grade'
                    loading={loading}
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={handleGradeChange}
                    value={selectedGrades[0]}
                  >
                    {gradesList.map((grade) => (
                      <Option key={grade.id} value={grade.grade_id}>
                        {grade.grade_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name='term_id'
                  label='Term'
                  rules={[{ required: true, message: 'Please select a term' }]}
                >
                  <Select
                    placeholder='Select term'
                    onChange={handleTermChange}
                    value={selectedTerm}
                  >
                    <Option value='1'>Semester 1</Option>
                    <Option value='2'>Semester 2</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <>
              <Divider orientation='left' className='th-primary'>
                Levels
              </Divider>

              <Form.Item
                name='level_dict'
                rules={[
                  {
                    validator: async (_, value) => {
                      if (value && value.length > 0) {
                        return validateUniqueSequence(_, value);
                      }
                    },
                  },
                ]}
              >
                <Form.List name='level_dict' initialValue={[]}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => (
                        <Row
                          key={field.key}
                          gutter={16}
                          align='middle'
                          style={{ marginBottom: 8 }}
                        >
                          <Col span={10}>
                            <Form.Item
                              {...field}
                              name={[field.name, 'level_name']}
                              rules={[
                                { required: true, message: 'Level name is required' },
                              ]}
                              noStyle
                            >
                              <Input
                                placeholder='Level Name'
                                disabled={
                                  index < (prefilledData?.[0]?.competition?.length || 0)
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col span={10}>
                            <Form.Item
                              {...field}
                              name={[field.name, 'sequence']}
                              rules={[
                                { required: true, message: 'Sequence is required' },
                                { validator: validateUniqueSequence(['level_dict']) },
                              ]}
                              noStyle
                            >
                              <Input
                                type='number'
                                placeholder='Sequence'
                                min={0}
                                style={{
                                  WebkitAppearance: 'none',
                                  MozAppearance: 'textfield',
                                  appearance: 'textfield',
                                }}
                                disabled={
                                  index <
                                  ((prefilledData?.[0]?.competition || []).length || 0)
                                }
                                onChange={(e) => {
                                  const value = Number(e.target.value);
                                  const levels = form.getFieldValue('level_dict') || [];
                                  const updatedLevels = [...levels];
                                  updatedLevels[field.name] = {
                                    ...updatedLevels[field.name],
                                    sequence: value,
                                  };
                                  form.setFieldsValue({
                                    level_dict: updatedLevels,
                                  });
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            {index >= (prefilledData?.[0]?.competition?.length || 0) ? (
                              <Tooltip title='Remove Level'>
                                <Popover
                                  content={
                                    <div>
                                      <p>Are you sure you want to remove this level?</p>
                                      <Button
                                        type='primary'
                                        size='small'
                                        onClick={() =>
                                          handleRemoveConfirm(field.name, remove)
                                        }
                                      >
                                        Yes
                                      </Button>
                                      <Button
                                        size='small'
                                        style={{ marginLeft: 8 }}
                                        onClick={() => hidePopover(field.name)}
                                      >
                                        No
                                      </Button>
                                    </div>
                                  }
                                  title='Confirm Removal'
                                  trigger='click'
                                  visible={popoverVisible[field.name]}
                                  onVisibleChange={(visible) =>
                                    visible
                                      ? showPopover(field.name)
                                      : hidePopover(field.name)
                                  }
                                >
                                  <Button
                                    type='link'
                                    danger
                                    icon={<MinusCircleOutlined />}
                                  />
                                </Popover>
                              </Tooltip>
                            ) : (
                              <Button
                                type='link'
                                danger
                                icon={<MinusCircleOutlined />}
                                disabled
                              />
                            )}
                          </Col>
                        </Row>
                      ))}
                      <Form.Item>
                        <Button
                          type='dashed'
                          onClick={() => add({ sequence: fields.length + 1 })}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Level
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form.Item>

              <Divider orientation='left' className='th-primary'>
                Sports
              </Divider>

              <Form.List
                name='sports'
                rules={[
                  {
                    validator: async (_, sports) => {
                      if (!sports || sports.length < 1) {
                        return Promise.reject(
                          new Error('At least one Sport is required')
                        );
                      }
                    },
                  },
                ]}
              >
                {(fields, { add: addSport, remove: removeSport }) => (
                  <>
                    {fields.map((field, index) => (
                      <Card
                        key={field.key}
                        title={<Title level={5}>{`Sport ${index + 1}`}</Title>}
                        extra={
                          index < (prefilledData?.length || 0) ? (
                            <Button
                              type='link'
                              danger
                              icon={<MinusCircleOutlined />}
                              disabled
                            />
                          ) : (
                            <Tooltip title='Remove Sport'>
                              <Popover
                                content={
                                  <div>
                                    <p>Are you sure you want to remove this sport?</p>
                                    <Button
                                      type='primary'
                                      size='small'
                                      onClick={() =>
                                        handleRemoveConfirm(field.name, removeSport)
                                      }
                                    >
                                      Yes
                                    </Button>
                                    <Button
                                      size='small'
                                      style={{ marginLeft: 8 }}
                                      onClick={() => hidePopover(field.name)}
                                    >
                                      No
                                    </Button>
                                  </div>
                                }
                                title='Confirm Removal'
                                trigger='click'
                                visible={popoverVisible[field.name]}
                                onVisibleChange={(visible) =>
                                  visible
                                    ? showPopover(field.name)
                                    : hidePopover(field.name)
                                }
                              >
                                <Button
                                  type='link'
                                  danger
                                  icon={<MinusCircleOutlined />}
                                />
                              </Popover>
                            </Tooltip>
                          )
                        }
                        style={{ marginBottom: 24 }}
                      >
                        <Row gutter={16}>
                          <Col span={16}>
                            <Form.Item
                              {...field}
                              name={[field.name, 'subject_name']}
                              rules={[
                                { required: true, message: 'Sport name is required' },
                              ]}
                              label='Sport Name'
                            >
                              <Input
                                placeholder='Sport Name'
                                disabled={index < (prefilledData?.length || 0)}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              {...field}
                              name={[field.name, 'sequence']}
                              rules={[
                                { required: true, message: 'Sequence is required' },
                                { validator: validateUniqueSportSequence },
                              ]}
                              label='Sequence'
                            >
                              <Input
                                type='number'
                                placeholder='Sequence'
                                min={0}
                                disabled={index < (prefilledData?.length || 0)}
                                style={{
                                  WebkitAppearance: 'none',
                                  MozAppearance: 'textfield',
                                  appearance: 'textfield',
                                }}
                                onChange={(e) => {
                                  const value = Number(e.target.value);
                                  const sports = form.getFieldValue('sports') || [];
                                  const updatedSports = sports.map((sport, idx) =>
                                    idx === field.name
                                      ? { ...sport, sequence: value }
                                      : sport
                                  );
                                  form.setFieldsValue({ sports: updatedSports });
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Divider orientation='left' className='th-primary'>
                          Parameters
                        </Divider>
                        <Form.List
                          name={[field.name, 'params_dict']}
                          rules={[
                            {
                              validator: async (_, params) => {
                                if (!params || params.length < 1) {
                                  return Promise.reject(
                                    new Error('At least one Parameter is required')
                                  );
                                }
                                const sequences = params
                                  .map((param) => Number(param.sequence))
                                  .filter((seq) => !isNaN(seq));
                                const uniqueSequences = new Set(sequences);
                                if (sequences.length !== uniqueSequences.size) {
                                  return Promise.reject(
                                    'Duplicate sequences found in Parameters. Please use unique sequences.'
                                  );
                                }
                              },
                            },
                          ]}
                        >
                          {(paramFields, { add: addParam, remove: removeParam }) => (
                            <>
                              {paramFields.map((paramField, paramIndex) => (
                                <Row
                                  key={paramField.key}
                                  gutter={16}
                                  align='middle'
                                  style={{ marginBottom: 8 }}
                                >
                                  <Col span={18}>
                                    <Form.Item
                                      {...paramField}
                                      name={[paramField.name, 'param_name']}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Parameter name is required',
                                        },
                                      ]}
                                    >
                                      <Input
                                        placeholder='Parameter Name'
                                        disabled={
                                          index < (prefilledData?.length || 0) &&
                                          paramIndex <
                                            ((prefilledData[index]?.sports_params || [])
                                              .length || 0)
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={4}>
                                    <Form.Item
                                      {...paramField}
                                      name={[paramField.name, 'sequence']}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Sequence is required',
                                        },
                                        {
                                          validator: (_, value) =>
                                            validateUniqueParamSequence(field.name)(
                                              _,
                                              value
                                            ),
                                        },
                                      ]}
                                      validateTrigger={['onChange', 'onBlur']}
                                    >
                                      <Input
                                        type='number'
                                        placeholder='Sequence'
                                        min={0}
                                        disabled={
                                          index < (prefilledData?.length || 0) &&
                                          paramIndex <
                                            ((prefilledData[index]?.sports_params || [])
                                              .length || 0)
                                        }
                                        style={{
                                          WebkitAppearance: 'none',
                                          MozAppearance: 'textfield',
                                          appearance: 'textfield',
                                        }}
                                        onChange={(e) => {
                                          const value = Number(e.target.value);
                                          const sports =
                                            form.getFieldValue('sports') || [];
                                          const updatedSports = sports.map(
                                            (sport, sportIndex) => {
                                              if (sportIndex === field.name) {
                                                const updatedParams = (
                                                  sport.params_dict || []
                                                ).map((param, idx) =>
                                                  idx === paramField.name
                                                    ? { ...param, sequence: value }
                                                    : param
                                                );
                                                return {
                                                  ...sport,
                                                  params_dict: updatedParams,
                                                };
                                              }
                                              return sport;
                                            }
                                          );
                                          form.setFieldsValue({
                                            sports: updatedSports,
                                          });
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={2}>
                                    <Tooltip title='Remove Parameter'>
                                      <Button
                                        type='link'
                                        danger
                                        onClick={() => removeParam(paramField.name)}
                                        icon={<MinusCircleOutlined />}
                                        disabled={
                                          index < (prefilledData?.length || 0) &&
                                          paramIndex <
                                            (prefilledData[index]?.sports_params
                                              ?.length || 0)
                                        }
                                      />
                                    </Tooltip>
                                  </Col>
                                </Row>
                              ))}
                              <Form.Item>
                                <Button
                                  type='dashed'
                                  onClick={() => {
                                    const sports = form.getFieldValue('sports') || [];
                                    const updatedSports = sports.map(
                                      (sport, sportIndex) => {
                                        if (sportIndex === field.name) {
                                          const params = sport.params_dict || [];
                                          return {
                                            ...sport,
                                            params_dict: [
                                              ...params,
                                              {
                                                param_name: '',
                                                sequence: params.length + 1,
                                              },
                                            ],
                                          };
                                        }
                                        return sport;
                                      }
                                    );
                                    form.setFieldsValue({ sports: updatedSports });
                                  }}
                                  block
                                  icon={<PlusOutlined />}
                                >
                                  Add Parameter
                                </Button>
                              </Form.Item>
                            </>
                          )}
                        </Form.List>
                      </Card>
                    ))}
                    <Form.Item>
                      <Button
                        type='dashed'
                        onClick={() => {
                          const sports = form.getFieldValue('sports') || [];
                          addSport({
                            subject_name: '',
                            sequence: sports.length + 1,
                            params_dict: [],
                          });
                        }}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Sport
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>

              <Form.Item className='text-right'>
                <Button
                  type='primary'
                  htmlType='submit'
                  disabled={!formValid || submitting}
                  loading={submitting}
                >
                  Submit
                </Button>
              </Form.Item>
            </>
          </Form>
        </div>
      </div>
    </Layout>
  );
};

export default SportsReportConfig;
