export const apiConstants = {
  GETALL_REQUEST: 'API_GETALL_REQUEST',
  GETALL_SUCCESS: 'API_GETALL_SUCCESS',
  GETALL_FAILURE: 'API_GETALL_FAILURE',

  BRANCH_REQUEST: 'API_BRANCH_REQUEST',
  BRANCH_SUCCESS: 'API_BRANCH_SUCCESS',
  BRANCH_FAILURE: 'API_BRANCH_FAILURE',

  CLASSGROUPTYPE_REQUEST: 'API_CLASSGROUPTYPE_REQUEST',
  CLASSGROUPTYPE_SUCCESS: 'API_CLASSGROUPTYPE_SUCCESS',
  CLASSGROUPTYPE_FAILURE: 'API_CLASSGROUPTYPE_FAILURE',

  DESIGNATION_REQUEST: 'API_DESIGNATION_REQUEST',
  DESIGNATION_SUCCESS: 'API_DESIGNATION_SUCCESS',
  DESIGNATION_FAILURE: 'API_DESIGNATION_FAILURE',

  ROLE_REQUEST: 'API_ROLE_REQUEST',
  ROLE_SUCCESS: 'API_ROLE_SUCCESS',
  ROLE_FAILURE: 'API_ROLE_FAILURE',

  SMSTYPES_REQUEST: 'API_SMSTYPES_REQUEST',
  SMSTYPES_SUCCESS: 'API_SMSTYPES_SUCCESS',
  SMSTYPES_FAILURE: 'API_SMSTYPES_FAILURE',

  DEPARTMENT_REQUEST: 'API_DEPARTMENT_REQUEST',
  DEPARTMENT_SUCCESS: 'API_DEPARTMENT_SUCCESS',
  DEPARTMENT_FAILURE: 'API_DEPARTMENT_FAILURE',

  GRADECATEGORY_REQUEST: 'API_GRADECATEGORY_REQUEST',
  GRADECATEGORY_SUCCESS: 'API_GRADECATEGORY_SUCCESS',
  GRADECATEGORY_FAILURE: 'API_GRADECATEGORY_FAILURE',

  LISTGRADECATEGORYID_REQUEST: 'API_LISTGRADECATEGORYID_REQUEST',
  LISTGRADECATEGORYID_SUCCESS: 'API_LISTGRADECATEGORYID_SUCCESS',
  LISTGRADECATEGORYID_FAILURE: 'API_LISTGRADECATEGORYID_FAILURE',

  SECTION_REQUEST: 'API_SECTION_REQUEST',
  SECTION_SUCCESS: 'API_SECTION_SUCCESS',
  SECTION_FAILURE: 'API_SECTION_FAILURE',

  SUBJECT_REQUEST: 'API_SUBJECT_REQUEST',
  SUBJECT_SUCCESS: 'API_SUBJECT_SUCCESS',
  SUBJECT_FAILURE: 'API_SUBJECT_FAILURE',

  ACADEMICSESSION_REQUEST: 'API_ACADEMICSESSION_REQUEST',
  ACADEMICSESSION_SUCCESS: 'API_ACADEMICSESSION_SUCCESS',
  ACADEMICSESSION_FAILURE: 'API_ACADEMICSESSION_FAILURE',

  GRADE_REQUEST: 'API_GRADE_REQUEST',
  GRADE_SUCCESS: 'API_GRADE_SUCCESS',
  GRADE_FAILURE: 'API_GRADE_FAILURE',

  STAFF_REQUEST: 'API_STAFF_REQUEST',
  STAFF_SUCCESS: 'API_STAFF_SUCCESS',
  STAFF_FAILURE: 'API_STAFF_FAILURE',

  STAFF_SEARCH: 'API_STAFF_SEARCH',
  STAFF_TAB: 'API_STAFF_TAB',

  GLOBAL_SEARCH_STAFF: 'GLOBAL_SEARCH_STAFF',

  GLOBAL_SEARCH: 'GLOBAL_SEARCH',

  GLOBAL_SEARCH_STUDENT: 'GLOBAL_SEARCH_STUDENT',

  STUDENT_REQUEST: 'API_STUDENT_REQUEST',
  STUDENT_SUCCESS: 'API_STUDENT_SUCCESS',
  STUDENT_FAILURE: 'API_STUDENT_FAILURE',
  STUDENT_ERROR_RESET: 'API_STUDENT_ERROR_RESET',

  STUDENTS_REQUEST: 'API_STUDENTS_REQUEST',
  STUDENTS_SUCCESS: 'API_STUDENTS_SUCCESS',
  STUDENTS_FAILURE: 'API_STUDENTS_FAILURE',
  STUDENTS_ERROR_RESET: 'API_STUDENT_ERROR_RESET',

  STUDENT_SEARCH: 'API_STUDENT_SEARCH',
  STUDENT_V2DATA: 'API_STUDENT_V2DATA',

  QUESTIONPAPER_SEARCH: 'API_QUESTIONPAPER_SEARCH',
  // LISTQUESTION_SEARCH: 'API_QUESTION_SEARCH',

  GRADEMAP_REQUEST: 'API_GRADEMAP_REQUEST',
  GRADEMAP_SUCCESS: 'API_GRADEMAP_SUCCESS',
  GRADEMAP_FAILURE: 'API_GRADEMAP_FAILURE',

  SUBJECTMAP_REQUEST: 'API_SUBJECTMAP_REQUEST',
  SUBJECTMAP_SUCCESS: 'API_SUBJECTMAP_SUCCESS',
  SUBJECTMAP_FAILURE: 'API_SUBJECTMAP_FAILURE',

  SECTIONMAP_REQUEST: 'API_SECTIONMAP_REQUEST',
  SECTIONMAP_SUCCESS: 'API_SECTIONMAP_SUCCESS',
  SECTIONMAP_FAILURE: 'API_SECTIONMAP_FAILURE',

  QUESTIONTYPE_REQUEST: 'API_QUESTIONTYPE_REQUEST',
  QUESTIONTYPE_SUCCESS: 'API_QUESTIONTYPE_SUCCESS',
  QUESTIONTYPE_FAILURE: 'API_QUESTIONTYPE_FAILURE',

  QUESTIONLEVEL_REQUEST: 'API_QUESTIONLEVEL_REQUEST',
  QUESTIONLEVEL_SUCCESS: 'API_QUESTIONLEVEL_SUCCESS',
  QUESTIONLEVEL_FAILURE: 'API_QUESTIONLEVEL_FAILURE',

  QUESTIONCATEGORY_REQUEST: 'API_QUESTIONCATEGORY_REQUEST',
  QUESTIONCATEGORY_SUCCESS: 'API_QUESTIONCATEGORY_SUCCESS',
  QUESTIONCATEGORY_FAILURE: 'API_QUESTIONCATEGORY_FAILURE',

  GRADECHAPTER_REQUEST: 'API_GRADECHAPTER_REQUEST',
  GRADECHAPTER_SUCCESS: 'API_GRADECHAPTER_SUCCESS',
  GRADECHAPTER_FAILURE: 'API_GRADECHAPTER_FAILURE',

  CHAPTER_REQUEST: 'API_CHAPTER_REQUEST',
  CHAPTER_SUCCESS: 'API_CHAPTER_SUCCESS',
  CHAPTER_FAILURE: 'API_CHAPTER_FAILURE',

  LISTTESTS_REQUEST: 'API_LISTTESTS_REQUEST',
  LISTTESTS_SUCCESS: 'API_LISTTESTS_SUCCESS',
  LISTTESTS_FAILURE: 'API_LISTTESTS_FAILURE',

  GETONLINETEST_REQUEST: 'API_GETONLINETEST_REQUEST',
  GETONLINETEST_SUCCESS: 'API_GETONLINETEST_SUCCESS',
  GETONLINETEST_FAILURE: 'API_GETONLINETEST_FAILURE',

  GETTESTPAPER_REQUEST: 'API_GETTESTPAPER_REQUEST',
  GETTESTPAPER_SUCCESS: 'API_GETTESTPAPER_SUCCESS',
  GETTESTPAPER_FAILURE: 'API_GETTESTPAPER_FAILURE',

  STUDENTASSESSMENT_REQUEST: 'API_SUBJECTMAP_REQUEST',
  STUDENTASSESSMENT_SUCCESS: 'API_SUBJECTMAP_SUCCESS',
  STUDENTASSESSMENT_FAILURE: 'API_SUBJECTMAP_FAILURE'

}
