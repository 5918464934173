let BASE, MEDIA_BASE, STATIC_BASE
let externalUrls
const PROTO = 'http'
const PROTO_HTTPS = 'https'
const SOCKET_PROTO = 'ws'
const SOCKET_PROTO_S = 'wss'
let HOSTNAME, PORT, BASE_URL, SOCKET_PORT, SOCKET_BASE, SOCKET_HOSTNAME, FRONTENT_BASE
let marketingUrl = null
if (process.env.REACT_APP_UI_ENV === 'dev_aol') {
  HOSTNAME = 'dev.alwaysonlearning.com'
  BASE = PROTO + '://' + HOSTNAME + '/qbox'
  // MEDIA_BASE = BASE
  FRONTENT_BASE = PROTO + '://' + HOSTNAME
  MEDIA_BASE = 'https://s3.ap-south-1.amazonaws.com/letseduvate/prod/media'
  STATIC_BASE = BASE + '/static'

  marketingUrl = 'http://13.126.157.227/qbox'
} else if (process.env.REACT_APP_UI_ENV === 'dev_https') {
  HOSTNAME = 'dev.letseduvate.com'
  BASE = PROTO_HTTPS + '://' + HOSTNAME + '/qbox'
  // MEDIA_BASE = BASE
  FRONTENT_BASE = PROTO_HTTPS + '://' + HOSTNAME
  MEDIA_BASE = 'https://s3.ap-south-1.amazonaws.com/letseduvate/prod/media'
  STATIC_BASE = BASE + '/static'

  marketingUrl = 'http://13.126.157.227/qbox'
} else if (process.env.REACT_APP_UI_ENV === 'dev') {
  HOSTNAME = '13.235.179.64'
  PORT = '85'
  BASE = PROTO + '://' + HOSTNAME + ':' + PORT + '/qbox'

  // MEDIA_BASE = BASE
  FRONTENT_BASE = PROTO + '://' + HOSTNAME
  MEDIA_BASE = 'https://s3.ap-south-1.amazonaws.com/letseduvate/prod/media'
  STATIC_BASE = BASE + '/static'
  // web socket
  SOCKET_HOSTNAME = 'websock.letseduvate.com'
  SOCKET_PORT = '443'
  SOCKET_BASE = SOCKET_PROTO_S + '://' + SOCKET_HOSTNAME + ':' + SOCKET_PORT + '/ws'

  marketingUrl = 'http://13.233.150.53/qbox'
} else if (process.env.REACT_APP_UI_ENV === 'prod') {
  // HOSTNAME = 'questbox.letseduvate.com'
  HOSTNAME = 'erp.letseduvate.com'
  PORT = '80'
  BASE = PROTO_HTTPS + '://' + HOSTNAME + '/qbox'
  FRONTENT_BASE = PROTO_HTTPS + '://' + HOSTNAME
  BASE_URL = PROTO_HTTPS + '://' + HOSTNAME
  MEDIA_BASE = 'https://s3.ap-south-1.amazonaws.com/letseduvate/prod/media'
  STATIC_BASE = BASE + '/static'

  // web socket
  SOCKET_HOSTNAME = 'websock.letseduvate.com'
  SOCKET_PORT = '443'
  SOCKET_BASE = SOCKET_PROTO_S + '://' + SOCKET_HOSTNAME + ':' + SOCKET_PORT + '/ws'

  marketingUrl = 'https://marketing.letseduvate.com/qbox'
} else if (process.env.REACT_APP_UI_ENV === 'dev_feature') {
  HOSTNAME = '13.235.179.64'
  PORT = '81'
  BASE = PROTO + '://' + HOSTNAME + ':' + PORT + '/qbox'

  // MEDIA_BASE = BASE
  FRONTENT_BASE = PROTO + '://' + HOSTNAME
  MEDIA_BASE = 'https://s3.ap-south-1.amazonaws.com/letseduvate/prod/media'
  STATIC_BASE = BASE + '/static'
  // web socket
  SOCKET_HOSTNAME = HOSTNAME
  SOCKET_PORT = '8001'
  SOCKET_BASE = SOCKET_PROTO + '://' + SOCKET_HOSTNAME + ':' + SOCKET_PORT + '/ws'

  marketingUrl = 'http://13.126.157.227/qbox'
} else if (process.env.REACT_APP_UI_ENV === 'finance_staging') {
  HOSTNAME = 'finance.letseduvate.com'
  PORT = '443'
  BASE = PROTO_HTTPS + '://' + HOSTNAME + ':' + PORT + '/qbox'
  MEDIA_BASE = BASE
  STATIC_BASE = BASE + '/static'
  FRONTENT_BASE = PROTO + '://' + HOSTNAME

  // web socket
  SOCKET_HOSTNAME = HOSTNAME
  SOCKET_PORT = '8001'
  SOCKET_BASE = SOCKET_PROTO + '://' + SOCKET_HOSTNAME + ':' + SOCKET_PORT + '/ws'

  marketingUrl = 'http://13.126.157.227/qbox'
} else if (process.env.REACT_APP_UI_ENV === 'dev_container') {
  HOSTNAME = '13.235.179.64'
  PORT = '91'
  BASE = 'http://erp.finance.letseduvate.com' + '/qbox'

  // MEDIA_BASE = BASE
  FRONTENT_BASE = PROTO + '://' + HOSTNAME
  MEDIA_BASE = 'https://s3.ap-south-1.amazonaws.com/letseduvate/prod/media'
  STATIC_BASE = BASE + '/static'

  // web socket
  SOCKET_HOSTNAME = HOSTNAME
  SOCKET_PORT = '8001'
  SOCKET_BASE = SOCKET_PROTO + '://' + SOCKET_HOSTNAME + ':' + SOCKET_PORT + '/ws'

  marketingUrl = 'http://13.126.157.227/qbox'
} else {
  HOSTNAME = 'localhost'
  PORT = '8000'
     BASE =`${window.location.origin}/qbox`

    //  BASE='https://marketing.letseduvate.com' +'/qbox'
  // BASE = 'http://erp.finance.letseduvate.com' + '/qbox'
  // BASE = 'http://localhost:8000' + '/qbox'
  FRONTENT_BASE = PROTO + '://' + HOSTNAME + ':' + '3000'
  BASE_URL = PROTO + '://' + HOSTNAME + ':8000' + '/qbox'
  MEDIA_BASE = 'http://localhost:8000'
  // MEDIA_URL = 'http://localhost:8000/qbox/media/'
  STATIC_BASE = BASE + '/static'

  // web socket
  SOCKET_HOSTNAME = HOSTNAME
  SOCKET_PORT = '8000'
  SOCKET_BASE = SOCKET_PROTO + '://' + SOCKET_HOSTNAME + ':' + SOCKET_PORT + '/ws'

  // marketingUrl = 'https://dev.marketing.letseduvate.com/qbox'
     marketingUrl='https://marketing.letseduvate.com/qbox'
}

if (process.env.REACT_APP_UI_ENV === 'prod') {
  externalUrls = {
    OnlineClass: 'https://streamyourclass.com/class_list_filter_role_and_grade/',
    OnlineClassRequest: 'https://streamyourclass.com/accept_request_save/',
    OnlineClassEntryRequest: 'https://streamyourclass.com/enter_class/',
    OnlineClassRequestTeacher: 'https://streamyourclass.com/accept_request_save_v2/',
    OnlineClassTeacher: 'https://streamyourclass.com/class_list_filter_role_subject_grade_v2/'
  }
} else {
  externalUrls = {
    OnlineClass: 'https://streamyourclass.com/class_list_filter_role_and_grade/',
    OnlineClassRequest: 'https://streamyourclass.com/accept_request_save/',
    OnlineClassEntryRequest: 'https://streamyourclass.com/enter_class/',
    OnlineClassRequestTeacher: 'https://streamyourclass.com/accept_request_save_v2/',
    OnlineClassTeacher: 'https://streamyourclass.com/class_list_filter_role_subject_grade_v2/'
  }
  // externalUrls = {
  //   OnlineClass: 'http://localhost:9000/class_list_filter_role_and_grade/',
  //   OnlineClassRequest: 'http://localhost:9000/accept_request_save/',
  //   OnlineClassEntryRequest: 'http://localhost:8000/enter_class/',
  //   OnlineClassRequestTeacher: 'http://127.0.0.1:8000/accept_request_save_v2/',
  //   OnlineClassTeacher: 'http://127.0.0.1:8000/class_list_filter_role_subject_grade_v2/'

  // }
}
const urls = {
  ConcessionReports: BASE + '/finance/concession_report/',
  WalletReport: BASE + '/finance/wallet_report/',
  UploadPaymentFile: BASE + '/finance/create_payment_upload_image_view/',
  NewStudentExtended: BASE + '/accounts/v2/student/extended/',
  NewStudent: BASE + '/accounts/v2/student/basic/',
  Finance: BASE + '/finance/',
  UpdateInstallmentAmount: '/InstallmentsAmountUpdate/',
  FinanceMedia: BASE + '/finance',
  BASE,
  BASE_URL,
  MEDIA_BASE,
  LOGIN: BASE + '/activity_app/login/',
  LOGOUT: BASE + '/accounts/user_logout/',
  BRANCH: BASE + '/accounts/branch/',
  BRANCHv2: BASE + '/activity_app/v2/branch/',
  GRADECHAPTER: BASE + '/activity_app/v2/grade_subjects/',
  GRADEMAPPINGv2: BASE + '/activity_app/v2/grademapping/',
  SUBJECTMAPPINGv2: BASE + '/activity_app/v2/subjectmapping/',
  SECTIONMAPPINGv2: BASE + '/activity_app/v2/sectionmapping/',
  SECTIONSHUFFLE: BASE + '/accounts/v2/student/sectionmapping/',
  GRADE: BASE + '/accounts/grade/',
  GetJournal: BASE + '/accounts/student/journal/',
  SECTION: BASE + '/accounts/section/',
  StudentAttendance: BASE + '/academic/attendance/student/',
  ACADEMICSESSION: BASE + '/accounts/academicsession/',
  ACADEMICSESSION: BASE + '/accounts/academicsession/',
  UTILACADEMICSESSION: BASE + '/finance/list-acad-session',
  session: BASE + '/accounts/add_branch_to_acadsession/',
  GrievanceType: BASE + '/academic/grievance_service_type/',
  GrievanceSubType: BASE + '/academic/grievance_service_sub_type/',
  AnnotateEbook: BASE + '/academic/ebook_user_status/',
  GuestStudentDetails: BASE + '/accounts/guest/class_grps/',
  Status_Update: BASE + '/accounts/student/guest/status_update/',
  ConfigurationAssessment: BASE + '/assessment/questbox/assessmenttypemappings/',
  EditAssessment: BASE + '/assessment/questbox/v2/assessment/',
  mappingDetails: BASE + '/assessment/assigndetails/assessment/',
  ACADSESSION: BASE + '/accounts/questbox/user_acad_session/',
  GetStudentDiscipline: BASE + '/accounts/questbox/student_discipline/',
  GetQuestionCount: BASE + '/academic/questbox/subject_questions/',
  GetSubjectsWithQuestionCount: BASE + '/academic/questbox/subject_questions/',
  GetQuestionLevelsWithQuestionCount: BASE + '/academic/questbox/questionlevel_count/',
  GetChaptersWithQuestionCount: BASE + '/academic/questbox/chapter_questions/',
  GetPracticeQuestionsWithResponses: BASE + '/academic/questbox/question/practice/include_response/',
  GetQuizQuestionsWithResponses: BASE + '/academic/online_class/quiz/user/questions/',
  QuizInfo: BASE + '/academic/quiz/details/',
  QuizBgms: BASE + '/academic/quiz/music/',
  SUBJECT: BASE + '/finance/subjects-for-kit/',
  SUBJECTMAPPING: BASE + '/finance/subjectmapping/',
  ERROR_HANDLER: BASE + '/internaltools/error_message/',
  FEEDS: BASE + '/accounts/feeds/',
  STUDENTGRADEBOOK: BASE + '/accounts/gradebook_evaluation_criteria_student/',
  COSCHOLASTICCHOICES: BASE + '/accounts/co_scholastic_choices/',
  GRADEBOOKEVALUATIONSEQUENCE: BASE + '/accounts/gradebook_evaluation_sequences/',
  GRADEBOOKEVALUATION: BASE + '/accounts/gradebookevaluation/',
  GRADEBOOKEVALUATIONTERMCOPY: BASE + '/accounts/gradebookevaluationtremcopy/',
  ASSIGNSTUDENTTOPARENT: BASE + '/accounts/student-parent/parent/',
  ASSIGNSIBLINGTOPARENT: BASE + '/accounts/student-parent/sibling-parent/',
  ADDSIBLINGTOPARENT: BASE + '/accounts/student-parent/addsibling/',
  GRADEBOOK: BASE + '/accounts/gradebook_evaluation_criteria/',
  UPLOADDANCERESULT: BASE + '/assessment/questbox/danceresult/',
  Student: BASE + '/accounts/student/',
  StudentDiscipline: BASE + '/accounts/questbox/student_discipline/',
  Teacher: BASE + '/accounts/teacher/',
  Staff: BASE + '/accounts/staff/',
  GrievanceDateRangeExport: BASE + '/academic/export_grievance_date_range/',
  monthwiseAttendanceReport: BASE + '/academic/attendancerep/',
  percentageWiseReport: BASE + '/academic/attendance/monthwiserep/',
  AttendanceAbsenteesExport: BASE + '/academic/export_attendance_absentees/',
  Students: BASE + '/accounts/student',
  ExcelColumns: BASE + '/accounts/download_custom_students/',
  Search: BASE + '/search/staff/',
  StudentContactDetails: BASE + '/accounts/v2/student/contact_details/',
  ElasticSearchOnlineTest: BASE + '/search/online_test/',
  GlobalSearchStaff: BASE + '/search/staff/',
  GlobalSearchStudent: BASE + '/search/student/',
  GlobalSearch: BASE + '/search/student/',
  STUDENTSEARCH: BASE + '/search/student/',
  STUDENTPARENTSEARCH: BASE + '/search/studentparent/',
  PARENTSEARCH: BASE + '/search/parent/',
  SectionMapping: BASE + '/accounts/sectionmapping/',
  SectionMapping_s: BASE + '/accounts/sectionmapping_s/',
  STUDENTFILTER: BASE + '/accounts/student_filter/',
  NEWSTUDENTFILTER: BASE + '/accounts/new_student_filter/',
  NEWSTUDENT: BASE + '/accounts/new_students/',
  TEACHERFILTER: BASE + '/accounts/sms/',
  DESIGNATION: BASE + '/accounts/designation/',
  DEPARTMENT: BASE + '/accounts/department/',
  ADMINREPORTS: BASE + '/academic/report_urls/',
  PRINCIPALREPORTS: BASE + '/academic/report_url/',
  EBOOK: BASE + '/academic/ebook/',
  EbookUser: BASE + '/academic/ebook_user/',
  ROLE: BASE + '/accounts/role/',
  SmsTypes: BASE + '/accounts/sms_type/',
  CreateSms: BASE + '/accounts/sms/',
  ClassGroupSms: BASE + '/accounts/sms_classgroup/',
  GRADECATEGORY: BASE + '/accounts/gradecategory/',
  assessmentConfiguration: BASE + '/assessment/configuration/',
  getAssessmentConfiguration: BASE + '/assessment/configuration/',
  getTypesAndSubType: BASE + '/assessment/qp_configuration/',
  GradeMapping: BASE + '/accounts/grademapping/',
  subjects: BASE + '/activity_app/v2/grade_subjects/',
  SubjectMapping: BASE + '/accounts/subjectmapping/',
  StaffExcelUpload: BASE + '/accounts/teacherexcel/',
  AttendanceExcelReportDownload: BASE + '/academic/attendance_report_urls/',
  StaffBulkUpload: BASE + '/accounts/staff_bulk_upload/',
  StudentExcelUpload: BASE + '/accounts/studentexcel/',
  TeacherDailyTimetable: BASE + '/accounts/teacher_daily_timetable/',
  StudentUpdatedExcelUpload: BASE + '/accounts/student_upload_excel/',
  StudenShuffleUpload: BASE + '/accounts/student_shuffle_excel/',
  DailyDiary: BASE + '/accounts/dailydiaryview',
  DailyDiaryMedia: BASE + '/accounts/dailydiarymedia',
  TimeTable: BASE + '/academic/timetable/',
  TimeTableGenerate: BASE + '/academic/timetabledata/',
  TeacherSubjectSectionMappingFilter: BASE + '/accounts/teachersubjectsectionmappingfilter',
  QuestBox: BASE + '?token=',
  AssignSubjectTeacher: BASE + '/accounts/teachersubjectsectionmapping/',
  DownloadExcel: BASE + '/academic/v2/reportmonth/',
  TeacherList: BASE + '/accounts/util/schoolstaff/',
  Report: BASE + '/academic/v2/report/',
  deleteAssessmentConfigurations: BASE + '/assessment/configuration/',
  DeleteRemarks: BASE + '/accounts/questbox/student_discipline/',
  ReportV2: BASE + '/academic/v2/report/',
  ReportHistory: BASE + '/academic/history_report/',
  Chapter: BASE + '/academic/questbox/chapter/',
  ChapterWiseQuesCount: BASE + '/academic/questbox/chapter_questions/',
  QuestionLevel: BASE + '/academic/questbox/questionlevel',
  QuestionType: BASE + '/academic/questbox/questiontype',
  QuestionpaperDetails: BASE + '/assessment/assessment_questionpaper_details/',
  QuestionCategory: BASE + '/academic/questbox/questioncategory',
  QuestionPaperType: BASE + '/academic/questbox/questionpapertype/',
  QuestionPaperSubType: BASE + '/academic/questbox/questionpapersubtype/',
  typesAndSubTypes: BASE + '/assessment/qp_configuration/',
  submitTypesAndSubTypes: BASE + '/assessment/qp_configuration/',
  QuestionPaper: BASE + '/academic/questbox/questionpaper/',
  QuestionPaperMarks: BASE + '/assessment/questbox/v2/questionpaper_marks/',
  GetQuestions: BASE + '/academic/questbox/questionpaper/set_question/',
  UploadQuestionExcel: BASE + '/academic/questbox/import_sheet_excel/',
  UploadResultExcel: BASE + '/assessment/questbox/result/',
  Calendar: BASE + '/accounts/calendarevent/',
  AcademicTerms: BASE + '/accounts/academic_terms/',
  LockGradebook: BASE + '/accounts/lockgradebook/',
  SmsEmailDetails: BASE + '/accounts/sms_email_details/',
  VideoSourceUrl: BASE + '/accounts/covid/video/request/',
  ActivityEvent: BASE + '/activity_app/v2/activity/',
  StudentActions: BASE + '/accounts/studentactivelog/',
  FileUpload: BASE + '/file_upload/questbox/fileupload/',
  UniqueTestId: BASE + '/assessment/questbox/uniquetest/',
  CreateAssessment: BASE + '/assessment/questbox/assessment/',
  CreateAssessmentV2: BASE + '/assessment/questbox/v2/assessment/',
  Applicant: BASE + '/accounts/registration/',
  GuestRegister: BASE + '/accounts/register/student/guest/',
  VerifyGuestRegister: BASE + '/accounts/register/student/guest/verify/',
  ListQuestionPaper: BASE + '/academic/questbox/questionpaper/',
  Assessment: BASE + '/assessment/questbox/assessment/',
  AssessmentSectionWise: BASE + '/assessment/questbox/sectionassessmentdetail/',
  StudentResult: BASE + '/assessment/questbox/studentresult/',
  StaffExcel: BASE + '/static/oms/Add Staff Template.xlsx',
  StudentExcel: BASE + '/static/oms/student_excel.xlsx',
  StudentUploadExcel: BASE + '/static/oms/upload_updated_student.xlsx',
  StudentShuffleUpload: BASE + '/static/oms/shuffle_students_format.xlsx',
  ResultExcel: BASE + '/static/oms/upload_result.xlsx',
  QuestionExcel: STATIC_BASE + '/static/oms/student_Question.xlsx',
  // AssessmentExcel: BASE + '/static/oms/assessment_excel_sheet.xlsx',
  // AssessmentMarksExcel: BASE + '/static/oms/assessment_template_marks_excel_sheet.xlsx',
  ViewQuestionPaper: BASE + '/academic/questbox/questionpaper/',
  ViewQuestionPaperDetail: BASE + '/academic/questbox/viewquestionpaper/',
  ExportQuestionTeacher: BASE + '/academic/questbox/viewquestionpaperteacher/',
  ExportQuestionStudentColWise: BASE + '/academic/questbox/viewquestionpaperstudent/',
  ExportQuestionStudent1Row: BASE + '/academic/questbox/viewquestionpaperstudentonerow/',
  ExportQuestionStudent2Row: BASE + '/academic/questbox/viewquestionpaperstudenttworow/',
  PracticeQuestionDashboard: BASE + '/assessment/questbox/dashbord',
  GuestStudentRegister: BASE + '/accounts/gueststudent/sibling/',
  LessonPlan: BASE + '/academic/lessonplan/',
  TeacherReportLessonPlanMicroSchedule: BASE + '/academic/teacherreport_lessonplan_microschedule/',
  Attendance: BASE + '/academic/attendance/',
  AbsentStudent: BASE + '/accounts/absentee_sms/',
  AttendanceSummaryBranchReport: BASE + '/academic/attendance/summaryreport/',
  HomeWorkSubmission: BASE + '/academic/homework/',
  HomeWorkImageEvaluation: BASE + '/evaluation_tool/',
  OnlineClassResourceUpload: BASE + '/academic/online/onlineClassResourceAPI/',
  DeleteOnlineClassResource: BASE + '/academic/online/deleteResourceFileAPI/',
  OnlineClassHomeworkUpload: BASE + '/academic/online/onlineClassHomeworkAPI/',
  DeleteOnlineClassHomework: BASE + '/academic/online/deleteHomeworkFileAPI/',
  VideoMessageCommunication: BASE + '/accounts/communication/',
  OnlineClassResourceLinks: BASE + '/academic/online_class/resource_link/',
  OnlineClassQuizLink: BASE + '/academic/online_class/quiz_link/',
  OnlineQuizSearch: BASE + '/academic/online_class/questionpaper/search/',
  OnlineQuizQuestionsList: BASE + '/academic/online_class/quiz/questions/',
  OnlineQuizPaper: BASE + '/academic/online_class/quiz/question_paper/',
  OrchadioAvailability: BASE + '/academic/radio/check_availability/',
  OrchadioList: BASE + '/academic/radio/create_program/',
  OrchadioDelete: BASE + '/academic/radio/delete_program/',
  OrchadioLike: BASE + '/academic/radio/add_like_and_dislike/',
  OrchadioParticipants: BASE + '/academic/radio/add_participant/',
  ExcelDownloadForGuestStudent: BASE + '/academic/bulk_online_class/',
  OnlineClass: MEDIA_BASE + '/class_list_filter_role_and_grade/',
  OnlineClassRequest: MEDIA_BASE + '/accept_request_save/',
  OnlineClassEntryRequest: MEDIA_BASE + '/enter_class/',
  mobileOtp: BASE + '/accounts/otp/send',
  mobileOtpVerify: BASE + '/accounts/otp/verify',
  CircularV1: BASE + '/academic/circular/',
  CircularIdV1: BASE + '/academic/circular/',
  StudentReview: BASE + '/academic/student_feedback_dashboard/',
  Circular: BASE + '/academic/v2/circular/',
  teacherView: BASE + '/academic/student_feedback_dashboard/',
  EngagementReport: BASE + '/accounts/student/guest/engagement_report/',
  EngagementReportExcel: BASE + '/accounts/export_engagement_report/guest/',
  EngagementReportSearch: BASE + '/accounts/student/guest/engagement_report/search/',
  TeacherReportList: BASE + '/academic/v2/report/teachers/',
  CircularId: BASE + '/academic/v2/circular/',
  ListQuestionSearch: BASE + '/accounts/search/elastic/question/',
  ListComprehenssionQuestionSearch: BASE + '/accounts/search/elastic/question/comprehesion/',
  DashboardConsolidationReport: BASE + '/academic/v2/dashboard/',
  OnlineTest: BASE + '/assessment/questbox/onlinetest/',
  ViewTest: BASE + '/assessment/questboxOld/onlinetest/',
  AssignDetails: BASE + '/assessment/assigndetails',
  StudentTest: BASE + '/assessment/questbox/v2/onlinetest/',
  ListTests: BASE + '/assessment/questbox/onlinetest/',
  OnlineTestInstance: BASE + '/assessment/questbox/onlinetestinstance/',
  QuestionPaperFilter: BASE + '/academic/questbox/getquestionpaper/',
  // SearchQuestionPapers: BASE + '/accounts/questionpaper/search/',
  SearchQuestionPapers: BASE + '/search/questionpaper/',
  SearchAssessments: BASE + '/accounts/assessment/search/',
  SearchAssessments1: BASE + '/search/assessment/',
  StaffExport: BASE + '/accounts/export_staff/',
  StudentExport: BASE + '/accounts/export_student/',
  BranchExport: BASE + '/accounts/export_branch/',
  DepartmentExport: BASE + '/accounts/export_department/',
  DesignationExport: BASE + '/accounts/export_designation/',
  GradeExport: BASE + '/accounts/export_grade/',
  GradeCategoryExport: BASE + '/accounts/export_grade_category/',
  RoleExport: BASE + '/accounts/export_role/',
  SectionExport: BASE + '/accounts/export_section/',
  SubjectExport: BASE + '/accounts/export_subject/',
  StudentIdcard: BASE + '/accounts/studentidcard/',
  StaffIdcard: BASE + '/accounts/staffidcard/',
  QuestionPaperQuesDetails: BASE + '/assessment/questbox/questionpaperpractice/',
  AsseesmentCategories: BASE + '/assessment/assessment_choices/',
  Message: BASE + '/academic/message/',
  DeleteMessage: BASE + '/academic/communication/',
  communication: BASE + '/academic/communication/',
  Messaging: BASE + '/academic/messaging/',
  GeneralDiary: BASE + '/academic/general_diary/',
  Communication: BASE + '/academic/communication',
  MicroSchedule: BASE + '/academic/questbox/microschedule/',
  ApplicantScholarship: BASE + '/accounts/applicantScholarship/',
  ApplicantAdmit: BASE + '/accounts/applicantAdmit/',
  NormalFeeType: BASE + '/finance/listnormalfee/',
  createFeeType: BASE + '/finance/createnormalfee/',
  FetchNonOrchids: BASE + '/finance/fetch_orchids_pdf_details/',
  // NormalFeeTypeDelete: BASE + '/finance/deletenormalfee',
  MiscFeeType: BASE + '/finance/listmiscellaneousfee/',
  createMiscFeeType: BASE + '/finance/createmiscellaneousfee/',
  OthersFeeType: BASE + '/finance/listotherfee/',
  createOtherFeeType: BASE + '/finance/createotherfee/',
  ReceiptList: BASE + '/finance/listreceipt/',
  ReceiptType: BASE + '/finance/listreceipttypeapiview/',
  FeeAccount: BASE + '/finance/listfeeaccountinfo/',
  ListStoreFeeAccount: BASE + '/finance/list-store-feeaccount/',
  CreateReceipt: BASE + '/finance/createreceipt/',
  NormalFeeTypeDelete: BASE + '/finance/deletenormalfee',
  ViewBanks: BASE + '/finance/listbankaccountinfo/',
  DeleteBanks: BASE + '/finance/deletebankaccountinfo',
  EditBanks: BASE + '/finance/editbankaccountinfo',
  AddBanks: BASE + '/finance/createbankaccountinfo/',
  ViewFeeAccounts: BASE + '/finance/listfeeaccountinfo/',
  ListApplicableBranches: BASE + '/finance/role/list-applicable-branch/',
  CheckReturn: BASE + '/finance/role/check-return/',
  AdminReturn: BASE + '/finance/role/return-admin/',
  ChangeAccBranch: BASE + '/finance/role/change-acc-branch/',
  ChangeBranchAdmin: BASE + '/finance/role/change-branch-admin/',
  EditFeeAccounts: BASE + '/finance/editfeeaccountinfo/',
  AddFeeAccount: BASE + '/finance/createfeeaccountinfo/',
  MiscFeeClass: BASE + '/finance/branch_filter/',
  MiscFee: BASE + '/finance/miscfeetoclasses/',
  UserSender: BASE + '/accounts/url/visit/count/',
  PlanApplicable: BASE + '/finance/liststudenttype/',
  CreateFeePlan: BASE + '/finance/createcreatefeeplan/',
  FeeType: BASE + '/finance/listfee/',
  CreateFeeTypePlanMapping: BASE + '/finance/createfeeplanfeetypemapping/',
  FeePlanList: BASE + '/finance/listcreatefeeplan/',
  GradeList: BASE + '/finance/grade/',
  FeeAccToBrnch: BASE + '/finance/listbranchfeeaccountmapping/',
  DeleteFeeAccToBranch: BASE + '/finance/deletebranchfeeaccountmapping/',
  RemainingFeeAccToBranch: BASE + '/finance/listofonebranchfeeaccountcustommapping/',
  AddFeeAccountsToBranch: BASE + '/finance/*/editbranchfeeaccountmapping/',
  BranchFeeList: BASE + '/finance/listfee/',
  FeeTypesForIT: BASE + '/finance/feeTypeforstudentit/',
  FeeAccToClass: BASE + '/finance/feeaccountstoclasses/',
  UpdateCreateFeePlan: BASE + '/finance/UpdateCreateFeePlan/',
  FeeTypeList: BASE + '/finance/listfeeplanfeetypemapping/',
  CreateInstallments: BASE + '/finance/createinstallments/',
  DisplayInstallments: BASE + '/finance/displayinstallments/',
  IndividualInstallment: BASE + '/finance/eachinstallmentsdisplay/',
  FeePLanTypeList: BASE + '/finance/listfeeforfeeplan/',
  CreateFeeTypeMapping: BASE + '/finance/createfeeplanfeetypecustommapping/',
  FeeAccountBranches: BASE + '/finance/listbranchfeeaccountcustommapping/',
  FeeAcoountsFromFeePlan: BASE + '/finance/feeaccountfeeplandisplay/',
  StudentFeeDetails: BASE + '/finance/feedisplay/',
  StudentMakePayment: BASE + '/finance/studentmakepayment/',
  StatusMakePayment: BASE + '/finance/accept_payment_upload_student_list/',
  StudentAllTransactions: BASE + '/finance/liststudenttransaction/',
  FeeStructureDefault: BASE + '/finance/studentfeedisplay/',
  StudentMakePaymentPost: BASE + '/finance/studentmakepaymentupdate/',
  CorporateBanks: BASE + '/finance/corporatebank/',
  ConcessionFeeTypes: BASE + '/finance/concession/',
  ConcessionDetails: BASE + '/finance/concessiondetail/',
  ConcessionBackdate: BASE + '/finance/ConcessionLastDate/',
  CreateMakePaymentAcc: BASE + '/finance/createmakepaymentaccountant/',
  TransactionStatusList: BASE + '/finance/listtransactionrecord/',
  IndividualConcessionDetails: BASE + '/finance/listofoneconcessionrecord/',
  StudentPayAtAcc: BASE + '/finance/studentpaymentataccountant/',
  UpdateTransactionList: BASE + '/finance/',
  StudentCheckPayment: BASE + '/finance/studentcheckpayment/',
  PaytmPayment: BASE + '/paytm/now/',
  // AirpayPayment: BASE + '/airpay/paywithairpay/',
  // AirpayPayment: BASE + '/razorpay/paywithrazorpay/',
  AirpayPayment: BASE + '/pg/fee_payment/',
  //  AirpayPayment: BASE + '/airpay/paywithairpay/',
  Eswipe: BASE + '/hdfcpos/paywithhdfc/',
  DeviceIdUrl: BASE + '/hdfcpos/hdfcdeviceid/',
  AxisPos: BASE + '/pos/pinelabs/upload-billed-info/',
  CardDetails: BASE + '/pos/pinelabs/update-payment/',
  concessionFeeType: BASE + '/finance/concessionfeetypes/',
  FeePlanYearApplicable: BASE + '/finance/CreateFeePlanYearsApplicableToRetriveAPIView/',
  PaytmResponse: BASE + '/paytm/response/',
  GetAccountOfEveryType: BASE + '/finance/corporatebankslistapiview/',
  SavePettyCashDiposit: BASE + '/finance/bankdepositcreateAPIView/',
  StudentPaymentAcc: BASE + '/finance/feedisplayaccountant/',
  PaymentDetails: BASE + '/finance/list_payment_upload_image_view/',
  CancelPyayment: BASE + '/finance/reject_payment_upload_image/',
  AcceptPayment: BASE + '/finance/accept_payment_upload_image/',
  ListPettyCashDeposit: BASE + '/finance/petty-cash-view-deposits/',
  AccountantTransaction: BASE + '/finance/studenttransactionataccountant/',
  AccountantChequeTransaction: BASE + '/finance/studenttransactionataccountantcheque/',
  PettyCashBankView: BASE + '/finance/PettyCashListAPIView/',
  LedgerList: BASE + '/finance/ledger_list/',
  LedgerUpdateDestroy: '/LedgerHeadRetrieveUpdateDestroyAPIView/',
  CreateMeasurement: BASE + '/store/createunitofmeasurement/',
  ListMeasurement: BASE + '/store/listunitofmeasurement/',
  CreateColor: BASE + '/store/createcolor/',
  ListColor: BASE + '/store/listcolor/',
  CreateStoreSubCat: BASE + '/store/createstoresubcategory/',
  ListStoreSubCat: BASE + '/store/liststoresubcategory/',
  CreateStoreItem: BASE + '/store/createstoreitem/',
  FetchRefund: BASE + '/store/wallet/studentrefundamount/',
  ListStoreItem: BASE + '/store/liststoreitem/',
  ListKit: BASE + '/store/listkit/',
  CreateKit: BASE + '/store/createkit/',
  StudentUniform: BASE + '/store/uniform/retreive-uniform-size/',
  StudentUniformUpdate: BASE + '/store/uniform/update-uniform-size/',
  DelieveryChargeErp: BASE + '/store/getalldeliverykit/',
  ListDelieveryCharge: BASE + '/store/getalldeliverykitname/',
  AssignDelieveryCharge: BASE + '/store/createalldeliverykit/',
  ListPDC: BASE + '/finance/listpostdatedcheque/',
  StudentGradeAcc: BASE + '/finance/studentgrade/',
  StudentGradeSectionAcc: BASE + '/finance/studentsection/',
  StudentListForAcc: BASE + '/finance/studentlistforaccountant/',
  ListFeePlanGrade: BASE + '/finance/listfeeplangrade/',
  ListFeePlanUrl: BASE + '/finance/ListFeePlan/',
  AdjustChangeFeePlan: BASE + '/finance/ChangeFeePlan-feetypewise/',
  Installmentsdetails: BASE + '/finance/fetchinstallmentsdetails/',
  StudentOtherFees: BASE + '/finance/listotherfees/',
  ListOtherFeesForStudentPayment: BASE + '/finance/listapiforotherfee/',
  AccountantOtherFeeList: BASE + '/finance/listotherfeesstudentmapping/',
  GetManageFees: BASE + '/finance/concessionlist/',
  AccountantFeeAccount: BASE + '/finance/FeeAccountInfoCustomListAPIView/',
  AddOtherFeeAccountant: BASE + '/finance/createotherfees/',
  AddFeeTypesAccountant: BASE + '/finance/concessionupdate/',
  AddConcessionType: BASE + '/finance/concessiontypeadd/',
  AssignOtherFees: BASE + '/finance/createotherfeestudentmapping/',
  OtherFeePAyment: BASE + '/finance/payotherfee/',
  CheckLanguage: BASE + '/finance/checklanguage/',
  ListStudentKits: BASE + '/finance/liststudentkits/',
  EditStudentFeePlan: BASE + '/finance/editstudentfeeplan/',
  ListStudentItems: BASE + '/finance/liststudentitems/',
  Store: BASE + '/store',
  DeleteKit: '/deletekit/',
  ListStoreItems: BASE + '/finance/listitems/',
  CheckKit: BASE + '/store/checkkit/',
  ReceiprRangeMsg: BASE + '/finance/ReceiptRangesFromTo/',
  GradesPerBranch: BASE + '/finance/gradeforbranch/',
  StorePaymentAcc: BASE + '/finance/makepaymentkitandstoreitem/',
  FeeTransactionReceipt: BASE + '/finance/studenttranctionpdf/',
  AccountantAssignOtherFees: BASE + '/finance/ListTransportStudent/',
  TeacherLagReport: BASE + '/academic/oms/teacher_lagging_report/',
  SMS: BASE + '/accounts/offlinesms/',
  SwitchRouter: BASE + '/internaltools/router_switch',
  BrowserErrorMessage: BASE + '/internaltools/error_message/',
  CRASHREPORT: BASE + '/internaltools/error_message/',
  SMSLOGReport: BASE + '/accounts/smslogreport/',
  IntegrationTestReport: BASE + '/integration_testing/testreport/',
  DeveloperListDisplay: BASE + '/integration_testing/send_reports_developers',
  LmsVideo: BASE + '/lms/add_video/',
  RecordedLeture: BASE + '/lms/recordedvideolectures/',
  RecordedLetureQuestionPaper: BASE + '/lms/recordedvideolectures/questionpaper/',
  LMS: BASE + '/lms/public/',
  WeeklyTestReport: BASE + '/assessment/questbox/weekly_report/',
  StudentAssessment: BASE + '/assessment/questbox/studentassessment/',
  WeeklyTestReportPdf: BASE + '/assessment/weekly_report_download_pdf/',
  AssessmentUserStatus: BASE + '/assessment/student/status',
  CreateOtherFeesForUnassigned: BASE + '/finance/listapiforotherfeeaccountant/',
  StudentsInfo: BASE + '/finance/ledger-tab/student-data/',
  SchoolDeatails: BASE + '/finance/school-name/',
  StudentInfo: BASE + '/finance/studentdata/',
  DeleteOtherFeesForassigned: BASE + '/finance/delete_otherfee_for_assigned/',
  ITC: BASE + '/finance/studentitcertificate/',
  ITCList: BASE + '/finance/studentcertificatefeetype/',
  ConcessionLastDateList: BASE + '/finance/createconcessionlastdate/',
  ListBackDate: BASE + '/finance/listbackdate/',
  FinanceFeeAccToBranch: BASE + '/finance/branch-fee-accounts-final-view/',
  FinanceGradeFeeAccountMapping: BASE + '/finance/fee-accounts-to-class-list-fee-accounts/',
  SaveConcessionReq: BASE + '/finance/createconcessionstudent/',
  FeeAccountPerBranch: BASE + '/finance/FeeAccountMultipleBranchListAPIView/',
  DownloadTallyReport: BASE + '/finance/reports/TallyReport/',
  FeeTypesPerType: BASE + '/finance/FeeTypesListAPIView/',
  GetFeeAccountsPerBranch: BASE + '/finance/FeeAccountReceiptBookListAPIView/',
  DownloadReceiptBook: BASE + '/finance/reports/ReceiptBookReport/',
  InstallmentsListPerFeeTypes: BASE + '/finance/InstallmentIntotalPaidAndDueReport/',
  FeeTypesPerBranchAndAcadId: BASE + '/finance/fee-types-in-total-paid-report/',
  DownloadTotalPaidReports: BASE + '/finance/reports/total-paid-and-due-reports/',
  DownloadTotalPaidOtherFeeReports: BASE + '/finance/reports/other-fee-total-paid-and-due-reports/',
  GenerateITC: BASE + '/finance/studentcertificate/',
  FeePlansForTransaction: BASE + '/finance/FeeAccountMultipleBranchListAPIViewForAccountantAndAdmin/',
  FDSReport: BASE + '/finance/reports/fee-day-sheet/',
  AdmissionRecords: BASE + '/finance/fetchalladmissionrecords/',
  SectionWiseSms: BASE + '/accounts/sms_sectionwise/',
  LISTCLASSGROUPTYPE: BASE + '/finance/classgroup_type/',
  PointOfContact: BASE + '/accounts/v2/student/extended/pointofcontact/',
  ClassGroup: BASE + '/accounts/class_group/',
  Feedback: BASE + '/assessment/feedback/',
  DeleteItcList: BASE + '/finance/studentcertificatedeletefeetype/',
  FatherDetails: BASE + '/accounts/v2/student/parent/father/',
  MotherDetails: BASE + '/accounts/v2/student/parent/mother/',
  GuardianDetails: BASE + '/accounts/v2/student/parent/guardian/',
  AllLedgerList: BASE + '/finance/ledger_list/',
  AddLedgerAccHead: BASE + '/finance/add_account_head/',
  EditLedgerAccHead: 'edit_account_head/',
  AddLedgerEntry: BASE + '/finance/add_ledger_name_to_existing_header/',
  EditDeleteLedgerEntry: '/edit_remove_sub_header/',
  RegistrationFeeTypeList: BASE + '/finance/registration_or_application_fee_detail/',
  BankListPettyCash: BASE + '/finance/list_bank_petty_cash/',
  SaveExpenseDeposit: BASE + '/finance/expense-deposit/',
  FetchChequeBounce: BASE + '/finance/bounce-cheque/',
  UpdateChequeBounce: BASE + '/finance/ledger-tab/update-bounce-details/',
  GetBackDate: BASE + '/finance/getbackdate/',
  BranchClassGroup: BASE + '/accounts/class_group/',
  StudentFilter: BASE + '/accounts/students_filter/',
  OMRUpload: BASE + '/assessment/omr_sheet_upload/',
  QPUpload: BASE + '/academic/upload_qp_pdf/',
  RemainingBranchesPerType: BASE + '/finance/branches-not-in-application-registration/',
  AddRegtAndAppFeetype: BASE + '/finance/add_application_registration_fee/',
  saveCollectionDeposit: BASE + '/finance/create-collection-deposit/',
  SaveChequeBounce: BASE + '/finance/update-cheque-bounce/',
  GetApplicationDetails: BASE + '/finance/fetchapplicationforms/',
  GetStudentInfoRegistration: BASE + '/finance/fetchstudentdetailsbyapplicationnumber/',
  RegistrationPayment: BASE + '/finance/registrationpayment/',
  AddApplicationDetails: BASE + '/finance/addapplicationform/',
  receiptSettingsList: BASE + '/finance/receipt-settings-list/',
  receiptSettingEdit: BASE + '/finance/receipt-settings-update-delete/',
  addReceiptSetting: BASE + '/finance/receipt-settings-create/',
  feeCollection: BASE + '/finance/list-other-fees/',
  GetBranchAtAcc: BASE + '/finance/getbranch/',
  ApplicationPayment: BASE + '/finance/applicationpayment/',
  PayNonOrchids: BASE + '/finance/payotherfeenonorchids/',
  OrchidsStudentPay: BASE + '/finance/orchid_student_miscfee_payment/',
  SaveOutsiders: BASE + '/finance/createotherfeenonorchids/',
  FetchPdfData: BASE + '/finance/fetchnonorchidspdfdetails/',
  FetchBankIfsc: BASE + '/finance/fetchbankdetails/',
  FetchBankMicr: BASE + '/finance/fetchbankdetailsmicr/',
  FetchSubcategoryStore: BASE + '/store/storesubcategoryitemsallowed/',
  FetchStudentShuffle: BASE + '/finance/fetchallstudentshuffle/',
  InternalShuffle: BASE + '/finance/InternalShuffleReport/',
  ExternalShuffle: BASE + '/finance/ExternalShuffleReport/',
  ContactDetails: BASE + '/finance/reports/Students-contact-details-report/',
  ApproveReject: BASE + '/finance/approveStatusTargetBranch/',
  EditAccTransaction: BASE + '/finance/fetchtransactionchangerequest/',
  UpdateAccTransaction: BASE + '/finance/updatetransactionchangerequest/',
  ConvertStudentStatus: BASE + '/accounts/student/status/',
  RequestAccTransaction: BASE + '/finance/requesttransactionchange/',
  UpdateStudentStatus: BASE + '/finance/updatestudentactivestatus/',
  StudentAdmissionRTE: BASE + '/finance/addadmissionform/',
  GetStudentActiveStatus: BASE + '/finance/getstudentactivestatus/',
  GetStudentDetailsbyregNumber: BASE + '/finance/fetchdetailsbyregistrationnumber/',
  GetStudentDetailsbyappNumber: BASE + '/finance/fetchstudentdetailsbyapplicationnumber/',
  SearchStudentDetailbyregNumber: BASE + '/finance/searchregistrationnumbers/',
  FetchAdmissionRecordByErp: BASE + '/finance/fetchadmissionrecordbyerp/',
  StudentAdmissionCertificate: BASE + '/finance/studentadmissioncertificate/',
  SuggestionsForErp: BASE + '/finance/search-student/',
  SuggestionsForErpStudent: BASE + '/finance/ledger-tab/search-student/',
  SuggestionsForErpAdm: BASE + '/finance/search-student-admin/',
  GetStudentInfoForFinAdm: BASE + '/finance/studentstatusfilter/',
  CheckForInstallments: BASE + '/finance/list-other-fee-installments/',
  SaveOtherFeesInstallments: BASE + '/finance/createotherfees/',
  DeleteOtherFeesInstallments: BASE + '/finance/delete-other-fees-installment/',
  DeleteInstallments: BASE + '/finance/deleteinstallments/',
  AdminOtherFeesList: BASE + '/finance/list-other-fees-admin/',
  GetInstallmentList: BASE + '/finance/otherfeeinstallment/',
  UpdateStudentData: BASE + '/finance/update_student/',
  UpdateOtherFeeInst: BASE + '/finance/Updateotherfee/',
  TotalPaidFeePlan: BASE + '/finance/fee-plan-in-total-paid/',
  FetchDate: BASE + '/finance/FetchDate/',
  FeeStructureFeeWise: BASE + '/finance/feetype_accountant_student/',
  UnassignFeestructure: BASE + '/finance/feetypeassignunassign/',
  OtherFeeTypeFeeStruc: BASE + '/finance/OtherFeeTypeAccountantList/',
  otherFeeInstWise: BASE + '/finance/OtherFeeConcession/',
  SaveOtherConcRequest: BASE + '/finance/createOtherFeeConcession/',
  FeeConcessionList: BASE + '/finance/feetypeconcessionrecord/',
  OtherFeeConcessionList: BASE + '/finance/OtherFeeConcessionList/',
  ConcessionListType: BASE + '/finance/allconcessiontypename/',
  UnassignRequestList: BASE + '/finance/feetypeforadmin/',
  pendingUnassignFeeReq: BASE + '/finance/feetypeforadminapprove/',
  ApproveAndRejectReq: BASE + '/finance/feetypeforadminapproverreject/',
  RequestTransactionChange: BASE + '/finance/requesttransactionchangecount/',
  GetBranchTransaction: BASE + '/finance/gettransactionchangedetailsbybranch/',
  GetEditTransDetails: BASE + '/finance/getdetailsbyrequesttransactionid/',
  UpdateEditDetails: BASE + '/finance/updatetransactionchangerequest/',
  EditViewDeposit: '/edit-view-deposit/',
  LedgerNameList: BASE + '/finance/ledger_name_list/',
  ListPettyCashAccounts: BASE + '/finance/list-petty-cash/',
  SavePettyCashExpenses: BASE + '/finance/add-money-spent/',
  ListCreateParty: BASE + '/finance/list-create-party/',
  CreateCoupon: BASE + '/store/coupons/createcoupon/',
  ListCoupon: BASE + '/store/coupons/listcoupon/',
  ListAllCoupon: BASE + '/store/coupons/list-all-coupons/',
  ErpcouponHistory: BASE + '/store/coupons/assignlistcoupon/',
  StudentAssignCoupon: BASE + '/store/coupons/listcouponstudentassign/',
  CouponAssignedToStudent: BASE + '/store/coupons/assigncouponstudentassign/',
  CouponDetailUpdate: BASE + '/store/coupons/couponupdate/',
  CouponReassign: BASE + '/store/coupons/unassignupdatecoupon/',
  CouponDelete: BASE + '/store/coupons/assigndeletecoupon/',
  CouponGetDiscount: BASE + '/store/coupons/assigngetcoupon/',
  ValidRequest: BASE + '/store/students-negativeamount-wallet/',
  WalletAmount: BASE + '/store/list-students-wallet-amount/',
  AddWalletAmount: BASE + '/store/wallet/addwalletamountatadmin/',
  FeeStructure: BASE + '/store/wallet/studentpaidamount/',
  WalletAmtNotUsed: BASE + '/store/wallet/walletamountnotused/',
  WalletAmtRemoved: BASE + '/store/wallet/walletamountremoved/',
  ListSubCategory: BASE + '/store/liststoresubcategoryitemsallowed/',
  CreatSubCategort: BASE + '/store/createstoresubcategoryitemsallowed/',
  TransactionDetails: BASE + '/store/student-wallet-transaction-info/',
  WalletAmountReport: BASE + '/store/students-wallet-report/',
  StudentPendingReq: BASE + '/finance/studentStatus/',
  PartialPaymentList: BASE + '/finance/listPartialPayment/',
  SetPartialPaymentBackDate: BASE + '/finance/createPartialPayment/',
  StudentDetails: BASE + '/finance/shufflefetcholdstudentdetails/',
  StudentTotalPaidAmount: BASE + '/finance/shufflefetchtotalpaidamount/',
  InstListPerFeePlan: BASE + '/finance/shufflelistinstallmentsshuffle/',
  StdShuffleFeePlan: BASE + '/finance/shufflelistfeeplangradewise/',
  ReassignShuffleReq: BASE + '/finance/shuffleassignnewfeeplan/',
  UpdateDeleteParty: '/Party-retrive-update-destroy/',
  ListCashSpent: BASE + '/finance/list-money-spent/',
  StaffV2: BASE + '/accounts/v2/staff/',
  GetRemidialQuestionPaper: BASE + '/assessment/student_remedial_question_pdf/',
  LoginCount: BASE + '/accounts/web_app_usercount/',
  RejectedShuffleReq: BASE + '/finance/shufflerejectadmin/',
  GetActiveRequestfinAdmin: BASE + '/finance/studentactiveinactiverequests/',
  InitiateShuffle: BASE + '/finance/initiatestudentshuffle/',
  ListFeemanagement: BASE + '/finance/feetypeassignafterremove/',
  UpdateMoneySpentInactive: '/update-money-spent-inactive/',
  ApproveStudentActivateInactiveReuest: BASE + '/finance/approvestudentactiverequest/',
  AddCashWithdraw: BASE + '/finance/add-cashwithdraw/',
  CashOpeningBalance: BASE + '/finance/list-opening-balance/',
  Studentstatusfilter: BASE + '/finance/studentstatusfilter/',
  ChequeBounceListAccountant: BASE + '/finance/ChequePaymentAccountantBounceList/',
  ChequeBounceListAdmin: BASE + '/finance/ChequePaymentAdminBounceList/',
  InitiateStudentActiveInactive: BASE + '/finance/initiatestudentactiverequest/',
  ChequeBounceListAdminReports: BASE + '/finance/ChequePaymentAdminBounceReports/',
  ChequeBounceListAccountantReports: BASE + '/finance/ChequePaymentAccountantBounceReportsList/',
  ReassignFeemanagement: BASE + '/finance/assignafterremovefeetype/',
  ChequePaymentPost: BASE + '/finance/ChequePaymentPost/',
  ChequePaymentPostCount: BASE + '/finance/ChequePaymentPostCount/',
  ListFinancialLedgerReport: BASE + '/finance/list-financial-ledger-report/',
  CollectionAccMoney: BASE + '/finance/add-collectionaccount-money/',
  LedgerFinancialReport: BASE + '/finance/petty-cash-ledger-amount-report/',
  AddLedgerType: BASE + '/finance/add-ledger-type/',
  AddRegForm: BASE + '/finance/addregistrationform/',
  GetStudentCountDashboard: BASE + '/finance/CountActiveInactiveRequests/',
  GetFinancialYear: BASE + '/finance/list-financial-year/',
  FeePlanPerErp: BASE + '/finance/CreateFeePlanList/',
  AutomaticStudentAssign: BASE + '/finance/AutomaticAssignFeePlan/',
  CurrentFeePlaNStd: BASE + '/finance/DistinctStudentListing/',
  OnlintTstResult: BASE + '/assessment/onlinetest_students/',
  AddSMS: BASE + '/accounts/sms_credit_usage/',
  GetRegistrationList: BASE + '/finance/fetchregistrationdetails/',
  BulkActiveInactive: BASE + '/finance/bulk-active-inactive/',
  BulkActiveInactiveParent: BASE + '/finance/bulk-parent-active-inactive/',
  ListDomainName: BASE + '/e_mandate/list_domain_name/',
  CreateDomainName: BASE + '/e_mandate/create_domain_name/',
  SetDomainDetails: BASE + '/e_mandate/create_mandate_per_student/',
  todayDetails: BASE + '/e_mandate/list_mandate_per_student/',
  DomainDailyBillGenerateStatus: BASE + '/e_mandate/calculate_daily_bill/',
  updateDomainName: BASE + '/e_mandate/update_domain_name/',
  dailyDetails: BASE + '/e_mandate/list_all_mandate_student/',
  setCustomerDetails: BASE + '/e_mandate/customers_details/',
  getCustumerDetails: BASE + '/e_mandate/list_customer_details/',
  setListCustomerDetails: BASE + '/e_mandate/list_mandate_customer_details/',
  order_payment: BASE + '/e_mandate/e_mandate_payment/',
  pettyReport: BASE + '/finance/expense-report/',
  getOrderDetails: BASE + '/e_mandate/list_order_details/',
  getGenerateSubsequent: BASE + '/e_mandate/e_mandate_subsequent_payment_list/',
  getDomainNameWithCusId: BASE + '/e_mandate/list_domain_name_cusid/',
  pendingOnlineAdmission: BASE + '/finance/admissions/list-pending-admissions/',
  updateCustDetails: BASE + '/e_mandate/update-customer-details/',
  createOrderDetails: BASE + '/e_mandate/e_mandate_order/',
  SubsequentPayment: BASE + '/e_mandate/e_mandate_subsequent_payment/',
  CreateLink: BASE + '/e_mandate/registration-link/',
  TotalBillingDetails: BASE + '/e_mandate/list_daily_bill/',
  OnlinePaymentUpload: BASE + '/airpay/upload-failed-transactions/',
  AirpayPayments: BASE + '/airpay/airpay-payment-update-store/',
  AxisPayments: BASE + '/axis/update_axis_bank_transaction/',
  HdfcPayments: BASE + '/pg/hdfc/update-status-hdfc/',
  BulkReportUpload: BASE + '/finance/mcb-reports/',
  BulkReportList: BASE + '/finance/List-Bulk-Report-Types/',
  BulkReportStatus: BASE + '/finance/mcb-reports/download-excel/',
  OldNewFeePlanType: BASE + '/finance/oldandnewfeeplanfeetype/',
  NormalSMS: BASE + '/accounts/normalsms/',
  TeacherFilter: BASE + '/accounts/staff_filter/',
  CashWithdrawReports: BASE + '/finance/list-cashwithdrawreport-monthwise/',
  VoucherReceiptHeaders: BASE + '/finance/retrieve-receipt-settings/',
  SearchStdDetbyAppNo: BASE + '/finance/Search_Admission_Form/',
  UpdateInstFineAmt: BASE + '/finance/addstudentfineamount/',
  UpdateStoreItems: BASE + '/store/updatestoreitem/',
  AirPayFeeAccGet: BASE + '/airpay/airpayfeeaccpget/',
  AirPayFeeAccPost: BASE + '/airpay/airpayfeeaccpost/',
  AccountantLogin: BASE + '/finance/Loginscript/',
  BranchListing: BASE + '/finance/BranchListing/',
  GetAppFormDet: marketingUrl + '/suggestion_api_view_v2',
  SearchAppNumber: BASE + '/finance/Search_Registration_Form/',
  NotificationsHistory: BASE + '/accounts/notification_history',
  SMSEmailLog: BASE + '/accounts/communicatelogfilter/',
  DownloadAdmRecptBook: BASE + '/finance/AppRegReceiptBook/',
  StoreReceiptPdfData: BASE + '/store/studentstorepdf/',
  DownloadAdmTotalPdreports: BASE + '/finance/App_Reg_Total_Pay_Due_Report/',
  UpdateKitInStore: BASE + '/store/addandupdatekit/',
  OthrFeeTypesPerGrade: BASE + '/finance/other-fee-types-reports/',
  OthrFeeInstAllments: BASE + '/finance/other-fee-installments-reports/',
  AppRegPdf: BASE + '/finance/appregpdf/',
  KitWiseItems: BASE + '/store/liststoreitemforkit/',
  StoreBranchAccList: BASE + '/store/storefeeaccountlist/',
  UpdateStoreFeeAcc: BASE + '/store/storefeeaccount/',
  UpdateFeeAccountName: BASE + '/store/update-store-fee-account/',
  TermConditionList: BASE + '/store/schollmeal-term-condition-list/',
  AddTermCondition: BASE + '/store/schollmeal-term-condition/',
  scoolMealAtStudent: BASE + '/finance/scoolmeal/generate-url/',
  ActiveInactiveFeeAccount: BASE + '/store/edit-store-fee-account-active-inactive/',
  UpdateOthrFeeFine: BASE + '/finance/OtherFeeFine/',
  Urlhistory: BASE + '/accounts/url/history/',
  CurrFeeTypeList: BASE + '/finance/list-misc-fee/',
  AddCurrFeeType: BASE + '/finance/Misc-Create-Fee/',
  UpdateCurrFeeType: BASE + '/finance/Update-Misc-Fee/',
  DeleteCurrFeeType: BASE + '/finance/Delete-Misc-Fee/',
  MiscList: BASE + '/finance/Misc_Fee_Listing/',
  StudentMiscDetails: BASE + '/finance/Student-Misc-Fee-Listing/',
  FetchMiscTypeDetails: BASE + '/finance/Misc-Fee-Details/',
  SaveStudentMiscType: BASE + '/finance/Misc-fee-Student-assign/',
  CountAppReg: BASE + '/finance/admissions/count-app-reg-adm/',
  StoreReport: BASE + '/finance/reports/store-report/',
  CountAppRegReport: BASE + '/finance/countappregadreport/',
  CheckMiscFeeAtOtherFee: BASE + '/finance/CheckMiscFee/',
  ListAllForm: BASE + '/finance/admissions/listappregad/',
  ListAllFormReport: BASE + '/finance/admissions/listappregadreport/',
  FetchInstallmentsDetails: BASE + '/finance/fetchinstallmentsdetails/',
  appMobileChecker: marketingUrl + '/MobileNumberChecker_V2/',
  updateTotalFormDetails: BASE + '/finance/UpdateFormDetails/',
  PaymentModeEdit: BASE + '/finance/PaymentModeEdit/',
  BranchList: BASE + '/finance/multiple-branch/',
  NormalSms: BASE + '/finance/NormalSmsAPIView/',
  BulkSms: BASE + '/finance/BulkSmsAPIView/',
  ClassWiseStudent: BASE + '/finance/MultiSectionsStudentsSearch/',
  fetchFormModeDetails: BASE + '/finance/FetchFormDetails/',
  TeacherRemarksAttendance: BASE + '/accounts/gradebook_remarks_attendance/',
  GradebookStudentsReport: BASE + '/accounts/gradebook_term_students/',
  PowerSelectorLatest: BASE + '/accounts/pselect/revision/latest/',
  DownloadStudentsRecords: BASE + '/accounts/gradebook/reports/zip/',
  DownloadStudentReportCard: BASE + '/accounts/gradebook/report/student/erp/',
  SchoolMeal: BASE + '/finance/scoolmeal/meal/',
  DownloadCircularExcel: BASE + '/academic/exceldownload_circular',
  BulkDownloadRecords: BASE + '/accounts/gradebook_bulk_download/',
  StudentWiseReportCard: BASE + '/accounts/gradebook_student_details/',
  publishReportCards: BASE + '/accounts/gradebook/publish/reportcard/',
  OnlineClassBulkDownload: BASE + '/academic/attendee_class/bulk/',
  participantReport: BASE + '/academic/participant_report/bulk',
  DeleteForms: BASE + '/finance/admissions/deleteappreg/',
  ClassWiseSms: BASE + '/finance/ClasswiseSmsAPIView/',
  FeeDefaultersList: BASE + '/finance/FetchFeeDefaulterAPIView/',
  WalletPaymentOne: BASE + '/store/wallet/student-wallet-payment/',
  DefaultersListSms: BASE + '/finance/FeeDefaulterSmsAPIView/',
  WalletInfo: BASE + '/store/student-wallet-amount/',
  IsPartialPay: BASE + '/finance/list_partial_payment_applicable/',
  FetchAccountantBranch: BASE + '/finance/Fetch-Acc-Branch-Deatils/',
  // AirpayStore: BASE + '/airpay/paywithairpaystore/',
  // AirpayStore: BASE + '/pg/hdfc/pay-store-fee/',
  // AirPayHdfcStore: BASE + '/pg/hdfc/pay-store-fee/',
  AirpayStore: BASE + '/pg/book_uniform-payment/',
  AirPayHdfcStore: BASE + '/pg/book_uniform-payment/',
  //  AirpayStore: BASE + '/pg/hdfc/pay-store-fee/',
  //  AirPayHdfcStore: BASE + '/pg/store-payment',
  StudentSMS: BASE + '/accounts/sms_received/',
  ApproveStatusShuffle: BASE + '/accounts/approve_status_shuffle',
  AssessmentConfiguration: BASE + '/assessment/configuration/',
  QuestionRange: BASE + '/assessment/question_range/',
  TestIds: BASE + '/assessment/v2/test_ids/',
  ParentOtpSend: BASE + '/accounts/parent_contact_otp/send/',
  ParentOtpVerify: BASE + '/accounts/parent_contact_otp/verify/',
  BulkFeeStructureUpload: BASE + '/finance/mcb-reports/fee-structure-report/',
  QuestionCount: BASE + '/academic/questioncount/',
  ReportQuestionByOptions: BASE + '/academic/question/report_type/',
  ReportQuestion: BASE + '/academic/questions/report/',
  PracticeQuestionAnalysis: BASE + '/academic/questbox/question/practice/analysis/',
  PracticeQuestionResponse: BASE + '/academic/practice_questions/',
  PracticeQuestionsAccuracyReport: BASE + '/academic/practice_questions/',
  PracticeQuestionsLeaderboard: BASE + '/academic/practice/question/leaderboard/',
  ChapterTopic: BASE + '/academic/topics/',
  BulkItemsUpload: BASE + '/store/bulk-item-upload/',
  ListGst: BASE + '/store/list-gst/',
  AddGst: BASE + '/store/add-gst/',
  SubjectStoreUrl: BASE + '/store/student-kit-language/',
  ShippingData: BASE + '/store/deliveryamountnotpaid/',
  StorePaymentCancelRequest: BASE + '/store/storepaymentcancelrequest/',
  StorePaymentReqList: BASE + '/store/storepaymentcancellist/',
  GetStoreBranchTransaction: BASE + '/store/storepaymentcancelrequestbybranch/',
  StoreAcceptPaymentRequest: BASE + '/store/storeacceptpaymentrequest/',
  SectionMappingRestore: BASE + '/accounts/sectionmapping/restore/',
  FetchOrderStatus: BASE + '/store/storeorderstatus/',
  OrderPaid: BASE + '/store/orderpaid/',
  GradebookAnalysis: BASE + '/accounts/gradebook_analysis/',
  GradebookCategory: BASE + '/accounts/gradebook/category/',
  GradebookAnalysisExcelDownload: BASE + '/accounts/gradebook_analysis_download/',
  OrderStatusUpload: BASE + '/store/orderstatusupload/',
  OrderFeesBulkUpload: BASE + '/store/otherfeebulkupload/',
  OrderReturn: BASE + '/store/orderreturn/',
  OrderDispatchDetails: BASE + '/store/orderstatusdatewise/',
  ProfileImages: BASE + '/accounts/student/profile_images/',
  EditOtherFeeInstallments: BASE + '/finance/Edit-OtherFee-Installments/',
  ListBulkUniform: BASE + '/store/uniform/list-bulk-uniform/',
  UpdateEachUni: BASE + '/store/uniform/update-uniform/',
  UpdateBulkUni: BASE + '/store/uniform/update-bulk-uniform/',
  DeliveryAddress: BASE + '/store/deliverylistddress/',
  SendDeliveryAddress: BASE + '/store/deliveryaddress/',
  DeliveryAmount: BASE + '/store/deliveryamount/',
  StudentPromotion: BASE + '/finance/student-promotion/student-finance-promotion/',
  StudentDues: BASE + '/finance/student-promotion/retrieve-student-dues/',
  StudentPromotionList: BASE + '/finance/student-promotion/student-promotion-newfeeplan/',
  PromotionEligebleStudents: BASE + '/accounts/promotion/eligible_students/',
  GetMappingDetails: BASE + '/accounts/mapping_details/',
  PromoteStudents: BASE + '/accounts/promotion/request/',
  PromotedStudents: BASE + '/accounts/promoted_students/',
  StudentOnlineClasses: BASE + '/academic/student_online_class/',
  StudentJoinOnlineClass: BASE + '/academic/join_online_class/',
  GuestStudentJoinOnlineClass: BASE + '/academic/join_gueststudent_online_class/',
  TeacherOnlineClass: BASE + '/academic/teacher_online_class/',
  TeacherJoinClass: BASE + '/academic/select_teacher_online/',
  OnlineclassAttendeeList: BASE + '/academic/attendee_class/',
  StudentAcceptRejectClass: BASE + '/academic/select_student_online_class/',
  GuestStudentAcceptRejectClass: BASE + '/academic/select_guest_student_online_class/',
  CancelOnlineClass: BASE + '/academic/cancel_class/',
  QuestionCountExcelDownload: BASE + '/academic/questioncount/exceldownload/',
  BranchAcadSession: BASE + '/accounts/branch_acad_session/',
  GuestStudentOnlineClasses: BASE + '/academic/gueststudent_online_class/',
  GuestStudentOnlineClassInterest: BASE + '/academic/guest_student/intrest/',
  GetGuestStudents: BASE + '/accounts/gueststudent/sibling/',
  GetStudentCount: BASE + '/accounts/guest/class_attend/count/',
  CreateBlog: BASE + '/academic/blog/create_blog/',
  LikeBlog: BASE + '/academic/blog/like_blog/',
  ViewBlog: BASE + '/academic/blog/view_blog/',
  BlogFeedBack: BASE + '/academic/blog/blog_revision_feedback/',
  GetOnlineClassFiles: BASE + '/academic/zoom/meeting/files/',
  SearchBlogs: BASE + '/search/blog/',
  ListGuestStudent: BASE + '/accounts/student/guest/filter/',
  ExportGuestStudent: BASE + '/accounts/export_guest_student/',
  ListGuestStudentSearch: BASE + '/accounts/student/guest/search/',
  ViewBlogClassDetails: BASE + '/academic/blog/blog_class_details/',
  BlogDashboard: BASE + '/academic/blog/blog_dashboard/',
  ClassGroupList: BASE + '/accounts/guest/list_groups/',
  CreateGroupOnlineClass: BASE + '/academic/online_class_group/excel/',
  ViewGroupOnlineClass: BASE + '/academic/view_group/',
  signatureUpload: BASE + '/accounts/signature_upload/',
  CertificateErpList: BASE + '/accounts/erp_list/',
  CertificateTemplatels: BASE + '/accounts/certificate_templates/',
  CertificateStudentView: BASE + '/accounts/certificate/recipient/',
  CategoryList: BASE + '/accounts/category/',
  CertificateSubmission: BASE + '/accounts/certificate/creation/',
  ClassGroupDetails: BASE + '/accounts/guest/list_guest_student/',
  SearchClassGroup: BASE + '/accounts/guest/group/search/',
  HomeworkMappingCount: BASE + '/academic/homework/mappings/count/',
  HomeworkFilter: BASE + '/academic/online_class/homework/filter/',
  OnlineClassHomeworkCount: BASE + '/academic/online_class/homework/count/',
  HomeWorkSubmissions: BASE + '/academic/homework/submissions/',
  HomeworkDashboard: BASE + '/academic/homework/getDashboardDetails/',
  HomeworkDashboardSubjects: BASE + '/academic/homework/get_subjects/',
  EditQuestionPaper: BASE + '/academic/questbox/qbquestionpaper/',
  WalletBulk : BASE + '/store/bulk-wallet-amount-upload/',
  showBadges: BASE + '/accounts/badges/view/',
  Base64Img: BASE + '/accounts/base64_img/',
  addStudentGroupApi: BASE + '/accounts/student/guest/filter/',
  OnlineClassFeedback: BASE + '/academic/ocfeedback/',
  PreviousOnlineClassAttendaceChecker: BASE + '/academic/online_class/attendance_checker/',
  ResetPassword: BASE + '/accounts/reset_password/',
  Publishing: BASE + '/academic/newsletter/',
  PublicationZone: BASE + '/academic/newsletterzones/',
  PublicationUpload: BASE + '/academic/newsletter/',
  ParticipantExcelUpload: BASE + '/accounts/participant/excel/',
  ParticipantDetails: BASE + '/accounts/participant/view/',
  BadgeTemplates: BASE + '/accounts/badges/template/',
  AssigningBadges: BASE + '/accounts/participant/',
  MultiplayerQuizAvatar: BASE + '/academic/quiz/avatar/',
  CircularTemplateCRUD: BASE + '/academic/circular-template-manager/',
  classinterestStats: BASE + '/academic/class_interest_stats/',
  OrchadioListeningParticipants: BASE + '/academic/radio/program_listened/',
  OrchadioProgramListners: BASE + '/academic/radio/program_listened/',
  IntrestedParticipants: BASE + '/academic/radio/interested_participant/',
  PublicOrchadio: BASE + '/public_orchadio/',
  PublicOrchadioLikes: BASE + '/academic/radio/add_likes_from_public/',
  PublicOrchadioListners: BASE + '/academic/radio/add_public_listeners/',
  GeneratePUblicToken: BASE + '/academic/radio/generate_token/',
  LmsQuestionPapers: BASE + '/academic/questbox/questionpapertype/',
  LmsVideoTypes: BASE + '/lms/video_type/',
  // Game: FRONTENT_BASE + '/Game/mathpop',
  Game: FRONTENT_BASE + '/Game/',
  GameDetail: BASE + '/academic/game/details/',
  GameRating: BASE + '/academic/game/rating/',
  // Game: FRONTENT_BASE + '/Game/mathpop',
  QuizsHeldCount: BASE + '/academic/quiz/gradesubject/count/',
  QuizResults: BASE + '/academic/quiz/student/marks/',
  QuizTestDetails: BASE + '/academic/quiz/grade/subject/',
  OnlineClassQuizResults: BASE + '/academic/quiz/student/filter/marks/',
  StudentWallet: BASE + '/store/student-wallet-amount/',
  AllStudentTransection: BASE + '/store/student-wallet-transaction-info/',
  HomeWorkReport: BASE + '/academic/homework/get_homework_summary_report/',
  CheckPayment : BASE + '/pg/allow_payment/'
}
const staticUrls = {
  AssessmentExcel: STATIC_BASE + '/oms/assessment_excel_sheet.xlsx',
  AssessmentMarksExcel: STATIC_BASE + '/oms/assessment_template_marks_excel_sheet.xlsx',
  ParticipantBadges: STATIC_BASE + '/oms/participant_badges.xlsx',
  UploadQuestionTemplateExcel: STATIC_BASE + '/oms/upload_question_excel.xlsx'
}

const discussionUrls = {
  PostDiscussion: BASE + '/discussion/posts/',
  GetReplies: BASE + '/discussion/comments/',
  addAndGetCategoryUrl: BASE + '/discussion/create_retrieve_category/',
  updateCategoryApi: BASE + '/discussion',
  getCategoryListApi: BASE + '/discussion/list_category/',
  addAndGetSubCategoryApi: BASE + '/discussion/create_retrieve_subcategory/',
  updateSubCategoryApi: BASE + '/discussion',
  addAndGetSubSubCategoryApi: BASE + '/discussion/create_retrieve_subsub_category/',
  getSubCategoryListApi: BASE + '/discussion/list_subcategory/',
  updateSubSubCategoryApi: BASE + '/discussion',
  roleListApi: BASE + '/discussion/list_roles/',
  usersListApi: BASE + '/discussion/list_users/',
  createandGetUserAccessViewApi: BASE + '/discussion/create_retrieve_user_access/',
  updateUserAccessApi: BASE + '/discussion/',
  subSubCategoryGetListApi: BASE + '/discussion/list_sub_subcategory/',
  getPostListApi: BASE + '/discussion/list_posts/',
  updatePostApi: BASE + '/discussion/',
  getCommentsonPostApi: BASE + '/discussion/retrieve_comments/',
  updateConnemmtForPostApi: BASE + '/discussion/',
  getReplayOnCommentsonPostApi: BASE + '/discussion/retrieve_reply/',
  UpdateReplayOnCommentsonPostApi: BASE + '/discussion/',
  GetSubReplies: BASE + '/discussion/replay/',
  CategoryApi: BASE + '/discussion/list_category/',
  SubCategoryApi: BASE + '/discussion/list_subcategory/',
  SubSubCategoryApi: BASE + '/discussion/list_sub_subcategory/',
  Branches: BASE + '/discussion/branches/',
  Grades: BASE + '/discussion/grades/',
  GrancWiseGradesApi: BASE + '/discussion/branch_related_grade/',
  gradeWiseSectionApi: BASE + '/discussion/grade_related_section/',
  Sections: BASE + '/discussion/sections/',
  apiForLikePost: BASE + '/discussion/add_like/',
  apiForLikeComment: BASE + '/discussion/AddCommentLike/',
  awardListNameApi: BASE + '/discussion/list_award/',
  addAwardForPosts: BASE + '/discussion/add_award_post/',
  getActivityPostApi: BASE + '/discussion/retrieve_activity_posts_view/',
  checkUserAccessApi: BASE + '/discussion/check_user_access/',
  discussionDashboardApi: BASE + '/discussion/dashboard_discussion/',
  discussionDashboardWithStudentCountApi: BASE + '/discussion/discussion_student_dashboard'
}

const qBUrls = {

  Grades: BASE + '/academic/questbox/chapter/',
  QuestionLevel: BASE + '/academic/questbox/questionlevel',
  QuestionLevelV2: BASE + '/academic/questbox/questionlevel_count/',
  QuestionType: BASE + '/academic/questbox/questiontype',
  QuestionCategory: BASE + '/academic/questbox/questioncategory',
  Tags: BASE + '/academic/questbox/tags',
  chapterTopic: BASE + '/academic/topics/',
  AddQuestion: BASE + '/academic/questbox/question/',
  EditQuestion: BASE + '/academic/questbox/question/',
  ListQuestion: BASE + '/academic/questbox/question/?',
  Chapter: BASE + '/academic/questbox/chapter_filter/',
  Dashboard: BASE + '/academic/questbox/dashboard/',
  CreateQuestionPaper: BASE + '/academic/questbox/questionpaper/',
  ListQuestionPaper: BASE + '/academic/questbox/qbquestionpaper/',
  change_password: BASE + '/accounts/change_password/',
  PublishQuestion: BASE + '/academic/questbox/approve/',
  AssessmentScore: BASE + '/assessment/questbox/v2/assessment_score/',
  AssessmentTags: BASE + '/assessment/assessment_tags/',
  Assessment_v2: BASE + '/assessment/questbox/v2/assessment/',
  NationalAvg: BASE + '/assessment/questbox/nationalise_average/',
  NationalAverage_v2: BASE + '/assessment/questbox/national_average/',
  QuestionPaper_v2: BASE + '/academic/questbox/qbquestionpaper/',
  ResetPassword: BASE + '/accounts/reset_password/',
  resetPassword: BASE + '/accounts/reset_passoword/guest/',
  ResetPasswrd: BASE + '/accounts/password_link/',
  SendOtp: BASE + '/accounts/reset_passowrd/send/',
  sendOtp: BASE + '/accounts/reset_passoword/guest/send/',
  VerifyOtp: BASE + '/accounts/reset_passowrd/verify/',
  AssessmentNew_v2: BASE + '/assessment/questbox/v2/assessmentV2/',
  UploadAssessmentExcelFile: BASE + '/assessment/questbox/uploadassessmentexcel/',
  UploadMarksAssessment: BASE + '/assessment/subjective_excel_upload/',
  PresenterEmail: BASE + '/academic/presenter_emails/zoom/',
  StudnetFilter: BASE + '/academic/student_filter/zoom/',
  CreateOnileClass: BASE + '/academic/online_class/',
  StudentCount: BASE + '/academic/student_filter/zoom/count/',
  VerifyTutorEmail: BASE + '/academic/tutor/email/check/',
  StudentYearWise: BASE + '/accounts/v2/student/',
  ZoomUser: BASE + '/academic/online_class/zoom_user_creation/',
  EditZoomUser: BASE + '/academic/online_class/zoom_user_edit/',
  UpdateAttendance: BASE + '/academic/class_list/attendance_status/',
  GenretType: BASE + '/academic/blog/genre_type/',
  ClassCounterUrl: BASE + '/academic/dashboard_onlineclass_duration/',
  RestoreReport: BASE + '/academic/v2/restore_report/',
  ManagementDashboardTotalExpense: BASE + '/management/total_expenses/',
  FacebookPageRating: BASE + '/management/facebook_page_rating/',
  FaceBookInsights: BASE + '/management/facebook_insights'

}

const vimeoUrl = {
  uploadAuthToken: process.env.REACT_APP_UI_ENV === 'prod'
    ? 'ddb7fdb742d97f42527aea2aa5512581'
    : '5cdf3607d38c7234b3b4cdfd106b1a08',
  video: 'https://api.vimeo.com/videos/',
  spaceCreation: 'https://api.vimeo.com/me/videos',
  get: 'https://player.vimeo.com/video/',
  domains: process.env.REACT_APP_UI_ENV === 'prod' ? ['erp.letseduvate.com', 'alwaysonlearning.com'] : ['localhost:3000', 'dev.alwaysonlearning.com', 'dev.letseduvate.com']
}
// 'ws://localhost:8001/ws/offline_sms/'
const socketUrls = {
  OfflineSMS: SOCKET_BASE + '/offline_sms/',
  MPQUIZ: SOCKET_BASE + '/lobby/'
}

const GOOGLE_RECAPTCHA_SITE_KEY = '6Lez_6cZAAAAAOk-hpKywAIGUoVaaOsduZZGvEvj'

export { urls, qBUrls, vimeoUrl, externalUrls, HOSTNAME, socketUrls, staticUrls, discussionUrls, GOOGLE_RECAPTCHA_SITE_KEY }
