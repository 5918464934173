if (
  window.location.href.includes('localhost') ||
  window.location.href.includes('ui-revamp1') ||
  window.location.href.includes('orchids-stage')
) {
  var AccessKey = 'ntNPDkdd5NXA.Kk32anAflhbTBD4W.owdaRMIEMbveyH';
} else {
  var AccessKey = 'ntNPDkXA.KkRN.i3udsidsophiui8VVI.DFdaMMIE32d';
}

export { AccessKey };
