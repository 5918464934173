import React, { useState, useEffect, useContext } from 'react';
import './blog.css';
import Layout from 'containers/Layout';
import { AlertNotificationContext } from '../../context-api/alert-context/alert-state';

import { useHistory } from 'react-router-dom';
import { X_DTS_HOST } from 'v2/reportApiCustomHost';
import axiosInstance from '../../config/axios';
import endpoints from '../../config/endpoints';
import { Breadcrumb, Tabs, Spin, Button, message } from 'antd';
import NoDataIcon from 'v2/Assets/dashboardIcons/teacherDashboardIcons/NoDataIcon.svg';
import { RightCircleOutlined, ReconciliationOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import { getActivityIcon } from 'v2/generalActivityFunction';

const drawerWidth = 350;
const { TabPane } = Tabs;

const CentralBlogRedirection = () => {
  let data = JSON.parse(localStorage.getItem('userDetails')) || {};
  const token = data?.token;
  const user_level = data?.user_level;
  const history = useHistory();
  const [activityListData, setActivityListData] = useState([]);
  const [subId, setSubId] = useState('');
  const [blogSubId, setBlogSubId] = useState('');
  const [visualSubId, setVisualSubId] = useState('');
  const [musicSubId, setMusicSubId] = useState('');
  const [theaterSubId, setTheaterSubId] = useState('');
  const [danceSubId, setDanceSubId] = useState('');
  const [loading, setLoading] = useState(false);
  const { setAlert } = useContext(AlertNotificationContext);
  const [blogLoginId, setBlogLoginId] = useState('');
  const [physicalSubId, setPhysicalSubId] = useState('');
  const [blogSubIdValue, setBlogSubIdValue] = useState('');

  const handleBlogWriting = () => {
    history.push('/blog/studentview');
  };

  const handlePublicSpeaking = () => {
    history.push({
      pathname: '/physical/activity',
      state: {
        subActiveId: subId,
      },
    });
  };

  const handleBlogActivity = () => {
    history.push({
      pathname: '/blog/blogview',
      state: {
        blogLoginId: blogLoginId,
      },
    });
  };

  const handleVisualActivityRoute = (data) => {
    if (data.toLowerCase() === 'visual art') {
      history.push({
        pathname: '/visual/activity',
        state: {
          subActiveId: visualSubId,
        },
      });
    } else if (data.toLowerCase() === 'music') {
      history.push({
        pathname: '/visual/activity',
        state: {
          subActiveId: musicSubId,
        },
      });
    } else if (data.toLowerCase() === 'dance') {
      history.push({
        pathname: '/visual/activity',
        state: {
          subActiveId: musicSubId,
        },
      });
    } else if (data.toLowerCase() === 'theatre') {
      history.push({
        pathname: '/visual/activity',
        state: {
          subActiveId: theaterSubId,
        },
      });
    }
  };

  const fetchActivityListData = () => {
    setLoading(true);
    axiosInstance
      .get(`${endpoints.newBlog.blogRedirectApi}`, {
        headers: {
          'X-DTS-HOST': X_DTS_HOST,
        },
      })
      .then((result) => {
        setActivityListData(result?.data?.result);
        const physicalData = result?.data?.result.filter(
          (item) => item?.name == 'Physical Activity'
        );
        setSubId(physicalData[0]?.id);
        setPhysicalSubId(physicalData[0]);
        const blogActivityData = result?.data?.result.filter(
          (item) => item?.name == 'Blog Activity'
        );
        setBlogSubId(blogActivityData[0]?.id);
        setBlogSubIdValue(blogActivityData[0]);
        const visualActivityData = result?.data?.result.filter(
          (item) => item?.name.toLowerCase() === 'visual art'
        );
        setVisualSubId(visualActivityData[0]);
        const musicActivityData = result?.data?.result.filter(
          (item) => item?.name.toLowerCase() === 'music'
        );
        setMusicSubId(musicActivityData[0]);
        const danceActivityData = result?.data?.result.filter(
          (item) => item?.name.toLowerCase() === 'dance'
        );
        setDanceSubId(danceActivityData[0]);
        const theaterActivityData = result?.data?.result.filter(
          (item) => item?.name.toLowerCase() === 'theatre'
        );
        setTheaterSubId(theaterActivityData[0]);
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
    // }
  };

  useEffect(() => {
    fetchActivityListData();
  }, []);

  const handleExplore = (data) => {
    let dataLower = data?.name.toLowerCase();
    if (
      dataLower == 'blog wall' ||
      dataLower == 'blog writing' ||
      dataLower == 'blog writting'
    ) {
      return;
    } else if (dataLower === 'public speaking') {
      // return
    } else if (dataLower === 'physical activity') {
      localStorage.setItem('ActivityData', JSON.stringify(physicalSubId));
      localStorage.setItem('PhysicalActivityId', JSON.stringify(subId));
      handlePublicSpeaking();
      return;
    } else if (dataLower === 'art writting' || dataLower === 'blog activity') {
      localStorage.setItem('BlogActivityId', JSON.stringify(blogSubId));
      localStorage.setItem('ActivityData', JSON.stringify(blogSubIdValue));
      if (user_level === 2 || user_level === 8 || user_level === 11) {
        localStorage.setItem('BlogActivityId', JSON.stringify(blogSubId));
        handleBlogActivity();
        return;
      } else if (user_level === 13) {
        handleBlogWriting();
        return;
      } else {
        setAlert('error', 'Permission Denied');
        return;
      }
    } else if (dataLower === 'visual art') {
      localStorage.setItem('ActivityData', JSON.stringify(visualSubId));
      if (user_level === 2 || user_level === 6 || user_level === 11 || user_level === 8) {
        handleVisualActivityRoute(dataLower);
        return;
      } else if (user_level === 13) {
        setAlert('error', 'Permission Denied');
        return;
      } else {
        setAlert('error', 'Permission Denied');
        return;
      }
    } else if (dataLower === 'music') {
      localStorage.setItem('ActivityData', JSON.stringify(musicSubId));
      if (user_level === 2 || user_level === 6 || user_level === 11 || user_level === 8) {
        handleVisualActivityRoute(dataLower);
        return;
      } else if (user_level === 13) {
        setAlert('error', 'Permission Denied');
        return;
      } else {
        setAlert('error', 'Permission Denied');
        return;
      }
    } else if (dataLower === 'dance') {
      localStorage.setItem('ActivityData', JSON.stringify(danceSubId));
      if (user_level === 2 || user_level === 6 || user_level === 11 || user_level === 8) {
        handleVisualActivityRoute(dataLower);
        return;
      } else if (user_level === 13) {
        setAlert('error', 'Permission Denied');
        return;
      } else {
        setAlert('error', 'Permission Denied');
        return;
      }
    } else if (dataLower === 'theatre') {
      localStorage.setItem('ActivityData', JSON.stringify(theaterSubId));
      if (user_level === 2 || user_level === 6 || user_level === 11 || user_level === 8) {
        handleVisualActivityRoute(dataLower);
        return;
      } else if (user_level === 13) {
        setAlert('error', 'Permission Denied');
        return;
      } else {
        setAlert('error', 'Permission Denied');
        return;
      }
    } else {
      setAlert('error', 'Level Does Not Exist');
      return;
    }
  };

  const getActivitySession = () => {
    setLoading(true);
    axios
      .post(
        `${endpoints.newBlog.activitySessionLogin}`,
        {},
        {
          headers: {
            'X-DTS-HOST': X_DTS_HOST,
            Authorization: `${token}`,
          },
        }
      )
      .then((response) => {
        setBlogLoginId(response?.data?.result);
        localStorage.setItem(
          'ActivityManagementSession',
          JSON.stringify(response?.data?.result)
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ActvityLocalStorage = () => {
    axios
      .post(
        `${endpoints.newBlog.activityWebLogin}`,
        {},
        {
          headers: {
            Authorization: `${token}`,
            'X-DTS-HOST': X_DTS_HOST,
          },
        }
      )
      .then((response) => {
        // getActivitySession();

        localStorage.setItem(
          'ActivityManagement',
          JSON.stringify(response?.data?.result)
        );
      });
  };

  useEffect(() => {
    localStorage.setItem('PhysicalActivityId', '');
    getActivitySession();
    ActvityLocalStorage();
  }, []);

  const handleRedirect = () => {
    history.push({
      pathname: '/principal-dashboard-activity',
      // state: {
      //   src: src
      // }
    });
  };
  return (
    <Layout>
      {''}
      <div className='row px-2'>
        <div className='col-md-10' style={{ zIndex: 2 }}>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item href='' className='th-black-1 th-18'>
              Activities Management
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className='col-md-2 text-center'>
          <Button
            className='th-button-active th-br-6 text-truncate th-pointer '
            onClick={() => handleRedirect()}
            icon={<ReconciliationOutlined />}
          >
            Report
          </Button>
        </div>
        <div className='row th-bg-white th-br-5 m-3'>
          {loading ? (
            <div
              className='d-flex align-items-center justify-content-center w-100'
              style={{ height: '50vh' }}
            >
              <Spin tip='Loading' />
            </div>
          ) : activityListData.length > 0 ? (
            <div className='row p-3'>
              {activityListData?.map((each, index) => (
                <div className='col-md-3' style={{ marginBottom: 30 }}>
                  <div className='th-br-10 th-bg-grey shadow-sm wall_card'>
                    <div className='row p-3'>
                      <div className='col-4 px-0 th-br-5' style={{ height: 100 }}>
                        <img
                          src={getActivityIcon(each?.name)}
                          alt='Icon'
                          style={{
                            height: '100%',
                            width: '100%',
                            objectFit: '-webkit-fill-available',
                          }}
                          className='th-br-5'
                        />
                      </div>
                      <div className='col-8 pr-0'>
                        <div className='d-flex flex-column justify-content-between h-100'>
                          <div className='d-flex flex-column align-item-center th-black-1 '>
                            <div className=''>
                              <span className='th-16 th-fw-700 text-capitalize'>
                                {each?.name}
                              </span>
                            </div>
                            {/* <div>
                              <span className='th-12 th-fw-300'>
                                {each?.count}{' '}
                                {each?.count == 1 ? 'Activity' : 'Activities'}
                              </span>
                            </div> */}
                          </div>
                          {/* <div className='d-flex flex-column th-bg-pink align-item-center th-br-5'>
                            <div className=''>
                              <span className='th-12 th-fw-300 ml-2 text-capitalize th-blue-1'>
                                Recently Added
                              </span>
                            </div>
                            <div>
                              <span className='th-12 th-fw-500 ml-2'>{each?.title}</span>
                            </div>
                          </div> */}
                          <div className='row align-items-center'>
                            {/* <div className='col-sm-6 pl-0'> */}
                            {/* <div className='th-12 th-fw-300 text-capitalize th-black-1'>
                                Last Updated
                              </div>
                              <div className='th-12 th-fw-400'>
                                {moment(each?.last_update).format('ll')}
                              </div> */}
                            {/* </div> */}
                            <div className='col-sm-10  px-0 pt-1 pt-sm-0'>
                              <div
                                className='th-button-active th-br-6 text-truncate th-pointer text-center p-1'
                                onClick={() => handleExplore(each)}
                              >
                                <RightCircleOutlined /> Explore
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className='text-center w-100 py-5'>
              <img src={NoDataIcon} />
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};
export default CentralBlogRedirection;
