/* eslint-disable react/jsx-no-duplicate-props */
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { Grid, useTheme, SvgIcon, IconButton } from '@material-ui/core';
// import { Pagination } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Layout from '../Layout';
import { AlertNotificationContext } from '../../context-api/alert-context/alert-state';
import CommonBreadcrumbs from '../../components/common-breadcrumbs/breadcrumbs';
import endpoints from '../../config/endpoints';
import axiosInstance from '../../config/axios';
import Loading from '../../components/loader/loader';
import CircularCard from './circular-card';
import CircularFilters from './circular-filterdata';
import ViewMoreCard from './view-more-card';
import unfiltered from '../../assets/images/unfiltered.svg';
import selectfilter from '../../assets/images/selectfilter.svg';
import BreadcrumbToggler from '../../components/breadcrumb-toggler';
import { Context } from './context/CircularStore';
import { Breadcrumb } from 'semantic-ui-react';
import Pagination from 'components/PaginationComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '-10px auto',
    boxShadow: 'none',
  },
  container: {
    maxHeight: '70vh',
    width: '100%',
  },
}));

const CircularList = () => {
  const classes = useStyles();
  const { setAlert } = useContext(AlertNotificationContext);
  const [page, setPage] = useState(1);
  const [periodData, setPeriodData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [viewMore, setViewMore] = useState(false);
  const [viewMoreData, setViewMoreData] = useState({});
  const [periodDataForView, setPeriodDataForView] = useState({});
  const [filterDataDown, setFilterDataDown] = useState({});
  const [completedStatus, setCompletedStatus] = useState(false);
  const limit = 9;
  const [isFilter, setIsFilter] = useState(true);
  const themeContext = useTheme();
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));
  const [chapterSearch, setChapterSearch] = useState();
  const [periodColor, setPeriodColor] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const sessionYear = JSON.parse(sessionStorage.getItem('acad_session'))
  const [grade, setGrade] = useState();
  const [branch, setBranch] = useState();
  const [section, setSection] = useState();
  const [acadYear, setAcadYear] = useState();
  const [startDateFilter, setStartDateFilter] = useState();
  const [endDateFilter, setEndDateFilter] = useState();
  const [deleteFlag, setDeleteFlag] = useState(false);
  const selectedAcademicYear = useSelector(
    (state) => state.commonFilterReducer?.selectedYear
  );

  //for edit circular data
  const [editData, setEditData] = useState([]);

  const [state, setState] = useContext(Context);

  const handlePagination = (event, page) => {
    setPage(page);
  };
  const [moduleId, setModuleId] = useState();
  const NavData = JSON.parse(localStorage.getItem('navigationData')) || {};

  const { role_details } = JSON.parse(localStorage.getItem('userDetails') || {});
  const userId = JSON.parse(localStorage.getItem('userDetails') || {});
  useEffect(() => {
    if (NavData && NavData.length) {
      NavData.forEach((item) => {
        if (
          item.parent_modules === 'Circular' &&
          item.child_module &&
          item.child_module.length > 0
        ) {
          item.child_module.forEach((item) => {
            if (
              item.child_name === 'Teacher Circular' &&
              window.location.pathname === '/teacher-circular'
            ) {
              setModuleId(item.child_id);
            }
            if (
              item.child_name === 'Student Circular' &&
              window.location.pathname === '/student-circular'
            ) {
              setModuleId(item.child_id);
            }
          });
        }
      });
    }
  }, [window.location.pathname]);

  const handlePeriodList = (grade, branch, section, year, startDate, endDate) => {
    setLoading(true);
    if (window.location.pathname === '/teacher-circular') {
      setPeriodData([]);
      setGrade(grade);
      setBranch(branch);
      setSection(section);
      setAcadYear(year);
      setStartDateFilter(startDate);
      setEndDateFilter(endDate);
      setFilterDataDown(grade, branch, section, year, startDate, endDate);
      axiosInstance
        .get(
          `${endpoints.circular.circularList}?is_superuser=True&branch=${
            branch.branch?.id
          }&session_year=${selectedAcademicYear?.id}&grade=${grade.grade_id}&section=${section?.section_id}&academic_year=${
            year.id
          }&start_date=${startDate.format('YYYY-MM-DD')}&end_date=${endDate.format(
            'YYYY-MM-DD'
          )}&page=${page}&page_size=${limit}`
        )
        .then((result) => {
          if (result.data.status_code === 200) {
            setTotalCount(result?.data?.count);
            setLoading(false);
            setPeriodData(result?.data?.result);
            setViewMore(false);
            setViewMoreData({});
          } else {
            setLoading(false);
            setAlert('error', result?.data?.description);
          }
        })
        .catch((error) => {
          setLoading(false);
          setAlert('error', error?.message);
        });
    } else {
      setPeriodData([]);
      setStartDateFilter(grade);
      setEndDateFilter(branch);
      setFilterDataDown(grade, branch);
      axiosInstance
        .get(
          `${endpoints.circular.circularList}?user_id=${
            userId?.user_id
          }&start_date=${grade.format('YYYY-MM-DD')}&end_date=${branch.format(
            'YYYY-MM-DD'
          )}&page=${page}&page_size=${limit}&role_id=${
            role_details?.role_id
          }&module_id=${moduleId}&module_name=Student Circular&academic_year=${sessionYear?.id}`
        )
        .then((result) => {
          if (result.data.status_code === 200) {
            setTotalCount(result.data.count);
            setLoading(false);
            setPeriodData(result.data.result);
            setViewMore(false);
            setViewMoreData({});
          } else {
            setLoading(false);
            setAlert('error', result.data.description);
          }
        })
        .catch((error) => {
          setLoading(false);
          setAlert('error', error.message);
        });
    }
  };
  useEffect(() => {
    if (window.location.pathname === '/teacher-circular') {
      if (
        page &&
        grade &&
        branch &&
        section &&
        acadYear &&
        startDateFilter &&
        endDateFilter
      )
        handlePeriodList(
          grade,
          branch,
          section,
          acadYear,
          startDateFilter,
          endDateFilter
        );
      if (deleteFlag)
        handlePeriodList(
          grade,
          branch,
          section,
          acadYear,
          startDateFilter,
          endDateFilter
        );
    }
  }, [page, deleteFlag]);
  useEffect(() => {
    if (window.location.pathname === '/student-circular') {
      if (startDateFilter && endDateFilter)
        handlePeriodList(startDateFilter, endDateFilter);
    }
  }, [page]);
  return (
    <>
      {loading ? <Loading message='Loading...' /> : null}
      <Layout>
        <BreadcrumbToggler isFilter={isFilter} setIsFilter={setIsFilter}>
          <CommonBreadcrumbs
            componentName={
              window.location.pathname === '/teacher-circular'
                ? 'Teacher Circular'
                : 'Student Circular'
            }
          />
        </BreadcrumbToggler>
        <div className={isFilter ? 'showFilters' : 'hideFilters'}>
          <CircularFilters
            handlePeriodList={handlePeriodList}
            setPeriodData={setPeriodData}
            setViewMore={setViewMore}
            setViewMoreData={setViewMoreData}
            setFilterDataDown={setFilterDataDown}
            setSelectedIndex={setSelectedIndex}
          />
        </div>

        <Paper className={classes.root}>
          {periodData?.length > 0 ? (
            <Grid
              container
              style={
                isMobile
                  ? { width: '95%', margin: '20px auto' }
                  : { width: '100%', margin: '20px auto' }
              }
              spacing={5}
            >
              <Grid item xs={12} sm={viewMore ? 7 : 12}>
                <Grid container spacing={isMobile ? 3 : 5}>
                  {periodData.map((period, i) => (
                    <Grid
                      item
                      xs={12}
                      style={isMobile ? { marginLeft: '-8px' } : null}
                      sm={viewMore ? 6 : 4}
                    >
                      <CircularCard
                        index={i}
                        filterDataDown={filterDataDown}
                        period={period}
                        setSelectedIndex={setSelectedIndex}
                        periodColor={selectedIndex === i ? true : false}
                        setPeriodColor={setPeriodColor}
                        viewMore={viewMore}
                        setLoading={setLoading}
                        setViewMore={setViewMore}
                        setViewMoreData={setViewMoreData}
                        setPeriodDataForView={setPeriodDataForView}
                        setCompletedStatus={setCompletedStatus}
                        setEditData={setEditData}
                        deleteFlag={deleteFlag}
                        setDeleteFlag={setDeleteFlag}
                        sessionYear = {sessionYear}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              {viewMore ? (
                <Grid item xs={12} sm={5} style={{ width: '100%' }}>
                  <ViewMoreCard
                    completedStatus={completedStatus}
                    viewMoreData={viewMoreData}
                    setViewMore={setViewMore}
                    setSelectedIndex={setSelectedIndex}
                    filterDataDown={filterDataDown}
                    periodDataForView={periodDataForView}
                    grade={grade}
                    section={section}
                    branch={branch}
                  />
                </Grid>
              ) : null}
            </Grid>
          ) : (
            <div className='periodDataUnavailable'>
              <SvgIcon
                component={() => (
                  <img
                    style={
                      isMobile
                        ? { height: '100px', width: '200px' }
                        : { height: '160px', width: '290px' }
                    }
                    src={unfiltered}
                  />
                )}
              />
              <SvgIcon
                component={() => (
                  <img
                    style={
                      isMobile
                        ? { height: '20px', width: '250px' }
                        : { height: '50px', width: '400px', marginLeft: '5%' }
                    }
                    src={selectfilter}
                  />
                )}
              />
            </div>
          )}
          {periodData?.length > 0 && (
            <div className='paginateData paginateMobileMargin'>
              {/* <Pagination
                onChange={handlePagination}
                style={{ marginTop: 25 }}
                count={Math.ceil(totalCount / limit)}
                color='primary'
                page={page}
              /> */}

            <Pagination
              totalPages={Math.ceil(totalCount / limit)}
              currentPage={page}
              setCurrentPage={setPage}
            />
            </div>
          )}
        </Paper>
      </Layout>
    </>
  );
};

export default CircularList;
