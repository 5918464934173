import React, { useContext } from 'react';
import {
  Box,
  Button,
  withStyles,
  TextField,
  Switch,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector, useDispatch } from 'react-redux';
import { updateAllCategory, editCategoryDataAction, editCategoryDataUpdated } from '../../../redux/actions/discussionForumActions';
import { AlertNotificationContext } from '../../../context-api/alert-context/alert-state';


const useStyles = makeStyles((theme) => ({
  paperStyles: {
    textAlign: 'center',
    backgroundColor: theme.palette.primary.primarylightest,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '12px',
    marginTop: '10px',
    marginLeft: '10px',
    padding: '10px 20px',
    transition: '3s',
  },
  formControl: {
    marginBottom: '40px',
  },
  switchButton: {
    marginLeft: '10px',
    marginRight: '10px',
  },
  activeLabel: {
    color: theme.palette.secondary.main,
  },
  closeIcon: {
    float: 'right',
    marginBottom: '10px',
  },
}));

const StyledButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    width: '156px',
    height: '36px',
    borderRadius: '12px',
    marginTop: '20px',
    marginBottom: '10px',
  },
}))(Button);

const StyledTextField = withStyles({
  root: {
    borderRadius: '10px',
    marginBottom: '40px',
  },
})(TextField);

const CategoryEdit = ({cardData, hadleClose}) => {
  const classes = useStyles({});
  const categoryData = useSelector((state) => state.discussionReducers.editCategoryData);
  const updateCategory = useSelector((state) => state.discussionReducers.updateCategory);
  const { setAlert } = useContext(AlertNotificationContext);
  const dispatch = useDispatch();
  const [category, setCategory] = React.useState('');
  const [subCategory, setSubCategory] = React.useState('');
  const [subSubCategory, setSubSubCategory] = React.useState();
  const [activeCategory, setActiveCategory] = React.useState(false);
  //console.log(categoryData, 'edit data');

  const handleChange = (e) => {
    setCategory(e.target.value);
  };

  const handleSubCategory = (e) => {
    setSubCategory(e.target.value);
  };

  const handleSubSubCategory = (e) => {
    setSubSubCategory(e.target.value);
  };

  const handleCategoryStatus = () => {
    setActiveCategory(!activeCategory);
  };

  const hadleCloseEdit = () => {
    hadleClose();
  };

  React.useEffect(() => {
    if(updateCategory !== ''){
      const data = '';
      setAlert('success', 'Category Updated');
      hadleCloseEdit();
      setActiveCategory(false);
      setCategory('');
      setSubCategory('');
      setSubSubCategory('');
      dispatch(editCategoryDataAction(data));
      dispatch(editCategoryDataUpdated());
    }
  },[updateCategory]);

  React.useEffect(() => {
    if(categoryData){
      setCategory(categoryData.category);
      setSubCategory(categoryData.sub_category_name);
      setSubSubCategory(categoryData.sub_sub_category_name);
      setActiveCategory(categoryData.is_delete);
    }
  },[categoryData])

  const handleUpdateCategory = () => {
    if(category !== categoryData.category) {
      const params = {category_name: category, category_type: "1"}
      const id = categoryData.category_id;
      dispatch(updateAllCategory(params, id));
    }
    if(subCategory !== categoryData.sub_category_name) {
      const params = {category_name: subCategory, category_type: "2", category_parent_id: categoryData.category_id}
      const id = categoryData.sub_category_id;
      dispatch(updateAllCategory(params, id));
    }
    if(subSubCategory !== categoryData.sub_sub_category_name && activeCategory === categoryData.is_delete) {
      const id = categoryData.sub_sub_category_id;
      const params = {category_name: subSubCategory, category_type: "3", category_parent_id: categoryData.sub_category_id}
      dispatch(updateAllCategory(params, id));
    }
    if(activeCategory !== categoryData.is_delete && subSubCategory === categoryData.sub_sub_category_name) {
      const id = categoryData.sub_sub_category_id;
      const params = {category_name: subSubCategory, category_type: "3", category_parent_id: categoryData.sub_category_id, is_delete: activeCategory}
      dispatch(updateAllCategory(params, id));
    }
    if(activeCategory !== categoryData.is_delete && subSubCategory !== categoryData.sub_sub_category_name) {
      const id = categoryData.sub_sub_category_id;
      const params = {category_name: subSubCategory, category_type: "3", category_parent_id: categoryData.sub_category_id, is_delete: activeCategory}
      dispatch(updateAllCategory(params, id));
    }
  }

  return (
    <Box className={classes.paperStyles}>
      <div className={classes.closeIcon}>
        <CloseIcon onClick={hadleCloseEdit} color="primary"/>
      </div>
      <StyledTextField
        id="outlined-basic"
        label="Category type"
        variant="outlined"
        placeholder="Type or select from filters above"
        value={category}
        defaultValue={category}
        onChange={handleChange}
        fullWidth
      />
      <StyledTextField
        id="outlined-basic"
        label="Sub category type"
        variant="outlined"
        placeholder="Type or select from filters above"
        value={subCategory}
        onChange={handleSubCategory}
        fullWidth
      />
      <StyledTextField
        id="outlined-basic"
        label="Sub sub category type"
        variant="outlined"
        placeholder="Type or select from filters above"
        value={subSubCategory}
        onChange={handleSubSubCategory}
        fullWidth
      />
      <div>
        <span className={classes.activeLabel}>In-Active</span>
          <Switch
            checked={!activeCategory}
            onChange={handleCategoryStatus}
            className={classes.switchButton}
            color="primary"
          />
        <span className={classes.activeLabel}>Active</span>
      </div>
      <StyledButton onClick={handleUpdateCategory}>Save</StyledButton>
    </Box>
  );
};

export default CategoryEdit;
