import React, { useState } from 'react';
import momemt from 'moment';

const QuestionPaper = ({ currentPaper, handleSelectQuestionPaper, selectedPaper }) => {
  return (
    <React.Fragment>
      <div
        className={`row p-2 mb-3 th-br-8 th-pointer ${
          selectedPaper?.id === currentPaper?.id ? 'shadow' : ''
        }`}
        style={{
          outline: `${
            selectedPaper?.id === currentPaper?.id ? '3px' : '1px'
          } solid ${
            selectedPaper?.id === currentPaper?.id ? '#5591b0' : '#f3f3f3'
          }`,
        }}
        onClick={() => handleSelectQuestionPaper(currentPaper)}
      >
        <div className='col-md-12 px-0 d-flex justify-content-between'>
          <div className='pb-2'>
            <span className='th-12'>
              Paper Status :{' '}
              {currentPaper?.paper_status === 1
                ? 'Draft'
                : currentPaper?.paper_status === 2
                ? 'Review'
                : currentPaper?.paper_status === 3
                ? 'Published'
                : currentPaper?.paper_status}{' '}
              | Total Question : {currentPaper?.total_questions} | Total Marks :{' '}
              {currentPaper?.total_marks}
            </span>
          </div>
          <div className='text-right'></div>
        </div>
        <div
          className='col-md-12 pl-0 border-right th-bg-blue-1 th-br-8'
          style={{ maxHeight: '200px', overflowY: 'auto' }}
        >
          <div className=' row p-2 '>
            <div className='th-question-box '>
              <span className='th-18 th-blue'>{currentPaper?.title}</span>
              <div className='mt-2'>
                Created At: {momemt(currentPaper?.created_at).format('DD-MM-YYYY')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default QuestionPaper;
