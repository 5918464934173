import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, TextField, Button, useTheme } from '@material-ui/core';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Autocomplete from '@material-ui/lab/Autocomplete';
import endpoints from '../../../../../config/endpoints';
import axiosInstance from '../../../../../config/axios';
import { AlertNotificationContext } from '../../../../../context-api/alert-context/alert-state';
import Divider from '@material-ui/core/Divider';
import '../../create-course/style.css';

const CourseFilter = ({ handleCourseList, setPageFlag, handleClearFilter, tabValue }) => {
  const themeContext = useTheme();
  const { gradeKey } = useParams();
  const history = useHistory();
  const { setAlert } = useContext(AlertNotificationContext);
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));
  const aolHostURL = window.location.host;
  const wider = isMobile ? '-10px 0px' : '-10px 0px 20px 8px';
  const widerWidth = isMobile ? '98%' : '95%';
  const [gradeDropdown, setGradeDropdown] = useState([]);
  const [gradeIds, setGradeIds] = useState([]);

  const [filterData, setFilterData] = useState({
    grade: [],
  });

  const NavData = JSON.parse(localStorage.getItem('navigationData')) || {};
  const [moduleId, setModuleId] = useState('');

  useEffect(() => {
    if (NavData && NavData.length) {
      NavData.forEach((item) => {
        if (
          item.parent_modules === 'Master Management' &&
          item.child_module &&
          item.child_module.length > 0
        ) {
          item.child_module.forEach((item) => {
            if (item.child_name === 'Course') {
              setModuleId(item.child_id);
            }
          });
        }
      });
    }
  }, []);

  const handleClear = () => {
    setFilterData({
      grade: [],
    });
    setGradeIds([]);
    setPageFlag(false);
    handleClearFilter();
  };

  const handleFilter = () => {
    handleCourseList(gradeIds, tabValue);
  };

  useEffect(() => {
    if (moduleId) {
      let url = `${endpoints.masterManagement.grades}`;
      if (aolHostURL === endpoints.aolConfirmURL)
        url += `?branch_id=1&module_id=${moduleId}`;
      else url += `?module_id=${moduleId}`;

      axiosInstance
        .get(url)
        .then((result) => {
          if (result.data.status_code === 200) {
            setGradeDropdown(result?.data?.result.results);
          } else {
            setAlert('error', result?.data?.message);
            setGradeDropdown([]);
          }
        })
        .catch((error) => {
          setAlert('error', error.message);
          setGradeDropdown([]);
        });
    }
  }, [moduleId]);

  useEffect(() => {
    if (gradeKey) {
      let url = `${endpoints.masterManagement.grades}`;
      if (aolHostURL === endpoints.aolConfirmURL)
        url += `?branch_id=1&module_id=${moduleId}`;
      else url += `?module_id=${moduleId}`;

      axiosInstance
        .get(url)
        .then((result) => {
          if (result.data.status_code === 200) {
            setGradeDropdown(result?.data?.result.results);
            const gradeObj = result.data?.result.results?.find(
              ({ id }) => id === Number(gradeKey)
            );
            if (gradeKey > 0) {
              setFilterData({
                grade: gradeObj,
              });
              setGradeIds(gradeKey);
              handleCourseList(gradeKey);
            } else history.push('/course-list');
          } else {
            setAlert('error', result?.data?.message);
            setGradeDropdown([]);
          }
        })
        .catch((error) => {
          setAlert('error', error.message);
          setGradeDropdown([]);
        });
    }
  }, [gradeKey]);

  const handleGrade = (event, value) => {
    setFilterData({ ...filterData, grade: '' });
    if (value) {
      setGradeIds(value.id || '');
      setFilterData({
        ...filterData,
        grade: value,
      });
    }
  };

  return (
    <>
      <Grid
        container
        spacing={isMobile ? 3 : 5}
        style={{ width: widerWidth, margin: wider }}
      >
        <Grid item xs={12} sm={3} className={isMobile ? '' : 'filterPadding'}>
          <Autocomplete
            style={{ width: '100%' }}
            size='small'
            onChange={handleGrade}
            id='volume'
            className='dropdownIcon'
            value={filterData?.grade || ''}
            options={gradeDropdown || []}
            getOptionLabel={(option) => option?.grade_name || ''}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                label='Grade'
                placeholder='Grade'
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={isMobile ? 3 : 5}
        style={{ width: widerWidth, margin: wider }}
      >
        <Grid item xs={6} sm={2} className={isMobile ? '' : 'addButtonPadding'}>
          <Button
            variant='contained'
            style={{ width: '100%' }}
            className='labelColor cancelButton'
            size='medium'
            onClick={handleClear}
          >
            Clear All
          </Button>
        </Grid>
        <Grid item xs={6} sm={2} className={isMobile ? '' : 'addButtonPadding'}>
          <Button
            variant='contained'
            style={{ color: 'white', width: '100%' }}
            color='primary'
            size='medium'
            onClick={handleFilter}
          >
            Filter
          </Button>
        </Grid>
        <div>
          <Divider
            orientation='vertical'
            style={{
              backgroundColor: '#014e7b',
              height: '40px',
              marginTop: '1rem',
              marginLeft: '2rem',
              marginRight: '1.25rem',
            }}
          />
        </div>
        <Grid
          item
          xs={6}
          sm={2}
          className={isMobile ? 'createButton' : 'createButton addButtonPadding'}
        >
          <Button
            startIcon={<AddOutlinedIcon style={{ fontSize: '30px' }} />}
            variant='contained'
            style={{ color: 'white', width: '100%' }}
            size='medium'
            color='primary'
            onClick={() => {
              sessionStorage.removeItem('isAol');
              sessionStorage.removeItem('gradeKey');
              sessionStorage.setItem('createCourse', 1);
              sessionStorage.removeItem('periodDetails');
              sessionStorage.removeItem('isErpClass');
              history.push({ pathname: '/create/course' });
            }}
          >
            Create
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default CourseFilter;
