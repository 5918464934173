import React, { useEffect, useRef, useState } from 'react';
import Layout from 'containers/Layout';
import {
  Breadcrumb,
  Button,
  Form,
  Input,
  message,
  Modal,
  Pagination,
  Popconfirm,
  Select,
  Table,
  Tag,
} from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import endpoints from 'config/endpoints';
import axiosInstance from 'config/axios';
import { useHistory } from 'react-router-dom';

const CityList = () => {
  const { Option } = Select;
  const formRef = useRef();
  const history = useHistory();
  const [cities, setCities] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  //eslint-disable-next-line
  const [pageLimit, setPageLimit] = useState(15);
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState(null);

  useEffect(() => {
    // Fetch cities from API
    fetchCities({ paginated: true, page: 1, page_size: pageLimit });
    fetchZoneList();
  }, []);

  const fetchCities = async (params = {}) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`${endpoints.masterManagement.city}`, {
        params: { ...params },
      });
      setCities(response?.data?.result?.results);
      setTotalPage(response?.data?.result?.count);
    } catch (error) {
      console.error('Error fetching cities:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchZoneList = (params = {}) => {
    axiosInstance
      .get(`${endpoints.masterManagement.zone}`, {
        params: params,
      })
      .then((response) => {
        if (response?.data?.status_code == 200) {
          setZoneList(response?.data?.result);
        }
      })
      .catch((error) => {
        message.error('error in fetching zones');
      });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    formRef.current.resetFields();
    setEditData(null);
    setIsModalVisible(false);
  };

  const handleSubmit = async (values) => {
    try {
      if (editData) {
        await axiosInstance.put(
          `${endpoints.masterManagement.city}${editData?.id}/`,
          values
        );
        message.success('City edited successfully');
      } else {
        await axiosInstance.post(`${endpoints.masterManagement.city}`, values);
        message.success('City added successfully');
      }

      setIsModalVisible(false);
      fetchCities({ paginated: true, page: 1, page_size: pageLimit }); // Refresh the city list
    } catch (error) {
      console.error('Error adding city:', error, error?.response?.da);
      message.error(`${editData ? 'Error in updating city' : 'Error in adding city'}`);
    } finally {
      formRef.current.resetFields();
      handleCancel();
    }
  };

  const columns = [
    {
      title: <span className='th-white th-fw-700 '>Sl. No.</span>,
      dataIndex: 'id',
      render: (data, record, index) => (
        <span className='th-black-1 th-14'>{(pageNo - 1) * pageLimit + index + 1}</span>
      ),
      key: 'id',
      width: '10%',
    },
    {
      title: <span className='th-white th-fw-700 '>City Name</span>,
      dataIndex: 'city_name',
      render: (data) => <span className='th-black-1 th-14'>{data}</span>,
      key: 'city_name',
      width: '30%',
    },
    {
      title: <span className='th-white th-fw-700 '>Zone Name</span>,
      dataIndex: 'zones',
      render: (data) => (
        <span className='th-black-1 th-14'>
          {data?.map((zone) => zone.zone_name).join(', ')}
        </span>
      ),
      key: 'zones',
      width: '40%',
    },

    {
      title: <span className='th-white th-fw-700 '>Action</span>,
      key: 'action',
      render: (_, record) => (
        <span>
          <Tag
            icon={<EditOutlined />}
            className='th-br-4 th-pointer'
            onClick={() => handleEdit(record)}
            color='processing'
          >
            Edit
          </Tag>
          <Popconfirm title='Sure to delete?' onConfirm={() => handleDelete(record)}>
            <Tag
              icon={<DeleteOutlined />}
              style={{ marginLeft: 8 }}
              color='error'
              className='th-br-4 th-pointer'
            >
              Delete
            </Tag>
          </Popconfirm>
        </span>
      ),
      width: '20%',
    },
  ];

  const handleEdit = (record) => {
    // Implement edit functionality
    setEditData((prevState) => ({ ...prevState, ...record }));
    setIsModalVisible(true);
    setTimeout(() => {
      formRef.current.setFieldsValue({
        city_name: record?.city_name,
        zone_ids: record?.zones?.map((e) => e.id),
      });
    }, 100);
  };

  const handleDelete = async (record) => {
    // Implement delete functionality

    try {
      await axiosInstance.delete(`${endpoints.masterManagement.city}${record?.id}/`);
      message.success('city deleted successfully');
    } catch (error) {
      message.error('failed to deleting city');
    } finally {
      fetchCities({ paginated: true, page: 1, page_size: pageLimit });
    }
  };

  const zoneOptions = zoneList?.map((each) => {
    return (
      <Option key={each?.id} value={each.id}>
        {each?.zone_name || ''}
      </Option>
    );
  });

  return (
    <React.Fragment>
      <Layout>
        <div className='row py-3'>
          <div className='col-md-6 th-bg-grey' style={{ zIndex: 2 }}>
            <Breadcrumb separator='>'>
              <Breadcrumb.Item className='th-black-1 th-16 th-grey'>
                Master Management
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className='th-black-1 th-16 th-grey th-pointer'
                onClick={() => {
                  history.push('/master-management/branch-table');
                }}
              >
                Branch
              </Breadcrumb.Item>
              <Breadcrumb.Item className='th-black-1 th-16'>City</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12'>
            <div className='th-bg-white th-br-5 py-3 px-2 px-4'>
              <div className='d-flex justify-content-between align-items-center'>
                <div>
                  <h5>City List</h5>
                </div>
                <div>
                  <Button
                    icon={<PlusOutlined />}
                    onClick={showModal}
                    style={{ marginBottom: 16 }}
                    type='primary'
                    className='th-br-4'
                  >
                    Add City
                  </Button>
                </div>
              </div>

              <Table
                className='th-table'
                rowClassName={(record, index) =>
                  index % 2 === 0 ? 'th-bg-grey' : 'th-bg-white'
                }
                loading={loading}
                columns={columns}
                rowKey={(record) => record?.id}
                dataSource={cities}
                pagination={false}
                scroll={{ y: '300px' }}
              />

              {cities?.length > 0 && (
                <div className='pt-3 '>
                  <Pagination
                    current={pageNo}
                    total={totalPage}
                    showSizeChanger={false}
                    pageSize={pageLimit}
                    onChange={(current) => {
                      setPageNo(current);
                      fetchCities({
                        paginated: true,
                        page: current,
                        page_size: pageLimit,
                      });
                    }}
                    className='text-center'
                  />
                </div>
              )}

              <Modal
                title={`${editData ? 'Edit City' : 'Add City'}`}
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                centered
              >
                <div className='p-3'>
                  <Form ref={formRef} layout='vertical' onFinish={handleSubmit}>
                    <Form.Item
                      name='city_name'
                      label='City Name'
                      rules={[{ required: true, message: 'Please enter the city name' }]}
                    >
                      <Input
                        value={editData ? editData?.city_name : null}
                        placeholder='City Name'
                      />
                    </Form.Item>
                    <Form.Item
                      name='zone_ids'
                      label='Select Zone'
                      rules={[
                        {
                          required: true,
                          message: 'Please select zone',
                        },
                      ]}
                    >
                      <Select
                        allowClear={true}
                        mode='multiple'
                        className='th-grey th-bg-white  w-100 text-left'
                        placement='bottomRight'
                        showArrow={true}
                        dropdownMatchSelectWidth={true}
                        optionFilterProp='children'
                        filterOption={(input, options) => {
                          return (
                            options.children
                              ?.toLowerCase()
                              .indexOf(input?.toLowerCase()) >= 0
                          );
                        }}
                        showSearch
                        getPopupContainer={(trigger) => trigger.parentNode}
                        placeholder='Select Zone*'
                        onChange={(e, value) => {
                          if (e) {
                            if (e.includes('all')) {
                              let values = zoneList?.map((e) => e?.id);
                              console.log({ values });

                              formRef.current.setFieldsValue({
                                zone_ids: values,
                              });
                            } else {
                              formRef.current.setFieldsValue({
                                zone_ids: [...e],
                              });
                            }
                          } else {
                            formRef.current.setFieldsValue({
                              zone_ids: [],
                            });
                          }
                        }}
                      >
                        {zoneList?.length > 1 && (
                          <Select.Option key={'all'} value={'all'}>
                            Select All
                          </Select.Option>
                        )}
                        {zoneOptions}
                      </Select>
                    </Form.Item>
                    <Form.Item className='text-center'>
                      <Button type='primary' htmlType='submit' className='px-4 th-br-4'>
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default CityList;
