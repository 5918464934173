export const LIST_GRADE_SUCCESS = 'LIST_GRADE_SUCCESS';
export const LIST_GRADE_FAILURE = 'LIST_GRADE_FAILURE';
export const LIST_GRADE_REQUEST = 'LIST_GRADE_REQUEST';

export const LIST_COURSE_SUCCESS = 'LIST_COURSE_SUCCESS';
export const LIST_COURSE_FAILURE = 'LIST_COURSE_FAILURE';
export const LIST_COURSE_REQUEST = 'LIST_COURSE_REQUEST';

export const LIST_SECTION_SUCCESS = 'LIST_SECTION_SUCCESS';
export const LIST_SECTION_FAILURE = 'LIST_SECTION_FAILURE';
export const LIST_SECTION_REQUEST = 'LIST_SECTION_REQUEST';

export const LIST_SUBJECT_SUCCESS = 'LIST_SUBJECT_SUCCESS';
export const LIST_SUBJECT_FAILURE = 'LIST_SUBJECT_FAILURE';
export const LIST_SUBJECT_REQUEST = 'LIST_SUBJECT_REQUEST';

export const LIST_STUDENT_SUCCESS = 'LIST_STUDENT_SUCCESS';
export const LIST_STUDENT_FAILURE = 'LIST_STUDENT_FAILURE';
export const LIST_STUDENT_REQUEST = 'LIST_STUDENT_REQUEST';

export const VERIFY_TUTOREMAIL_REQUEST = 'VERIFY_TUTOREMAIL_REQUEST';
export const VERIFY_TUTOREMAIL_SUCCESS = 'VERIFY_TUTOREMAIL_SUCCESS';
export const VERIFY_TUTOREMAIL_FAILURE = 'VERIFY_TUTOREMAIL_FAILURE';
export const CLEAR_VALIDATION = 'CLEAR_VALIDATION';

export const CLEAR_FILERED_STUDENTS = 'CLEAR_FILERED_STUDENTS';
export const LIST_FILTERED_STUDENTS = 'LIST_FILTERED_STUDENTS';

export const CLEAR_GRADE_DROP = 'CLEAR_GRADE_DROP';
export const CLEAR_SECTION_DROP = 'CLEAR_SECTION_DROP';
export const CLEAR_SUBJECT_DROP = 'CLEAR_SUBJECT_DROP';
export const CLEAR_COURSE_DROP = 'CLEAR_COURSE_DROP';

export const CREATE_NEW_CLASS_REQUEST = 'CREATE_NEW_CLASS_REQUEST';
export const CREATE_NEW_CLASS_SUCCESS = 'CREATE_NEW_CLASS_SUCCESS';
export const CREATE_NEW_CLASS_FAILURE = 'CREATE_NEW_CLASS_FAILURE';

export const RESET_CREATE_CLASS_CONTEXT = 'RESET_CREATE_CLASS_CONTEXT';

export const LIST_TUTOR_EMAILS_REQUEST = 'LIST_TUTOR_EMAILS_REQUEST';
export const LIST_TUTOR_EMAILS_SUCCESS = 'LIST_TUTOR_EMAILS_SUCCESS';
export const LIST_TUTOR_EMAILS_FAILURE = 'LIST_TUTOR_EMAILS_FAILURE';

export const UPDATE_CLASS_TYPE = 'UPDATE_CLASS_TYPE';

export const SET_EDIT_DATA = 'SET_EDIT_DATA';
export const SET_EDIT_DATA_FALSE = 'SET_EDIT_DATA_FALSE';

export const LIST_GROUP_REQUEST = 'LIST_GROUP_REQUEST';
export const LIST_GROUP_SUCCESS = 'LIST_GROUP_SUCCESS';
export const LIST_GROUP_FAILURE = 'LIST_GROUP_FAILURE'
export const CLEAR_GROUP_LIST = 'CLEAR_GROUP_LIST'
