import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ClearIcon = ({ color }) => {
  return (
    <SvgIcon viewBox='0 0 15.995 15.995'>
      <g transform='translate(-2.503 -2.01)'>
        <g transform='translate(2.503 2.01)'>
          <path
            className='a'
            d='M13.759,2.565l4.184,4.184a1.9,1.9,0,0,1,0,2.679l-7.311,7.31h5.135a.632.632,0,0,1,.626.546l.006.086a.632.632,0,0,1-.546.626L15.767,18H8.685a1.89,1.89,0,0,1-1.444-.552L3.058,13.266a1.9,1.9,0,0,1,0-2.679l8.022-8.022A1.9,1.9,0,0,1,13.759,2.565ZM10.245,15.271,5.231,10.257,3.963,11.492a.632.632,0,0,0,0,.893l4.165,4.165a.632.632,0,0,0,.893-.014Z'
            transform='translate(-2.503 -2.01)'
            style={{ color }}
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default ClearIcon;
