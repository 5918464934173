import React, { useState, useRef, useEffect } from 'react';
import Layout from 'containers/Layout';
import { Breadcrumb, Tabs, message } from 'antd';
import ClickerQuiz from '../Clicker/ClickerQuiz';
import AssignClickerUser from './AssignUser';

const { TabPane } = Tabs;

const Clicker = () => {
  const [showTab, setShowTab] = useState('1');

  const onChange = (key) => {
    setShowTab(key);
  };

  return (
    <React.Fragment>
      <Layout>
        <div className='row py-3 px-2'>
          <div className='col-md-6 th-bg-grey' style={{ zIndex: 2 }}>
            <Breadcrumb separator='>'>
              <Breadcrumb.Item href='/dashboard' className='th-grey th-16'>
                Dashboard
              </Breadcrumb.Item>

              <Breadcrumb.Item className='th-black-1 th-16'>Clicker</Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className='row'>
            <div className='col-12'>
              <div className='th-tabs th-bg-white'>
                <Tabs type='card' onChange={onChange} defaultActiveKey={showTab}>
                  <TabPane tab='Clicker Quiz' key='1'>
                    <ClickerQuiz />
                  </TabPane>

                  <TabPane tab='Assign User' key='2'>
                    <AssignClickerUser tabValue={showTab} />
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default Clicker;
