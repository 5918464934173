import React from 'react';
import './style.scss';
import LetsEduvateLogo from './../../../assets/images/logo.png';

const Policy = () => {
  return (
    <>
      <div className='' style={{ overflowY: 'scroll', height: '100%' }}>
        <div className='th-br-12 th-bg-white m-1 m-md-3 mb-5  px-4 pt-4 pb-5 shadow-sm justify-container'>
          <div className='text-center justify-content-center mb-2 row'>
            <div className='col-md-3 col-5'>
              <img src={LetsEduvateLogo} alt='Lets Eduvate' className='img-fluid' />
            </div>
          </div>
          <h3 className='pt-2' style={{ textAlign: 'left' }}>
            Privacy Policy
          </h3>
          <p>
            Thank you for visiting LetsEduvate. This privacy policy governs your use of
            the Website <a href='https://letseduvate.com/'> https://letseduvate.com/</a>{' '}
            (“Website”) and the other associated/ancillary applications, products and
            services associated with LetsEduvate. Please read this privacy policy
            (“Policy“) carefully before using the Website and/or services and products
            offered by LetsEduvate, along with the Terms and Conditions (“T&C“) provided
            on the Website. Your use of the Website, application, or services in
            connection with LetsEduvate, Website or products, or registrations with us
            through any mode or use of any products including that of SD cards, tablets or
            other storage/transmitting device shall signify your acceptance of this Policy
            and your agreement to be legally bound by the same. For the sake of brevity
            your use of ‘LetsEduvate’ in any electronic form or device shall be bound by
            the terms and conditions enumerated and agreed upon hereunder with wilful and
            free consent. Each time you use our services, or allow others to use the
            services via your account, you permit us to collect, use and/or disclose your
            information as well of the user who is using your account, as described in
            this privacy policy.
          </p>
          <br />
          <ul className='main-list'>
            <li>
              User Provided Information
              <ul className='child-list'>
                <li>
                  The Website and other services/products of LetsEduvate records the
                  information you provide when you download and register for the Website,
                  services or products. When you register with us, you generally provide
                  <ul className='sub-child-list'>
                    <li>
                      your name, age, email address, phone number, password and your
                      ward’s educational interests;
                    </li>
                    <li>
                      {' '}
                      transaction-related information, such as when you make purchases,
                      respond to any offers, or download or use applications from us;
                    </li>
                    <li>information you provide us when you contact us for help;</li>
                    <li>
                      information you enter into its system when using the Website and{' '}
                    </li>
                    <li>
                      We offer various opportunities to interact with us through contests,
                      discussion forums, challenges, webinars, etc. which may require you
                      to complete registration process and share personally identifiable
                      information.
                    </li>
                  </ul>
                </li>
                <li>
                  The said information collected from the users could be categorized as
                  “Personal Information”, “Sensitive Personal Information” and “Associated
                  Information”. Personal Information, Sensitive Personal Information and
                  Associated Information (each as individually defined under this
                  Information Technology (Reasonable security practices and procedures and
                  sensitive personal data or information) Rules, 2011 (the “Data
                  Protection Rules”) shall collectively be referred to as “Information” in
                  this Policy.
                </li>
                <li>
                  LetsEduvate may use this Information to contact you from time to time,
                  to provide you with the services, important information, updates,
                  required notices and marketing promotions.
                </li>
                <li>
                  LetsEduvate will not differentiate between who is using the device to
                  access the Website, so long as the log in/access credentials match with
                  yours. In order to make the best use of the Website and enable your
                  Information to be captured accurately on the Website, it is essential
                  that you have logged in using your own credentials.
                </li>
                <li>
                  We will, at all times, provide the option to you, not to provide the
                  Personal Information or Sensitive Personal Information, However if you
                  choose not to share your Personal Information, you may not be eligible
                  to use or participate in our services.
                </li>
                <li>
                  Further, you can, at any time while using the Website, also have an
                  option to withdraw your consent given earlier to it, to use such
                  Personal Information or Sensitive Personal Information. Withdrawal of
                  the consent by you is required to be sent in writing to us at the
                  contact details provided in this Policy below. In any such event,
                  LetsEduvate fully reserves the right to withdraw further usage of the
                  Website provide any further services/products thereunder to you.
                </li>
                <li>
                  Additionally we may collect certain information automatically,
                  including, but not limited to, computer’s IP address and browser or
                  computer platform information, the type of mobile device or tab you use,
                  your mobile or tab devices unique device ID, the IP address of your
                  mobile or tab device, your mobile or tab operating system, the type of
                  mobile or tab Internet browsers you use, and information about the way
                  you use the Website. You agree that we may use such information to do
                  internal research on our users demographic to better understand, protect
                  and serve our users.
                </li>
              </ul>
            </li>

            <li>
              How LetsEduvate uses your information
              <ul className='child-list'>
                <li>
                  The Personal Information that you voluntarily share is collected for
                  your voluntary subscriptions to our services, order confirmation,
                  sending order intimation and receipt, confirmation of user password and
                  login details, sharing our newsletters and promotional mails or to
                  secure free trial access to any service. Unless you opt out, LetsEduvate
                  may connect with you via email to inform and alert you on new services
                  which may be of your interest. It is also used to identify and suggest
                  personalized options for your needs. Collecting navigation and website
                  visit data helps us to take steps to improve the user experience and
                  make it more useful and valuable.
                </li>
                <li>
                  LetsEduvate may share your data for third party payment gateway, to
                  websites such as Google to identify demographic information to enable
                  LetsEduvate to facilitate and improve our marketing and communication
                  with the society.
                </li>

                <li>
                  We may be required to share your information :
                  <ul className='sub-child-list'>
                    <li>
                      as required by law, such as to comply with a subpoena, or similar
                      legal process;
                    </li>
                    <li>
                      to enforce applicable T&C, including investigation of potential
                      violations thereof;
                    </li>
                    <li>
                      when it believes in good faith that the disclosure is necessary to
                      protect its rights, protect your safety or the safety of others,
                      investigate fraud, address security or technical issues or respond
                      to a government request;
                    </li>
                    <li>
                      to protect against imminent harm to the rights, property or safety
                      of the LetsEduvate or its users or the public as required or
                      permitted by law;
                    </li>
                    <li>
                      with third party service providers in order to personalize the
                      Website and/or other products services offered by LetsEduvate for a
                      better user experience and to perform behavioural analysis;
                    </li>
                  </ul>
                </li>

                <li>
                  Any portion of the Information containing personal data relating to
                  minors provided by you shall be deemed to be given with the consent of
                  the minor’s legal guardian. Such consent is deemed to be provided by
                  your registration with LetsEduvate.
                </li>
                <li>
                  We will only keep records of your Personal Information for as long as is
                  reasonably necessary for the purposes for which we have collected it,
                  and in order to comply with any statutory or regulatory obligations in
                  relation to retention of records.
                </li>
              </ul>
            </li>

            <li>
              Privacy of the children who use the website
              <ul className='child-list'>
                <li>
                  We do allow individual subscriptions for individuals less than 18 years
                  of age. A consent is required incase the use is underage from the
                  parents or legal guardian to subscribe and make payments.
                </li>
              </ul>
            </li>

            <li>
              Links to other Sites
              <ul className='child-list'>
                <li>
                  Our Platform may contain links to other websites. Please note that when
                  you click on one of these links, you are entering another website over
                  which we have no control and for which we will bear no responsibility.
                  Often these websites require you to enter your Personal Information. We
                  encourage you to read the privacy policies of all such websites as their
                  policies may differ from our Privacy Policy. You agree that we shall not
                  be liable for any breach of your privacy of Personal Information or loss
                  incurred by your use of such websites or services.
                </li>
              </ul>
            </li>

            <li>
              Cookies
              <ul className='child-list'>
                <li>
                  LetsEduvate uses cookies to improve user experience. For example, we
                  collect information the way visitors use the website – the services they
                  explore, the links which they use, and how long they stay on a page.
                  Cookies are used to track your preferences, help you login faster, and
                  aggregated to determine user trends. This data is used to improve its
                  offerings, such as providing more content in areas of greater interest
                  to a majority of users.
                </li>
                <li>
                  Most browsers are initially set up to accept cookies, but you can reset
                  your browser to refuse all cookies or to indicate when a cookie is being
                  sent.
                </li>
                <li>
                  However, some of our features and services may not function properly if
                  your cookies are disabled.
                </li>
              </ul>
            </li>

            <li>
              Terms of acceptance of the user
              <ul className='child-list'>
                <li>
                  By registering with LetsEduvate, you are expressly consenting to
                  collection, processing, storing, disclosing and handling of your
                  Information as set forth in this Policy now and as amended by us.
                  Processing, your information in any way, including, but not limited to,
                  collecting, storing, deleting, using, combining, sharing, transferring
                  and disclosing information, all of which activities will take place in
                  India. If you reside outside India your information will be transferred,
                  processed and stored in accordance with the applicable data protection
                  laws of India. You are free to opt out of the all or part services at
                  any time, update or change your account information. If you choose to
                  opt out of our services, you will no longer be eligible for any of our
                  services in whole or part. You can opt out of all your subscribed
                  services anytime by connecting us at the mail provided below.
                </li>
              </ul>
            </li>
            <li>
              Changes to privacy policy
              <ul className='child-list'>
                <li>
                  We may periodically make changes to our privacy policy. As we evolve,
                  the privacy policy will need to evolve as well to cover new situations.
                  You are advised to review this Policy regularly for any changes, as
                  continued use is deemed approval of all changes. Your continued use of
                  LetsEduvate after we post any modifications to the Privacy Policy on
                  this page will constitute your acknowledgment of the modifications and
                  your consent to abide and be bound by the modified Privacy Policy.
                </li>
              </ul>
            </li>
            <li>
              Contact us
              <ul className='child-list' style={{ listStyle: 'none' }}>
                <li>
                  If you have any questions or concerns, please don't hesitate to reach
                  out to us. You can contact our Grievance Officer/Data Protection Officer
                  through any of the following methods:
                </li>
                <li>
                  Email – <a href='mailto:info@letseduvate.com'>info@letseduvate.com</a>
                </li>
                <li>
                  By visiting this page on our website –{' '}
                  <a href='/contact'>{window.location.origin}/contact</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Policy;
