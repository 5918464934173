import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ChatIcon = () => {
  return (
    <SvgIcon viewBox='0 0 26.203 26.203'>
      <g transform='translate(0 0)' fill='#78b5f3'>
        <path
          className='a'
          d='M23.4,0H2.807A2.807,2.807,0,0,0,0,2.809V17.783A2.807,2.807,0,0,0,2.807,20.59H6.13l-.509,4.576a.936.936,0,0,0,1.556.8l5.972-5.374H23.4A2.807,2.807,0,0,0,26.2,17.783V2.809A2.807,2.807,0,0,0,23.4,0Z'
          transform='translate(0 -0.002)'
        />
        />
      </g>
    </SvgIcon>
  );
};

export default ChatIcon;
