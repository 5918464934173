import React, { useEffect, useState, useContext } from 'react';
import _ from 'lodash';
import ENVCONFIG from 'v2/config/config';

export default function AssesmentReportBackNew({ reportCardDataNew, principalSignData }) {
  const {
    s3: { ERP_BUCKET = '' },
  } = ENVCONFIG;

  var backData = reportCardDataNew?.back_page_report;

  return (
    <React.Fragment>
      <div className='row bg-white py-2 px-2'>
        {/* Teachers Remarks */}
        <table className='w-100 mt-3 th-12 th-report-table '>
          <tbody className='th-table-border'>
            <tr>
              <td
                className=' py-2 text-center th-fw-600'
                style={{ background: '#FDBF8E' }}
              >
                OBSERVATION AND FEEDBACK
              </td>
            </tr>
          </tbody>
        </table>
        <table className='w-100 mt-1 th-12 th-report-table '>
          <colgroup>
            <col style={{ width: '10%' }} />
            <col style={{ width: '15%' }} />
            <col style={{ width: '70%' }} />
            <col style={{ width: '5%' }} />
          </colgroup>
          {backData?.map((eachData, j) => {
            return (
              <tbody className='th-table-border'>
                <tr>
                  <td
                    className='py-2 text-center th-fw-600'
                    rowSpan={eachData?.parameter_details?.length + 2}
                  >
                    {eachData?.subject?.subject_name}
                  </td>

                  <td colSpan={2} className=' py-2 text-center th-fw-600'>
                    <div className='d-flex justify-content-between px-2 th-fw-600 '>
                      <div>MARKS - {eachData?.marks}</div>
                      <div>Grade - {eachData?.grade_name} </div>
                      <div></div>

                      {/* <div>AIR - {eachData?.AIR}</div> */}
                    </div>
                  </td>

                  <td
                    className='py-2 text-center th-fw-600'
                    rowSpan={eachData?.parameter_details?.length + 1}
                  ></td>
                </tr>

                {eachData?.parameter_details?.map((eachParameter, i) => {
                  return (
                    <>
                      <tr>
                        <td
                          className='py-2 text-center th-fw-600'
                          style={{ background: '#ffffff' }}
                        >
                          {eachParameter?.parameter_name}
                        </td>
                        <td className='py-2' style={{ background: '#ffffff' }}>
                          {eachParameter?.parameter_description}
                        </td>
                      </tr>
                    </>
                  );
                })}

                <tr>
                  <td
                    className=' py-2 text-center th-fw-600'
                    style={{ background: '#fdbf8e' }}
                  ></td>
                  <td className=' py-2' style={{ background: '#fdbf8e' }}></td>
                  <td className=' py-2' style={{ background: '#fdbf8e' }}></td>
                </tr>
              </tbody>
            );
          })}
        </table>

        <table className='w-100 mt-1 th-12 th-report-table '>
          <tbody className='th-table-border'>
            <tr>
              <td
                className='py-2 text-center th-fw-600'
                style={{ background: '#FDBF8E' }}
              >
                PRINCIPAL -
                {principalSignData?.length > 0 ? (
                  <>
                    <span>{principalSignData[0]?.author_id__name}</span>
                    <span className='pl-2'>
                      <img
                        src={`${ERP_BUCKET}${principalSignData[0].signature}`}
                        width={'120px'}
                      />
                    </span>
                  </>
                ) : null}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
}
