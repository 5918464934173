import { types } from '../actions/discussionForumActions';

const initialState = {
  post: {},
  filters: {
    year: '',
    branch: '',
    grade: '',
    section: '',
  },
  isEditPost: '',
  hasEdited: '',
  postEditData: '',
  category_data: '',
  category_list: '',
  sub_category_list: '',
  sub_sub_category_list: '',
  categoryData: [],
  categoryList: [],
  subCategoryList: [],
  subSubCategoryList: [],
  editCategoryData: '',
  updateCategory: '',
  categoryCreadted: '',
  categoryPageCount: 0,
};

const discussionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.DISCUSSION_POST:
      return {
        post: action.payload,
      };
    case types.FILTER_DATA:
      return {
        filters: {
          year: '',
          branch: '',
          grade: '',
          section: '',
        },
      };
    case types.EDIT_DISCCUSION_POST:
      return {
        ...state,
        hasEdited: '',
      };
    case types.UPADATE_DISCCUSION_POST_SUCCESS:
      return {
        ...state,
        hasEdited: true,
      };
    case types.UPADATE_DISCCUSION_POST_FAILURE:
      return {
        ...state,
        hasEdited: false,
      };
    case types.EDIT_DISCCUSION_POST_SUCCESS:
      return {
        ...state,
        postEditData: action.data,
        hasEdited: true,
      };
    case types.EDIT_DISCCUSION_POST_FAILURE:
      return {
        ...state,
        postEditData: action.data,
        hasEdited: false,
      };
    case types.EDIT_CATEGORI_DATA:
      return {
        ...state,
        editCategoryData: action.payload,
      };
    case types.FETCH_CATEGORY_DATA:
      return {
        ...state,
        category_data: true
      }
    case types.FETCH_CATEGORY_DATA_SUCCESS:
      return {
        ...state,
        categoryData: action.data
      }
    case types.FETCH_CATEGORY_DATA_FAILURE:
      return {
        ...state,
        categoryData: action.data
      }
    
    case types.FETCH_CATEGORY_LIST:
      return {
        ...state,
        category_list: true
      }
    case types.FETCH_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        categoryList: action.data
      }
    case types.FETCH_CATEGORY_LIST_FAILURE:
      return {
        ...state,
        categoryList: action.data
      }
    
    case types.FETCH_SUB_CATEGORY_LIST:
      return {
        ...state,
        sub_category_list: true
      }
    case types.FETCH_SUB_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        subCategoryList: action.data
      }
    case types.FETCH_SUB_CATEGORY_LIST_FAILURE:
      return {
        ...state,
        subCategoryList: action.data
      }
    
    case types.FETCH_SUB_SUB_CATEGORY_LIST:
      return {
        ...state,
        sub_sub_category_list: true
      }
    case types.FETCH_SUB_SUB_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        subSubCategoryList: action.data
      }
    case types.FETCH_SUB_SUB_CATEGORY_LIST_FAILURE:
      return {
        ...state,
        subSubCategoryList: action.data
      }
    case types.UPDATE_CATAGORY_SUCCESS:
      return {
        ...state,
        updateCategory: action.data
      }
    case types.UPDATE_CATAGORY_DATA:
      return {
        ...state,
        updateCategory: ''
      }
    case types.CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryCreadted: action.data
      }
    case types.NEW_CATEGORY_CREATED:
      return {
        ...state,
        categoryCreadted: ''
      }
    case types.CATEGORI_PAGE_COUNT:
      return {
        ...state,
        categoryPageCount: action.count
      }
    default:
      return state;
  }
};

export default discussionReducer;
