import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Empty,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Tag,
} from 'antd';
import React, { useState, useEffect } from 'react';
import { getArrayValues } from '../../../../utility-functions';
import QuestionPaper from './questionPaper';
import Question from './question';
import axiosInstance from 'v2/config/axios';
import endpoints from 'v2/config/endpoints';
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;
const CreateEditQuestion = ({
  createQuestionmodalData,
  setCreateQuestionmodalData,
  getQuizList,
}) => {
  const [actionLoading, setActionLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const handleCloseCreateQuizModal = () => {
    setCreateQuestionmodalData({
      ...createQuestionmodalData,
      show: false,
      isEdit: {
        show: false,
        id: null,
      },
      data: {
        quizName: '',
        quizInstructions: '',
        quizDuration: null,
        quizDateAndTime: null,
        selectedQuestionPaper: null,
        academicYear: null,
        branch: null,
        grade: null,
        subject: null,
        section: [],
        dropDown: {
          branch: null,
          grade: null,
          subject: null,
          section: null,
        },
      },
      questionPaperModalData: {
        show: false,
        data: {
          allQuestionPaper: null,
          selectedQuestionPaper: null,
          loading: false,
          page: { currentPage: 1, pageSize: 15, totalData: null },
        },
      },
    });
  };
  const handleSectionWiseData = (list) => {
    let myArray = [];
    let testList = list?.map((each) => {
      if (
        createQuestionmodalData.data?.sectionWiseDate?.filter(
          (eachItem) => each?.id === eachItem?.section?.id
        )?.length
      ) {
        myArray.push(
          createQuestionmodalData.data?.sectionWiseDate?.filter(
            (eachItem) => each?.id === eachItem?.section?.id
          )[0]
        );
      } else {
        myArray.push({
          date: null,
          section: each,
        });
      }
    });
    return myArray;
  };
  console.log({ createQuestionmodalData });
  const handleDropdownChange = (keyname, value, reason) => {
    if (reason === 'quiz') {
      if (keyname === 'branch') {
        setCreateQuestionmodalData((prev) => ({
          ...prev,
          data: {
            ...prev.data,
            branch: value ? value.optionsData : null,
            grade: null,
            subject: null,
            section: [],
            dropDown: {
              ...prev.data.dropDown,
              grade: [],
              section: [],
              subject: [],
            },
          },
        }));
        return;
      }
      if (keyname === 'grade') {
        setCreateQuestionmodalData((prev) => ({
          ...prev,
          data: {
            ...prev.data,
            grade: value ? value.optionsData : null,
            subject: null,
            section: [],
            dropDown: {
              ...prev.data.dropDown,
              section: [],
              subject: [],
            },
          },
        }));
        return;
      }
      if (keyname === 'subject') {
        setCreateQuestionmodalData((prev) => ({
          ...prev,
          data: {
            ...prev.data,
            subject: value ? value.optionsData : null,
          },
        }));
        return;
      }
      if (keyname === 'section') {
        setCreateQuestionmodalData((prev) => ({
          ...prev,
          data: {
            ...prev.data,
            subject: null,
            section: value ? getArrayValues(value, 'optionsData') : [],
            isSectionWiseDate: value?.length ? prev.data?.isSectionWiseDate : false,
            sectionWiseDate:
              value?.length && prev.data?.isSectionWiseDate
                ? handleSectionWiseData(getArrayValues(value, 'optionsData'))
                : null,
          },
          dropDown: {
            ...prev.data.dropDown,
            subject: [],
          },
        }));
        return;
      }
      if (keyname === 'quizName') {
        setCreateQuestionmodalData({
          ...createQuestionmodalData,
          data: {
            ...createQuestionmodalData.data,
            quizName: value ? value : '',
          },
        });
        return;
      }
      if (keyname === 'quizInstructions') {
        setCreateQuestionmodalData({
          ...createQuestionmodalData,
          data: {
            ...createQuestionmodalData.data,
            quizInstructions: value ? value : '',
          },
        });
        return;
      }
      setCreateQuestionmodalData({
        ...createQuestionmodalData,
        data: {
          ...createQuestionmodalData.data,
          [keyname]: value?.optionsData || value,
        },
      });
    }
  };

  const handleSelectQuestionPaperModal = () => {
    if (!createQuestionmodalData?.data?.branch) {
      message.error('Please select branch');
      return;
    }
    if (!createQuestionmodalData?.data?.grade) {
      message.error('Please select grade');
      return;
    }
    if (!createQuestionmodalData?.data?.subject) {
      message.error('Please select subject');
      return;
    }
    setCreateQuestionmodalData({
      ...createQuestionmodalData,
      questionPaperModalData: {
        ...createQuestionmodalData?.questionPaperModalData,
        show: true,
      },
    });
  };

  const handleSelectQuestionPaper = (questionPaper) => {
    setCreateQuestionmodalData({
      ...createQuestionmodalData,
      questionPaperModalData: {
        ...createQuestionmodalData?.questionPaperModalData,
        data: {
          ...createQuestionmodalData?.questionPaperModalData?.data,
          selectedQuestionPaper: questionPaper,
        },
      },
    });
  };
  const handleQuestionPaperMoveToQuiz = () => {
    setAddLoading(true);
    axiosInstance
      .get(endpoints.clicker.questionPaperList, {
        params: {
          qp_id:
            createQuestionmodalData?.questionPaperModalData?.data?.selectedQuestionPaper
              ?.id,
        },
      })
      .then((res) => {
        setCreateQuestionmodalData({
          ...createQuestionmodalData,
          data: {
            ...createQuestionmodalData?.data,
            selectedQuestionPaper: res?.data?.data?.result?.results?.[0],
          },
          questionPaperModalData: {
            show: false,
            data: {
              allQuestionPaper: null,
              selectedQuestionPaper: null,
              loading: false,
              page: { currentPage: 1, pageSize: 10, totalData: null },
            },
          },
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setAddLoading(false);
      });
  };

  const handleDateSectionWise = (checked) => {
    if (!createQuestionmodalData?.data?.section?.length) {
      message.error('Please select any section');
      return;
    }
    setCreateQuestionmodalData({
      ...createQuestionmodalData,
      data: {
        ...createQuestionmodalData?.data,
        isSectionWiseDate: checked,
        sectionWiseDate: !checked
          ? null
          : createQuestionmodalData?.data?.section?.map((eachSection) => {
              return { date: null, section: eachSection };
            }),
      },
    });
  };

  const handleChangeDateSectionWise = (value, index) => {
    setCreateQuestionmodalData({
      ...createQuestionmodalData,
      data: {
        ...createQuestionmodalData?.data,
        sectionWiseDate: createQuestionmodalData?.data?.sectionWiseDate?.map(
          (eachSection, i) => {
            return i === index ? { ...eachSection, date: value } : eachSection;
          }
        ),
      },
    });
  };

  const checkvalidation = () => {
    if (!createQuestionmodalData?.data?.branch) {
      message.error('Please select branch');
      return;
    }
    if (!createQuestionmodalData?.data?.grade) {
      message.error('Please select grade');
      return;
    }
    if (!createQuestionmodalData?.data?.section?.length) {
      message.error('Please select section');
      return;
    }
    if (!createQuestionmodalData?.data?.subject) {
      message.error('Please select subject');
      return;
    }

    if (!createQuestionmodalData?.data?.quizName) {
      message.error('Please enter quiz title');
      return;
    }
    if (!createQuestionmodalData?.data?.quizInstructions) {
      message.error('Please enter instructions');
      return;
    }
    if (!createQuestionmodalData?.data?.selectedQuestionPaper?.questions?.length) {
      message.error('Please select question paper');
      return;
    }
    if (!createQuestionmodalData?.data?.quizDuration) {
      message.error('Please select quiz duration');
      return;
    }
    if (
      !createQuestionmodalData?.data?.selectedQuestionPaper?.questions?.every((eachQ) => {
        return eachQ?.question_mark > 0;
      })
    ) {
      message.error('question mark can not be less than 0');
      return;
    }
    if (
      !Number.isInteger(
        createQuestionmodalData?.data?.selectedQuestionPaper?.questions?.reduce(
          (acc, eachQ) => {
            return acc + (eachQ?.question_mark || 0);
          },
          0
        )
      )
    ) {
      message.error('Sum of all total question marks must be an integer');
      return;
    }
    if (
      !createQuestionmodalData?.data?.isSectionWiseDate &&
      !createQuestionmodalData?.data?.quizDateAndTime
    ) {
      message.error('Please enter quiz date and time');
      return;
    }
    if (
      createQuestionmodalData?.data?.isSectionWiseDate &&
      !createQuestionmodalData?.data?.sectionWiseDate?.every((eachSec) => {
        return eachSec?.date !== null;
      })
    ) {
      message.error('Please select question quiz duration for every section');
      return;
    }
    return true;
  };

  const createQuiz = () => {
    let validate = checkvalidation();
    if (!validate) {
      return;
    }
    let payload = {
      grade: createQuestionmodalData?.data?.grade?.grade_id,
      subject: createQuestionmodalData?.data?.subject?.subject_id,
      acad_session: createQuestionmodalData?.data?.branch?.id,
      // question_paper: 9,
      // section_mapping: [{ test_date: '2024-07-31T15:13', section_mapping: [5167] }],
      section_mapping: createQuestionmodalData?.data?.isSectionWiseDate
        ? createQuestionmodalData?.data?.sectionWiseDate?.map((eachSec) => {
            return {
              test_date: moment(eachSec?.date).format('YYYY-MM-DDTHH:mm'),
              section_mapping: [eachSec?.section?.id],
            };
          })
        : getArrayValues(createQuestionmodalData?.data?.section, 'id'),
      is_section_wise_date: createQuestionmodalData?.data?.isSectionWiseDate,
      test_date: !createQuestionmodalData?.data?.isSectionWiseDate
        ? moment(createQuestionmodalData?.data?.quizDateAndTime).format(
            'YYYY-MM-DDTHH:mm'
          )
        : null,
      question_paper_id: createQuestionmodalData?.data?.selectedQuestionPaper?.id,
      test_duration: createQuestionmodalData?.data?.quizDuration,
      instructions: createQuestionmodalData?.data?.quizInstructions,
      test_name: createQuestionmodalData?.data?.quizName,

      test_mark: createQuestionmodalData?.data?.selectedQuestionPaper?.questions?.map(
        (eachQ) => {
          return { question_id: eachQ?.id, question_mark: eachQ?.question_mark };
        }
      ),
      question_details: createQuestionmodalData?.data?.selectedQuestionPaper?.questions,
    };
    setActionLoading(true);
    axiosInstance
      .post(endpoints.clicker.quizList, payload)
      .then((res) => {
        if (res.data.status_code === 200) {
          message.success(res.data.message);
          // getQuizList();
          handleCloseCreateQuizModal();
        } else if (res.data.status_code === 409) {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message ?? err?.message);
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const updateQuiz = () => {
    let validate = checkvalidation();
    if (!validate) {
      return;
    }
    let payload = {
      test_id: createQuestionmodalData?.isEdit?.id,
      test_date: moment(createQuestionmodalData?.data?.quizDateAndTime).format(
        'YYYY-MM-DDTHH:mm'
      ),
      test_name: createQuestionmodalData?.data?.quizName,
      instructions: createQuestionmodalData?.data?.quizInstructions,
      test_duration: createQuestionmodalData?.data?.quizDuration,
    };
    setActionLoading(true);
    axiosInstance
      .put(endpoints.clicker.quizList, payload)
      .then((res) => {
        if (res.data.status_code === 200) {
          message.success(res.data.message);
          getQuizList();
          handleCloseCreateQuizModal();
        } else if (res.data.status_code === 409) {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message ?? err?.message);
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const branchOptions = createQuestionmodalData?.data?.dropDown?.branch?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id} optionsData={el}>
        {el?.branch?.branch_name}
      </Option>
    );
  });
  const gradeOptions = createQuestionmodalData?.data?.dropDown?.grade?.map((el) => {
    return (
      <Option key={el?.grade_id} id={el?.grade_id} value={el.grade_id} optionsData={el}>
        {el?.grade_name}
      </Option>
    );
  });
  const subjectOptions = createQuestionmodalData?.data?.dropDown?.subject?.map((el) => {
    return (
      <Option
        key={el?.subject_id}
        id={el?.subject_id}
        value={el.subject_id}
        optionsData={el}
      >
        {el?.subject__subject_name}
      </Option>
    );
  });
  const sectionOptions = createQuestionmodalData?.data?.dropDown?.section?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id} optionsData={el}>
        {el?.sec_name}
      </Option>
    );
  });
  return (
    <>
      <Drawer
        title={
          createQuestionmodalData?.isEdit?.show
            ? 'Edit Clicker Quiz'
            : 'Create Clicker Quiz'
        }
        destroyOnClose={true}
        maskClosable={false}
        size='large'
        onClose={() => handleCloseCreateQuizModal()}
        visible={createQuestionmodalData?.show}
        footer={
          <>
            <Space>
              <Button
                type='primary'
                onClick={() =>
                  createQuestionmodalData?.isEdit?.show ? updateQuiz() : createQuiz()
                }
                loading={actionLoading}
              >
                {createQuestionmodalData?.isEdit?.show ? 'Update Quiz' : 'Create Quiz'}
              </Button>
              {/* <Button>Create Question as Draft</Button> */}
            </Space>
          </>
        }
      >
        <div className='th-bg-grey th-br-8 p-3 mb-4'>
          <Row gutter={[8, 8]}>
            <Col md={6}>
              <label htmlFor='branch' className='' title='Branch*'>
                Branch *
              </label>
              <Select
                showSearch
                placeholder='Select Branch*'
                optionFilterProp='children'
                className='w-100'
                allowClear
                getPopupContainer={(trigger) => trigger.parentNode}
                l
                dropdownMatchSelectWidth={false}
                filterOption={(input, options) => {
                  return options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                disabled={createQuestionmodalData?.isEdit?.show}
                value={createQuestionmodalData?.data?.branch?.id ?? null}
                onChange={(e, row) => handleDropdownChange('branch', row, 'quiz')}
              >
                {branchOptions}
              </Select>
            </Col>
            <Col md={6}>
              <label htmlFor='grade' className='' title='Grade*'>
                Grade *
              </label>
              <Select
                showSearch
                placeholder='Select Grade*'
                optionFilterProp='children'
                className='w-100'
                allowClear
                getPopupContainer={(trigger) => trigger.parentNode}
                l
                dropdownMatchSelectWidth={false}
                filterOption={(input, options) => {
                  return options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                disabled={createQuestionmodalData?.isEdit?.show}
                value={createQuestionmodalData?.data?.grade?.grade_id ?? null}
                onChange={(e, row) => handleDropdownChange('grade', row, 'quiz')}
              >
                {gradeOptions}
              </Select>
            </Col>
            <Col md={6}>
              <label htmlFor='section' className='' title='Section*'>
                Section *
              </label>
              <Select
                showSearch
                placeholder='Select Section*'
                optionFilterProp='children'
                className='w-100'
                allowClear
                showArrow
                getPopupContainer={(trigger) => trigger.parentNode}
                mode='multiple'
                dropdownMatchSelectWidth={false}
                filterOption={(input, options) => {
                  return options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                disabled={createQuestionmodalData?.isEdit?.show}
                maxTagCount={1}
                value={
                  createQuestionmodalData?.data?.section
                    ? getArrayValues(createQuestionmodalData?.data?.section, 'id')
                    : []
                }
                onChange={(e, row) => handleDropdownChange('section', row, 'quiz')}
              >
                {sectionOptions}
              </Select>
            </Col>
            <Col md={6}>
              <label htmlFor='subject' className='' title='Subject*'>
                Subject *
              </label>
              <Select
                showSearch
                placeholder='Select Subject*'
                optionFilterProp='children'
                className='w-100'
                allowClear
                getPopupContainer={(trigger) => trigger.parentNode}
                l
                dropdownMatchSelectWidth={false}
                filterOption={(input, options) => {
                  return options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                disabled={createQuestionmodalData?.isEdit?.show}
                value={createQuestionmodalData?.data?.subject?.subject_id ?? null}
                onChange={(e, row) => handleDropdownChange('subject', row, 'quiz')}
              >
                {subjectOptions}
              </Select>
            </Col>

            <Col md={24}>
              <label htmlFor='Subject' className='' title='Subject*'>
                Title *
              </label>
              <Input
                placeholder='Quiz Title'
                value={createQuestionmodalData?.data?.quizName ?? ''}
                maxLength={200}
                showCount
                onChange={(e) => handleDropdownChange('quizName', e.target.value, 'quiz')}
              />
            </Col>
            <Col md={24}>
              <label htmlFor='Description' className='' title='Title*'>
                Instruction *
              </label>
              <TextArea
                placeholder='Quiz Instruction'
                rows={3}
                maxLength={500}
                showCount
                value={createQuestionmodalData?.data?.quizInstructions ?? ''}
                onChange={(e) =>
                  handleDropdownChange('quizInstructions', e.target.value, 'quiz')
                }
              />
            </Col>
            <Col md={12}>
              <label htmlFor='QuizDuration' className='' title='Quiz Duration *'>
                Quiz Duration *
              </label>
              <InputNumber
                className='w-100'
                controls={false}
                placeholder='Quiz Duration (in mins)'
                onChange={(value) => {
                  handleDropdownChange('quizDuration', value, 'quiz');
                }}
                value={createQuestionmodalData?.data?.quizDuration}
                precision={2}
                min={1}
                max={180}
              />
            </Col>

            <Col md={12}></Col>
            <Col md={12}>
              <label htmlFor='QuizDuration' className='' title='Quiz Duration *'>
                Quiz Date and Time *{' '}
                <Switch
                  checked={createQuestionmodalData?.data?.isSectionWiseDate}
                  checkedChildren='Section Wise'
                  unCheckedChildren='Common'
                  disabled={createQuestionmodalData?.isEdit?.show}
                  onChange={(checked) => handleDateSectionWise(checked)}
                />
              </label>
              {!createQuestionmodalData.data?.sectionWiseDate && (
                <DatePicker
                  className='w-100'
                  onChange={(date, dateString) =>
                    handleDropdownChange('quizDateAndTime', date, 'quiz')
                  }
                  value={createQuestionmodalData?.data?.quizDateAndTime}
                  format='YYYY-MM-DD HH:mm'
                  showTime={true}
                />
              )}
            </Col>
            {createQuestionmodalData.data?.sectionWiseDate && <Col md={12}></Col>}
            {createQuestionmodalData.data?.sectionWiseDate &&
              createQuestionmodalData.data?.sectionWiseDate?.map((eachSection, index) => {
                return (
                  <>
                    <Col md={12}>
                      <Select
                        value={eachSection?.section?.id}
                        className='w-100'
                        disabled
                        options={[
                          {
                            value: eachSection?.section?.id,
                            label: eachSection?.section?.sec_name,
                          },
                        ]}
                      />
                    </Col>
                    <Col md={12}>
                      <DatePicker
                        className='w-100'
                        onChange={(date, dateString) =>
                          handleChangeDateSectionWise(date, index)
                        }
                        value={eachSection?.date}
                        format='YYYY-MM-DD HH:mm'
                        showTime={true}
                      />
                    </Col>
                  </>
                );
              })}
          </Row>
        </div>
        <div>
          <Button
            type='primary'
            className='th-br-4  float-right'
            onClick={() => handleSelectQuestionPaperModal()}
          >
            Select Question Paper
          </Button>
        </div>
        <div className='mt-3'>
          {createQuestionmodalData?.data?.selectedQuestionPaper?.questions?.map(
            (eachQuestion, index) => {
              return (
                <Question
                  currentQuestion={eachQuestion}
                  index={index}
                  key={index}
                  createQuestionmodalData={createQuestionmodalData}
                  setCreateQuestionmodalData={setCreateQuestionmodalData}
                  onlyShowQuestion={false}
                />
              );
            }
          )}
        </div>

        <Drawer
          title='Select Question Paper'
          destroyOnClose={true}
          maskClosable={false}
          width={'40%'}
          onClose={() =>
            setCreateQuestionmodalData({
              ...createQuestionmodalData,
              questionPaperModalData: {
                show: false,
                data: {
                  allQuestionPaper: null,
                  selectedQuestionPaper: null,
                  loading: false,
                  page: { currentPage: 1, pageSize: 15, totalData: null },
                },
              },
            })
          }
          visible={createQuestionmodalData?.questionPaperModalData?.show}
          footer={
            <>
              <Space>
                <Button
                  type='primary'
                  loading={addLoading}
                  onClick={() => handleQuestionPaperMoveToQuiz()}
                  disabled={
                    !createQuestionmodalData?.questionPaperModalData?.data
                      ?.selectedQuestionPaper?.id
                  }
                >
                  Add to Quiz
                </Button>
              </Space>
            </>
          }
        >
          <div>
            <Spin
              tip='Loading...'
              size='large'
              spinning={createQuestionmodalData?.questionPaperModalData?.data?.loading}
            >
              <>
                {Array.isArray(
                  createQuestionmodalData?.questionPaperModalData?.data?.allQuestionPaper
                ) &&
                createQuestionmodalData?.questionPaperModalData?.data?.allQuestionPaper
                  ?.length > 0 ? (
                  createQuestionmodalData?.questionPaperModalData?.data?.allQuestionPaper?.map(
                    (item, index) => {
                      return (
                        <QuestionPaper
                          currentPaper={item}
                          index={index}
                          key={index}
                          handleSelectQuestionPaper={handleSelectQuestionPaper}
                          selectedPaper={
                            createQuestionmodalData?.questionPaperModalData?.data
                              ?.selectedQuestionPaper
                          }
                        />
                      );
                    }
                  )
                ) : (
                  <div
                    className='row justify-content-center align-items-center'
                    style={{ height: 300 }}
                  >
                    <div className='col-12 text-center'>
                      <Spin
                        tip='Loading...'
                        size='large'
                        spinning={
                          createQuestionmodalData?.questionPaperModalData?.data?.loading
                        }
                      ></Spin>
                      {createQuestionmodalData?.questionPaperModalData?.data
                        ?.loading ? null : (
                        <Empty description={'No question Paper found'} />
                      )}
                    </div>
                  </div>
                )}

                {createQuestionmodalData?.questionPaperModalData?.data?.allQuestionPaper
                  ?.length > 0 && (
                  <div className='row justify-content-center'>
                    <div className='col-12'>
                      <div className='text-center'>
                        {/* <Pagination
                    current={pageNo}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    pageSize={pageLimit}
                    total={totalPage}
                    style={{ justifyContent: 'center' }}
                    onChange={(currentPage) => {
                      setPageNo(currentPage);
                      fetchQuestionPaperList({
                        ...questionFilter,
                        page: currentPage,
                      });
                    }}
                  /> */}
                      </div>
                    </div>
                  </div>
                )}
              </>
            </Spin>
          </div>
        </Drawer>
      </Drawer>
    </>
  );
};

export default CreateEditQuestion;
